/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LogarithmicScale,
  Filler,
} from 'chart.js';
import { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';

import {
  ChartBox,
  ChartContainer,
  ChartTitle,
  FlexBox,
  LabelBox,
  LabelLine,
  LabelTypography,
} from '../styles';

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LogarithmicScale,
  Filler
);

interface AxisProps {
  x: number;
  y: number;
}

interface DataProps {
  title: string;
  color: string;
  data: AxisProps[];
}

interface LineIntervalProps {
  chart: {
    x_axis_title: string;
    y_axis_title: string;
    data: DataProps[];
  };
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
}

export function LineInterval({
  chart,
  chartCallback,
}: LineIntervalProps): JSX.Element {
  const chartRef = useRef<ChartJS<'line', number[], number>>(null);

  const handleSaveImage = (): void => {
    if (chartRef?.current) {
      const { canvas } = chartRef.current;

      if (chartCallback) {
        chartCallback(canvas, chart.x_axis_title);
      }
    }
  };

  useEffect(() => {
    if (chartRef !== null && chartCallback) {
      handleSaveImage();
    }
  }, [chartRef]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: chart.y_axis_title,
          font: {
            size: 16,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: chart.x_axis_title,
          font: {
            size: 16,
          },
        },
        type: 'linear' as const,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: chart.data[0].data.map((e) => e.x),
    datasets: [
      {
        type: 'line' as const,
        data: chart.data[0].data.map((e) => e.y),
        backgroundColor: chart.data[0].color,
        borderColor: chart.data[0].color,
        pointRadius: 0,
      },
      {
        type: 'line' as const,
        data: chart.data[1].data.map((e) => e.y),
        backgroundColor: chart.data[1].color,
        borderColor: chart.data[1].color,
        pointRadius: 0,
        fill: 0,
        order: 10,
      },
      {
        type: 'line' as const,
        data: chart.data[2].data.map((e) => e.y),
        backgroundColor: chart.data[2].color,
        borderColor: chart.data[2].color,
        pointRadius: 0,
        fill: 0,
        order: 10,
      },
      {
        type: 'line' as const,
        data: chart.data[3].data.map((e) => e.y),
        backgroundColor: chart.data[3].color,
        borderColor: chart.data[3].color,
        pointRadius: 0,
        fill: 0,
      },
      {
        type: 'line' as const,
        data: chart.data[4].data.map((e) => e.y),
        backgroundColor: chart.data[4].color,
        borderColor: chart.data[4].color,
        pointRadius: 0,
        fill: 0,
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartTitle>{chart.x_axis_title}</ChartTitle>
      <FlexBox>
        <LabelBox>
          <LabelTypography>Regressão</LabelTypography>
          <LabelLine sx={{ color: chart.data[0].color }} />
        </LabelBox>
        <LabelBox>
          <LabelTypography>Intervalo de predição</LabelTypography>
          <LabelLine sx={{ color: chart.data[1].color }} />
        </LabelBox>
        <LabelBox>
          <LabelTypography>Intervalo de confiança</LabelTypography>
          <LabelLine sx={{ color: chart.data[3].color }} />
        </LabelBox>
      </FlexBox>
      <ChartBox>
        <Line ref={chartRef} options={options} data={data} />
      </ChartBox>
    </ChartContainer>
  );
}
