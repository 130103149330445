/* eslint-disable react/require-default-props */
import { CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';

import { exportSample } from '../../api/workOrders';
import { IconTableViewMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { ExportButton } from './styles';

interface ExportSampleProps {
  osId: number;
  title?: string;
  responsive?: boolean;
  isDIsabled?: boolean;
}

export function ExportSample({
  osId,
  title = 'exportar dados do cálculo e formulário',
  responsive = false,
  isDIsabled = false,
}: ExportSampleProps): JSX.Element {
  const [loadingSample, setLoadingSample] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { handleDownloadXlsx } = useGeneral();
  const { getErrorMessage } = useErrorMessage();

  const handleExportSamples = async (): Promise<void> => {
    setLoadingSample(true);
    try {
      const response = await exportSample(osId);

      if (response.ok) {
        handleDownloadXlsx(response);
        setLoadingSample(false);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingSample(false);
    }
  };
  return (
    <ExportButton
      id="xlsx-button"
      responsive={responsive ? 'true' : 'false'}
      onClick={handleExportSamples}
      disabled={isDIsabled || loadingSample}
    >
      {loadingSample ? (
        <CircularProgress size={32} />
      ) : (
        <>
          {IconTableViewMS}
          {title}
        </>
      )}
    </ExportButton>
  );
}
