import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableCellProps } from '@mui/material/TableCell';

export const CellCaptionBorder = styled(TableCell)(() => ({
  padding: '12px',
  fontSize: '21px',
  fontWeight: '600',
  color: '#ECF0F6',
  textAlign: 'center',
  borderRight: '1px solid #fff',
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth }) => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  minWidth: minwidth,
  textAlign: 'center',
}));

export const CellMinWidth = styled(TableCell)(({ theme }) => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  color: '#707070',
  backgroundColor: '#FAFCFF',
  [theme.breakpoints.up('xl')]: {
    width: '240px',
  },
}));
