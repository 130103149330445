export const Constants = {
  contract: 'Contrato',
  osNumber: 'número OS',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  upload: 'upload contrato',
  addUpload: 'adicionar novo contrato',
  files: 'Contratos: ',
  clientData: 'Dados do cliente',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  approve: 'aprovar',
  pept: 'enviar para PEPT',
  changeStatus: 'alterar o status da OS ** de Contrato para Agendamento',
};
