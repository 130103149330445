import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SampleContainer = styled(Box)(() => ({
  border: '1px solid #D7D8DB',
  backgroundColor: '#FFFFFF',
  padding: '32px 40px',
  marginTop: '40px',
}));

export const LabelBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const IdLabel = styled(Box)(() => ({
  fontWeight: '600',
  fontSize: '21px',
  backgroundColor: '#ECF0F6',
  width: 180,
  height: 39,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  color: '#2B3550',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: -32,
  left: -40,
}));

export const LevelLabel = styled(Box)(() => ({
  fontWeight: '600',
  backgroundColor: '#2B3550',
  width: 228,
  height: 39,
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  color: '#FCFAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: -32,
  right: -40,
}));

interface InputTitleProps {
  error?: 'true' | undefined;
}

export const InputTitle = styled(Typography)<InputTitleProps>(({ error }) => ({
  color: error && '#FC6C71',
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)<InputTitleProps>(
  ({ theme, error }) => ({
    color: error && '#FC6C71',
    fontWeight: '600',
    fontSize: '18px',
    borderBottom: `1px solid ${error ? '#FC6C71' : '#8B8C8E'}`,
    inlineSize: 'fit-content',
    minWidth: '100px',
    padding: '0 8px',
    minHeight: '28px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
      minHeight: '20px',
    },
  })
);

export const SectionBox = styled(Box)(({ theme }) => ({
  margin: '40px 0',
  [theme.breakpoints.down('xl')]: {
    margin: '20px 0',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  '& span': { color: '#00B39B' },
  [theme.breakpoints.down('xl')]: {
    fontSize: '18px',
  },
}));

export const ErrorButton = styled(Button)(() => ({
  borderRadius: '10px',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  width: '100%',
  color: '#E89710',
  border: '2px solid #E89710',
  '&:hover': {
    backgroundColor: '#E89710',
    '& span': {
      color: 'white',
    },
  },
  '&:active': {
    backgroundColor: '#E89710',
  },
}));

export const StyledProgress = styled(CircularProgress)(() => ({
  color: '#E89710',
}));

export const OutlinedButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const RemovedButton = styled(Button)(() => ({
  right: '-80%',
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #FC6C71',
  color: '#FC6C71',
  backgroundColor: '#FAFCFF',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: '#FC6C71',
  },
  '&:disabled': {
    color: '#FC6C71',
  },
}));

export const ApproveButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
}));

export const StyledIcon = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '21px',
  fontWeight: '600',
  paddingLeft: '10px',
  cursor: 'pointer',
  color: '#00B39B',
}));

export const DescriptionTypography = styled(Typography)(() => ({
  fontWeight: '600',
  margin: '20px 0',
}));

export const DownloadPdf = styled(Button)(() => ({
  width: 210,
  height: 32,
  backgroundColor: '#FAFCFF',
  fontSize: 15,
  fontWeight: '600',
  lineHeight: '20px',
  border: '2px solid #004D43',
  borderRadius: 10,
  color: '#004D43',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#004D43',
  },
  '&:disabled': {
    color: '#004D43',
  },
}));
