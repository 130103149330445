import { Box, Button, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 32px 64px 120px',
  backgroundColor: '#FAFCFF',
}));

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const EditButton = styled(Button)(() => ({
  width: 282,
  height: 40,
  border: '2px solid #00B39B',
  borderRadius: 10,
  color: '#00B39B',
  fontWeight: 600,
  fontSize: 15,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#00B39B',
    '& span': {
      color: '#FAFCFF',
    },
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  marginTop: '32px',
}));

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
