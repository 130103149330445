import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
  },
}));

export const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseIcon = styled(IconButton)(() => ({
  margin: '12px 12px -40px -40px',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#004D43',
  },
}));

export const DialogButton = styled(Button)(({ theme }) => ({
  minWidth: 100,
  border: '1px solid #004D43',
  color: '#004D43',
  backgroundColor: 'transparent',
  marginRight: 20,
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
  [theme.breakpoints.down('xl')]: {
    width: 200,
    padding: 16,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  margin: '0 0 20px',
  '& span': { color: '#00B39B' },
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  minWidth: 100,
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
  [theme.breakpoints.down('xl')]: {
    width: 200,
    padding: 16,
  },
}));
