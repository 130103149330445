export const Constants = {
  title: 'Plataforma de Controle',
  buttonAdd: 'adicionar nova OS',
  TVMode: 'Modo TV',
  DesktopMode: 'Modo desktop',
  os: 'número OS:',
  client: 'cliente:',
  clientType: 'tipo do cliente:',
  address: 'localização:',
  report: 'tipo de laudo:',
  goal: 'objetivo:',
  function: 'finalidade:',
  inspector: 'vistoriador:',
  schedule: 'agendamento:',
  limitFinish: 'limite para entrega:',
  reason: 'motivo:',
};
