import { Divider, List, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import { IconSearchMS } from '../../../../../constants/icons';
import alpha from '../../icons/alpha.png';
import beta from '../../icons/beta.png';
import delta from '../../icons/delta.png';
import gama from '../../icons/gama.png';
import omega from '../../icons/omega.png';
import logoRealPrice from '../../icons/realprice.svg';
import theta from '../../icons/theta.png';
import {
  BoxesContainer,
  CitiesServerd,
  CompanyDescription,
  CompanyTextDescription,
  Container,
  DescriptionContainer,
  HeaderDescription,
  ListCompanies,
  ListItemStyled,
  SearchContainer,
  SelectContainer,
  SelectStyled,
} from './styles';

export function Location(): JSX.Element {
  const [value, setValue] = useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mockData = [
    {
      name: 'Real Price',
      icon: logoRealPrice,
    },
    {
      name: 'Empresa Alpha',
      icon: alpha,
    },
    {
      name: 'Empresa Beta',
      icon: beta,
    },
    {
      name: 'Empresa Gama',
      icon: gama,
    },
    {
      name: 'Empresa Ômega',
      icon: omega,
    },
    {
      name: 'Empresa Delta',
      icon: delta,
    },
    {
      name: 'Empresa Theta',
      icon: theta,
    },
  ];

  useEffect(() => {
    setValue(mockData[0].name);
  }, [mockData]);
  return (
    <Container>
      <SelectContainer>
        <SelectStyled defaultValue="São Paulo">
          <MenuItem value="São Paulo">São Paulo</MenuItem>
          <MenuItem value="Curitiba">Curitiba</MenuItem>
          <MenuItem value="Rio de Janeiro">Rio de Janeiro</MenuItem>
        </SelectStyled>
        <SelectStyled defaultValue="São Paulo">
          <MenuItem value="São Paulo">São Paulo</MenuItem>
          <MenuItem value="Curitiba">Curitiba</MenuItem>
          <MenuItem value="Rio de Janeiro">Rio de Janeiro</MenuItem>
        </SelectStyled>
        <SelectStyled defaultValue="Zona Oeste">
          <MenuItem value="Zona Oeste">Zona Oeste</MenuItem>
          <MenuItem value="Zona Sul">Zona Sul</MenuItem>
          <MenuItem value="Zona Leste">Zona Leste</MenuItem>
          <MenuItem value="Zona Norte">Zona Norte</MenuItem>
        </SelectStyled>
      </SelectContainer>
      <BoxesContainer>
        <ListCompanies>
          <SearchContainer>
            {IconSearchMS}
            <input placeholder="buscar informação de contato" />
            <button type="button">buscar</button>
          </SearchContainer>
          <List>
            {mockData.map((company) => (
              <ListItemStyled
                key={company.name}
                className={company.name === value ? 'active' : ''}
                onClick={() => setValue(company.name)}
              >
                <img src={company.icon} alt={company.name} />
                <p>{company.name}</p>
              </ListItemStyled>
            ))}
          </List>
        </ListCompanies>
        <CompanyDescription>
          <HeaderDescription>
            <img src={logoRealPrice} alt="" style={{ height: 100 }} />
            <h1>Real Price</h1>
          </HeaderDescription>
          <Divider sx={{ width: '90%' }} />
          <DescriptionContainer>
            <CitiesServerd>
              <p>Cidades atendidas:</p>
              <p>São Paulo - SP</p>
              <p>Boituva - SP</p>
              <p>Brotas - SP</p>
              <p>Botucatu - SP</p>
              <p>Barra - SP</p>
            </CitiesServerd>
            <CompanyTextDescription>
              <p>
                CNPJ: <strong>11.222.333/0001-00</strong>
              </p>
              <p>
                Email: <strong>realprice@contato.com.br</strong>
              </p>
              <p>
                Telefone: <strong>(11) 9 9999-6666</strong>
              </p>
              <p>
                CEP: <strong>05003-020</strong>
              </p>
              <p>
                Logradouro:{' '}
                <strong>Av. Antártica, 675 - Sala 1502 - Água Branca.</strong>
              </p>
              <p>
                Cidade: <strong>São Paulo - SP</strong>
              </p>
            </CompanyTextDescription>
          </DescriptionContainer>
        </CompanyDescription>
      </BoxesContainer>
    </Container>
  );
}
