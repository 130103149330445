import { Checkbox, FormControl, Grid, Radio, RadioGroup } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { QuestionType } from '../../../../api/enumerations';
import { QuestionFormData } from '../../../../api/inspections/types';
import {
  CheckboxFormControl,
  FlexBox,
  FlexCenterGrid,
  ListTextField,
  MultiFormControl,
  NumberTypography,
  PaperQuestion,
  ShortAnswerTextField,
  StyledDatePicker,
  TitleTypography,
} from './styles';

interface QuestionProps {
  question: QuestionFormData;
  questionNum: number;
}

export function Question({
  question,
  questionNum,
}: QuestionProps): JSX.Element {
  const [answer, setAnswer] = useState<string | number>();
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (!question.answer) {
      setAnswer('');
      return;
    }
    if (Array.isArray(question.answer)) {
      const questionOptions = question.choices.map((data) => {
        const questionAnswer = question.answer as number[];
        const hasAnswer = questionAnswer.find((e) => e === data.id);
        return !!hasAnswer;
      });
      setCheckboxValues(questionOptions);
    } else {
      setAnswer(question.answer);
      if (question.type === QuestionType.DATE) {
        const formatedDate = dayjs(question.answer);
        setDate(formatedDate);
      }
    }
  }, [question]);

  return (
    <PaperQuestion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox>
            <NumberTypography>{questionNum}. </NumberTypography>
            <TitleTypography>{question.title}</TitleTypography>
          </FlexBox>
        </Grid>
        {question.type === QuestionType.SHORT_ANSWER && (
          <FlexCenterGrid item xs={12}>
            <ShortAnswerTextField
              id="short-answer"
              multiline
              rows={3}
              label="resposta curta"
              color="secondary"
              value={answer}
              disabled
            />
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.SINGLE_SELECTION && (
          <Grid item xs={12}>
            <FormControl disabled>
              <RadioGroup value={answer}>
                {question.choices.map((opt) => (
                  <MultiFormControl
                    key={opt.id}
                    value={opt.id}
                    label={opt.title}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {question.type === QuestionType.MULTIPLE_CHOICE && (
          <>
            {question.choices.map((opt, i) => (
              <Grid item xs={12} key={opt.id}>
                <CheckboxFormControl
                  label={opt.title}
                  control={
                    <Checkbox
                      checked={checkboxValues[i] || false}
                      value={opt.id}
                      color="primary"
                      disabled
                    />
                  }
                />
              </Grid>
            ))}
          </>
        )}
        {question.type === QuestionType.LIST && (
          <ListTextField
            id="question-list"
            select
            label="selecione"
            color="secondary"
            value={answer}
            disabled
          />
        )}
        {question.type === QuestionType.DATE && (
          <FlexCenterGrid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="data"
                format="DD-MM-YYYY"
                sx={StyledDatePicker}
                value={date}
                disabled
              />
            </LocalizationProvider>
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.NUMERIC && (
          <FlexCenterGrid item xs={12}>
            <NumericFormat
              customInput={ShortAnswerTextField}
              id="numeric"
              label="resposta numérica"
              color="secondary"
              allowNegative={false}
              decimalScale={0}
              allowLeadingZeros
              value={answer}
              disabled
            />
          </FlexCenterGrid>
        )}
      </Grid>
    </PaperQuestion>
  );
}
