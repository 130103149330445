/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';

import { PropertyType, StatusCode } from '../../../../api/enumerations';
import {
  approveElement,
  getAllElementPictures,
  getElementPictures,
  deleteElement,
  getOriginalElement,
} from '../../../../api/samples';
import { ElementData, PicturesData } from '../../../../api/samples/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';

interface UseElementCardHook {
  initialPictures: PicturesData[] | undefined;
  allPictures: PicturesData[] | undefined;
  age: number;
  setAge: (value: number) => void;
  handleConservationEstimate: () => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  landArea: number;
  setLandArea: (value: number) => void;
  disableInfo: boolean;
  isApproved: boolean;
  estimatedConservation: boolean;
  setEstimatedConservation: (value: boolean) => void;
  showDescription: boolean;
  setShowDescription: (value: boolean) => void;
  openCheckError: boolean;
  setOpenCheckError: (value: boolean) => void;
  openErrorDialog: boolean;
  setOpenErrorDialog: (value: boolean) => void;
  loadingErrorReport: boolean;
  loadingMorePictures: boolean;
  updatedPictures: boolean;
  handleApproveElement: () => Promise<void>;
  handleDeleteElement: () => void;
  handleOriginalElement: (value: boolean) => Promise<void>;
  handleDisapproveElement: () => Promise<void>;
  getMorePictures: () => Promise<void>;
  loadingApproveElement: boolean;
  loadingDeleteElement: boolean;
  housePropertyType: boolean;
}

export default function useElementCard(
  disapprovedElements: number[],
  setDisapprovedElements: (value: number[]) => void,
  sampleData: ElementData[],
  setSampleData: (sample: ElementData[]) => void,
  elementData: ElementData,
  propertyData: WorkOrderData,
  sampleId: number,
  updateSample: () => void,
  page: number,
  setPage: (value: number) => void,
  goToLastPage: boolean,
  deactivateFilters?: () => void
): UseElementCardHook {
  const [age, setAge] = useState<number>(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [disableInfo, setDisableInfo] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [estimatedConservation, setEstimatedConservation] = useState(false);
  const [initialPictures, setInitialPictures] = useState<PicturesData[]>([]);
  const [allPictures, setAllPictures] = useState<PicturesData[]>([]);
  const [showDescription, setShowDescription] = useState(false);
  const [openCheckError, setOpenCheckError] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [loadingApproveElement, setLoadingApproveElement] = useState(false);
  const [loadingErrorReport, setLoadingErrorReport] = useState(false);
  const [loadingMorePictures, setLoadingMorePictures] = useState(false);
  const [updatedPictures, setUpdatedPictures] = useState(false);
  const [loadingDeleteElement, setLoadingDeleteElement] = useState(false);
  const { description } = elementData;
  const housePropertyType =
    propertyData.real_estate_kind === PropertyType.HOUSE;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleConservationEstimate = (): void => {
    setEstimatedConservation(true);

    if (age < 2) {
      setConservation(1);
    }
    if (age >= 2 && age < 5) {
      setConservation(2);
    }
    if (age >= 5 && age < 15) {
      setConservation(3);
    }
    if (age >= 15 && age < 25) {
      setConservation(4);
    }
    if (age >= 25 && age < 35) {
      setConservation(5);
    }
    if (age >= 35 && age < 45) {
      setConservation(6);
    }
    if (age >= 45) {
      setConservation(7);
    }
  };

  const handleApproveElement = async (): Promise<void> => {
    if (!estimatedConservation) {
      setSnackbarMessage('Calcule o estado de conservação!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (age === null) {
      setSnackbarMessage('Confirme a idade do imóvel!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!buildingStandard) {
      setSnackbarMessage('Selecione o padrão construtivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (housePropertyType && landArea === 0) {
      setSnackbarMessage('Forneça o valor da área do terreno!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLoadingApproveElement(true);

    if (housePropertyType) {
      description.land_area = landArea;
    }

    const approveData = {
      age,
      constructive_standard: buildingStandard,
      preservation_state: conservation,
      description: JSON.stringify(elementData?.description),
    };

    try {
      const response = await approveElement(elementData.id, approveData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLoadingApproveElement(false);
      setIsApproved(true);
      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);

      if (disapprovedElements) {
        if (
          goToLastPage &&
          deactivateFilters &&
          elementData.description.level === 1
        ) {
          deactivateFilters();
          setPage(1);
        }

        const removeId = disapprovedElements.filter((element) => {
          return element !== sampleId;
        });
        setDisapprovedElements(removeId);
      }

      if (sampleData) {
        const changeElement = sampleData.find((element) => {
          return element === elementData;
        });
        if (changeElement) {
          changeElement.approved = true;
          changeElement.age = age;
          changeElement.preservation_state = conservation;
          changeElement.constructive_standard = buildingStandard;
          const newData = [...sampleData];
          setSampleData(newData);
        }
      }

      setDisableInfo(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingApproveElement(false);
    }
  };

  const getDataCallback = useCallback(async () => {
    setAge(elementData.age ? elementData.age : 0);
    setLandArea(description.land_area ? description.land_area : 0);
    if (elementData.constructive_standard) {
      setBuildingStandard(elementData.constructive_standard);
    }
    if (elementData.preservation_state) {
      setConservation(elementData.preservation_state);
    }
    if (elementData.approved) {
      setDisableInfo(true);
      setIsApproved(true);
    }

    try {
      const response = await getElementPictures(elementData.id, 1, 6);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setInitialPictures(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [elementData, setAge]);

  useEffect(() => {
    setUpdatedPictures(false);
    setAllPictures([]);
    setIsApproved(false);
    setDisableInfo(false);
    getDataCallback();
  }, [getDataCallback, elementData]);

  useEffect(() => {
    setEstimatedConservation(false);
  }, [age]);

  const handleDeleteElement = useCallback(async () => {
    setLoadingDeleteElement(true);

    try {
      const response = await deleteElement(elementData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Elemento excluído com sucesso!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setLoadingDeleteElement(false);
      if (goToLastPage) {
        setPage(page - 1);
      }
      updateSample();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingDeleteElement(false);
    }
  }, [setSnackbarMessage, setOpenSnackbar, setErrorMessage, elementData]);

  const handleOriginalElement = useCallback(async (update: boolean) => {
    setLoadingErrorReport(true);
    try {
      const response = await getOriginalElement(elementData.id, update);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.updated_by_human) {
        setOpenCheckError(true);
      } else {
        setOpenErrorDialog(true);
      }
      setLoadingErrorReport(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingErrorReport(false);
    }
  }, []);

  const handleDisapproveElement = async (): Promise<void> => {
    setDisableInfo(false);
    setIsApproved(false);
  };

  const getMorePictures = async (): Promise<void> => {
    setLoadingMorePictures(true);

    try {
      const response = await getAllElementPictures(elementData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setAllPictures(response.data);
      setLoadingMorePictures(false);
      setUpdatedPictures(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setLoadingMorePictures(false);
    }
  };

  return {
    initialPictures,
    allPictures,
    age,
    setAge,
    handleConservationEstimate,
    buildingStandard,
    setBuildingStandard,
    conservation,
    setConservation,
    landArea,
    setLandArea,
    disableInfo,
    isApproved,
    estimatedConservation,
    setEstimatedConservation,
    showDescription,
    setShowDescription,
    openCheckError,
    setOpenCheckError,
    openErrorDialog,
    setOpenErrorDialog,
    loadingErrorReport,
    loadingMorePictures,
    updatedPictures,
    handleApproveElement,
    handleDeleteElement,
    handleOriginalElement,
    handleDisapproveElement,
    getMorePictures,
    loadingApproveElement,
    loadingDeleteElement,
    housePropertyType,
  };
}
