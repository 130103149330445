import {
  Box,
  FormGroup,
  IconButton,
  Modal,
  Typography,
  styled,
} from '@mui/material';

export const ModalStyled = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },
}));

export const IconButtonStyled = styled(IconButton)(() => ({
  position: 'relative',
  right: '-100%',
  top: '-5%',
}));

export const Title = styled(Typography)(() => ({
  fontSize: 26,
  marginTop: -20,
  marginBottom: 20,
  color: '#595959',
  fontWeight: 500,
}));

export const AssignmentContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '16px 0',
  color: '#00B39B',
}));

export const AssingData = styled(Box)(() => ({
  width: 300,
  height: 40,
  border: '1px solid #00B39B',
  color: '#004E43',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
}));

export const FormGroupStyled = styled(FormGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
}));

export const InputContainer = styled(Box)(() => ({
  display: 'flex',
  gap: 16,
  margin: '32px 0',
  alignItems: 'end',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
}));
