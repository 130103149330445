export const Constants = {
  archived: 'arquivado',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noForm: 'esta OS não possui formulário',
  noSample: 'esta OS não possui amostra',
  sample: 'Amostra',
  report: 'Cálculo automatizado',
  noReport: 'Relatório não pode ser criado',
  reason: 'motivo:',
};
