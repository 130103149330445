import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UfBox = styled(Box)(() => ({
  width: '220px',
  marginBottom: '20px',
}));

export const SectionTitleContainer = styled(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
}));

export const SectionTitle = styled(Typography)(() => ({
  minWidth: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  '& span': { color: '#00B39B' },
}));

export const DividerContainer = styled(Box)(() => ({
  width: '100%',
}));

export const StyledDivider = styled(Divider)(() => ({
  borderBottomWidth: '2px',
  borderBottomColor: '#00B39B',
}));

export const Subtitle = styled(Typography)(() => ({
  fontSize: '18px',
  color: '#707070',
  fontWeight: '600',
}));

export const TableGrid = styled(Grid)(() => ({
  maxWidth: '1000px',
  marginBottom: '60px',
}));

export const TableHeader = styled(Typography)(() => ({
  width: '100%',
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#00B39B',
  fontSize: '21px',
  fontWeight: '600',
  color: '#EDEFF2',
  borderRadius: '6px',
}));

export const TableCell = styled(Typography)(() => ({
  width: '100%',
  minHeight: '48px',
  padding: '0 20px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '21px',
  border: '1px solid #707070',
  borderRadius: '6px',
}));

export const UpdateBox = styled(Box)(() => ({
  marginBottom: '60px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const UpdateButton = styled(Button)(() => ({
  width: '400px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const UfTextField = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
};

export const UfMenuItem = {
  '&:first-of-type': {
    color: '#7c7c7c',
    '&.Mui-selected': { backgroundColor: '#EDEDED' },
    '&:hover': {
      backgroundColor: '#EDEDED',
      cursor: 'auto',
    },
  },
  '&:hover': { backgroundColor: '#00B39B66' },
};
