/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getCompaniesPayments } from '../../../../../api/companies';
import { Company } from '../../../../../api/companies/types';
import {
  IconArrowDownwardMS,
  IconArrowUpwardMS,
  IconInfoMS,
} from '../../../../../constants/icons';
import {
  Constants,
  companyHeader,
} from '../../../../../constants/userManagement';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import {
  InfoBox,
  StyledCell,
  StyledPagination,
  StyledRow,
  TableCellHeader,
} from '../../../styles';

interface TrialProps {
  search: string;
}

export function Trial({ search }: TrialProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [order, setOrder] = useState(false);

  const companiesPerPage = 10;
  const paymentCapture = 'trial';

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    let orderBy = 'trial_expires_at%23asc';
    if (order) orderBy = 'trial_expires_at%23desc';

    try {
      const response = await getCompaniesPayments(
        page,
        companiesPerPage,
        search,
        paymentCapture,
        orderBy
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTotalPages(response.detail.total_pages);
      setCompanies(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [page, search, order]);

  useEffect(() => {
    getDataCallback();
  }, [page, order]);

  useEffect(() => {
    if (page === 1) {
      getDataCallback();
    } else {
      setPage(1);
    }
  }, [search]);

  return loading ? (
    <Box width="100%" mt={4}>
      <LinearProgress />
    </Box>
  ) : (
    <Box>
      {companies.length ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {companyHeader.map((cell) => (
                    <TableCellHeader key={cell}>{cell}</TableCellHeader>
                  ))}
                  <TableCellHeader
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setOrder(!order)}
                  >
                    {Constants.leftTime}
                    <span>
                      {order ? IconArrowUpwardMS : IconArrowDownwardMS}
                    </span>
                  </TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((item) => (
                  <StyledRow key={item.id}>
                    <StyledCell width="22%">
                      {item.responsible_user.name}
                    </StyledCell>
                    <StyledCell width="18%">{item.document}</StyledCell>
                    <StyledCell width="18%">{item.license_document}</StyledCell>
                    <StyledCell width="20%">{item.name}</StyledCell>
                    <StyledCell width="22%">
                      {item.trial_remaining_days}
                    </StyledCell>
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledPagination
            page={page}
            count={totalPages}
            onChange={(_, v) => setPage(v)}
          />
        </>
      ) : (
        <InfoBox component={Paper}>
          {IconInfoMS}
          {Constants.noTrial}
        </InfoBox>
      )}
    </Box>
  );
}
