/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusCode } from '../../api/enumerations';
import { login } from '../../api/login';
import logo from '../../assets/images/logo-realprice-login.png';
import {
  EmailIcon,
  IconVisibility,
  PasswordIcon,
} from '../../components/InputIcon';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/login';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import {
  ContainerGrid,
  InputBox,
  InputTitle,
  LoginGrid,
  LogoGrid,
  LogoImg,
  StyledButtonBase,
  StyledText,
  StyledTextField,
  SubmitButton,
} from './styles';

export function Login(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const token = localStorage.getItem('control-center-token');

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      navigate('/home');
    }
  }, [navigate, token]);

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    try {
      setSubmitLoading(true);
      const response = await login(trimEmail, password);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (
        response.detail.status_code !== StatusCode.OK ||
        !response.access_token ||
        !response.user
      ) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      // check if user has subdomain realprice to continue
      const isRealPrice = response.user.companies.find(
        (element) =>
          element.subdomain === 'realprice-st' ||
          element.subdomain === 'realprice'
      );
      if (!isRealPrice) {
        throw new Error('Usuário sem acesso à Real Price.');
      }

      const item = response.access_token;
      localStorage.setItem('control-center-token', item);
      setSubmitLoading(false);
      navigate('/home');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  return (
    <ContainerGrid container>
      <LogoGrid item xs={8}>
        <LogoImg image={logo} title="logo" />
      </LogoGrid>
      <LoginGrid item xs={4}>
        <InputBox component="form" id="login" onSubmit={handleSubmit}>
          <InputTitle>{Constants.title}</InputTitle>
          <StyledTextField
            required
            id="email"
            variant="standard"
            InputProps={EmailIcon}
            placeholder={Constants.emailPlaceholder}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <StyledTextField
            required
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            InputProps={{
              startAdornment: <PasswordIcon />,
              endAdornment: (
                <IconVisibility
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              ),
            }}
            id="password"
            placeholder={Constants.passwordPlaceholder}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton form="login" type="submit" disabled={submitLoading}>
            {submitLoading ? <CircularProgress size={22} /> : Constants.submit}
          </SubmitButton>
          <StyledButtonBase
            disableTouchRipple
            onClick={() => navigate('/forgot-password')}
          >
            {Constants.forgotPassword}
          </StyledButtonBase>
          <StyledText>
            {Constants.firstAccess}
            <StyledButtonBase
              disableTouchRipple
              onClick={() => navigate('/first-access')}
            >
              {Constants.click}
            </StyledButtonBase>
          </StyledText>
        </InputBox>
      </LoginGrid>
      {openSnackbar && <Snackbar />}
    </ContainerGrid>
  );
}
