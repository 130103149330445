import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(({ theme }) => ({
  margin: '20px 180px 60px',
  [theme.breakpoints.down('xl')]: {
    margin: '20px 140px 60px',
  },
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '60px',
  alignItems: 'center',
  '@media (max-height: 820px)': {
    marginBottom: '40px',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const FlexBoxGap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
}));

export const FlexSpaceBetweenBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CancelOsBox = styled(Box)(() => ({
  width: '210px',
  height: '32px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '160px 0',
}));
