export const Constants = {
  adjustment: 'Ajustes',
  residence: 'Residencial',
  house: 'Casa',
  flat: 'Apartamento',
  comerce: 'Comercial',
  freeFloor: 'Andar livre',
  store: 'Salas e lojas',
  warehouse: 'Galpão',
  industry: 'Industrial',
  cub: 'CUB transformado',
  rstandard: 'R-Padrão',
  logic: 'Lógica',
  popular: 'Popular',
  simple: 'Simples',
  normal: 'Normal',
  high: 'Alto',
  luxe: 'Luxo',
  tableInfo:
    '***O termo CUB representa o valor indicado em R-Padrão e é obrigatório na lógica',
  confirm: 'confirmar alterações',
};
