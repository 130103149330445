import {
  Box,
  Grid,
  IconButton,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

export const BackButton = styled(IconButton)(() => ({
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const BoxContainer = styled(Box)(() => ({
  padding: '20px 40px',
}));

export const StyledGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
  color: '#004D43',
  marginBottom: 8,
}));

export const BoxText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #D7D8DB',
  background: '#F5F7FB',
  padding: '16px',
  minHeight: 64,
  maxHeight: 386,
  borderRadius: 4,
  overflowY: 'auto',
}));

export const StyledTypography = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const HeaderBoxText = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
}));

export const ListParagraphs = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  '& p': {
    fontWeight: 500,
  },
}));

export const ColumnsStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& p': {
    marginTop: 48,
  },
}));

export const CellHeader = styled(TableCell)(() => ({
  borderBottom: 'none',
  fontWeight: 400,
  fontSize: 16,
  color: '#595959',
  background: '#F5F7FB',
}));

export const CellBody = styled(TableCell)(() => ({
  borderBottom: 'none',
  fontWeight: 500,
  fontSize: '15px',
  color: '#595959',
}));

export const TableHeadStyled = styled(TableHead)(() => ({
  '& .MuiTableCell-head': {
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 3px',
  },
}));

export const StyledRow = styled(TableRow)(() => ({
  '&:first-of-type td, &:first-of-type th': {
    paddingTop: '20px',
  },
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#8f929a',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#8f929acc',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '48px 0 0',
  },
}));
