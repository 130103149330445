/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Company } from '../../../../../api/companies/types';
import { getInvoices } from '../../../../../api/invoices';
import { Invoice } from '../../../../../api/invoices/types';
import { IconDescriptionMS, IconInfoMS } from '../../../../../constants/icons';
import { Constants } from '../../../../../constants/userManagement';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../hooks/useGeneral';
import { InfoBox, StyledPagination } from '../../../styles';
import {
  BoxText,
  CellBody,
  CellHeader,
  StyledRow,
  StyledTableContainer,
  TableHeadStyled,
  Title,
} from '../styles';

interface PaymentHistoryTableProps {
  company: Company;
}

export function PaymentHistoryTable({
  company,
}: PaymentHistoryTableProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const invoicesPerPage = 10;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { handleDownloadFile } = useGeneral();
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    if (!company) return;
    setLoading(true);

    try {
      const response = await getInvoices(page, invoicesPerPage, company.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTotalPages(response.detail.total_pages);
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [page, company]);

  useEffect(() => {
    getDataCallback();
  }, [company, page]);

  return (
    <>
      <Title>{Constants.paymentHistory}</Title>
      <BoxText sx={{ flexDirection: 'column' }}>
        {loading ? (
          <Box width="100%" mt={4}>
            <LinearProgress />
          </Box>
        ) : (
          <Box>
            {invoices.length ? (
              <>
                <StyledTableContainer>
                  <Table size="small" stickyHeader>
                    <TableHeadStyled>
                      <TableRow>
                        <CellHeader>{Constants.historyDueDate}</CellHeader>
                        <CellHeader align="right">
                          {Constants.historyValue}
                        </CellHeader>
                        <CellHeader align="center">
                          {Constants.historyAtt}
                        </CellHeader>
                      </TableRow>
                    </TableHeadStyled>
                    <TableBody>
                      {invoices.map((invoice) => (
                        <StyledRow key={invoice.id}>
                          <CellBody>
                            {new Intl.DateTimeFormat('pt-BR').format(
                              new Date(invoice?.due_date)
                            )}
                          </CellBody>
                          <CellBody align="right">
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(invoice.total)}
                          </CellBody>
                          <CellBody align="center">
                            <IconButton
                              color="primary"
                              disabled={!invoice.attachment}
                              onClick={() =>
                                handleDownloadFile(
                                  invoice.attachment,
                                  `anexo ${invoice.due_date}`
                                )
                              }
                            >
                              {IconDescriptionMS}
                            </IconButton>
                          </CellBody>
                        </StyledRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                <StyledPagination
                  page={page}
                  count={totalPages}
                  onChange={(_, v) => setPage(v)}
                />
              </>
            ) : (
              <InfoBox>
                {IconInfoMS}
                {Constants.noPayHistory}
              </InfoBox>
            )}
          </Box>
        )}
      </BoxText>
    </>
  );
}
