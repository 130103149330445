/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  EvaluationType,
  PropertyType,
  Status,
  StatusCode,
} from '../../api/enumerations';
import { RoomData } from '../../api/rooms/types';
import { checkSample, getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import {
  ApproveWorkOrder,
  editWorkOrder,
  GetWorkOrder,
  addGoogleMapsImagesWithPins,
} from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { GlobalContext } from '../../context/global';
import { validateFiles } from '../../helpers';
import { useAttachment } from '../../hooks/useAttachmentFiles';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';

type LatLngLiteral = google.maps.LatLngLiteral;

interface UseSampleCreationHook {
  getDataCallback: () => Promise<void>;
  propertyData: WorkOrderData | undefined;
  osId: number;
  disableInfo: boolean;
  age: number;
  setAge: (value: number) => void;
  handleConservationEstimate: () => void;
  conservation: number;
  setConservation: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  handleApproveConservation: () => Promise<void>;
  handleSearchSample: () => Promise<void>;
  loading: boolean;
  sampleData: ElementData[] | undefined;
  setSampleData: (sample: ElementData[]) => void;
  completeElements: ElementData[] | undefined;
  incompleteElements: ElementData[] | undefined;
  pinPlace: LatLngLiteral | undefined;
  showMap: boolean;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  updateSample: () => Promise<void>;
  showModal: boolean;
  evaluationTypeIsFactors: boolean;
  checkApprovedSample: () => void;
  page: number;
  setPage: (value: number) => void;
  view: string;
  handleView: (event: React.MouseEvent<HTMLElement>, view: string) => void;
  minElements: string;
  complete: boolean;
  setComplete: (value: boolean) => void;
  incomplete: boolean;
  setIncomplete: (value: boolean) => void;
  supplyFactor: number;
  setSupplyFactor: (value: number) => void;
  rooms: RoomData[];
  deactivateFilters: () => void;
  reset: () => void;
  extrapolationsErrors: string;
  showAddressModal: boolean;
  setShowAddressModal: (value: boolean) => void;
  loadingPage: boolean;
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  client: string;
  setClient: (value: string) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  registerFileName: string;
  iptuFileName: string;
  downloadIptu: string;
  downloadRegister: string;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  zone: number;
  setZone: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  suites: number;
  setSuites: (value: number) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  submitLoading: boolean;
  lastElementEdited: number | undefined;
  setLastElementEdited: (value: number | undefined) => void;
  estimatedConservation: boolean;
  isSupplyEditionDisabled: boolean;
  setIsSupplyEditionDisabled: (value: boolean) => void;
  handleNewSupplyFactor: () => Promise<void>;
  approvedSampleLoading: boolean;
  maxRadius: number;
  setMaxRadius: (value: number) => void;
  parkingError: boolean;
}

export default function useSampleCreation(): UseSampleCreationHook {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(2);
  const [conservation, setConservation] = useState(1);
  const [estimatedConservation, setEstimatedConservation] = useState(false);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>();
  const [showMap, setShowMap] = useState(false);
  const [disableInfo, setDisableInfo] = useState(false);
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maxRadius, setMaxRadius] = useState<number>(0.5);
  const [sampleRadius, setSampleRadius] = useState<number>(2.5);
  const [supplyFactor, setSupplyFactor] = useState(6);
  const [supplyIsUpdated, setSupplyIsUpdated] = useState(false);
  const [isSupplyEditionDisabled, setIsSupplyEditionDisabled] = useState(false);
  const [approvedSampleLoading, setApprovedSampleLoading] = useState(false);
  const [extrapolationsErrors, setExtrapolationsErrors] = useState('');
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [modalWasShown, setModalWasShown] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [lastElementEdited, setLastElementEdited] = useState<number>();
  const [parkingError, setParkingError] = useState(false);

  const [completeElements, setCompleteElements] = useState<ElementData[]>();
  const [incompleteElements, setIncompleteElements] = useState<ElementData[]>();
  const [disapprovedElements, setDisapprovedElements] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const [view, setView] = useState('card');
  const [minElements, setMinElements] = useState('');
  const [complete, setComplete] = useState(false);
  const [incomplete, setIncomplete] = useState(false);

  const [enableEditing, setEnableEditing] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Editable data
  const [client, setClient] = useState('');
  const [solicitor, setSolicitor] = useState('');
  const [goal, setGoal] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [propertyUse, setPropertyUse] = useState(0);
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [iptuFile, setIptuFile] = useState<File | string>();
  const [registerFile, setRegisterFile] = useState<File | string>();
  const [registerNumber, setRegisterNumber] = useState('');
  const [concept, setConcept] = useState(0);
  const [zone, setZone] = useState(0);
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [suites, setSuites] = useState(0);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { getErrorMessage } = useErrorMessage();
  const { osId } = useGeneral();
  const { handleRooms, rooms } = useRooms();

  const evaluationTypeIsFactors =
    propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS;

  const factorsMax = 12;
  const factorsMin = 5;
  const inferenceMax = 70;
  const inferenceMin = 16;
  // Minimum elements to consider parking lots in the avm calculation.
  const minConsiderParking = 20;

  const reset = (): void => {
    setSampleData(undefined);
    setCompleteElements(undefined);
    setIncompleteElements(undefined);
    setDisapprovedElements([]);
    setPage(1);
    setView('card');
    setMinElements('');
    setIsSupplyEditionDisabled(false);
    setExtrapolationsErrors('');
  };

  const getMinElements = (totalElements: number): void => {
    if (evaluationTypeIsFactors) {
      if (factorsMax - totalElements > 0) {
        setMinElements(`${factorsMax - totalElements}`);
      }
    } else if (inferenceMax - totalElements > 0) {
      setMinElements(`${inferenceMax - totalElements}`);
    }
  };

  const updateSample = async (): Promise<void> => {
    setShowMap(false);
    try {
      const response = await getSample(osId, 1, 105);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('A amostra não pode ser carregada.');
      }

      if (
        response.data.sample_extrapolations_errors &&
        response.data.sample_extrapolations_errors.length > 0
      ) {
        setExtrapolationsErrors(response.data.sample_extrapolations_errors);
      } else {
        setExtrapolationsErrors('');
      }

      if (response.data.max_radius) {
        setSampleRadius(response.data.max_radius);
        setMaxRadius(response.data.max_radius);
      }

      const sample = response.data.items;
      setSampleData(sample);

      const filteredSamples: number[] = sample
        .filter((data) => data.approved === false)
        .map((data) => sample.indexOf(data) + 1);
      setDisapprovedElements(filteredSamples);

      setMinElements('');
      setIsSupplyEditionDisabled(true);
      setShowMap(true);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  };

  const getDataCallback = useCallback(async () => {
    let getSamples = false;

    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.SAMPLE_CREATION) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
      }

      if (response.data.latitude && response.data.longitude) {
        setPinPlace({
          lat: Number(response.data.latitude),
          lng: Number(response.data.longitude),
        });
      }

      if (response.data.supply_factor) {
        setSupplyFactor(response.data.supply_factor);
      }

      if (response.data.sample_extrapolations_errors) {
        setExtrapolationsErrors(response.data.sample_extrapolations_errors);
      }

      setPropertyData(response.data);
      setLoadingPage(false);
      setAge(response.data.age);
      setConservation(response.data.preservation_state);
      setBuildingStandard(response.data.constructive_standard);
      setDownloadIptu(response.data.iptu_document || '');
      setDownloadRegister(response.data.real_estate_registry || '');
      setClient(response.data.client_name);
      setSolicitor(response.data.requester_name);
      setGoal(response.data.report_goal);
      setPropertyFunction(response.data.report_function);
      setPropertyUse(response.data.use);
      setRegisterNumber(response.data.registration_number);
      setConcept(response.data.communities_kind);
      setZone(response.data.zone);
      setJudicialDistrict(response.data.judicial_district);
      setRegistrationUf(response.data.uf);
      setPropertyRooms(response.data.bedrooms);
      setToilets(response.data.bathrooms);
      setSuites(response.data.suites);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      updateSample();
    }
  }, [iptuLink, registerLink]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, iptuLink, registerLink]);

  useEffect(() => {
    if (lastElementEdited) {
      setPage(lastElementEdited);
      setLastElementEdited(undefined);
    }
  }, [sampleData]);

  const handleConservationEstimate = (): void => {
    setEstimatedConservation(true);

    if (age < 2) {
      setConservation(1);
    }
    if (age >= 2 && age < 5) {
      setConservation(2);
    }
    if (age >= 5 && age < 15) {
      setConservation(3);
    }
    if (age >= 15 && age < 25) {
      setConservation(4);
    }
    if (age >= 25 && age < 35) {
      setConservation(5);
    }
    if (age >= 35 && age < 45) {
      setConservation(6);
    }
    if (age >= 45) {
      setConservation(7);
    }
  };

  const handleApproveConservation = async (): Promise<void> => {
    if (!estimatedConservation) {
      setSnackbarMessage('Calcule o estado de conservação!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const data = {
      age,
      preservation_state: conservation,
      constructive_standard: buildingStandard,
    };

    try {
      const response = await ApproveWorkOrder(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setDisableInfo(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const updateGoogleMap = useCallback(
    async (osPin: LatLngLiteral, samplePins: ElementData[]): Promise<void> => {
      setApprovedSampleLoading(true);

      let zoom = 14;
      if (sampleRadius < 1.5) zoom = 15;
      if (sampleRadius < 1.0) zoom = 16;

      const markers = samplePins.map((sample) => {
        return `${sample.description.latitude}, ${sample.description.longitude} |`;
      });
      const sampleMarkers = markers.join(' ');

      const mapFile = await fetch(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/staticmap?center=${osPin.lat},${osPin.lng}&style=feature:poi|element:labels|visibility:off&zoom=${zoom}&size=460x390&scale=2&markers=color:0xe67615|${osPin.lat},${osPin.lng}&markers=color:0xae38f9|size:tiny|${sampleMarkers}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) => new File([bFile], 'map-elements', { type: 'image/png' })
        );

      const mapSatelliteFile = await fetch(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/staticmap?center=${osPin.lat},${osPin.lng}&style=feature:poi|element:labels|visibility:off&zoom=${zoom}&size=460x390&scale=2&maptype=satellite&markers=color:0xe67615|${osPin.lat},${osPin.lng}&markers=color:0xae38f9|size:tiny|${sampleMarkers}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) =>
            new File([bFile], 'satellite-map-elements', { type: 'image/png' })
        );

      const mapImageData = new FormData();
      mapImageData.append('google_maps', mapFile);
      mapImageData.append('satellite_view', mapSatelliteFile);

      const formData = new FormData();
      formData.append('supply_factor', supplyFactor.toString());

      try {
        const response = await addGoogleMapsImagesWithPins(osId, mapImageData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        const res = await editWorkOrder(osId, formData);

        if (res.detail.description) {
          throw new Error(res.detail.description);
        }

        if (res.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('A OS pode ser aprovada!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setShowModal(true);
        setApprovedSampleLoading(false);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        setApprovedSampleLoading(false);
      }
    },
    []
  );

  const checkApprovedSample = (): void => {
    if (!disableInfo) {
      setSnackbarMessage('Aprove o estado de conservação da OS!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }
    if (supplyIsUpdated) {
      setSnackbarMessage(
        'Fator oferta foi editado, busque a amostra novamente.'
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }
    if (sampleData) {
      const minRequired = evaluationTypeIsFactors ? factorsMin : inferenceMin;
      if (sampleData.length < minRequired) {
        setSnackbarMessage(`Mínimo ${minRequired} elementos para prosseguir`);
        setErrorMessage(true);
        setOpenSnackbar(true);
        return;
      }
    }
    if (disapprovedElements.length > 0) {
      setSnackbarMessage(
        `Aprove o estado de conservação dos seguintes elementos: ${disapprovedElements.join(
          ', '
        )}.`
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }
    if (sampleData && pinPlace) {
      updateGoogleMap(pinPlace, sampleData);
      if (
        !evaluationTypeIsFactors &&
        propertyData?.real_estate_kind === PropertyType.APARTMENT &&
        sampleData.length < minConsiderParking
      ) {
        setParkingError(true);
      } else {
        setParkingError(false);
      }
    } else {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSearchSample = async (): Promise<void> => {
    if (!disableInfo) {
      setSnackbarMessage('Aprove o estado de conservação para buscar amostras');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    setLoading(true);
    setSupplyIsUpdated(false);
    setIsSupplyEditionDisabled(true);
    setExtrapolationsErrors('');
    let getSamples = false;

    try {
      const response = await checkSample(osId, supplyFactor, maxRadius);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setExtrapolationsErrors(response.data.extrapolations_errors);
      }

      getSamples = true;
    } catch (error) {
      setLoading(false);
      setSupplyIsUpdated(true);
      setIsSupplyEditionDisabled(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    if (getSamples) {
      updateSample();
    }
  };

  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ): void => {
    setView(newView);
  };

  useEffect(() => {
    setShowModal(false);

    if (!sampleData || sampleData.length < 1) {
      setSupplyIsUpdated(true);
    }
    if (propertyData && sampleData) {
      getMinElements(sampleData.length);
    }
    if (sampleData) {
      if (
        !modalWasShown &&
        propertyData?.real_estate_kind === PropertyType.APARTMENT
      ) {
        const filteredAddress: ElementData[] = sampleData.filter(
          (data) =>
            data.description.street === propertyData.street &&
            data.description.number === propertyData.number
        );

        if (filteredAddress.length < 1) {
          setShowAddressModal(true);
          setModalWasShown(true);
        }
      }
      setSupplyIsUpdated(false);
      const filteredCompleteElements: ElementData[] = sampleData.filter(
        (data) => data.description.level === 1 && data.approved
      );
      setCompleteElements(filteredCompleteElements);

      const filteredIncompleteElements: ElementData[] = sampleData.filter(
        (data) => !(data.description.level === 1 && data.approved)
      );
      setIncompleteElements(filteredIncompleteElements);
    }
  }, [sampleData, propertyData]);

  const handleNewSupplyFactor = useCallback(async (): Promise<void> => {
    if (supplyFactor < 0 || supplyFactor > 100) {
      setSnackbarMessage('O valor de oferta deve ser entre 0 e 100.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('supply_factor', supplyFactor.toString());

    try {
      const res = await editWorkOrder(osId, formData);

      if (res.detail.description) {
        throw new Error(res.detail.description);
      }

      if (res.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Fator de oferta editado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsSupplyEditionDisabled(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [supplyFactor]);

  const deactivateFilters = (): void => {
    setComplete(false);
    setIncomplete(false);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    if (fileType === 'register') {
      formData.append('real_estate_registry', file);
    }
    if (fileType === 'iptu') {
      formData.append('iptu_document', file);
    }

    try {
      const response = await editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (fileType === 'register') {
        setRegisterLink(file.name);
        setRegisterFileName(file.name);
        setRegisterFile(file);
      }
      if (fileType === 'iptu') {
        setIptuLink(file.name);
        setIptuFileName(file.name);
        setIptuFile(file);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (
      concept === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('client_name', client);
    formData.append('requester_name', solicitor);
    formData.append('report_goal', goal.toString());
    formData.append('report_function', propertyFunction.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('communities_kind', concept.toString());
    formData.append('zone', zone.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('uf', registrationUf.toString());
    formData.append('bedrooms', propertyRooms.toString());
    formData.append('bathrooms', toilets.toString());
    formData.append('suites', suites.toString());

    if (registerFile) {
      formData.append('real_estate_registry', registerFile);
    }
    if (iptuFile) {
      formData.append('iptu_document', iptuFile);
    }

    setSubmitLoading(true);

    try {
      const response = await editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setSubmitLoading(false);
      setEnableEditing(false);
      getDataCallback();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  return {
    getDataCallback,
    propertyData,
    osId,
    disableInfo,
    age,
    setAge,
    handleConservationEstimate,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    handleApproveConservation,
    handleSearchSample,
    loading,
    sampleData,
    setSampleData,
    completeElements,
    incompleteElements,
    pinPlace,
    showMap,
    disapprovedElements,
    setDisapprovedElements,
    updateSample,
    showModal,
    evaluationTypeIsFactors,
    checkApprovedSample,
    page,
    setPage,
    view,
    handleView,
    minElements,
    complete,
    setComplete,
    incomplete,
    setIncomplete,
    supplyFactor,
    setSupplyFactor,
    rooms,
    deactivateFilters,
    reset,
    extrapolationsErrors,
    showAddressModal,
    setShowAddressModal,
    loadingPage,
    enableEditing,
    setEnableEditing,
    client,
    setClient,
    solicitor,
    setSolicitor,
    goal,
    setGoal,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    handleFileUpload,
    registerFileName,
    iptuFileName,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    concept,
    setConcept,
    zone,
    setZone,
    judicialDistrict,
    setJudicialDistrict,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    toilets,
    setToilets,
    suites,
    setSuites,
    handleSubmit,
    submitLoading,
    lastElementEdited,
    setLastElementEdited,
    estimatedConservation,
    isSupplyEditionDisabled,
    setIsSupplyEditionDisabled,
    handleNewSupplyFactor,
    approvedSampleLoading,
    maxRadius,
    setMaxRadius,
    parkingError,
  };
}
