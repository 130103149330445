/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import chartTrendline from 'chartjs-plugin-trendline';
import { useEffect, useRef } from 'react';
import { Scatter } from 'react-chartjs-2';

import { ScatterTrendlineData } from '../../../api/workOrders/types';
import {
  ChartBox,
  ChartContainer,
  ChartTitle,
  LabelLine,
  LabelTypography,
  SingleLabelBox,
} from '../styles';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  chartTrendline
);

interface ScatterTrendlineProps {
  chart: ScatterTrendlineData;
  bisector?: boolean;
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
}

export function ScatterTrendline({
  chart,
  bisector,
  chartCallback,
}: ScatterTrendlineProps): JSX.Element {
  const bissetriz = chart.line_data.data;
  const scatterData = chart.scatter_data.data;
  const chartTitle = JSON.parse(`["${chart.chart_title}"]`);
  const bissetrizColor = chart.line_data.color;

  const chartRef =
    useRef<ChartJS<'scatter', { x: number; y: number }[], number>>(null);

  const handleSaveImage = (): void => {
    if (chartRef?.current) {
      const { canvas } = chartRef.current;

      if (chartCallback) {
        chartCallback(canvas, chart.chart_title);
      }
    }
  };

  useEffect(() => {
    if (chartRef !== null) {
      handleSaveImage();
    }
  }, [chartRef]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: chart.y_axis_title,
          font: {
            size: 16,
          },
        },
        min: bisector
          ? Math.floor(bissetriz[0].y / 100) * 100
          : bissetriz[0].y - 1.2,
        max: bisector
          ? Math.ceil(bissetriz[1].y / 100) * 100
          : bissetriz[1].y + 0.2,
      },
      x: {
        title: {
          display: true,
          text: chart.x_axis_title,
          font: {
            size: 16,
          },
        },
        min: bisector
          ? Math.floor(bissetriz[0].x / 100) * 100
          : bissetriz[0].x - 2,
        max: bisector
          ? Math.ceil(bissetriz[1].x / 100) * 100
          : bissetriz[1].x + 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: scatterData.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.scatter_data.color,
        TrendlineLinear: 'none',
      },
      {
        data: bissetriz.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.line_data.color,
        pointRadius: 0,
        trendlineLinear: {
          colorMin: chart.line_data.color,
          colorMax: chart.line_data.color,
          lineStyle: 'solid',
          width: 2,
        },
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartTitle>{chartTitle}</ChartTitle>
      {bisector && (
        <SingleLabelBox>
          <LabelTypography sx={{ color: bissetrizColor }}>
            Bissetriz
          </LabelTypography>
          <LabelLine sx={{ color: bissetrizColor }} />
        </SingleLabelBox>
      )}
      <ChartBox>
        <Scatter ref={chartRef} options={options} data={data} />
      </ChartBox>
    </ChartContainer>
  );
}
