import { ToggleButton } from '@mui/material';
import { Fragment } from 'react';

import { ElementData } from '../../../../api/samples/types';
import { IconViewArrayMS, IconViewListMS } from '../../../../constants/icons';
import { DashboardIcon } from '../DashboardIcon';
import {
  DashboardContainer,
  SectionBox,
  StyledToggleButtonGroup,
} from './styles';

interface SampleDashboardProps {
  setPage: (value: number) => void;
  sampleData: ElementData[] | undefined;
  view: string;
  handleView: (event: React.MouseEvent<HTMLElement>, view: string) => void;
}

export function SampleDashboard({
  setPage,
  sampleData,
  view,
  handleView,
}: SampleDashboardProps): JSX.Element {
  return (
    <>
      <SectionBox id="top">
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="visualização"
        >
          <ToggleButton value="card" aria-label="individual">
            {IconViewArrayMS}
          </ToggleButton>
          <ToggleButton value="list" aria-label="lista">
            {IconViewListMS}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </SectionBox>
      <DashboardContainer>
        {sampleData?.map((element, ind) => (
          <Fragment key={element.id}>
            <DashboardIcon
              page={ind + 1}
              setPage={setPage}
              sampleId={ind + 1}
              alert={false}
            />
          </Fragment>
        ))}
      </DashboardContainer>
    </>
  );
}
