import { Box, Divider } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useState } from 'react';

import { Search } from '../../../components/Search';
import { SearchBox } from '../styles';
import { BoxContainer, StyledTabs, TabContainer } from './styles';
import { Actives } from './Tab/Actives';
import { OpenMonthlyPayment } from './Tab/OpenMonthlyPayment';
import { PaymentsMade } from './Tab/PaymentsMade';
import { Trial } from './Tab/Trial';

export function Payments(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState('monthly');
  const [search, setSearch] = useState('');

  return (
    <BoxContainer>
      <SearchBox>
        <Search setSearchField={setSearch} />
      </SearchBox>
      <TabContainer>
        <StyledTabs
          orientation="vertical"
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          aria-label="abas de pagamentos"
        >
          <Tab label="Mensalidade em aberto" value="monthly" />
          <Tab label="Pagamentos realizados" value="payments" />
          <Tab label="Ativos" value="actives" />
          <Tab label="Trial" value="trial" />
        </StyledTabs>
        <Divider orientation="vertical" />
        <Box px={2} width="100%">
          {selectedTab === 'monthly' && <OpenMonthlyPayment search={search} />}
          {selectedTab === 'payments' && <PaymentsMade search={search} />}
          {selectedTab === 'actives' && <Actives search={search} />}
          {selectedTab === 'trial' && <Trial search={search} />}
        </Box>
      </TabContainer>
    </BoxContainer>
  );
}
