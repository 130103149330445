/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import { useEffect } from 'react';

import { EvaluationType } from '../../../api/enumerations';
import { ElementData } from '../../../api/samples/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { SampleText } from '../../../pages/SampleCreation/Sample/styles';
import ElementsMap from '../../Maps/ElementsMap';
import { CardPagination } from './CardPagination';
import { ElementReceipt } from './ElementReceipt';
import { SampleDashboard } from './SampleDashboard';
import { useSampleDashboard } from './SampleDashboard/useSampleDashboard';
import { UpButton } from './UpButton';

interface SampleSectionProps {
  sampleData: ElementData[];
  propertyData?: WorkOrderData;
}

export function SampleSection({
  sampleData,
  propertyData,
}: SampleSectionProps): JSX.Element {
  const { page, setPage, view, handleView, pinPlace, setPinPlace } =
    useSampleDashboard();

  useEffect(() => {
    setPinPlace({
      lat: Number(propertyData?.latitude),
      lng: Number(propertyData?.longitude),
    });
  }, [propertyData, sampleData]);

  useEffect(() => {
    if (view === 'list') {
      document.getElementById(`${page}`)?.scrollIntoView();
    }
  }, [page, view]);

  const showUnusedElements =
    propertyData?.evaluation_type === EvaluationType.AUTOINFERENCES ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <>
      <SampleText>
        {`A amostra possui ${sampleData?.length} elementos`}
      </SampleText>
      <ElementsMap
        sampleData={sampleData}
        pinPlace={pinPlace}
        page={page}
        setPage={setPage}
        showUnusedElements={
          showUnusedElements &&
          propertyData?.avm_report?.utilized_samples_ids &&
          propertyData?.avm_report?.utilized_samples_ids?.length > 0
        }
      />
      <SampleDashboard
        setPage={setPage}
        sampleData={sampleData}
        view={view}
        handleView={handleView}
      />
      {view === 'card' && sampleData ? (
        <>
          <CardPagination
            sampleLength={sampleData?.length}
            page={page}
            setPage={setPage}
          />
          <ElementReceipt
            elementData={sampleData[page - 1]}
            elementId={page}
            propertyType={propertyData?.real_estate_kind}
            highPrice={propertyData?.sample_sell_price_above_400k}
          />
        </>
      ) : (
        <Box>
          {sampleData?.map((element, idx) => (
            <Box id={`${idx + 1}`} key={element.id}>
              <ElementReceipt
                key={element.id}
                elementData={element}
                elementId={idx + 1}
                propertyType={propertyData?.real_estate_kind}
                highPrice={propertyData?.sample_sell_price_above_400k}
              />
            </Box>
          ))}
          <UpButton />
        </Box>
      )}
    </>
  );
}
