/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { companyStats } from '../../../../api/companies';
import {
  ByRequester,
  Company,
  CompanyStats,
  ReportPerUser,
} from '../../../../api/companies/types';
import { IconArrowCircleLeftMS, IconInfoMS } from '../../../../constants/icons';
import {
  Constants,
  presentRegisterData,
  presentReport,
  presentResponsible,
} from '../../../../constants/userManagement';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { InfoBox } from '../../styles';
import {
  BackButton,
  BoldTypography,
  BoxContainer,
  BoxText,
  StyledGrid,
  StyledTypography,
  Title,
} from './styles';
import { LinkedUsersTable } from './Tables/LinkedUsersTable';
import { PaymentHistoryTable } from './Tables/PaymentHistoryTable';

export interface CompanyStatsProps {
  accepted_invitation: boolean;
  accepted_invitation_at: string | null;
  document?: string | null;
  email: string | null;
  engineer_document: string | null;
  finished_reports_count: number;
  id: number;
  last_access: string | null;
  name?: string | null;
  responsible_document: string | null;
  responsible_name: string | null;
  report?: {
    error: number;
    success: number;
  };
  sampler?: {
    success: number;
  };
}

interface UserDataProps {
  show: boolean;
  setShow: (show: boolean) => void;
  company: Company | undefined;
}

export function UserData({
  show,
  setShow,
  company,
}: UserDataProps): JSX.Element {
  const [stats, setStats] = useState<CompanyStatsProps[]>();
  const [allCompanyStats, setAllCompanyStats] = useState<CompanyStats>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleBack = (): void => {
    setShow(!show);
  };

  const mergeInfo = (
    reportsPerUser: ReportPerUser,
    usersWithSampler: ByRequester[]
  ): void => {
    const result: CompanyStatsProps[] = [];
    const reportsPerUserArr = Object.values(reportsPerUser);

    // Add reports per user to result list
    reportsPerUserArr.forEach((report: ReportPerUser) => {
      result.push({ ...report });
    });

    // Find user id from ByRequester in result, then add sampler and report to user
    usersWithSampler.forEach((requester: ByRequester) => {
      const existing = result.find((item) => item.id === requester.id);
      if (existing) {
        Object.assign(existing, requester, {
          sampler: requester.sampler,
          report: requester.report,
        });
      }
    });

    setStats(result);
  };

  const getCompanyStats = useCallback(async () => {
    if (company) {
      try {
        const { data, detail } = await companyStats(company.id);

        if (detail.description) {
          throw new Error(detail.description);
        }

        if (!data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setAllCompanyStats(data);
        mergeInfo(data.reports.reports_per_user, data.requests.by_requester);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  }, [company]);

  useEffect(() => {
    getCompanyStats();
  }, [company]);

  return (
    <Box>
      <BackButton onClick={handleBack}>{IconArrowCircleLeftMS}</BackButton>
      {company ? (
        <BoxContainer>
          <StyledGrid container spacing={4}>
            <Grid item xs={8}>
              <Title>{Constants.responsible}</Title>
              <BoxText>
                {presentResponsible(company).map((data) => (
                  <StyledTypography key={data.label}>
                    {data.label}
                    <BoldTypography>{data.value}</BoldTypography>
                  </StyledTypography>
                ))}
              </BoxText>
            </Grid>
            <Grid item xs={4} mb={2}>
              <Title>{Constants.plan}</Title>
              <BoxText>
                <StyledTypography>
                  <BoldTypography>{company.plan.name}</BoldTypography>
                </StyledTypography>
              </BoxText>
            </Grid>
            <Grid item xs={6}>
              <Title>{Constants.registerData}</Title>
              <BoxText>
                <Grid container spacing={2}>
                  {presentRegisterData(company).map((data) => (
                    <Grid item xs={data.size} key={data.label}>
                      <StyledTypography>
                        {data.label}
                        <BoldTypography>{data.value}</BoldTypography>
                      </StyledTypography>
                    </Grid>
                  ))}
                </Grid>
              </BoxText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Grid container spacing={4}>
                {presentReport(company, allCompanyStats).map((data) => (
                  <Grid item xs={6} xl={4} key={data.label}>
                    <Title>{data.label}</Title>
                    <BoxText sx={{ marginBottom: '0' }}>
                      <BoldTypography>{data.value}</BoldTypography>
                    </BoxText>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
              <LinkedUsersTable stats={stats} />
            </Grid>
            <Grid item xs={12} xl={6}>
              <PaymentHistoryTable company={company} />
            </Grid>
          </StyledGrid>
        </BoxContainer>
      ) : (
        <InfoBox component={Paper} mx={1}>
          {IconInfoMS}
          {Constants.noCompany}
        </InfoBox>
      )}
    </Box>
  );
}
