/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { getReportPDF } from '../../../api/workOrders';
import { IconDownloadMS } from '../../../constants/icons';
import { Constants } from '../../../constants/revision';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { ExportSample } from '../../ExportSample';
import { ContainerButtons, DownloadButton, Iframe } from './styles';

interface PdfViewerProps {
  osId: number;
}

export function PdfViewer({ osId }: PdfViewerProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<File>();
  const [pdfURL, setPdfURL] = useState('');

  const { setOpenSnackbar, setSnackbarMessage, setErrorMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadPdf } = useGeneral();

  const getPdf = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getReportPDF(osId);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(binaryData);
      const file = binaryData
        ? new File([binaryData], 'report.pdf', { type: binaryData.type })
        : undefined;
      setPdfURL(url);
      setPdfFile(file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, []);

  useEffect(() => {
    getPdf();
  }, [getPdf]);

  if (loading) return <LinearProgress />;

  return (
    <Box>
      {!pdfFile ? (
        <>não foi possível carregar o relatório</>
      ) : (
        <>
          <Iframe src={pdfURL} title="relatório" />
          <ContainerButtons>
            <ExportSample osId={osId} />
            <DownloadButton
              disabled={!pdfFile}
              onClick={() => handleDownloadPdf(pdfFile)}
            >
              {IconDownloadMS}
              {Constants.download}
            </DownloadButton>
          </ContainerButtons>
        </>
      )}
    </Box>
  );
}
