import { ChangeEvent, DragEvent, useContext, useState } from 'react';

import { UploadFacadeImage } from '../../api/workOrders';
import { IconAddPhotoAlternateMS, IconCloseMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { validateImg } from '../../helpers';
import useErrorMessage from '../../hooks/useErrorMessage';
import Snackbar from '../Snackbar';
import {
  Button,
  Container,
  ContainerButtons,
  DeleteFacadePhoto,
  ImagePreview,
  InsideBox,
  ModalContainer,
  TitleModal,
  UploadAndPreviewContainer,
  UploadBox,
  UploadIcon,
  UploadSubtitle,
  UploadTitle,
} from './styles';

interface FacadeModalProps {
  osId: number;
  open: boolean;
  onClose: () => void;
}

export function FacadeModal({
  osId,
  open,
  onClose,
}: FacadeModalProps): JSX.Element {
  const [dragActive, setDragActive] = useState(false);
  const [facadePicture, setFacadePicture] = useState<FormData | undefined>();
  const [imagePreview, setImagePreview] = useState('');

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    const formData = new FormData();

    if (file) {
      formData.append('image', file);
      setFacadePicture(formData);

      const reader = new FileReader();
      reader.onload = () => {
        const urlImage = reader.result as string;
        setImagePreview(urlImage);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const formData = new FormData();

    if (!e.dataTransfer.files || !validateImg(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.dataTransfer;

    if (files.length > 1) {
      setSnackbarMessage('Você só pode adicionar 1 imagem de fachada');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    formData.append('image', files[0]);
    setFacadePicture(formData);

    const reader = new FileReader();
    reader.onload = () => {
      const url = reader.result as string;
      setImagePreview(url);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleCancel = (): void => {
    setFacadePicture(undefined);
    setImagePreview('');
    onClose();
  };

  const handleSubmitPhotos = async (): Promise<void> => {
    try {
      if (!facadePicture) {
        throw new Error('Para continuar, você precisa enviar uma imagem.');
      }
      const response = await UploadFacadeImage(osId, facadePicture);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Imagem atualizada');
      setErrorMessage(false);
      setOpenSnackbar(true);

      handleCancel();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeletePhoto = (): void => {
    setImagePreview('');
    setFacadePicture(undefined);
  };

  return (
    <>
      <ModalContainer open={open} onClose={onClose}>
        <Container>
          <TitleModal>Adicionar foto</TitleModal>
          <UploadAndPreviewContainer>
            <UploadBox
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <label htmlFor="uploadPhotos">
                <input
                  accept="image/*"
                  multiple={false}
                  id="uploadPhotos"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e)
                  }
                />
                {!dragActive ? (
                  <InsideBox>
                    <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                    <UploadTitle>selecione a foto</UploadTitle>
                    <UploadSubtitle>(JPG, GIF e PNG somente)</UploadSubtitle>
                  </InsideBox>
                ) : (
                  <UploadTitle>solte o arquivo aqui</UploadTitle>
                )}
              </label>
            </UploadBox>
            {imagePreview && (
              <ImagePreview>
                <DeleteFacadePhoto onClick={() => handleDeletePhoto()}>
                  {IconCloseMS}
                </DeleteFacadePhoto>
                <img src={imagePreview} alt="" />
              </ImagePreview>
            )}
          </UploadAndPreviewContainer>
          <ContainerButtons>
            <Button type="button" className="outlined" onClick={handleCancel}>
              cancelar
            </Button>
            <Button
              type="button"
              className="contained"
              onClick={handleSubmitPhotos}
            >
              salvar
            </Button>
          </ContainerButtons>
        </Container>
      </ModalContainer>
      {openSnackbar && <Snackbar />}
    </>
  );
}
