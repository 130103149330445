import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import { BlockageData, DetailProps, UserBlockagesProps } from './types';

export const createBlockage = (data: BlockageData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/calendar-blockages`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteBlockage = (blockedId: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/calendar-blockages/${blockedId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const editBlockage = (
  data: BlockageData,
  blockedId: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${BaseUrl}/api/V1/calendar-blockages/${blockedId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getUserBlockages = (
  userId: number,
  date?: string
): Promise<UserBlockagesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/calendar-blockages/user/${userId}?date=${date}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
