import { Table, TableBody, TableRow } from '@mui/material';

import { CompanyStatsProps } from '..';

import {
  Constants,
  LinkedUsers,
} from '../../../../../constants/userManagement';
import { formatCPF } from '../../../../../helpers';
import {
  BoxText,
  CellBody,
  CellHeader,
  StyledRow,
  StyledTableContainer,
  TableHeadStyled,
  Title,
} from '../styles';

interface LinkedUsersTableProps {
  stats: CompanyStatsProps[] | undefined;
}

export function LinkedUsersTable({
  stats,
}: LinkedUsersTableProps): JSX.Element {
  return (
    <>
      <Title>{Constants.linkedUsers}</Title>
      <BoxText sx={{ flexDirection: 'column' }}>
        <StyledTableContainer>
          <Table size="small" stickyHeader>
            <TableHeadStyled>
              <TableRow>
                {LinkedUsers.map((data) => (
                  <CellHeader key={data}>{data}</CellHeader>
                ))}
              </TableRow>
            </TableHeadStyled>
            <TableBody>
              {stats?.map((item) => (
                <StyledRow key={item.id}>
                  <CellBody>{item.responsible_name}</CellBody>
                  <CellBody>
                    {item.responsible_document &&
                      formatCPF(item.responsible_document)}
                  </CellBody>
                  <CellBody>{item.engineer_document}</CellBody>
                  <CellBody align="center">{item.sampler?.success}</CellBody>
                  <CellBody align="center">{item.report?.success}</CellBody>
                </StyledRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </BoxText>
    </>
  );
}
