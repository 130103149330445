/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Status } from '../../../api/enumerations';
import {
  addPathology,
  deletePathology,
  getAllPathologies,
} from '../../../api/pathologies';
import { AllPathologiesData } from '../../../api/pathologies/types';
import {
  deletePicture,
  getPicture,
  updatePicture,
} from '../../../api/pictures';
import { PictureData } from '../../../api/pictures/types';
import { GetWorkOrder } from '../../../api/workOrders';
import { MultilineTextField } from '../../../components/CustomInput';
import DeletePictureDialog from '../../../components/DeletePictureDialog';
import { Title } from '../../../components/Sections/Title';
import Snackbar from '../../../components/Snackbar';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddPhotoAlternateMS,
  IconArrowBackMS,
  IconArrowCircleLeftMS,
  IconArrowForwardMS,
  IconAssignmentMS,
  IconDesignServicesMS,
  IconDoorFrontMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/imageDetails';
import { GlobalContext } from '../../../context/global';
import { validateImg } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import Pathologies from './Pathologies';
import {
  BackIcon,
  ButtonBox,
  Container,
  DownloadButton,
  FlexEndBox,
  FlexReverseBox,
  GalleryContainer,
  GridContainer,
  ImageCard,
  InsideBox,
  PhotoContainer,
  PicBackButton,
  PicForwardButton,
  SectionBox,
  SectionContainer,
  SectionSubtitle,
  StyledCardMedia,
  SubmitButton,
  UploadBox,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export function ImageDetails(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [observation, setObservation] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isPictureNew, setIsPictureNew] = useState(false);
  const [newPathologyId, setNewPathologyId] = useState<number>();
  const [dragActive, setDragActive] = useState(false);
  const [pictureData, setPictureData] = useState<PictureData>();
  const [pathologiesData, setPathologiesData] = useState<AllPathologiesData[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const picturesPerPage = 11;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const navigate = useNavigate();
  const location = useLocation();
  const { id, inspection, room, imageId } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);
  const roomId = parseInt(room as string, 10);
  const pictureId = parseInt(imageId as string, 10);
  const newImage = location.pathname.includes('new');

  const navigateBack = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
    );
  };

  const resetData = (): void => {
    setObservation('');
    setPathologiesData([]);
    setLastCalledPage(0);
    setPage(1);
  };

  const navigatePreviousImage = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureData?.next_id}`
    );
    resetData();
  };

  const navigateNextImage = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureData?.previous_id}`
    );
    resetData();
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await getPicture(pictureId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setPictureData(response.data);
        setRoomName(response.data.room.name);

        if (response.data.observations) {
          setObservation(response.data.observations);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }

      try {
        const response = await GetWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PROPOSAL ||
          response.data.status === Status.CONTRACT ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await getAllPathologies(
        pictureId,
        page,
        picturesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPathologiesData([...pathologiesData, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, pictureId]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page, pictureId]);

  const openPathologyModal = (pictureId: number): void => {
    setNewPathologyId(pictureId);
    setOpenModal(true);
  };

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    const formData = new FormData();
    Array.from(files).map((file) =>
      formData.append('pathology_pictures', file)
    );
    formData.append('picture_id', pictureId.toString());

    try {
      const response = await addPathology(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsPictureNew(true);
      setNewPathologyId(response.data.first_id);
      setOpenModal(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !validateImg(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.dataTransfer;

    handleSubmitPhotos(files);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.target;

    handleSubmitPhotos(files);
  };

  const handleDeletePhoto = async (pictureId: number): Promise<void> => {
    try {
      const response = await deletePicture(pictureId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate(
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
      );
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleOpenNextModal = async (pictureId: number): Promise<void> => {
    setNewPathologyId(pictureId);
    setTimeout(() => {
      setOpenModal(true);
    }, 2000);
  };

  const handleDeletePathology = async (pathologyId: number): Promise<void> => {
    try {
      const response = await deletePathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredPathologies = pathologiesData.filter((element) => {
        return element.id !== pathologyId;
      });

      setPathologiesData(filteredPathologies);

      setSnackbarMessage('Patologia deletada!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('observations', observation);

    try {
      const response = await updatePicture(pictureId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Edições realizadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (location.pathname.includes('new') && pictureData?.next_id) {
        resetData();
        navigate(
          // eslint-disable-next-line max-len
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${pictureData.next_id}`
        );
      } else {
        navigateBack();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackIcon onClick={navigateBack}>{IconArrowCircleLeftMS}</BackIcon>
      <Container>
        <Title title={roomName} icon={IconDoorFrontMS} />
        <SectionTitle>
          {IconAssignmentMS}
          {Constants.imageDetails}
        </SectionTitle>
        <SectionContainer>
          <SectionBox>
            {pictureData && (
              <>
                {!newImage && (
                  <>
                    <PicBackButton
                      disabled={!pictureData.next_id}
                      onClick={() => {
                        navigatePreviousImage();
                      }}
                    >
                      {IconArrowBackMS}
                    </PicBackButton>
                    <FlexEndBox>
                      <PicForwardButton
                        disabled={!pictureData.previous_id}
                        onClick={() => navigateNextImage()}
                      >
                        {IconArrowForwardMS}
                      </PicForwardButton>
                    </FlexEndBox>
                  </>
                )}
                <PhotoContainer>
                  <ImageCard
                    sx={{ marginTop: newImage ? '32px' : '' }}
                    image={pictureData.file}
                    title={pictureData.name || ''}
                  />
                </PhotoContainer>
                <FlexEndBox>
                  <DownloadButton
                    onClick={() =>
                      handleDownloadFile(
                        pictureData.file,
                        pictureData.name || 'imagem'
                      )
                    }
                  >
                    {IconDownloadMS}
                    {Constants.download}
                  </DownloadButton>
                </FlexEndBox>
              </>
            )}
            {edit ? (
              <MultilineTextField
                id="observation"
                label="observações"
                value={observation}
                setValue={setObservation}
              />
            ) : (
              <Typography>Observações: {observation}</Typography>
            )}
            <SectionSubtitle>
              {IconDesignServicesMS}
              {Constants.pathologies}
            </SectionSubtitle>
            <Typography>{Constants.pathologiesText}</Typography>
            <GalleryContainer>
              {edit && (
                <UploadBox
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <label htmlFor="uploadPathologies">
                    <input
                      accept="image/*"
                      multiple
                      id="uploadPathologies"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                        handleFileUpload(e)
                      }
                    />
                    {!dragActive ? (
                      <InsideBox>
                        <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                        <UploadText>{Constants.uploadText}</UploadText>
                        <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                      </InsideBox>
                    ) : (
                      <UploadText>{Constants.dragText}</UploadText>
                    )}
                  </label>
                </UploadBox>
              )}
              {pathologiesData?.map((photo) => (
                <PhotoContainer key={photo.id}>
                  {edit && (
                    <FlexReverseBox>
                      <DeletePictureDialog
                        outlinedButton={false}
                        modalCallback={handleDeletePathology}
                        pictureId={photo.id}
                      />
                    </FlexReverseBox>
                  )}
                  <StyledCardMedia
                    image={photo.file}
                    title="patologias"
                    onClick={() => openPathologyModal(photo.id)}
                  />
                </PhotoContainer>
              ))}
            </GalleryContainer>
          </SectionBox>
        </SectionContainer>
        <ButtonBox>
          {pictureData && edit && (
            <DeletePictureDialog
              outlinedButton
              modalCallback={handleDeletePhoto}
              pictureId={pictureData.id}
            />
          )}
          {edit && (
            <SubmitButton onClick={handleSubmit}>
              {Constants.submitImage}
            </SubmitButton>
          )}
        </ButtonBox>
      </Container>
      {openModal && newPathologyId && (
        <Pathologies
          isOpen={openModal}
          setOpenModal={setOpenModal}
          pathologyId={newPathologyId}
          isPictureNew={isPictureNew}
          nextModalCallback={handleOpenNextModal}
          pictureId={pictureId}
          setPathologies={setPathologiesData}
          edit={edit}
        />
      )}
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
