import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  ApproveWorkOrderProps,
  CreateWorkOrderProps,
  DetailProps,
  ForceFactorsProps,
  GetAllWorkOrderProps,
  GetTopWorkOrders,
  GetWorkOrderProps,
  GetInStatusWorkOrdersProps,
  GetArchivedWorkOrdersProps,
} from './types';

export const createWorkOrder = (
  data: FormData
): Promise<CreateWorkOrderProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/work-orders`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const editWorkOrder = (
  id: number,
  data: FormData,
  updateCoordinate = false
): Promise<CreateWorkOrderProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}?force_coordinate_event=${updateCoordinate}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getArchivedWorkOrders = (
  page: number,
  size: number
): Promise<GetArchivedWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    `${BaseUrl}/api/V1/work-orders/archived?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllWorkOrders = (
  search: string
): Promise<GetAllWorkOrderProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders?search_string=${search}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getTopWorkOrders = (): Promise<GetTopWorkOrders> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/top`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrdersInStatus = (
  status: number,
  page: number,
  size: number
): Promise<GetInStatusWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/in-status/${status}?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const GetWorkOrder = (id: number): Promise<GetWorkOrderProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const CancelWorkOrder = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/cancel`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const ChangeStatus = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/status`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const PreviousStatus = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/previous-status`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const SendToPept = (
  id: number,
  observation: string
): Promise<DetailProps> => {
  const data = JSON.stringify({ observation });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/pept`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const SendInvoiceAndReceipt = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/invoice-receipt`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const SendProofOfPayment = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/proof_of_payment`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addGoogleMapsImagesWithPins = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/google-maps-images`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getReportPDF = (id: number): Promise<Uint8Array> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/report-pdf`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Não foi possível carregar o relatório');
      }
      return response.arrayBuffer();
    })
    .then((buffer) => new Uint8Array(buffer))
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const ApproveWorkOrder = (
  id: number,
  data: ApproveWorkOrderProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/approve`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendGraphs = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/report-graphs`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const generateReportPDF = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/generate-report-pdf`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrderPdf = (
  id: number,
  token: string
): Promise<GetWorkOrderProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const UploadFacadeImage = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/facade-image`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const exportSample = (id: number): Promise<Response> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/samples-xlsx`,
    requestOptions
  ).then((response) => response);
};

export const deleteAllSample = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };

  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/samples`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendForceFactors = (
  id: number,
  data: ForceFactorsProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/avm-info`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
