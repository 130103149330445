import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const FlexSpaceBetweenBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '180px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  gap: 20,
  margin: '80px 0',
}));

export const OutlineButton = styled(Button)(() => ({
  minWidth: '250px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
