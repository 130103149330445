export const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const myHeadersWithTokenContentType = (): Headers => {
  const token = localStorage.getItem('control-center-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

export const myHeadersWithToken = (): Headers => {
  const token = localStorage.getItem('control-center-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  return myHeaders;
};
