/* eslint-disable react/require-default-props */
import { useContext, useState } from 'react';

import { addReport } from '../../api/errorReport';
import { NewReport } from '../../api/errorReport/types';
import { IconEditNoteMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { WhiteProgress } from '../UI/Typography';
import {
  ButtonBox,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  StyledTextField,
  TextTypography,
} from './styles';

interface ReportErrorDialogProps {
  element?: boolean;
  sampleId?: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  updateSample?: () => void;
}

export function ReportErrorDialog({
  element,
  sampleId,
  open,
  setOpen,
  updateSample,
}: ReportErrorDialogProps): JSX.Element {
  const [report, setReport] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const handleClose = (): void => setOpen(false);

  const handleSubmit = async (): Promise<void> => {
    setLoadingSubmit(true);

    if (!report) {
      setSnackbarMessage('Preencha o motivo do erro!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
      return;
    }

    const reportData: NewReport = {
      error_description: report,
      work_order_id: osId,
      report_source: element ? 1 : 2,
    };

    if (element) {
      reportData.sample_id = sampleId;
    }

    try {
      const response = await addReport(reportData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Erro reportado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
      handleClose();
      if (element && updateSample) {
        updateSample();
      } else {
        navigateHome();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContainer>
        <TextTypography>
          {IconEditNoteMS}
          Reportar erro
        </TextTypography>
        <StyledTextField
          id="report"
          multiline
          rows={6}
          color="secondary"
          placeholder="Escreva aqui o motivo do erro"
          value={report}
          onChange={(e) => {
            setReport(e.target.value);
          }}
        />
        <ButtonBox>
          <OutlinedButton onClick={handleClose}>cancelar</OutlinedButton>
          <FilledButton onClick={handleSubmit} disabled={loadingSubmit}>
            {loadingSubmit ? <WhiteProgress size={22} /> : 'enviar'}
          </FilledButton>
        </ButtonBox>
      </DialogContainer>
    </StyledDialog>
  );
}
