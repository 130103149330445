import { Box, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(() => ({
  '.map-container': {
    position: 'relative',
    height: '318px',
    minWidth: '400px',
  },
  '.map-container.expanded': {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '99vw',
    height: '100vh',
    zIndex: 1000000,
  },
  '.customMap': {
    height: '100%',
    width: '100%',
    minHeight: '318px',
    minWidth: '400px',
  },
  '.customMap > div:first-of-type': {
    border: '1px solid #D7D8DB',
    borderRadius: '10px',
  },
  '.yNHHyP-marker-view': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '.expand-button': {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'white',
    zIndex: 10,
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

export const PinText = styled(Typography)(() => ({
  marginTop: '6px',
  display: 'flex',
  flexDirection: 'row-reverse',
  fontSize: '14px',
  fontWeight: '500',
}));

interface StyledTypographyProps {
  error?: 'true';
}

export const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ error }) => ({
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontSize: '14px',
    fontWeight: '500',
    color: error ? '#00B39B' : '#FF334B',
  })
);

export const CheckboxFormControl = styled(FormControlLabel)(() => ({
  '&:hover': {
    '& .MuiTypography-root': {
      fontWeight: '600',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
}));
