export const Constants = {
  title: 'Usuário',
  buttonAdd: 'adicionar usuário',
  buttonSearch: 'buscar',
  buttonEdit: 'confirmar alterações',
  search: 'buscar nesta página',
  panel: 'Painel de usuários',
  status: 'situação',
  active: 'ativo',
  appExclusiveUser: 'usuário exclusivo app',
  name: 'nome',
  role: 'função',
  email: 'e-mail',
  appExclusive: 'exclusivo APP',
  newUser: 'nome do usuário',
  editUser: 'editar dados do usuário',
  namePlaceholder: 'digite o nome do usuário',
  emailPlaceholder: 'digite o email do usuário',
  rolePlaceholder: 'selecione a função',
  protoUser: 'usuário do ProtoApp',
};
