import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '60px',
}));

export const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '600px',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  height: '96px',
  width: '245px',
}));

export const StyledIcon = styled(Typography)(() => ({
  '& span': { fontSize: '180px', color: '#004D43' },
}));

export const BoldTypography = styled(Typography)(() => ({
  fontSize: '48px',
  fontWeight: '700',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '21px',
  textAlign: 'center',
  color: '#707070',
}));
