import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    padding: '60px 80px 0',
    [theme.breakpoints.down('lg')]: {
      minWidth: '700px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '1000px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1200px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const CloseIcon = styled(IconButton)(() => ({
  right: '20px',
  top: '20px',
  position: 'absolute',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const SectionSubtitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '20px',
  '& span': { color: '#00B39B' },
}));

export const ImageCard = styled(CardMedia)(({ theme }) => ({
  backgroundColor: '#bcbcbc66',
  width: '768px',
  height: '576px',
  borderRadius: '15px',
  alignSelf: 'center',
  backgroundSize: 'contain',
  objectFit: 'contain',
  margin: 'auto',
  marginBottom: 40,
  [theme.breakpoints.down('xl')]: {
    width: '640px',
    height: '480px',
  },
}));

export const FlexEndBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
}));

export const DownloadButton = styled(ButtonBase)(() => ({
  float: 'right',
  height: '42px',
  width: '280px',
  border: '1px solid #737475',
  borderRadius: '16px',
  display: 'flex',
  gap: '10px',
  '& span': {
    color: '#06DFC2',
  },
  '&:hover': {
    backgroundColor: '#EDEFF2',
    borderColor: '#06DFC2',
  },
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '60px 0',
}));

export const SubmitButton = styled(Button)(() => ({
  minWidth: '250px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
