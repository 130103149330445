import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
}));

export const BackToSamplesButton = styled(Button)(({ theme }) => ({
  width: 330,
  height: 48,
  /* UI Properties */
  background: '#FF6600 0% 0% no-repeat padding-box',
  borderRadius: 30,
  opacity: 1,
  marginRight: 16,
  '&:hover': {
    background: '#FF6600AA',
  },
  '&:active': {
    background: '#FF6600AA',
  },
  [theme.breakpoints.down('xl')]: {
    width: 'auto',
    padding: 16,
  },
}));

export const ContainerHeaderButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const AvmStatusBox = styled(Box)(() => ({
  width: '100%',
  padding: '23px 37px',
  margin: '50px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  background: '#F5F7FB',
  border: `1px solid #E80B25`,
  color: '#E80B25',
  fontSize: 21,
  textAlign: 'justify',
}));
