import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  AddPictureProps,
  DetailProps,
  GetPictureProps,
  GetPicturesProps,
  SelectInspectionPicturesData,
} from './types';

export const addPicture = (data: FormData): Promise<AddPictureProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/pictures`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllPictures = (
  id: number,
  page: number,
  size: number
): Promise<GetPicturesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/rooms/${id}/pictures?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getPicture = (id: number): Promise<GetPictureProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updatePicture = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const selectInspectionPictures = (
  data: SelectInspectionPicturesData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/pictures/select`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deletePicture = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
