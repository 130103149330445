import { CardMedia, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridFactors = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
}));

export const BoxProto = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}));

export const TypographyProto = styled(Typography)(() => ({
  color: '#707070',
  width: '320px',
  textAlign: 'center',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  height: '72px',
  width: '72px',
}));
