import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '50px',
  backgroundColor: '#FAFCFF',
  height: '100%',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '40px 0 40px 20px',
  alignItems: 'center',
}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '42px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  '& span': { fontSize: '42px', color: '#00B39B' },
}));

export const DesktopMode = styled(Button)(() => ({
  fontSize: '34px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '60px',
  gap: '10px',
  height: '110px',
  width: '400px',
  '& span': { fontSize: '42px' },
}));

export const PipelineContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '52px',
  padding: '20px',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    height: '30px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#00B39B',
    },
  },
  '&::-webkit-scrollbar-track-piece:start': {
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: '2px 3px 6px #00000033',
    margin: '0 380px',
    borderRadius: '20px',
  },
  '::-webkit-scrollbar-track-piece:end': {
    backgroundColor: 'white',
    borderRadius: '20px',
  },
}));

export const PipelineBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  minWidth: '700px',
  width: '700px',
  textAlign: 'center',
}));

export const PipelineTitle = styled(Typography)(() => ({
  borderRadius: '20px',
  backgroundColor: '#004D43',
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#EDEFF2',
  fontWeight: '32px',
  fontSize: '40px',
}));

export const PipelinePaper = styled(Paper)(() => ({
  minHeight: '1500px',
  maxHeight: '1500px',
  overflowY: 'auto',
  boxSizing: 'border-box',
  backgroundColor: '#8B8C8E',
  borderRadius: '20px',
  padding: '16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  marginBottom: '30px',

  '&.active': {
    outline: 'none',
    backgroundColor: '#00B39B',
  },
}));
