import {
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  alpha,
  styled,
} from '@mui/material';

export const GridContainer = styled(Grid)(() => ({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 4,
  [theme.breakpoints.down('xl')]: {
    gap: 8,
    marginLeft: -24,
  },
}));

export const GridTitle = styled(Typography)(() => ({
  color: '#004D43',
  font: '500 21px Montserrat',
  marginBottom: 16,
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
  width: 500,
  height: 600,
  background: '#FFFCFE',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
  padding: '30px 42px',
  justifyContent: 'center',
  overflowY: 'scroll',

  [theme.breakpoints.down('xl')]: {
    width: 425,
  },
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },

  [theme.breakpoints.down('xl')]: {
    width: 240,
  },
}));

export const SearchContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  // width: '100%',
  marginBottom: 42,
  position: 'static',

  '& span': {
    color: '#909090',
    fontSize: 32,
  },

  '& input': {
    border: 'none',
    flex: 1,
    font: '400 16px Montserrat',
    marginLeft: 8,
    height: 32,
    borderBottom: '1px solid #909090',
  },

  '& button': {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: '#004E43',
    height: 32,
    font: '600 16px Montserrat',
    borderBottom: '1px solid #909090',

    '&:hover': {
      color: alpha('#004E43', 0.8),
    },
  },
}));

export const CardContainer = styled(Box)(() => ({
  display: 'grid',
  width: '100%',
  gap: 16,
  // overflow: 'scroll',
  padding: 4,
}));

export const CardStyled = styled(Box)(({ theme }) => ({
  height: 193,
  background: '#fafcff',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
  padding: 25,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,

  '&.selected': {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  [theme.breakpoints.down('xl')]: {
    fontSize: 15,
  },
}));

export const CompanyHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '& img': {
    width: 60,
    height: 60,
  },

  '& span': {
    fontSize: 20,
    fontWeight: 500,
    color: '#004D43',

    '& strong': {
      fontWeight: 500,
    },
  },
}));

export const SkeletonStyled = styled(Skeleton)(() => ({
  height: 193,
  background: '#fafcff',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
  padding: 25,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));
