import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(() => ({
  width: '52px',
  height: '52px',
  minWidth: '50px',
  borderRadius: '50%',
  position: 'sticky',
  float: 'right',
  bottom: '20px',
  margin: '-26px -26px 20px 0',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '& span': {
    fontSize: '32px',
  },
}));
