/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  addAttachment,
  deleteAttachment,
  getAllAttachedFiles,
  getAttachment,
} from '../../api/attachments';
import { AttachmentData } from '../../api/attachments/types';
import { Status, StatusCode } from '../../api/enumerations';
import { exportSample, getReportPDF } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import {
  IconAttachFileMS,
  IconCloseMS,
  IconDeleteMS,
  IconDownloadMS,
  IconEditMS,
  IconFolderOpenMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { validateFiles } from '../../helpers';
import { useAttachment } from '../../hooks/useAttachmentFiles';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { CustomTextField } from '../CustomInput';
import { Constants } from './constants';
import EditAttachments from './EditAttachments';
import {
  AttachFileButon,
  AttachmentsBox,
  CancelButton,
  CloseButton,
  ContainerBox,
  DateTypography,
  DialogTitle,
  FileContainer,
  FileList,
  FileNameTypography,
  InputBox,
  List,
  OpenDialogButton,
  StyledDialog,
  StyledDownload,
  StyledIcon,
  SubmitBox,
  SubmitButton,
  TopBox,
  UploadBox,
} from './styles';

interface AllAttachmentsProps {
  propertyData?: WorkOrderData;
  osId: number;
  update?: boolean;
}

export function AllAttachments({
  propertyData,
  osId,
  update,
}: AllAttachmentsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fileToEdit, setFileToEdit] = useState('');
  const [iptuDocument, setIptuDocument] = useState('');
  const [register, setRegister] = useState('');
  const [receipt, setReceipt] = useState('');
  const [invoice, setInvoice] = useState('');
  const [proofOfPayment, setProofOfPayment] = useState('');
  const [description, setDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileAttached, setFileAttached] = useState<File | string>();

  const [updateFiles, setUpdateFiles] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [files, setFiles] = useState<AttachmentData[]>([]);
  const filesPerPage = 8;
  const editOS =
    !osId ||
    propertyData?.status === Status.ENTRANCE ||
    propertyData?.status === Status.SCHEDULE ||
    propertyData?.status === Status.INSPECTION ||
    propertyData?.status === Status.SAMPLE_CREATION;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { updateAttFiles, setUpdateAttFiles } = useAttachment();
  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadXlsx, handleDownloadPdf } = useGeneral();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    setOpen(false);
    setEdit(false);
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await getAllAttachedFiles(osId, page, filesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setFiles([...files, ...response.data]);
      setLastCalledPage(page);
      setUpdateFiles(false);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (osId) getDataCallback();
  }, [getDataCallback, update, osId]);

  useEffect(() => {
    if (updateFiles) {
      setLastCalledPage(0);
      setPage(1);
      setDescription('');
      setFileName('');
      setFileAttached(undefined);
      setFiles([]);
      getDataCallback();
    }
  }, [getDataCallback, updateFiles]);

  useEffect(() => {
    if (propertyData) {
      setRegister(propertyData.real_estate_registry || '');
      setIptuDocument(propertyData.iptu_document || '');
      setReceipt(propertyData.receipt);
      setInvoice(propertyData.invoice);
      setProofOfPayment(propertyData.proof_of_payment);
    }
  }, [propertyData]);

  const downloadFile = (link: string): void => {
    const linkSource = link;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.click();
  };

  const handleDownloadFile = async (fileId: number): Promise<void> => {
    try {
      const response = await getAttachment(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        downloadFile(response.data.file);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteFile = async (fileId: number): Promise<void> => {
    try {
      const response = await deleteAttachment(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredFiles = files.filter((e) => e.id !== fileId);
      setFiles(filteredFiles);
      setUpdateAttFiles(!updateAttFiles);
      setSnackbarMessage('Arquivo deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleExportSamples = async (): Promise<void> => {
    try {
      const response = await exportSample(osId);

      if (response.ok) {
        handleDownloadXlsx(response);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleExportReportPDF = async (): Promise<void> => {
    try {
      const response = await getReportPDF(osId);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const file = binaryData
        ? new File([binaryData], 'report.pdf', { type: binaryData.type })
        : undefined;
      if (response && file) {
        handleDownloadPdf(file);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setFileName(file.name);
    setFileAttached(file);
  };

  const handleSubmitFile = async (): Promise<void> => {
    if (!description) {
      setSnackbarMessage('Adicione uma descrição para o arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!fileAttached) {
      setSnackbarMessage('Adicione um arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('work_order_id', osId.toString());
    formData.append('attachment', fileAttached);
    formData.append('description', description);

    try {
      const response = await addAttachment(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Arquivo adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);

      setUpdateFiles(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <ContainerBox>
      <OpenDialogButton disableTouchRipple onClick={handleOpen}>
        {IconAttachFileMS}
        {Constants.attachments}
      </OpenDialogButton>
      <StyledDialog open={open} onClose={handleClose}>
        {edit ? (
          <EditAttachments setEdit={setEdit} file={fileToEdit} osId={osId} />
        ) : (
          <>
            <TopBox>
              <DialogTitle>
                {IconAttachFileMS}
                {Constants.attachments}
              </DialogTitle>
              <CloseButton onClick={handleClose} disableTouchRipple>
                {IconCloseMS}
              </CloseButton>
            </TopBox>
            <UploadBox>
              <CustomTextField
                id="file-description"
                label="descrição"
                value={description}
                setValue={setDescription}
                maxLength={60}
              />
              <InputBox>
                <CustomTextField
                  disabled
                  id="file-name"
                  label="arquivo"
                  value={fileName}
                  setValue={setFileName}
                />
                <label htmlFor="uploadAttachFiles">
                  <input
                    accept="image/*,application/pdf"
                    id="uploadAttachFiles"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e)
                    }
                  />
                  <AttachFileButon component="span">
                    {Constants.selectFile}
                  </AttachFileButon>
                </label>
              </InputBox>
              <SubmitBox>
                <CancelButton onClick={handleClose}>
                  {Constants.cancel}
                </CancelButton>
                <SubmitButton onClick={handleSubmitFile}>
                  {Constants.add}
                </SubmitButton>
              </SubmitBox>
            </UploadBox>
            <AttachmentsBox>
              <DialogTitle my={3}>
                {IconFolderOpenMS}
                {Constants.files}
              </DialogTitle>
              <FileList>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {Constants.propertyReg}
                  </Box>
                  <Box>
                    {editOS && (
                      <StyledDownload
                        disabled={osId ? !register : false}
                        onClick={() => {
                          setEdit(true);
                          setFileToEdit('register');
                        }}
                      >
                        {IconEditMS}
                      </StyledDownload>
                    )}
                    <StyledDownload
                      onClick={() => downloadFile(register)}
                      disabled={!register}
                    >
                      {IconDownloadMS}
                    </StyledDownload>
                  </Box>
                </List>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {Constants.iptu}
                  </Box>
                  <Box>
                    {editOS && (
                      <StyledDownload
                        disabled={osId ? !iptuDocument : false}
                        onClick={() => {
                          setEdit(true);
                          setFileToEdit('IPTU');
                        }}
                      >
                        {IconEditMS}
                      </StyledDownload>
                    )}
                    <StyledDownload
                      onClick={() => downloadFile(iptuDocument)}
                      disabled={!iptuDocument}
                    >
                      {IconDownloadMS}
                    </StyledDownload>
                  </Box>
                </List>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {Constants.paymentSlip}
                  </Box>
                  <StyledDownload
                    onClick={() => downloadFile(receipt)}
                    disabled={!receipt}
                  >
                    {IconDownloadMS}
                  </StyledDownload>
                </List>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {Constants.invoice}
                  </Box>
                  <StyledDownload
                    onClick={() => downloadFile(invoice)}
                    disabled={!invoice}
                  >
                    {IconDownloadMS}
                  </StyledDownload>
                </List>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {Constants.paymentVoucher}
                  </Box>
                  <StyledDownload
                    onClick={() => downloadFile(proofOfPayment)}
                    disabled={!proofOfPayment}
                  >
                    {IconDownloadMS}
                  </StyledDownload>
                </List>
                {propertyData &&
                  propertyData.status >= Status.SAMPLE_CREATION && (
                    <List>
                      <Box>
                        {IconAttachFileMS}
                        {Constants.exportSamples}
                      </Box>
                      <StyledDownload
                        onClick={() => handleExportSamples()}
                        disabled={!propertyData.report_xlsx}
                      >
                        {IconDownloadMS}
                      </StyledDownload>
                    </List>
                  )}
                {propertyData && propertyData.status >= Status.REVISION && (
                  <List>
                    <Box>
                      {IconAttachFileMS}
                      {Constants.report}
                    </Box>
                    <StyledIcon
                      onClick={handleExportReportPDF}
                      disabled={!propertyData.report_pdf}
                    >
                      {IconDownloadMS}
                    </StyledIcon>
                  </List>
                )}
                {files.length > 0 &&
                  files.map((file) => (
                    <FileContainer container key={file.id}>
                      <Box>
                        <FileNameTypography>
                          {IconAttachFileMS}
                          {file.filename}
                        </FileNameTypography>
                        <DateTypography sx={{ paddingLeft: '21px' }}>
                          {file.description}
                        </DateTypography>
                      </Box>
                      <Box>
                        <StyledIcon onClick={() => handleDownloadFile(file.id)}>
                          {IconDownloadMS}
                        </StyledIcon>
                        <StyledIcon onClick={() => handleDeleteFile(file.id)}>
                          {IconDeleteMS}
                        </StyledIcon>
                      </Box>
                    </FileContainer>
                  ))}
              </FileList>
            </AttachmentsBox>
          </>
        )}
      </StyledDialog>
    </ContainerBox>
  );
}
