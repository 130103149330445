/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, Button } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { updateErrorReport } from '../../../../api/errorReport';
import {
  AvmInferenceData,
  AvmFactorsData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { MultilineTextField } from '../../../../components/CustomInput';
import { AccordionTitle } from '../../../../components/Sections/AccordionTitle';
import { ClientData } from '../../../../components/Sections/ClientData';
import { PdfViewer } from '../../../../components/Sections/PdfViewer';
import { PropertyAddress } from '../../../../components/Sections/PropertyAddress';
import { PropertyData } from '../../../../components/Sections/PropertyData';
import { PropertyInfo } from '../../../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../../../components/Sections/ReportInfo';
import { Title } from '../../../../components/Sections/Title';
import { ToogleAccordion } from '../../../../components/Sections/ToogleAccordion';
import { Constants } from '../../../../constants/errorReport';
import {
  IconApartmentMS,
  IconDescriptionMS,
  IconEditDocumentMS,
  IconEngineeringMS,
  IconLocationCityMS,
  IconLocationOnMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { useAccordion } from '../../../../hooks/useAccordion';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { BoxContainer, SectionBox, SectionTitle, SubmitBox } from '../styles';

interface RevisionProps {
  propertyData: WorkOrderData;
  errorFindings: string | undefined;
}

export function Revision({
  propertyData,
  errorFindings,
}: RevisionProps): JSX.Element {
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [findings, setFindings] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const reportId = parseInt(id as string, 10);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (expandOne && expandTwo && expandThree && expandEight && expandNine) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree, expandEight, expandNine]);

  const getDataCallback = useCallback(async () => {
    const avmData = propertyData.avm_report as
      | AvmInferenceData
      | AvmFactorsData
      | null;

    if (avmData) {
      setHasAvmReport(true);
    }
  }, []);

  useEffect(() => {
    if (errorFindings) {
      setFindings(errorFindings);
    }
    setExpandNine(true);
    getDataCallback();
  }, [getDataCallback]);

  const handleUpdateReport = async (): Promise<void> => {
    if (findings.length < 3) {
      setSnackbarMessage('Adicione observações!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const reportData = {
      findings,
      finished: isUpdated,
    };

    try {
      const response = await updateErrorReport(reportId, reportData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(
        isUpdated ? 'Erro finalizado!' : 'Dados alterados com sucesso!'
      );
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsUpdated(true);
      if (isUpdated) navigate('/error-report');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <BoxContainer>
      <Title
        osNumber={0}
        newOs
        title={Constants.revision}
        createdAt={propertyData?.created_at}
      />
      <ClientData propertyData={propertyData} />
      <Box>
        <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyData}
            icon={IconApartmentMS}
            openAccordion={expandOne}
            setOpenAccordion={setExpandOne}
          />
          {expandOne && <PropertyData propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyAddress}
            icon={IconLocationOnMS}
            openAccordion={expandTwo}
            setOpenAccordion={setExpandTwo}
          />
          {expandTwo && <PropertyAddress propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyDetails}
            icon={IconLocationCityMS}
            openAccordion={expandThree}
            setOpenAccordion={setExpandThree}
          />
          {expandThree && <PropertyInfo propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.report}
            icon={IconEngineeringMS}
            openAccordion={expandEight}
            setOpenAccordion={setExpandEight}
          />
          {expandEight && (
            <Box>
              {hasAvmReport && propertyData ? (
                <ReportInfo propertyData={propertyData} />
              ) : (
                <Box>{Constants.noReport}</Box>
              )}
            </Box>
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.pdfReport}
            icon={IconDescriptionMS}
            openAccordion={expandNine}
            setOpenAccordion={setExpandNine}
          />
          {expandNine && <PdfViewer osId={propertyData.id} />}
        </SectionBox>
        <SectionBox>
          <SectionTitle mb="20px">
            {IconEditDocumentMS}
            {Constants.findings}
          </SectionTitle>
          <MultilineTextField
            id="findings"
            label="observações"
            value={findings}
            setValue={setFindings}
          />
        </SectionBox>
        <SubmitBox>
          {isUpdated ? (
            <Button onClick={handleUpdateReport}>{Constants.finish}</Button>
          ) : (
            <Button onClick={handleUpdateReport}>{Constants.save}</Button>
          )}
        </SubmitBox>
      </Box>
    </BoxContainer>
  );
}
