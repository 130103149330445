import { Box, Tab, styled } from '@mui/material';

export const BoxContainer = styled(Box)(({ theme }) => ({
  '& .Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export const TabContainer = styled(Box)(() => ({
  padding: '40px 0',
}));

export const TabStyled = styled(Tab)(() => ({
  textTransform: 'capitalize',
  fontSize: 16,
  fontWeight: 400,
  color: '#707070',
}));
