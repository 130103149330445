import {
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
  display: 'flex',
  gap: '10px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const SampleText = styled(Typography)(() => ({
  width: '100%',
  margin: '20px 0 -10px',
  textAlign: 'center',
  fontSize: '21px',
  fontWeight: '600',
  color: '#004D43',
}));

export const DashboardContainer = styled(Box)(() => ({
  margin: '20px 0 0',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  height: '45px',
  border: 'none',
  '& :hover': {
    color: '#00B39B',
  },
  '& .MuiButtonBase-root.MuiToggleButton-root': {
    '&.Mui-selected': {
      color: '#FAFCFF',
      backgroundColor: '#004D43',
      border: 'none',
      borderRadius: '4px',
      '& :hover': {
        color: '#FAFCFF',
        backgroundColor: '#004D43',
        opacity: '80%',
      },
      '&:hover': {
        backgroundColor: '#004D43',
        opacity: '80%',
      },
    },
  },
}));

export const StyledDivider = styled(Divider)(() => ({
  margin: '0 10px',
  borderRightWidth: 'medium',
}));

interface LevelButtonProps {
  complete?: string;
}

export const LevelButton = styled(ToggleButton)<LevelButtonProps>(
  ({ complete }) => ({
    width: '140px',
    height: '45px',
    borderRadius: '8px',
    fontSize: '18px',
    textTransform: 'none',
    marginRight: '15px',
    color: complete ? '#58147F' : '#FF872E',
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: complete ? '#58147F' : '#FF872E',
    '&.Mui-selected': {
      color: '#FAFCFF',
      backgroundColor: complete ? '#58147F' : '#FF872E',
      border: 'none',
      '& :hover': {
        color: '#FAFCFF',
        backgroundColor: complete ? '#58147F' : '#FF872E',
        opacity: '80%',
      },
      '&:hover': {
        backgroundColor: complete ? '#58147F' : '#FF872E',
        opacity: '80%',
      },
    },
  })
);

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#7E7E7E',
    color: '#FAFCFF',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 6px',
    marginBottom: '2px !important',
  },
}));

export const MissingBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  fontSize: '18px',
  color: '#FAFCFF',
  backgroundColor: '#ADADAD',
  borderRadius: '4px',
  width: '132px',
  height: '32px',
}));
