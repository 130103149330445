/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';

import { ProfileUsers, StatusCode } from '../../api/enumerations';
import { getAllInspections } from '../../api/inspections';
import { InspectionAgendaData } from '../../api/inspections/types';
import { getAllUsers } from '../../api/users';
import { UserData } from '../../api/users/types';
import { views } from '../../constants/agenda';
import { GlobalContext } from '../../context/global';
import { generateRandomHexColor } from '../../helpers';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';

interface AgendaHook {
  getAgendaEvents: (
    startDate: string,
    endDate: string,
    engIds?: string
  ) => Promise<void>;
  handleCheckboxChange: (engineerId: number) => void;
  usersData: IEngineerData[] | [];
  blockUsersData: UserData[] | [];
  engineersData: IEngineerData[] | [];
  selectedEngineers: number[];
  searchMonth: number;
  setSearchMonth: (value: number) => void;
  searchYear: number;
  setSearchYear: (value: number) => void;
  calendarView: number;
  setCalendarView: (value: number) => void;
  engineersIdQuery: string;
  loading: boolean;
  newDate: string;
  setNewDate: (value: string) => void;
  updateAgenda: () => void;
}

export interface IEngineerData extends UserData {
  color: string;
  events: InspectionAgendaData[] | [];
}

const useAgenda = (): AgendaHook => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const today = new Date().toISOString().split('T');

  const [usersData, setUsersData] = useState<IEngineerData[]>([]);
  const [blockUsersData, setBlockUsersData] = useState<UserData[]>([]);
  const [engineersData, setEngineersData] = useState<IEngineerData[]>([]);
  const [selectedEngineers, setSelectedEngineers] = useState<number[]>([]);
  const [newDate, setNewDate] = useState(today[0]);
  const [searchMonth, setSearchMonth] = useState(currentMonth);
  const [searchYear, setSearchYear] = useState(currentYear);
  const [calendarView, setCalendarView] = useState(1);
  const [engineersIdQuery, setEngineersIdQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const updateAgenda = (): void => setUpdate(true);

  const loggedUser = useStoragedJwt();
  const subdomains = ['realprice-st', 'realprice'];
  const companyData = loggedUser?.user.companies.filter((e) =>
    subdomains.includes(e.subdomain)
  );

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getUsersData = useCallback(async () => {
    try {
      const response = await getAllUsers(1, 150);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const activeEngineers = response.data.filter(
        (engineer) => engineer.is_active
      );

      const engineerDataArrayWithColor = activeEngineers.map((user) => {
        const newUserData = {
          ...user,
          color: generateRandomHexColor(),
          events: [],
        };
        return newUserData;
      });
      setUsersData(engineerDataArrayWithColor);

      if (companyData && companyData[0].role === ProfileUsers.ADMIN) {
        setBlockUsersData(response.data);
      } else if (loggedUser?.user.id) {
        setBlockUsersData(
          response.data.filter((user) => user.id === loggedUser.user.id)
        );
      }
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    getUsersData();
  }, []);

  const handleCheckboxChange = (engineerId: number): void => {
    const isSelected = selectedEngineers.includes(engineerId);
    if (isSelected) {
      setSelectedEngineers(selectedEngineers.filter((id) => id !== engineerId));
    } else {
      setSelectedEngineers([...selectedEngineers, engineerId]);
    }
  };

  useEffect(() => {
    if (usersData.length > 0) {
      const url = '&users_ids=';
      let link = '';
      for (let i = 0; i < selectedEngineers.length; i += 1) {
        if (i === 0) {
          link = `${url}${selectedEngineers[i]}`;
        } else {
          link += `&${url}${selectedEngineers[i]}`;
        }
      }
      setEngineersIdQuery(link);
    }
  }, [selectedEngineers]);

  const mapEngineerEvents = (
    engineer: IEngineerData,
    events: InspectionAgendaData[]
  ): IEngineerData => ({
    ...engineer,
    events,
  });

  const updateEngineersData = (response: InspectionAgendaData[]): void => {
    const updatedEngineersData = usersData.map((engineer) => {
      const matchingEvents = response.filter(
        (event) => event.user.id === engineer.id
      );
      return mapEngineerEvents(engineer, matchingEvents);
    });
    setEngineersData(updatedEngineersData);
  };

  const getAgendaEvents = async (
    startDate: string,
    endDate: string,
    engIds?: string
  ): Promise<void> => {
    try {
      const response = await getAllInspections(
        startDate,
        endDate,
        engIds || ''
      );

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data && response.data.length > 0) {
        if (engIds && engIds?.length > 0) {
          const selectedUsers = usersData.filter((user) =>
            response.data.some(
              (selectedUser) => selectedUser.user.id === user.id
            )
          );
          const updatedEngineersData = selectedUsers.map((engineer) => {
            const matchingEvents = response.data.filter(
              (event) => event.user.id === engineer.id
            );
            return mapEngineerEvents(engineer, matchingEvents);
          });
          setEngineersData(updatedEngineersData);
        } else {
          updateEngineersData(response.data);
        }
      } else {
        setEngineersData([]);
      }
      setUpdate(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleParamsAndGetEvents = (): void => {
    const dateView = dayjs(newDate).month(searchMonth).year(searchYear);
    let engineersQuery;
    if (engineersIdQuery.length > 0) {
      engineersQuery = engineersIdQuery;
    }

    let startDate;
    let endDate;

    if (
      calendarView === views.indexOf('dayGridMonth') ||
      calendarView === views.indexOf('timeGridDay')
    ) {
      startDate = dateView.startOf('month').format('YYYY-MM-DD');
      endDate = dateView.endOf('month').format('YYYY-MM-DD');
    } else {
      startDate = dateView.startOf('week').format('YYYY-MM-DD');
      endDate = dateView.endOf('week').format('YYYY-MM-DD');
    }

    getAgendaEvents(startDate, endDate, engineersQuery);
    setNewDate(dateView.format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (usersData.length === 0) return;
    handleParamsAndGetEvents();
  }, [engineersIdQuery, usersData, searchMonth, searchYear, calendarView]);

  useEffect(() => {
    if (update) {
      handleParamsAndGetEvents();
    }
  }, [update]);

  return {
    getAgendaEvents,
    handleCheckboxChange,
    usersData,
    blockUsersData,
    engineersData,
    selectedEngineers,
    searchMonth,
    setSearchMonth,
    searchYear,
    setSearchYear,
    calendarView,
    setCalendarView,
    engineersIdQuery,
    loading,
    newDate,
    setNewDate,
    updateAgenda,
  };
};

export default useAgenda;
