import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  '& span': { color: '#00B39B' },
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '26px',
  marginTop: '32px',
}));

export const UploadBox = styled(Box)(() => ({
  width: '300px',
  minHeight: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(() => ({
  '& span': { fontSize: '72px', color: '#00B39B' },
}));

export const UploadTitle = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
  backgroundColor: '#bcbcbc66',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  objectFit: 'contain',
  backgroundSize: 'contain',
  width: '300px',
  height: '220px',
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const PathologiesBox = styled(Box)(() => ({
  margin: '-52px 12px 0',
  zIndex: '10',
  backgroundColor: '#1A1A1A',
  borderRadius: '50%',
  padding: '4px',
  color: '#EDEFF2',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #707070',
  fontWeight: '600',
  cursor: 'default',
}));
