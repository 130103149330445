import {
  TableCell,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: '100%',
  paddingBottom: '10px',
}));

export const Cell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#F2F2F2',
    backgroundColor: '#004D43',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderRight: '1px solid #F2F2F2',
    '&:last-child': {
      borderRight: '0',
    },
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  color: '#fff',
  fontWeight: '600',
  fontSize: '16px',
  padding: '0 12px',
  height: 47,
  borderLeft: '1px solid #004D43',
  borderBottom: '1px solid #004D43',
  '&:last-child': {
    borderRight: '1px solid #004D43',
  },
  '& span': {
    fontSize: 21,
  },
}));

export const Row = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ECF0F6',
  },
  '&:hover': {
    backgroundColor: '#06DFC2',
    '& > td': {
      color: '#FAFCFF',
    },
  },
}));
