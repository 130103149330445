import { FormControlLabel, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { createUser } from '../../../api/users';
import {
  IconAddMS,
  IconCloseMS,
  IconPersonAddMS,
} from '../../../constants/icons';
import { selectProfileUser } from '../../../constants/selectOptions';
import { Constants } from '../../../constants/users';
import { GlobalContext } from '../../../context/global';
import { validateEmail, validateUserName } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  CloseButton,
  DialogButton,
  DialogContainer,
  FormStyled,
  SaveButton,
  StyledCheckbox,
  StyledDialog,
  StyledTitle,
  TextFieldStyled,
  TopBox,
} from './styles';

export interface NewUserDialogProps {
  updateUsers: () => void;
}

export default function NewUserDialog({
  updateUsers,
}: NewUserDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [mobileOnly, setMobileOnly] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState(0);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const resetInputs = (): void => {
    setEmail('');
    setName('');
    setSelectedRole(0);
  };

  const handleDialog = (): void => {
    setOpen(!open);
    resetInputs();
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (selectedRole === 0) {
      setSnackbarMessage('Selecione uma função');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const userData = {
      email,
      role: selectedRole,
      name,
      is_only_mobile: mobileOnly,
    };

    try {
      const response = await createUser(userData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.status_code === StatusCode.OK) {
        setSnackbarMessage('Usuário adicionado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        resetInputs();
        updateUsers();
        setOpen(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      resetInputs();
      setOpen(false);
    }
  };

  const handleChangeRole = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSelectedRole(Number(e.target.value));
  };

  return (
    <>
      <DialogButton disableTouchRipple onClick={handleDialog}>
        {IconAddMS}
        {Constants.buttonAdd}
      </DialogButton>
      <StyledDialog
        open={open}
        onClose={handleDialog}
        aria-labelledby="add new user"
      >
        <DialogContainer>
          <TopBox>
            <CloseButton onClick={handleDialog}>{IconCloseMS}</CloseButton>
          </TopBox>
          <StyledTitle>
            {IconPersonAddMS}
            {Constants.buttonAdd}
          </StyledTitle>
          <FormStyled component="form" id="login" onSubmit={handleSubmit}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={mobileOnly}
                  onChange={() => setMobileOnly(!mobileOnly)}
                />
              }
              label={Constants.appExclusiveUser}
            />
            <TextFieldStyled
              required
              focused
              id="name"
              value={name}
              error={nameError}
              color="secondary"
              variant="outlined"
              label={Constants.newUser}
              placeholder={Constants.namePlaceholder}
              onChange={(e) => {
                setName(e.target.value);
                !validateUserName(e.target.value)
                  ? setNameError(true)
                  : setNameError(false);
              }}
            />
            <TextFieldStyled
              focused
              required
              id="email"
              type="email"
              value={email}
              color="secondary"
              variant="outlined"
              error={emailError}
              label={Constants.email}
              placeholder={Constants.emailPlaceholder}
              onChange={(e) => {
                setEmail(e.target.value);
                !validateEmail(e.target.value)
                  ? setEmailError(true)
                  : setEmailError(false);
              }}
            />
            <TextFieldStyled
              id="role"
              color="secondary"
              select
              label={Constants.role}
              focused
              required
              value={selectedRole}
              onChange={(e) => handleChangeRole(e)}
            >
              {selectProfileUser().map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </TextFieldStyled>
            <SaveButton
              form="login"
              type="submit"
              disabled={emailError || nameError}
            >
              {Constants.buttonAdd}
            </SaveButton>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
