import {
  Box,
  ButtonBase,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  marginTop: 40,
  '@media (max-height: 820px)': {
    marginTop: 20,
  },
}));

interface CalendarBoxProps {
  view: string;
}

export const CalendarBox = styled(Box)<CalendarBoxProps>(({ view }) => ({
  '.fc ': {
    height: '680px',
    '@media (max-height: 820px)': {
      height: 590,
    },
  },
  '.fc td': {
    border: view === 'dayGridMonth' ? '1px solid #B8B8B9' : '',
  },
  '.fc-button-group': {
    '.fc-button:not(:first-of-type)': {
      display: 'none',
    },
  },
  '.fc-scroller': {
    overflow: 'auto !important',
  },
  '.fc-scroller::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: '#DDDDDD',
  },
  '.fc-theme-standard .fc-scrollgrid': {
    border: '0px',
  },
  '.fc-scroller::-webkit-scrollbar-thumb ': {
    borderRadius: '12px',
    backgroundColor: '#004D43',
  },
  '.fc-theme-standard th': {
    border: 'none',
  },
  '.fc-toolbar-title': {
    marginLeft: '90px',
    textTransform: 'capitalize',
    '@media (max-height: 820px)': {
      fontSize: '1.5rem',
    },
  },
  '.fc-toolbar.fc-header-toolbar': {
    marginBottom: '0',
  },
  '.fc-button-primary': {
    backgroundColor: 'transparent',
    color: '#004D43',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':focus': {
      boxShadow: 'none !important',
    },
    ':active': {
      borderRadius: '6px !important',
      backgroundColor: '#1a2f2e !important',
      borderColor: '#1a2f2e !important',
    },
    '@media (max-height: 820px)': {
      fontSize: '1rem',
    },
  },
  '.fc-monthPrev-button': {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':hover': {
      backgroundColor: 'transparent',
      border: 'none !important',
      transform: 'scale(1.2)',
    },
  },
  '.fc-monthNext-button': {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':hover': {
      backgroundColor: 'transparent',
      border: 'none !important',
      transform: 'scale(1.2)',
    },
  },
  '.fc-prev-button:hover': {
    backgroundColor: 'transparent',
    border: 'none !important',
    transform: 'scale(1.2)',
    color: '#004D43',
  },
  '.fc-next-button:hover': {
    backgroundColor: 'transparent',
    border: 'none !important',
    transform: 'scale(1.2)',
    color: '#004D43',
  },
  '.fc-col-header': {
    margin: '8px 0',
  },
  '.fc-col-header-cell-cushion': {
    padding: '8px',
    fontSize: '16px',
    fontWeight: '500',
    '@media (max-height: 820px)': {
      padding: 0,
      fontSize: '14px',
    },
  },
  '.fc-col-header-cell.fc-day.fc-day-today': {
    backgroundColor: '#00B39B',
    color: '#FFFFFF',
    borderRadius: 10,
  },
  '.fc-timegrid-body': {
    borderLeft: '1px solid #DDDDDD',
    marginTop: '2px',
  },
  '.fc-timegrid-slot-label-frame': {
    backgroundColor: '#ECF0F6',
  },
  '.fc-timegrid-slot-label-cushion': {
    color: '#004D43',
    fontWeight: '600',
    padding: '8px',
  },
  '.fc-timegrid-col.fc-day-today ': {
    background:
      view === 'timeGridDay'
        ? 'transparent !important'
        : '#00B39B1A !important',
    border: 'none !important',
  },
  '.fc-timegrid-event-harness>.fc-timegrid-event': {
    background: 'transparent',
    fontSize: '12px',
  },
  '.fc-today-button': {
    backgroundColor: '#00B39B',
    color: '#FAFCFF',
    padding: '4px 12px',
    borderRadius: '4px !important',
    ':disabled': {
      backgroundColor: '#00B39B',
      opacity: 0.5,
      ':hover': {
        backgroundColor: '#00B39B',
        opacity: 0.5,
      },
    },
    ':hover': {
      backgroundColor: '#004D43',
    },
  },
  '.fc-daygrid-day.fc-day-today': {
    backgroundColor: '#00B39B1A !important',
    color: '#00B39B',
  },
  '.fc-event fc-event-start .fc-event-end .fc-event-past .fc-daygrid-event .fc-daygrid-dot-event':
    {
      ':hover': {
        backgroundColor: '#004D43',
      },
    },
  '.more-events': {
    color: '#004D43',
  },
  '.fc-popover': {
    backgroundColor: '#FAFCFF',
    color: 'black',
    zIndex: 1,
  },
  '.fc-v-event': {
    backgroundColor: 'transparent',
    color: '#FAFCFF !important',
    borderWidth: '2px',
  },
  '.fc-timegrid-event-harness': {
    margin: 0,
    minHeight: '38px',
  },
  '.fc-direction-ltr .fc-timegrid-more-link': {
    left: 0,
    height: 25,
    marginTop: 40,
    textAlign: 'center',
    color: '#00B39B',
  },
  '.fc-direction-ltr .fc-timegrid-col-events': {
    margin: 0,
  },
  // eslint-disable-next-line max-len
  '.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start':
    {
      overflow: 'hidden',
      backgroundColor: 'transparent',
      border: 'none',
    },

  '.fc-timegrid-event .fc-event-main': {
    overflow: 'hidden !important',
    backgroundColor: '#ECF0F6',
  },
  '.fc-h-event': {
    backgroundColor: 'transparent',
    border: 'none',
  },
}));

export const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '22px',
  marginBottom: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#1A1A1A',
  textAlign: 'start',
}));

export const OsButton = styled(ButtonBase)(({ theme }) => ({
  width: 'fit-content',
  justifyContent: 'flex-start',
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.main,
  margin: '-4px',
  padding: '6px',
  borderRadius: '4px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const SpaceAroundBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '20px',
}));

export const spanStyle = {
  fontWeight: '400',
};

interface EventProps {
  color: string;
}

export const EventBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
  alignItems: 'flex-start',
  width: 'fit-content',
  cursor: 'pointer',
  p: {
    whiteSpace: 'nowrap',
    margin: 0,
    color: '#76787B',
  },
}));

export const EventBlockage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
  alignItems: 'flex-start',
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  p: {
    whiteSpace: 'nowrap',
    margin: 0,
    color: '#76787B',
  },
}));

export const EventDisabledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
  cursor: 'default',
  p: {
    whiteSpace: 'nowrap',
    margin: 0,
    color: '#76787B',
  },
}));

export const LabelBox = styled(Box)<EventProps>(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  p: {
    fontWeight: '600',
    color: '#76787B',
  },
  '.material-symbols-outlined': {
    color,
    fontWeight: 600,
    fontSize: '18px !important',
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ECF0F6',
    color: '#76787B',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 6px',
    marginTop: '4px !important',
    marginBottom: '2px !important',
  },
}));

export const ColorDot = styled(Box)<EventProps>(({ color }) => ({
  width: 8,
  minWidth: 8,
  borderRadius: 50,
  background: color,
}));
