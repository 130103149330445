import { useContext, useState } from 'react';

import { StatusCode } from '../api/enumerations';
import { ChangeStatus, PreviousStatus } from '../api/workOrders';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';
import useGeneral from './useGeneral';

interface UseChangeStatusProps {
  handleStatus: (
    osId: number,
    referenceNumber: number | undefined,
    snackbarMessage?: string
  ) => Promise<void>;
  handlePreviousStatus: (
    osId: number,
    referenceNumber: number | undefined,
    previousStatus: string
  ) => Promise<void>;
  loadingApprove: boolean;
  setLoadingApprove: (value: boolean) => void;
  loadingPreviousStatus: boolean;
}

export function useChangeStatus(): UseChangeStatusProps {
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingPreviousStatus, setLoadingPreviousStatus] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { navigateHome } = useGeneral();

  const handleStatus = async (
    osId: number,
    referenceNumber: number | undefined,
    snackbarMessage = 'OS ** aprovada!'
  ): Promise<void> => {
    setLoadingApprove(true);
    try {
      const response = await ChangeStatus(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (
        response.detail.status_code !== StatusCode.OK &&
        response.detail.status_code !== StatusCode.PROCESSING
      ) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(snackbarMessage.replace('**', `${referenceNumber}`));
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoadingApprove(false);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingApprove(false);
    }
  };

  const handlePreviousStatus = async (
    osId: number,
    referenceNumber: number | undefined,
    previousStatus: string
  ): Promise<void> => {
    setLoadingPreviousStatus(true);
    try {
      const response = await PreviousStatus(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(
        `OS ${referenceNumber} enviada para ${previousStatus}`
      );
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoadingPreviousStatus(false);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingPreviousStatus(false);
    }
  };

  return {
    handleStatus,
    handlePreviousStatus,
    loadingApprove,
    setLoadingApprove,
    loadingPreviousStatus,
  };
}
