import { Table, TableBody, TableHead, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  convertReportGoal,
  convertReportSource,
} from '../../../api/enumerations';
import { ErrorReportData } from '../../../api/errorReport/types';
import { Constants } from '../../../constants/errorReport';
import { convertDate } from '../../../helpers';
import {
  Cell,
  DescriptionTableCell,
  Row,
  StyledTableCell,
  StyledTableContainer,
} from './styles';

interface ReportTableProps {
  tableData: ErrorReportData[];
  page: number;
  setPage: (value: number) => void;
  totalRecords: number;
  rowsPerPage: number;
}

export function ReportTable({
  tableData,
  page,
  setPage,
  totalRecords,
  rowsPerPage,
}: ReportTableProps): JSX.Element {
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row>
            <Cell align="center" sx={{ borderTopLeftRadius: 8 }}>
              {Constants.reportDate}
            </Cell>
            <Cell align="center">{Constants.reportedBy}</Cell>
            <Cell align="center">{Constants.osNumber}</Cell>
            <Cell align="center">{Constants.client}</Cell>
            <Cell align="center">{Constants.goal}</Cell>
            <Cell align="center">{Constants.source}</Cell>
            <Cell align="center" sx={{ borderTopRightRadius: 8 }}>
              {Constants.errorDescription}
            </Cell>
          </Row>
        </TableHead>
        <TableBody>
          {tableData?.map((report) => (
            <Row
              key={report.id}
              onClick={() => navigate(`/error-report/${report.id}`)}
            >
              <StyledTableCell align="center">
                {report.created_at && convertDate(report.created_at)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {report.user_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {report.reference_number}
              </StyledTableCell>
              <StyledTableCell align="center">
                {report.requester_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {report.report_goal && convertReportGoal(report.report_goal)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {report.report_source &&
                  convertReportSource(report.report_source)}
              </StyledTableCell>
              <DescriptionTableCell align="center">
                {report.error_description}
              </DescriptionTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage="Qtd por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </StyledTableContainer>
  );
}
