import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(({ theme }) => ({
  padding: '32px',
  paddingLeft: '120px',
  backgroundColor: theme.palette.background.default,
}));

export const GridMain = styled(Grid)(() => ({
  padding: '47px 100px 32px 180px',
  background: '#FAFCFF',
}));
