export const Constants = {
  firstAccess: 'Primeiro Acesso',
  recoverPassword: 'Recuperar Senha',
  emailPlaceholder: 'digite seu e-mail cadastrado',
  submit: 'ENVIAR',
  check: 'VERIFICAR',
  cancel: 'CANCELAR',
  timer: 'você poderá solicitar um novo código em ',
  seconds: ' segundos:',
  newToken: 'solicitar novo código',
  newPassword: 'Nova senha',
  passwordText: 'Digite uma senha para concluir o login',
  save: 'SALVAR',
};
