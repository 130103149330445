/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { Grid, Rating } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { convertClientKind } from '../../../../api/enumerations';
import { getAllWorkOrders } from '../../../../api/workOrders';
import { WorkOrderData } from '../../../../api/workOrders/types';
import {
  IconKeyboardDoubleArrowRightMS,
  IconSearchMS,
} from '../../../../constants/icons';
import iconBeta from '../icons/beta.png';
import iconDelta from '../icons/delta.png';
import iconOmega from '../icons/omega.png';
import { AssignModal } from './AssignModal';
import {
  CardContainer,
  CardStyled,
  CompanyHeader,
  GridButton,
  GridContainer,
  GridStyled,
  GridTitle,
  OutlinedButton,
  SearchContainer,
  SkeletonStyled,
} from './styles';

export function Requests(): JSX.Element {
  const [search, setSearch] = useState('');
  const [stars, setStars] = useState(3);
  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>();
  const [selectedOS, setSelectedOS] = useState<number>();
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [openModal, setOpenModal] = useState(false);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getAllWorkOrders(search);

      if (response.data) {
        setWorkOrders(response.data?.[1]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [search]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <GridContainer container>
      <Grid item xs={4}>
        <GridTitle>Lista de OS</GridTitle>
        <GridStyled item>
          <SearchContainer>
            {IconSearchMS}
            <input placeholder="buscar informação de contato" />
            <button type="button">buscar</button>
          </SearchContainer>
          <CardContainer>
            {workOrders
              ? workOrders.map((workOrder) => (
                  <CardStyled
                    key={workOrder.id}
                    onClick={() => setSelectedOS(workOrder.id)}
                    className={workOrder.id === selectedOS ? 'selected' : ''}
                  >
                    <span>
                      <strong>número OS:</strong> {workOrder.reference_number}
                    </span>
                    <span>
                      <strong>nome do cliente:</strong> {workOrder.client_name}
                    </span>
                    <span>
                      <strong>tipo do cliente:</strong>{' '}
                      {convertClientKind(workOrder.client_kind)}
                    </span>
                    <span>
                      <strong>cidade:</strong> {workOrder.city}
                    </span>
                    <span>
                      <strong>localização:</strong> {workOrder.street} -{' '}
                      {workOrder.number}
                    </span>
                  </CardStyled>
                ))
              : [1, 2, 3, 4, 5].map((item) => (
                  <SkeletonStyled
                    key={item}
                    variant="rectangular"
                    animation="pulse"
                    // height={230}
                  />
                ))}
          </CardContainer>
        </GridStyled>
      </Grid>
      <GridButton item xs={2}>
        <OutlinedButton onClick={() => setOpenModal(!openModal)}>
          atribuir {IconKeyboardDoubleArrowRightMS}
        </OutlinedButton>
      </GridButton>
      <Grid item xs={4}>
        <GridTitle>Parceiros</GridTitle>
        <GridStyled item>
          <SearchContainer>
            {IconSearchMS}
            <input placeholder="buscar informação de contato" />
            <button type="button">buscar</button>
          </SearchContainer>
          <CardContainer>
            <CardStyled
              sx={{ height: 250 }}
              onClick={() => setSelectedCompany(1)}
              className={selectedCompany === 1 ? 'selected' : ''}
            >
              <CompanyHeader>
                <img src={iconDelta} alt="" />
                <span>Empresa Delta</span>
              </CompanyHeader>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <span>
                  <strong>CNPJ:</strong> 00.000.000/0001-00
                </span>
                <span>
                  <strong>Cidade:</strong> São Paulo
                </span>
                <span>
                  <strong>OS:</strong>
                  <strong style={{ color: '#00B39B' }}> ativa</strong>
                </span>
                <Rating value={stars} />
              </div>
            </CardStyled>
            <CardStyled
              sx={{ height: 250 }}
              onClick={() => setSelectedCompany(2)}
              className={selectedCompany === 2 ? 'selected' : ''}
            >
              <CompanyHeader>
                <img src={iconBeta} alt="" />
                <span>Empresa Beta</span>
              </CompanyHeader>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <span>
                  <strong>CNPJ:</strong> 00.000.000/0001-00
                </span>
                <span>
                  <strong>Cidade:</strong> São Paulo
                </span>
                <span>
                  <strong>OS:</strong>
                  <strong style={{ color: '#00B39B' }}> ativa</strong>
                </span>
                <Rating value={stars} />
              </div>
            </CardStyled>
            <CardStyled
              sx={{ height: 250 }}
              onClick={() => setSelectedCompany(3)}
              className={selectedCompany === 3 ? 'selected' : ''}
            >
              <CompanyHeader>
                <img src={iconOmega} alt="" />
                <span>Empresa Ômega</span>
              </CompanyHeader>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <span>
                  <strong>CNPJ:</strong> 00.000.000/0001-00
                </span>
                <span>
                  <strong>Cidade:</strong> São Paulo
                </span>
                <span>
                  <strong>OS:</strong>
                  <strong style={{ color: '#00B39B' }}> ativa</strong>
                </span>
                <Rating value={stars} />
              </div>
            </CardStyled>
          </CardContainer>
        </GridStyled>
      </Grid>
      <AssignModal open={openModal} onClose={() => setOpenModal(!openModal)} />
    </GridContainer>
  );
}
