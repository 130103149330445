import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerGrid = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  minHeight: '800px',
  height: '100vh',
  justifyContent: 'center',
}));

export const LogoGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const LogoImg = styled(CardMedia)(({ theme }) => ({
  zIndex: 5,
  [theme.breakpoints.down('lg')]: {
    width: '400px',
    height: '157px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '547px',
    height: '215px',
  },
}));

export const LoginGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#004D43',
  borderTopLeftRadius: '30px',
  borderBottomLeftRadius: '30px',
}));

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const InputTitle = styled(Typography)(() => ({
  fontSize: '37px',
  marginBottom: '140px',
  color: '#FAFCFF',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #D7D8DB99',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #D7D8DB99',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:after': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #FFFFFF',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #FFFFFF',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #004D43 inset',
    paddingLeft: '10px',
    WebkitTextFillColor: '#FFFFFF',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  margin: '40px 0',
  paddingTop: '10px',
  border: '1px solid #ECF0F6',
  borderRadius: '16px',
  backgroundColor: '#595959',
  fontWeight: '700',
  color: '#D6D4D0',
  '&:hover': {
    backgroundColor: '#1A1A1A',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));

export const StyledButtonBase = styled(ButtonBase)(() => ({
  textDecoration: 'underline',
  color: '#D7D8DB',
  fontSize: '18px',
  '&:hover': {
    fontWeight: '600',
  },
}));

export const StyledText = styled(Typography)(() => ({
  color: '#D7D8DB',
  display: 'flex',
  gap: '10px',
  marginTop: '100px',
}));
