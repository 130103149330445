/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Status } from '../api/enumerations';
import { GetWorkOrder } from '../api/workOrders';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../api/workOrders/types';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';
import useGeneral from './useGeneral';

interface PropertyDataProps {
  status: Status;
}

const usePropertyData = ({
  status,
}: PropertyDataProps): {
  propertyData: WorkOrderData | undefined;
  hasAvmReport: boolean;
  loadingPage: boolean;
  getDataCallback: () => Promise<void>;
} => {
  const [propertyData, setPropertyData] = useState<WorkOrderData | undefined>();
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { osId } = useGeneral();

  const navigate = useNavigate();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== status) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const avmData = response.data.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;

      if (avmData && avmData.scores) setHasAvmReport(true);
      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return {
    propertyData,
    hasAvmReport,
    loadingPage,
    getDataCallback,
  };
};

export { usePropertyData };
