import { Box, Select, TextField, styled } from '@mui/material';

export const SelectStyled = styled(Select)(() => ({
  height: 56,
  borderRadius: 16,
  padding: 16,
  color: '#707070',
}));

export const TextFieldStyled = styled(TextField)(() => ({
  height: 56,
  color: '#707070',
  '& .MuiInputBase-root': {
    borderRadius: 16,
  },
}));

export const FilterInputsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'start',
  padding: '60px 0 30px 0',
  gap: 16,
}));

export const FilteredContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'start',
  gap: 8,
  margin: '30px 0',
}));

export const Filter = styled('div')(({ theme }) => ({
  background: '#909090',
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  height: 30,
  gap: 4,
  borderRadius: 8,

  '& span': {
    color: theme.palette.common.white,
    fontSize: 15,
  },

  '& button': {
    width: 25,
    height: 25,
    padding: 0,
  },
}));
