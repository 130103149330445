import { Company, CompanyStats } from '../api/companies/types';

export const Constants = {
  userManagement: 'Gestão de Usuários',
  noOpenPayment: 'Não há mensalidades em aberto',
  noPaymentHistory: 'Não há histórico de pagamentos',
  noActives: 'Não há histórico de ativos',
  noTrial: 'Não há histórico de trial',
  noActivities: 'Não há histórico de atividades',
  noUsers: 'Não há usuários cadastrados',
  attachment: 'Anexo',
  dueDate: 'Data de Vencimento',
  startDate: 'Data de Início',
  leftTime: 'Tempo Restante',
  notDone: 'não realizado',
  responsible: 'Responsável',
  plan: 'Plano de assinatura',
  registerData: 'Dados Cadastrais',
  noCompany: 'Não há histórico de empresa',
  linkedUsers: 'Usuários vinculados',
  paymentHistory: 'Histórico de pagamentos',
  noPayHistory: 'Sem histórico de pagamentos',
  historyDueDate: 'Vencimento:',
  historyValue: 'Valor:',
  historyAtt: 'Comprovante:',
};

export const paymentsHeader = ['Responsável', 'CPF/CNPJ', 'Empresa'];
export const companyHeader = ['Responsável', 'CPF/CNPJ', 'CAU/CREA', 'Empresa'];
export const userHeader = ['Empresa', 'Responsável/Usuário', 'Identificação'];
export const LinkedUsers = [
  'Nome',
  'CPF',
  'CREA/CAU',
  'Amostras buscadas',
  'Laudos concluídos',
];

export const activitiesHeader = [
  {
    name: 'Responsável',
    width: '30%',
  },
  {
    name: 'Acesso ao Sistema',
    width: '25%',
  },
  {
    name: 'Último Login',
    width: '25%',
  },
  {
    name: 'Laudos Feitos',
    width: '20%',
  },
];

interface TableValues {
  label: string;
  value: string | number;
}

interface TableGridValues {
  label: string;
  size: number;
  value: string | number;
}

export const presentResponsible = (data: Company): TableValues[] => {
  return [
    {
      label: 'Nome:',
      value: data.responsible_user.name ? data.responsible_user.name : '',
    },
    {
      label: 'CPF:',
      value: data.responsible_user.document
        ? data.responsible_user.document
        : '',
    },
    {
      label: 'CREA:',
      value: data.license_document,
    },
  ];
};

export const presentRegisterData = (data: Company): TableGridValues[] => {
  return [
    {
      label: 'Empresa:',
      size: 7,
      value: data.name,
    },
    {
      label: 'CNPJ:',
      size: 5,
      value: data.document,
    },
    {
      label: 'Email:',
      size: 12,
      value: data.email,
    },
    {
      label: 'Telefone:',
      size: 12,
      value: data.phone_number,
    },
    {
      label: 'Endereço:',
      size: 12,
      value: `${data.address} ${data.address_number}`,
    },
    {
      label: 'CEP:',
      size: 4,
      value: data.zipcode,
    },
    {
      label: 'Cidade:',
      size: 6,
      value: data.city,
    },
    {
      label: 'UF:',
      size: 2,
      value: data.uf,
    },
  ];
};

export const presentReport = (
  company: Company,
  stats: CompanyStats | undefined
): TableValues[] => {
  return [
    {
      label: 'Data de início',
      value: company.subscription_started_at
        ? new Intl.DateTimeFormat('pt-BR').format(
            new Date(company?.subscription_started_at)
          )
        : '-',
    },
    {
      label: 'Último login',
      value: company?.responsible_user.last_access
        ? new Intl.DateTimeFormat('pt-BR').format(
            new Date(company?.responsible_user.last_access)
          )
        : '-',
    },
    {
      label: 'Laudos com erro',
      value: stats?.requests.total.report.error || 0,
    },
    {
      label: 'Laudos concluídos',
      value: stats?.reports.company_reports.finished_reports_count || 0,
    },
    {
      label: 'Amostras buscadas',
      value: stats?.requests.total.sampler.success || 0,
    },
    {
      label: 'Cálculos efetuados',
      value: stats?.requests.total.report.success || 0,
    },
  ];
};
