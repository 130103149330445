/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { addAttachment, getAllAttachedFiles } from '../../api/attachments';
import { Status } from '../../api/enumerations';
import { GetWorkOrder } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconUploadMS,
} from '../../constants/icons';
import { Constants } from '../../constants/proposal';
import { GlobalContext } from '../../context/global';
import { validateFiles } from '../../helpers';
import { useAccordion } from '../../hooks/useAccordion';
import { useAttachment } from '../../hooks/useAttachmentFiles';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import {
  BackButton,
  ButtonContainer,
  SectionBox,
  SubmitBox,
  UploadButton,
} from './styles';

export function Proposal(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [updateAttachments, setUpdateAttachments] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [proposalFileName, setProposalFileName] = useState<string[]>([]);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { updateAttFiles } = useAttachment();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();

  useEffect(() => {
    if (expandOne && expandTwo && expandThree) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree]);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.PROPOSAL) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    try {
      const response = await getAllAttachedFiles(osId, 1, 60);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      const filteredFiles = response.data
        .filter((e) => e.description === 'proposta')
        .map((data) => data.filename);
      if (filteredFiles) {
        setProposalFileName(filteredFiles);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, updateAttFiles]);

  const handleSubmitFile = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('work_order_id', osId.toString());
    formData.append('attachment', file);
    formData.append('description', 'proposta');

    try {
      const response = await addAttachment(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setProposalFileName([...proposalFileName, file.name]);
      setUpdateAttachments(!updateAttachments);
      setSnackbarMessage('Arquivo adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.proposal}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <AllAttachments
                propertyData={propertyData}
                osId={osId}
                update={updateAttachments}
              />
            </FlexSpaceBetweenBox>
            <ClientData propertyData={propertyData} />
            <ButtonContainer>
              <label htmlFor="uploadProposal">
                <input
                  accept={'image/*,application/pdf'}
                  id="uploadProposal"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleSubmitFile(e)
                  }
                />
                <UploadButton variant="contained" component="span">
                  {IconUploadMS}
                  {proposalFileName.length > 0
                    ? Constants.addUpload
                    : Constants.upload}
                </UploadButton>
              </label>
              {proposalFileName.length > 1 ? (
                <FlexBox mt={1}>
                  <Box fontWeight="bold">{Constants.files}</Box>
                  <Box>{proposalFileName.join(' / ')}</Box>
                </FlexBox>
              ) : (
                proposalFileName.length > 0 && (
                  <Box mt={1}>{proposalFileName}</Box>
                )
              )}
            </ButtonContainer>
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps propertyData={propertyData} />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              <SubmitBox>
                <PeptDialog
                  osId={osId}
                  referenceNumber={propertyData?.reference_number}
                />
                <ConfirmationDialog
                  title={Constants.approve}
                  loading={loadingApprove}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={() =>
                    handleStatus(osId, propertyData?.reference_number)
                  }
                />
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
