import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    padding: '32px 60px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '700px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1400px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogButton = styled(Button)(() => ({
  width: '280px',
  margin: '20px 0',
  backgroundColor: '#FAFCFF',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
  border: '2px solid #00B39B',
  borderRadius: 10,
  color: '#00B39B',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  textTransform: 'capitalize',
  '& span': { color: '#00B39B' },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'right',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '220px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  span: { marginRight: '8px' },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '220px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));
