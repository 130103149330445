/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

import { ElementData } from '../../../api/samples/types';
import SampleMarker from '../../../assets/mapMarkers/element.svg';
import OSMarker from '../../../assets/mapMarkers/os.svg';
import SelectedElement from '../../../assets/mapMarkers/selected.svg';
import UnusedElement from '../../../assets/mapMarkers/unused.svg';
import { IconLocationOnMS } from '../../../constants/icons';
import { offsetDuplicateCoord } from '../../../helpers';
import { AccordionTitle } from '../../Sections/AccordionTitle';
import CustomMap from './CustomMap';
import { BoxContainer, Item, LegendBox, Marker, StyledText } from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;

interface IOption {
  id: number;
  isUtilized: boolean;
  position: LatLngLiteral;
}

interface LocationsProps {
  sampleData: ElementData[];
  pinPlace: LatLngLiteral;
  page: number;
  setPage: (value: number) => void;
  deactivateFilters?: () => void;
  showUnusedElements?: boolean;
  sampleCreationStatus?: boolean;
}

export default function ElementsMap({
  sampleData,
  pinPlace,
  setPage,
  page,
  deactivateFilters,
  showUnusedElements,
  sampleCreationStatus = false,
}: LocationsProps): JSX.Element {
  const [showMap, setShowMap] = useState(true);
  const [markersData, setMarkersData] = useState<IOption[]>([]);

  useEffect(() => {
    const elements = sampleData.map((element) => {
      const { latitude, longitude } = element.description;
      const option: IOption = {
        id: element.id,
        isUtilized: element.is_utilized || false,
        position: {
          lat: latitude,
          lng: longitude,
        },
      };
      return option;
    });

    const updatedElementsCoord: IOption[] = offsetDuplicateCoord(elements);

    setMarkersData(updatedElementsCoord);
  }, [sampleData]);

  return (
    <Box
      sx={{
        'button.gm-ui-hover-effect': {
          visibility: 'hidden',
        },
        '.gm-style-iw-c': {
          height: '40px',
          padding: '0 !important',
        },
        '.gm-style-iw-d': {
          textAlign: 'center',
          overflow: 'visible !important',
          minWidth: '34px',
          minHeight: '28px',
          marginTop: '-40px',
        },
      }}
    >
      <Box>
        <AccordionTitle
          title="Localizações"
          icon={IconLocationOnMS}
          openAccordion={showMap}
          setOpenAccordion={setShowMap}
        />
      </Box>
      {showMap && (
        <BoxContainer>
          <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
            libraries={['marker']}
          >
            <CustomMap
              markersData={markersData}
              pinPlace={pinPlace}
              page={page}
              setPage={setPage}
              deactivateFilters={deactivateFilters}
              showUnusedElements={showUnusedElements}
            />
          </APIProvider>
          <LegendBox>
            <Item>
              <Marker image={SampleMarker} title="utilizados" />
              <StyledText>
                {sampleCreationStatus ? 'elementos' : 'utilizados'}
              </StyledText>
            </Item>
            {showUnusedElements && (
              <Item>
                <Marker image={UnusedElement} title="não utilizados" />
                <StyledText>não utilizados</StyledText>
              </Item>
            )}
            <Item>
              <Marker image={SelectedElement} title="selecionado" />
              <StyledText>selecionado</StyledText>
            </Item>
            <Item>
              <Marker image={OSMarker} title="avaliando" />
              <StyledText>avaliando</StyledText>
            </Item>
          </LegendBox>
        </BoxContainer>
      )}
    </Box>
  );
}
