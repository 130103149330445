/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField } from '@mui/material';
import { evaluate } from 'mathjs';
import { useEffect, useState } from 'react';

import { TableCubData } from '../../../api/cub/types';
import { Constants } from '../../../constants/adjustment';
import {
  CubTextField,
  TableCell,
  TableGrid,
  TableHeader,
  TableInfo,
} from './styles';

interface TableProps {
  popularLogicValue: number | undefined;
  simpleLogicValue: number;
  normalLogicValue: number;
  highLogicValue: number;
  luxeLogicValue: number | undefined;
  tableData: TableCubData;
  setTableData: (data: TableCubData) => void;
}

export function Table({
  popularLogicValue,
  simpleLogicValue,
  normalLogicValue,
  highLogicValue,
  luxeLogicValue,
  tableData,
  setTableData,
}: TableProps): JSX.Element {
  const [popular, setPopular] = useState<string | null>('');
  const [simple, setSimple] = useState<string | null>('');
  const [normal, setNormal] = useState<string | null>('');
  const [high, setHigh] = useState<string | null>('');
  const [luxe, setLuxe] = useState<string | null>('');

  const updateExpressionLogic = (cub: number, logic: string): string | null => {
    try {
      const newExpression = logic.replace(/CUB/gi, cub.toString());
      return evaluate(newExpression).toFixed(2);
    } catch (error) {
      /* empty */
    }
    return null;
  };

  useEffect(() => {
    if (popularLogicValue && tableData.popular) {
      const newValue = updateExpressionLogic(
        popularLogicValue,
        tableData.popular.logic
      );
      setPopular(newValue);
      setTableData({
        ...tableData,
        popular: {
          ...tableData.popular,
          converted_cub: Number(newValue),
        },
      });
    }
  }, [popularLogicValue, tableData.popular?.logic]);

  useEffect(() => {
    if (simpleLogicValue && tableData.simple) {
      const newValue = updateExpressionLogic(
        simpleLogicValue,
        tableData.simple.logic
      );
      setSimple(newValue);
      setTableData({
        ...tableData,
        simple: {
          ...tableData.simple,
          converted_cub: Number(newValue),
        },
      });
    }
  }, [simpleLogicValue, tableData.simple?.logic]);

  useEffect(() => {
    if (normalLogicValue && tableData.normal) {
      const newValue = updateExpressionLogic(
        normalLogicValue,
        tableData.normal.logic
      );
      setNormal(newValue);
      setTableData({
        ...tableData,
        normal: {
          ...tableData.normal,
          converted_cub: Number(newValue),
        },
      });
    }
  }, [normalLogicValue, tableData.normal?.logic]);

  useEffect(() => {
    if (highLogicValue && tableData.high) {
      const newValue = updateExpressionLogic(
        highLogicValue,
        tableData.high.logic
      );
      setHigh(newValue);
      setTableData({
        ...tableData,
        high: {
          ...tableData.high,
          converted_cub: Number(newValue),
        },
      });
    }
  }, [highLogicValue, tableData.high?.logic]);

  useEffect(() => {
    if (luxeLogicValue && tableData.luxe) {
      const newValue = updateExpressionLogic(
        luxeLogicValue,
        tableData.luxe.logic
      );
      setLuxe(newValue);
      setTableData({
        ...tableData,
        luxe: {
          ...tableData.luxe,
          converted_cub: Number(newValue),
        },
      });
    }
  }, [luxeLogicValue, tableData.luxe?.logic]);

  return (
    <TableGrid container spacing={1}>
      <Grid item xs={12}>
        <TableHeader>{Constants.flat}</TableHeader>
      </Grid>
      <Grid item xs={4}>
        <TableHeader>{Constants.rstandard}</TableHeader>
      </Grid>
      <Grid item xs={4}>
        <TableHeader>{Constants.logic}</TableHeader>
      </Grid>
      <Grid item xs={4}>
        <TableHeader>{Constants.cub}</TableHeader>
      </Grid>
      {tableData.popular && (
        <>
          <Grid item xs={4}>
            <TableCell>
              {Constants.popular} -<span>CUB: R8 Baixo</span>
            </TableCell>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="popular"
              color="secondary"
              value={tableData.popular.logic}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                if (tableData.popular) {
                  setTableData({
                    ...tableData,
                    popular: {
                      ...tableData.popular,
                      logic: e.target.value,
                    },
                  });
                }
              }}
              sx={CubTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <TableCell>R$ {popular}</TableCell>
          </Grid>
        </>
      )}
      {tableData.simple && (
        <>
          <Grid item xs={4}>
            <TableCell>
              {Constants.simple} -<span>CUB: R8 Baixo</span>
            </TableCell>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="simple"
              color="secondary"
              value={tableData.simple.logic}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                if (tableData.simple) {
                  setTableData({
                    ...tableData,
                    simple: { ...tableData.simple, logic: e.target.value },
                  });
                }
              }}
              sx={CubTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <TableCell>R$ {simple}</TableCell>
          </Grid>
        </>
      )}
      {tableData.normal && (
        <>
          <Grid item xs={4}>
            <TableCell>
              {Constants.normal} -<span>CUB: R8 Normal</span>
            </TableCell>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="normal"
              color="secondary"
              value={tableData.normal.logic}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                if (tableData.normal) {
                  setTableData({
                    ...tableData,
                    normal: { ...tableData.normal, logic: e.target.value },
                  });
                }
              }}
              sx={CubTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <TableCell>R$ {normal}</TableCell>
          </Grid>
        </>
      )}
      {tableData.high && (
        <>
          <Grid item xs={4}>
            <TableCell>
              {Constants.high} -<span>CUB: R16 Alto</span>
            </TableCell>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="high"
              color="secondary"
              value={tableData.high.logic}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                if (tableData.high) {
                  setTableData({
                    ...tableData,
                    high: { ...tableData.high, logic: e.target.value },
                  });
                }
              }}
              sx={CubTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <TableCell>R$ {high}</TableCell>
          </Grid>
        </>
      )}
      {tableData.luxe && (
        <>
          <Grid item xs={4}>
            <TableCell>
              {Constants.luxe} -<span>CUB: R16 Alto</span>
            </TableCell>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="luxe"
              color="secondary"
              value={tableData.luxe.logic}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                if (tableData.luxe) {
                  setTableData({
                    ...tableData,
                    luxe: { ...tableData.luxe, logic: e.target.value },
                  });
                }
              }}
              sx={CubTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <TableCell>R$ {luxe}</TableCell>
          </Grid>
        </>
      )}
      <TableInfo>{Constants.tableInfo}</TableInfo>
    </TableGrid>
  );
}
