import { useState } from 'react';

type LatLngLiteral = google.maps.LatLngLiteral;

interface UseSampleDashboardHook {
  page: number;
  setPage: (value: number) => void;
  view: string;
  handleView: (event: React.MouseEvent<HTMLElement>, view: string) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
}

export function useSampleDashboard(): UseSampleDashboardHook {
  const [page, setPage] = useState(1);
  const [view, setView] = useState('card');
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });

  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ): void => {
    setView(newView);
    setTimeout(() => {
      document.getElementById(`${page}`)?.scrollIntoView();
    }, 1000);
  };
  return {
    page,
    setPage,
    view,
    handleView,
    pinPlace,
    setPinPlace,
  };
}
