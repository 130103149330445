import { ButtonBase, IconButton, TableCell, styled } from '@mui/material';

export const StatusButton = styled(ButtonBase)(({ theme }) => ({
  width: 150,
  height: 48,
  background: '#004E43',
  font: '600 15px Montserrat',
  color: `${theme.palette.common.white}`,
  textTransform: 'lowercase',

  borderRadius: 8,

  '&:disabled': {
    backgroundColor: '#c2c4c6',
  },
}));

export const TableCellStyled = styled(TableCell)(() => ({
  background: '#ECF0F6',
  height: 50,
  color: '#004E43',
  fontWeight: 600,
  borderRight: '1px solid #ccc',
  textAlign: 'center',
  '&:last-child': {
    border: 'none',
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
