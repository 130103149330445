import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const ContainerAttach = styled(Box)(() => ({
  padding: 32,
  display: 'flex',
  gap: 24,
  justifyContent: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
}));

export const ButtonContainer = styled(Box)(() => ({
  margin: '50px 0 30px',
  display: 'flex',
  justifyContent: 'center',
  gap: '80px',
}));

export const LabelInput = styled('label')(() => ({
  color: '#004D43',
  font: '400 16px Montserrat',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 10,

  '&:hover': {
    backgroundColor: '#ECF0F6',
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

export const InputAttach = styled('input')(() => ({
  height: 48,
  border: '2px solid #004D43',
  borderRadius: 10,
  display: 'none',
  width: '100%',

  font: '400 16px Montserrat',
  color: '#004D43',

  '&:hover': {
    fontWeight: 700,
    backgroundColor: '#ECF0F6',
  },
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '60px 0',
}));

export const AttachmentsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '-3rem',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component, theme }) => ({
    display: 'flex',
    gap: '18px',
    width: '360px',
    [theme.breakpoints.down('lg')]: {
      width: '280px',
    },
    component,
  })
);
