import { BaseUrl, myHeadersWithToken } from '../global';
import { GetInvoiceProps } from './types';

export const getInvoices = (
  page: number,
  size: number,
  companyId: number
): Promise<GetInvoiceProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/invoices?page=${page}&size=${size}&company_id=${companyId}&status=pago&source=plano`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getOpenInvoices = (
  page: number,
  size: number,
  search: string,
  orderBy: string
): Promise<GetInvoiceProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/invoices?page=${page}&size=${size}&status=aberto&source=plano&search=${search}&order_by=${orderBy}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getPayedInvoices = (
  page: number,
  size: number,
  search: string,
  orderBy: string
): Promise<GetInvoiceProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/invoices?page=${page}&size=${size}&status=pago&source=plano&search=${search}&order_by=${orderBy}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
