/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StatusCode } from '../../../api/enumerations';
import { getSelectedInspectionPictures } from '../../../api/inspections';
import { selectInspectionPictures } from '../../../api/pictures';
import {
  GalleryPictureData,
  SelectedPicture,
} from '../../../api/pictures/types';
import { getAllRooms } from '../../../api/rooms';
import { RoomData } from '../../../api/rooms/types';
import Snackbar from '../../../components/Snackbar';
import { TitleBox } from '../../../components/UI/Box';
import { GridContainer } from '../../../components/UI/Grid';
import { TitleTypography } from '../../../components/UI/Typography';
import {
  IconArrowCircleLeftMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/report';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { AccordionRoom } from './AccordionRoom';
import {
  BackButton,
  FlexCenterBox,
  SectionBox,
  SelectedText,
  SubmitButton,
} from './styles';

export function InspectionPhotos(): JSX.Element {
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [rooms, setRooms] = useState<RoomData[]>([]);
  const [selectedPictures, setSelectedPictures] = useState<SelectedPicture[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const { getErrorMessage } = useErrorMessage();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const { id, inspection } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);

  const selectionLimit = 12;
  const pageSize = 12;

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/report`);
  };

  const getSelectedPictures = async (inspectionId: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await getSelectedInspectionPictures(inspectionId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSelectedPictures(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
    setLoading(false);
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await getAllRooms(inspectionId, page, pageSize);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setRooms([...rooms, ...response.data]);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/home');
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page, inspectionId]);

  useEffect(() => {
    getSelectedPictures(inspectionId);
  }, []);

  const handleSelectPhoto = (
    picture: GalleryPictureData,
    roomId?: number
  ): void => {
    const isImageSelected = selectedPictures.some(
      (photo) => photo.id === picture.id
    );

    if (roomId === 10) {
      setSnackbarMessage('A foto da fachada não pode ser modificada.');
      setErrorMessage(true);
      setOpenSnackbar(true);

      return;
    }

    if (isImageSelected) {
      const updatedPhotos = selectedPictures.filter(
        (photo) => photo.id !== picture.id
      );

      const formattedPhotos = updatedPhotos.map((photo, index) => {
        return { id: photo.id, selected_index: index + 1 };
      });
      setSelectedPictures(formattedPhotos);
    } else {
      if (selectedPictures.length >= selectionLimit) {
        setSnackbarMessage(
          `Somente ${selectionLimit} imagens podem ser selecionadas.`
        );
        setOpenSnackbar(true);
        setErrorMessage(true);
        return;
      }
      setSelectedPictures([
        ...selectedPictures,
        {
          id: picture.id,
          selected_index: selectedPictures.length + 1,
        },
      ]);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const selectedPicturesData = {
      inspection_id: inspectionId,
      selected_pictures: selectedPictures,
    };
    try {
      const response = await selectInspectionPictures(selectedPicturesData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.detail.status) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Imagens selecionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateBack();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <SectionBox>
        <TitleBox>
          <TitleTypography>
            {IconPhotoLibraryMS}
            {Constants.gallery}
          </TitleTypography>
          <SelectedText>
            selecionar {selectedPictures.length}/{selectionLimit}
          </SelectedText>
        </TitleBox>
        {rooms.map((room) => (
          <AccordionRoom
            key={room.id}
            roomData={room}
            selectedPictures={selectedPictures}
            setSelectedPictures={setSelectedPictures}
            handleSelectPhoto={handleSelectPhoto}
            loading={loading}
          />
        ))}
      </SectionBox>
      <FlexCenterBox>
        <SubmitButton onClick={handleSubmit}>
          {Constants.selectImages}
        </SubmitButton>
      </FlexCenterBox>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
