/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Status } from '../../../api/enumerations';
import {
  deleteInspectionPictures,
  getInspectionPictures,
} from '../../../api/inspections';
import { getAllRooms } from '../../../api/rooms';
import { RoomData } from '../../../api/rooms/types';
import { GetWorkOrder } from '../../../api/workOrders';
import ConfirmationDeleteDialog from '../../../components/ConfirmationDeleteDialog';
import Snackbar from '../../../components/Snackbar';
import { FlexBoxGap, TitleBox } from '../../../components/UI/Box';
import {
  SectionTitle,
  TitleTypography,
} from '../../../components/UI/Typography';
import {
  IconArrowCircleLeftMS,
  IconAssignmentMS,
  IconDoorFrontMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { RoomCard } from './RoomCard';
import {
  BackButton,
  EditButton,
  FlexCenterBox,
  GalleryContainer,
  GridContainer,
  SectionBox,
} from './styles';

export function Rooms(): JSX.Element {
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [rooms, setRooms] = useState<RoomData[]>([]);
  const [edit, setEdit] = useState(false);
  const pageSize = 12;

  const {
    openSnackbar,
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    inspectionPath,
  } = useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const navigate = useNavigate();
  const { id, inspection } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);

  const navigateBack = (): void => {
    if (inspectionPath) {
      navigate(inspectionPath);
    } else {
      navigate('/home');
    }
  };

  const navigateSelectRoom = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms/select`);
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await GetWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PROPOSAL ||
          response.data.status === Status.CONTRACT ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await getAllRooms(inspectionId, page, pageSize);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setRooms([...rooms, ...response.data]);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/home');
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page]);

  const handleDownloadPictures = async (): Promise<void> => {
    setDownloading(true);
    try {
      const response = await getInspectionPictures(inspectionId);

      if (response.ok) {
        const contentDisposition = response.headers
          .get('Content-Disposition')
          ?.split('filename=')[1];
        const filename = contentDisposition || 'file.zip';
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          handleDownloadFile(url, filename);
        });
        setDownloading(false);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setDownloading(false);
    }
  };

  const handleDeletePictures = async (): Promise<void> => {
    try {
      const response = await deleteInspectionPictures(inspectionId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Todas as imagens foram deletas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateBack();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <SectionBox>
        <TitleBox sx={{ marginBottom: '20px' }}>
          <TitleTypography>
            {IconAssignmentMS}
            {Constants.inspection}
          </TitleTypography>
          <FlexBoxGap>
            <EditButton onClick={handleDownloadPictures} disabled={downloading}>
              {downloading ? <CircularProgress size={22} /> : IconDownloadMS}
              {Constants.downloadImages}
            </EditButton>
            <Box width={282}>
              <ConfirmationDeleteDialog
                iconDelete
                title={Constants.deleteImages}
                text={Constants.deleteImagesText}
                modalCallback={handleDeletePictures}
              />
            </Box>
          </FlexBoxGap>
        </TitleBox>
        <SectionTitle>
          {IconDoorFrontMS}
          {Constants.rooms}
        </SectionTitle>
        <GalleryContainer>
          {rooms.map((room) => (
            <RoomCard
              key={room.id}
              room={room}
              rooms={rooms}
              setRooms={setRooms}
              edit={edit}
            />
          ))}
        </GalleryContainer>
      </SectionBox>
      <FlexCenterBox>
        {edit && (
          <Button onClick={navigateSelectRoom}>{Constants.addRoom}</Button>
        )}
      </FlexCenterBox>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
