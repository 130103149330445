/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconHubMS } from '../../constants/icons';
import { BoxContainer, TabStyled } from './styles';
import { Contacts } from './Tabs/Contacts';
import { Payments } from './Tabs/Payments';
import { Requests } from './Tabs/Requests';

export function Hive(): JSX.Element {
  const [value, setValue] = useState('0');

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>{IconHubMS} The Hive</TitleTypography>
      </TitleBox>
      <BoxContainer>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: '#707070' }}>
            <TabList onChange={handleChangeTab}>
              <TabStyled label="Pedidos" value="0" />
              <TabStyled label="Contatos" value="1" />
              <TabStyled label="Pagamentos" value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Requests />
          </TabPanel>
          <TabPanel value="1">
            <Contacts />
          </TabPanel>
          <TabPanel value="2">
            <Payments />
          </TabPanel>
        </TabContext>
      </BoxContainer>
    </GridMain>
  );
}
