import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  margin: '0 0 20px',
  '& span': { color: '#00B39B' },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '150px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '150px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
