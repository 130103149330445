import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
}));

export const ButtonContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '50px 0 30px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component }) => ({
    display: 'flex',
    gap: '18px',
    width: '480px',
    component,
  })
);

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '60px 0',
}));
