import attic from '../assets/roomImages/attic.png';
import balcony from '../assets/roomImages/balcony.png';
import basement from '../assets/roomImages/basement.png';
import bathroom from '../assets/roomImages/bathroom.png';
import bedroom from '../assets/roomImages/bedroom.png';
import closet from '../assets/roomImages/closet.png';
import corridor from '../assets/roomImages/corridor.png';
import diningroom from '../assets/roomImages/diningroom.png';
import entrancehall from '../assets/roomImages/entrancehall.png';
import front from '../assets/roomImages/front.png';
import garage from '../assets/roomImages/garage.png';
import garden from '../assets/roomImages/garden.png';
import gourmetarea from '../assets/roomImages/gourmetarea.png';
import hall from '../assets/roomImages/hall.png';
import kitchen from '../assets/roomImages/kitchen.png';
import laundry from '../assets/roomImages/laundry.png';
import livingroom from '../assets/roomImages/livingroom.png';
import mezzanine from '../assets/roomImages/mezzanine.png';
import office from '../assets/roomImages/office.png';
import pantry from '../assets/roomImages/pantry.png';
import pool from '../assets/roomImages/pool.png';
import porch from '../assets/roomImages/porch.png';
import sitingroom from '../assets/roomImages/sitingroom.png';
import stairs from '../assets/roomImages/stairs.png';
import standard from '../assets/roomImages/standard.png';
import terrace from '../assets/roomImages/terrace.png';
import toilet from '../assets/roomImages/toilet.png';
import yard from '../assets/roomImages/yard.png';

export const roomImg = {
  attic,
  balcony,
  basement,
  bathroom,
  bedroom,
  closet,
  corridor,
  diningroom,
  entrancehall,
  front,
  garage,
  garden,
  gourmetarea,
  hall,
  kitchen,
  laundry,
  livingroom,
  mezzanine,
  office,
  pantry,
  pool,
  porch,
  sitingroom,
  stairs,
  standard,
  terrace,
  toilet,
  yard,
};

export const roomsOptions = {
  data: [
    { id: 1, image: gourmetarea, name: 'churrasqueira' },
    { id: 2, image: pool, name: 'piscina' },
    { id: 3, image: bathroom, name: 'banheiro' },
    { id: 4, image: closet, name: 'closet' },
    { id: 5, image: corridor, name: 'corredor' },
    { id: 6, image: kitchen, name: 'cozinha' },
    { id: 7, image: pantry, name: 'despensa' },
    { id: 8, image: stairs, name: 'escada' },
    { id: 9, image: office, name: 'escritório' },
    { id: 10, image: front, name: 'fachada' },
    { id: 11, image: garage, name: 'garagem' },
    { id: 12, image: entrancehall, name: 'hall de entrada' },
    { id: 13, image: garden, name: 'jardim' },
    { id: 14, image: toilet, name: 'lavabo' },
    { id: 15, image: laundry, name: 'lavanderia' },
    { id: 16, image: mezzanine, name: 'mezanino' },
    { id: 17, image: basement, name: 'porão' },
    { id: 18, image: bedroom, name: 'quarto' },
    { id: 19, image: yard, name: 'quintal' },
    { id: 20, image: balcony, name: 'sacada' },
    { id: 21, image: livingroom, name: 'sala' },
    { id: 22, image: sitingroom, name: 'sala de estar' },
    { id: 23, image: diningroom, name: 'sala de jantar' },
    { id: 24, image: hall, name: 'salão' },
    { id: 25, image: attic, name: 'sótão' },
    { id: 26, image: terrace, name: 'terraço' },
    { id: 27, image: porch, name: 'varanda' },
    { id: 28, image: standard, name: 'outros' },
  ],
};
