export const Constants = {
  inspector: 'vistoriador',
  responsible: 'responsável',
  contact: 'contato',
  date: 'data',
  hour: 'horário',
  facade: 'download fachada',
  selfie: 'download selfie',
  document: 'download CNH',
};
