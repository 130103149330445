import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  '& span': { color: '#00B39B' },
}));

export const UploadBox = styled(Box)(() => ({
  width: '100%',
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const UploadIcon = styled(Typography)(() => ({
  '& span': { fontSize: '172px', color: '#00B39B' },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component }) => ({
    marginTop: '20px',
    display: 'flex',
    gap: '10px',
    component,
  })
);

export const StyledDivider = styled(Divider)(() => ({
  margin: '20px 0',
  '&.MuiDivider-root::before': {
    borderTop: '4px solid #004D43',
  },
  '&.MuiDivider-root::after': {
    borderTop: '4px solid #004D43',
  },
  '& span': {
    fontSize: '18px',
    fontWeight: '600',
    padding: '0 14px',
  },
}));

export const TipsBox = styled(Box)(() => ({
  backgroundColor: '#FAFCFF',
  borderRadius: '8px',
  boxShadow: '3px 3px 6px #8B8C8E',
  textAlign: 'center',
  padding: '20px',
  minHeight: '160px',
}));

export const TipsTitle = styled(Typography)(() => ({
  fontWeight: '700',
  color: '#00B39B',
  paddingBottom: '10px',
}));

export const TipsText = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#595959',
}));
