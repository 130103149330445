/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import { Location } from './Location';
import { Map } from './Map';
import { RadioButtonContainer, TopButton } from './styles';

export function Contacts(): JSX.Element {
  const [radioButtomValue, setRadioButtomValue] = useState('location');

  return (
    <div style={{ padding: 42 }}>
      <RadioButtonContainer>
        <TopButton
          type="button"
          className={radioButtomValue === 'location' ? 'selected' : ''}
          onClick={() => setRadioButtomValue('location')}
        >
          Localização
        </TopButton>
        <TopButton
          type="button"
          className={radioButtomValue === 'map' ? 'selected' : ''}
          onClick={() => setRadioButtomValue('map')}
        >
          Mapa
        </TopButton>
      </RadioButtonContainer>
      {radioButtomValue === 'location' ? <Location /> : <Map />}
    </div>
  );
}
