/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { EvaluationType, Status } from '../../api/enumerations';
import { getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import {
  ChangeStatus,
  GetWorkOrder,
  SendInvoiceAndReceipt,
} from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportDownload } from '../../components/Sections/ReportDownload';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { Constants } from '../../constants/delivery';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
  IconUploadMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { validateImg } from '../../helpers';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';
import {
  BackButton,
  ButtonBox,
  ButtonContainer,
  SectionBox,
  SubmitBox,
  UploadButton,
} from './styles';

export function Delivery(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [receipt, setReceipt] = useState<File | string>();
  const [receiptfileName, setReceiptFileName] = useState('');
  const [invoice, setInvoice] = useState<File | string>();
  const [invoiceFileName, setInvoiceFileName] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingApprove, setLoadingApprove] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
  ]);

  const getDataCallback = useCallback(async () => {
    let getSamples = false;
    let isFactors = false;

    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.DELIVERY) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (
        response.data.evaluation_type === EvaluationType.AUTOFACTORS ||
        response.data.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      try {
        const response = await getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [osId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const onInputChange = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    if (fileType === 'receipt') {
      setReceiptFileName(file.name);
      setReceipt(file);
    } else if (fileType === 'invoice') {
      setInvoiceFileName(file.name);
      setInvoice(file);
    }
  };

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleSubmit = async (): Promise<void> => {
    if (!receipt) {
      setSnackbarMessage('O upload do boleto é obrigatório');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    if (!invoice) {
      setSnackbarMessage('O upload da nota fiscal é obrigatório');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    setLoadingApprove(true);

    const formData = new FormData();
    formData.append('invoice', invoice);
    formData.append('receipt', receipt);

    try {
      const res = await SendInvoiceAndReceipt(osId, formData);

      if (res.detail.description) {
        throw new Error(res.detail.description);
      }

      if (res.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const response = await ChangeStatus(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }

      setSnackbarMessage(`OS ${propertyData?.reference_number} aprovada!`);
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoadingApprove(false);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingApprove(false);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.delivery}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <AllAttachments propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            <ClientData propertyData={propertyData} />
            <ButtonContainer>
              <ButtonBox>
                <label htmlFor="ticket-attach">
                  <input
                    accept="image/*"
                    id="ticket-attach"
                    type="file"
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, 'receipt')
                    }
                  />
                  <UploadButton variant="contained" component="span">
                    {IconUploadMS}
                    {receiptfileName
                      ? Constants.changeUploadSlip
                      : Constants.uploadSlip}
                  </UploadButton>
                </label>
                {receiptfileName && <Box mt={1}>{receiptfileName}</Box>}
              </ButtonBox>
              <ButtonBox>
                <label htmlFor="invoice-attach">
                  <input
                    accept="image/*"
                    id="invoice-attach"
                    type="file"
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                      onInputChange(e, 'invoice')
                    }
                  />
                  <UploadButton variant="contained" component="span">
                    {IconUploadMS}
                    {invoiceFileName
                      ? Constants.changeUploadInvoice
                      : Constants.uploadInvoice}
                  </UploadButton>
                </label>
                {invoiceFileName && <Box mt={1}>{invoiceFileName}</Box>}
              </ButtonBox>
            </ButtonContainer>
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.report}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && <ReportDownload id={osId} />}
              </SectionBox>
              <SubmitBox>
                <ConfirmationDialog
                  title={Constants.confirmEdit}
                  loading={loadingApprove}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={handleSubmit}
                />
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
