/* eslint-disable react-hooks/exhaustive-deps */
import {
  Switch,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ChangeEvent, useCallback, useContext, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { sendForceFactors } from '../../../api/workOrders';
import { WhiteProgress } from '../../../components/UI/Typography';
import { Constants, forceFactorsData } from '../../../constants/calculation';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import {
  BoldCell,
  ButtonBox,
  CellContent,
  CellHeader,
  DialogButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  RowHeader,
  SectionTitle,
  StyledDialog,
  StyledTable,
  StyledTooltip,
} from './styles';

interface ForceFactorsProps {
  refNumber: number | undefined;
  factors: string[];
}

export function ForceFactors({
  refNumber,
  factors,
}: ForceFactorsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [forceFactors, setForceFactors] = useState(factors);
  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    setForceFactors(factors);
    setOpen(false);
  };

  const handleForceFactors = useCallback(async (): Promise<void> => {
    setLoading(true);

    let data = {
      force_factors: forceFactors,
    };

    if (!forceFactors.includes('Área Construída')) {
      data = {
        force_factors: forceFactors.concat('Área Construída'),
      };
    }

    try {
      const response = await sendForceFactors(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLoading(false);
      setErrorMessage(false);
      setSnackbarMessage(`OS ${refNumber} aprovada`);
      setOpenSnackbar(true);
      handleClose();
      navigateHome();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [forceFactors]);

  return (
    <>
      <DialogButton onClick={handleOpen}>{Constants.recalculate}</DialogButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="calculation force factors"
      >
        <DialogContainer>
          <SectionTitle>{Constants.forceFactors}</SectionTitle>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <RowHeader>
                  <CellHeader />
                  <CellHeader>{Constants.name}</CellHeader>
                  <CellHeader>{Constants.forceFactor}</CellHeader>
                </RowHeader>
              </TableHead>
              <TableBody>
                {forceFactorsData.map((data) => (
                  <TableRow key={data.index}>
                    <CellContent>{data.index}</CellContent>
                    <BoldCell>{data.name}</BoldCell>
                    <CellContent>
                      {data.index === 2 ? (
                        <StyledTooltip title={Constants.areaTooltip}>
                          <Switch checked />
                        </StyledTooltip>
                      ) : (
                        <Switch
                          checked={forceFactors.includes(data.value)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                              setForceFactors([...forceFactors, data.value]);
                            } else {
                              const deleteChoice = forceFactors?.filter(
                                (element) => {
                                  return element !== data.value;
                                }
                              );
                              setForceFactors(deleteChoice);
                            }
                          }}
                        />
                      )}
                    </CellContent>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>
              {Constants.cancel}
            </OutlinedButton>
            <FilledButton onClick={handleForceFactors}>
              {loading ? <WhiteProgress size={22} /> : Constants.confirm}
            </FilledButton>
          </ButtonBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
