import { useContext } from 'react';

import { CancelWorkOrder } from '../api/workOrders';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';
import useGeneral from './useGeneral';

interface UseCancelWorkOrderProps {
  handleCancelWorkOrder: () => Promise<void>;
}

export function useCancelWorkOrder(): UseCancelWorkOrderProps {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const handleCancelWorkOrder = async (): Promise<void> => {
    try {
      const response = await CancelWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('A OS foi cancelada!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };
  return {
    handleCancelWorkOrder,
  };
}
