import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const EditButton = styled(Button)(() => ({
  width: 210,
  height: 32,
  border: '1.5px solid #00B39B',
  borderRadius: 10,
  color: '#00B39B',
  fontWeight: 600,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  '& span': { color: '#00B39B' },
}));

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '180px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const ButtonContainer = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-around',
  gap: '40px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
}));

export const ButtonTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  padding: '10px',
}));

export const DownloadButton = styled(Button)(() => ({
  display: 'flex',
  gap: '10px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component }) => ({
    display: 'flex',
    gap: '10px',
    component,
  })
);

export const CepStyle = {
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
};

export const CoordGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
  gap: '40px',
}));

export const ConfirmEditButton = styled(Button)(({ theme }) => ({
  minWidth: '250px',
  border: '1px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
}));

export const OutlineButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '280px',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  marginTop: '16px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const textFiledDefaultStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const StyledTextField = styled(TextField)(() => textFiledDefaultStyle);

export const PatternFormatStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};
