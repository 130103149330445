import { Box, Button, CardMedia, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
}));

export const FormButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  width: '920px',
  [theme.breakpoints.down('lg')]: {
    width: '600px',
  },
}));

export const SectionTitleContainer = styled(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
}));

export const SectionTitle = styled(Typography)(() => ({
  minWidth: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  '& span': { color: '#00B39B' },
}));

export const CardContainer = styled(Paper)(() => ({
  minHeight: '60px',
  borderRadius: '10px',
  margin: '8px 0 16px',
  padding: '16px',
}));

export const TypeFlag = styled(Box)(() => ({
  width: '50px',
  height: '42px',
  float: 'right',
  margin: '-17.5px -17px 0',
  clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
  borderTopRightRadius: '9px',
}));

export const BankFlag = styled(CardMedia)(() => ({
  width: '18px',
  height: '18px',
  float: 'right',
  margin: '-12px -30px 0',
  zIndex: '200',
  position: 'relative',
}));

export const CardLabel = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
  fontWeight: '600',
  color: '#1A1A1A',
  textAlign: 'start',
  textTransform: 'capitalize',
}));

export const CardSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
  margin: '-10px 10px',
  color: '#8c8c8c',
}));
