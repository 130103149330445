/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ClientKind } from '../../../api/enumerations';
import {
  addInspectionForm,
  deleteInspectionForm,
  getInspectionForm,
  updateInspectionForm,
} from '../../../api/forms';
import { QuestionData } from '../../../api/forms/types';
import {
  CustomFormControl,
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import Snackbar from '../../../components/Snackbar';
import { BoxContainer, TitleBox } from '../../../components/UI/Box';
import { TitleTypography } from '../../../components/UI/Typography';
import { Constants } from '../../../constants/forms';
import {
  IconArrowCircleLeftMS,
  IconDownloadMS,
  IconKeyboardDoubleArrowDownMS,
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
  IconKeyboardDoubleArrowUpMS,
  IconListAltMS,
  IconUploadMS,
} from '../../../constants/icons';
import {
  selectClientKind,
  selectPropertyType,
} from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import DeleteDialog from './DeleteDialog';
import { localQuestionsData } from './localQuestionsData';
import NewQuestionDialog from './NewQuestionDialog';
import { QuestionCard } from './QuestionCard';
import { SelectedQuestionCard } from './SelectedQuestionCard';
import {
  BackButton,
  FilledDarkButton,
  FlexBox,
  GridButton,
  GridContainer,
  GridQuestions,
  IconButtonBase,
  OutlinedButton,
  OutlinedDarkButton,
  PaperQuestions,
  TitleQuestions,
} from './styles';
import { ViewQuestions } from './ViewQuestionsDialog';

interface JumpProps {
  title: string;
  jumpTo: number | null;
  jumpToSelectedTitle: string | undefined;
  jumpToBankTitle: string | undefined;
}

export function FormCreation(): JSX.Element {
  const [formName, setFormName] = useState('');
  const [clientType, setClientType] = useState(0);
  const [propertyType, setPropertyType] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [showTemplate, setShowTemplate] = useState(true);
  const [questions, setQuestions] = useState<QuestionData[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionData[]>(
    []
  );
  const [checkedBankQ, setCheckedBankQ] = useState<QuestionData[]>([]);
  const [checkedSelectQ, setCheckedSelectQ] = useState<QuestionData[]>([]);
  const [newForm, setNewForm] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [checkAllSelected, setCheckAllSelected] = useState(false);
  const [checkAllBank, setCheckAllBank] = useState(false);

  const { openSnackbar, setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const formId = parseInt(id as string, 10);

  const getDataCallback = useCallback(async () => {
    if (location.pathname.includes('/edit')) {
      setNewForm(false);
      try {
        const response = await getInspectionForm(formId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setFormName(response.data.title);
        setClientType(response.data.client_kind);
        setPropertyType(response.data.real_estate_type);

        setShowProgress(response.data.selected_questions.show_progress);
        setSelectedQuestions(response.data.selected_questions.json);

        if (response.data.question_bank) {
          setQuestions(response.data.question_bank);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    const clientQuestions = selectedQuestions.filter((e) => e.id > 9);
    if (clientType === ClientKind.INSPECTION) {
      setSelectedQuestions(clientQuestions);
      setSaveTemplate(false);
      setShowTemplate(false);
    } else {
      const mergedQuestions = localQuestionsData.concat(clientQuestions);
      setSelectedQuestions(mergedQuestions);
      setShowTemplate(true);
    }
  }, [clientType]);

  const handleNewId = (): number => {
    const idArray = [...questions, ...selectedQuestions];
    if (idArray.length === 0) {
      return 1;
    }
    // checks biggest id on both lists before creating new one
    const ids = idArray.map((item) => item.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  };

  const handleQuestionUp = (): void => {
    if (checkedSelectQ.length > 1) {
      setSnackbarMessage('Selecione apenas uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (checkedSelectQ.length === 0) {
      setSnackbarMessage('Selecione uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const checkedQuestion = checkedSelectQ[0];
    const questionIndex = selectedQuestions.indexOf(checkedQuestion);

    if (questionIndex === 0) return;

    const newIndex = questionIndex - 1;

    // uses splice to move question up on the list
    selectedQuestions.splice(questionIndex, 1);
    selectedQuestions.splice(newIndex, 0, checkedQuestion);

    setSelectedQuestions([...selectedQuestions]);
  };

  const handleQuestionDown = (): void => {
    if (checkedSelectQ.length > 1) {
      setSnackbarMessage('Selecione apenas uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (checkedSelectQ.length === 0) {
      setSnackbarMessage('Selecione uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const checkedQuestion = checkedSelectQ[0];
    const questionIndex = selectedQuestions.indexOf(checkedQuestion);

    if (questionIndex > selectedQuestions.length) return;

    const newIndex = questionIndex + 1;

    // uses splice to move question down on the list
    selectedQuestions.splice(questionIndex, 1);
    selectedQuestions.splice(newIndex, 0, checkedQuestion);

    setSelectedQuestions([...selectedQuestions]);
  };

  useEffect(() => {
    if (checkAllBank) {
      setCheckedBankQ(questions);
    } else {
      setCheckedBankQ([]);
    }
  }, [checkAllBank]);

  useEffect(() => {
    if (checkAllSelected) {
      setCheckedSelectQ(selectedQuestions);
    } else {
      setCheckedSelectQ([]);
    }
  }, [checkAllSelected]);

  const addSelectedQuestion = (): void => {
    if (checkedBankQ.length < 1) return;

    setSelectedQuestions([...selectedQuestions, ...checkedBankQ]);

    // filters questions to return the ones that are not included in checkedBankQ
    const newBankQuestions = questions.filter((element) => {
      return !checkedBankQ.includes(element);
    });

    setQuestions(newBankQuestions);
    setCheckedBankQ([]);
    setCheckAllBank(false);
  };

  const removeSelectedQuestion = (): void => {
    if (checkedSelectQ.length < 1) return;

    const filterSelected = checkedSelectQ.filter((element) => {
      return !element.blocked;
    });

    setQuestions([...questions, ...filterSelected]);

    // filters questions to return the ones that are not included in checkedSelectQ
    const newSelectedQuestions = selectedQuestions.filter((element) => {
      return !filterSelected.includes(element);
    });

    setSelectedQuestions(newSelectedQuestions);
    setCheckedSelectQ([]);
    setCheckAllSelected(false);
  };

  const checkJumpTo = (): JumpProps[] => {
    const questionWithDependencies = selectedQuestions
      .filter((question) => question.choices.find((e) => e.jump_to !== null))
      .map((question) => {
        return question.choices
          .filter((choice) => choice.jump_to !== null)
          .map((choice) => {
            const jumpQuestion = selectedQuestions.find(
              (q) => q.id === choice.jump_to
            );
            return {
              title: `${question.id}. ${question.title}`,
              jumpTo: choice.jump_to,
              jumpToSelectedTitle: jumpQuestion?.title,
              jumpToBankTitle: jumpQuestion?.title,
            };
          });
      });

    return questionWithDependencies
      .map((question) =>
        question.filter((choice) => choice.jumpToSelectedTitle === undefined)
      )
      .flat();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!formName || clientType === 0 || propertyType === 0) {
      setSnackbarMessage('Preencha os dados obrigatórios!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const missingQuestions = checkJumpTo();

    if (missingQuestions.length > 0) {
      setSnackbarMessage(
        // eslint-disable-next-line max-len
        `${missingQuestions[0].jumpToBankTitle} é utilizida em ${missingQuestions[0].title}. Mova-a do banco de perguntas para perguntas selecionadas.`
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formQuestions = {
      show_progress: showProgress,
      json: selectedQuestions,
    };

    const inspectionForm = {
      client_kind: clientType,
      question_bank: JSON.stringify(questions),
      real_estate_type: propertyType,
      selected_questions: JSON.stringify(formQuestions),
      title: formName,
    };

    if (location.pathname.includes('/edit')) {
      try {
        const response = await updateInspectionForm(formId, inspectionForm);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Formulário editado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        navigate('/forms');
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      try {
        const response = await addInspectionForm(inspectionForm, saveTemplate);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Formulário criado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        navigate('/forms');
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  const handleDeleteForm = async (): Promise<void> => {
    try {
      const response = await deleteInspectionForm(formId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Formulário deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate('/forms');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileJSONChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        try {
          const parsedData = JSON.parse(content);
          setQuestions([...questions, ...parsedData]);
        } catch (error) {
          setSnackbarMessage(getErrorMessage(error));
          setErrorMessage(true);
          setOpenSnackbar(true);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleDownloadJson = (): void => {
    const removeDefaultQuestions = selectedQuestions.filter(
      (question) =>
        !localQuestionsData.some(
          (localQuestion) =>
            question.title === localQuestion.title &&
            question.id === localQuestion.id
        )
    );

    const JSONdata = JSON.stringify(removeDefaultQuestions);

    const blob = new Blob([JSONdata], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <GridContainer>
      <BackButton onClick={() => navigate('/forms')}>
        {IconArrowCircleLeftMS}
      </BackButton>
      <BoxContainer>
        <TitleBox>
          <TitleTypography>
            {IconListAltMS}
            {newForm ? Constants.createForm : Constants.editForm}
          </TitleTypography>
        </TitleBox>
        <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
          <Grid item xs={3.5} xl={3}>
            <CustomTextField
              required
              id="form-name"
              label="título"
              value={formName}
              setValue={setFormName}
            />
          </Grid>
          {newForm && showTemplate ? (
            <Grid item xs={2.5} xl={3.5}>
              <CustomFormControl
                label={Constants.saveAsTemplate}
                isChecked={saveTemplate}
                setIsChecked={setSaveTemplate}
              />
            </Grid>
          ) : (
            <Grid item xs={2.5} xl={3.5} />
          )}

          <Grid item xs={2} xl={2.5}>
            <input
              type="file"
              ref={fileInputRef}
              accept=".json"
              multiple={false}
              onChange={handleFileJSONChange}
              style={{ display: 'none' }}
            />
            <IconButtonBase onClick={handleButtonClick}>
              {IconUploadMS}
              {Constants.importQuestions}
            </IconButtonBase>
          </Grid>
          <Grid item xs={2} xl={1.5}>
            <IconButtonBase
              disabled={!selectedQuestions}
              onClick={handleDownloadJson}
            >
              {IconDownloadMS}
              {Constants.download}
            </IconButtonBase>
          </Grid>
          <Grid item xs={2} xl={1.5}>
            <DeleteDialog modalCallback={handleDeleteForm} />
          </Grid>
          <Grid item xs={3.5} xl={3}>
            <SelectTextField
              id="client-type"
              label="tipo do cliente"
              value={clientType}
              setValue={setClientType}
              selectOptions={selectClientKind()}
            />
          </Grid>
          <Grid item xs={3.5} xl={3}>
            <SelectTextField
              id="property-type"
              label="tipo do imóvel"
              value={propertyType}
              setValue={setPropertyType}
              selectOptions={selectPropertyType()}
            />
          </Grid>
          <Grid item xs={0.5} xl={3} />
          <Grid item xs={4.5} xl={3}>
            <CustomFormControl
              label={Constants.showProgress}
              isChecked={showProgress}
              setIsChecked={setShowProgress}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <GridQuestions item xs={4.5}>
            <TitleQuestions>{Constants.questionBank}</TitleQuestions>
            <CustomFormControl
              label={Constants.selectAll}
              isChecked={checkAllBank}
              setIsChecked={setCheckAllBank}
            />
            <PaperQuestions>
              {questions.map((data, i) => (
                <QuestionCard
                  key={data.id}
                  question={data}
                  orderNumber={i + 1}
                  questions={questions}
                  setQuestions={setQuestions}
                  checkedQ={checkedBankQ}
                  setCheckedQ={setCheckedBankQ}
                  allQuestions={questions.concat(selectedQuestions)}
                  checkAll={checkAllBank}
                />
              ))}
            </PaperQuestions>
            <NewQuestionDialog
              newQuestionId={handleNewId()}
              questions={questions}
              setQuestions={setQuestions}
              allQuestions={questions.concat(selectedQuestions)}
            />
          </GridQuestions>
          <GridButton item xs={2.5}>
            <OutlinedButton onClick={addSelectedQuestion}>
              {Constants.add} {IconKeyboardDoubleArrowRightMS}
            </OutlinedButton>
            <OutlinedButton onClick={removeSelectedQuestion}>
              {IconKeyboardDoubleArrowLeftMS} {Constants.remove}
            </OutlinedButton>
          </GridButton>
          <GridQuestions item xs={4.5}>
            <TitleQuestions>{Constants.selectedQuestions}</TitleQuestions>
            <CustomFormControl
              label={Constants.selectAll}
              isChecked={checkAllSelected}
              setIsChecked={setCheckAllSelected}
            />
            <PaperQuestions>
              {selectedQuestions.map((data, i) => (
                <SelectedQuestionCard
                  key={data.id}
                  question={data}
                  orderNumber={i + 1}
                  checkedQ={checkedSelectQ}
                  setCheckedQ={setCheckedSelectQ}
                  checkAll={checkAllSelected}
                />
              ))}
            </PaperQuestions>
            <ViewQuestions questions={selectedQuestions} />
            <FlexBox>
              <OutlinedDarkButton onClick={() => navigate('/forms')}>
                {Constants.cancel}
              </OutlinedDarkButton>
              <FilledDarkButton onClick={handleSubmit}>
                {Constants.save}
              </FilledDarkButton>
            </FlexBox>
          </GridQuestions>
          <GridButton item xs={0.5}>
            <OutlinedButton onClick={handleQuestionUp}>
              {IconKeyboardDoubleArrowUpMS}
            </OutlinedButton>
            <OutlinedButton onClick={handleQuestionDown}>
              {IconKeyboardDoubleArrowDownMS}
            </OutlinedButton>
          </GridButton>
        </Grid>
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
