/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  IconAttachFileMS,
  IconDescriptionMS,
} from '../../../../../constants/icons';
import { IconButtonStyled, StatusButton, TableCellStyled } from './styles';

export function PaymentTable(): JSX.Element {
  const tableData = [
    {
      os: 75338,
      company: 'Real Price',
      cnpj_cpf: '00.000.000/0001-00',
      city: 'São Paulo',
      due: '2023-10-13',
      payday: '2023-10-10',
      combined_value: 30000,
      status: 'pago',
    },
    {
      os: 75339,
      company: 'Empresa Delta',
      cnpj_cpf: '00.000.000/0001-00',
      city: 'São Paulo',
      due: '2023-10-30',
      payday: 'pendente',
      combined_value: 35675,
      status: 'pendente',
    },
    {
      os: 75340,
      company: 'Empresa Gama',
      cnpj_cpf: '00.000.000/0001-00',
      city: 'São Paulo',
      due: '2023-10-02',
      payday: 'em atraso',
      combined_value: 24032,
      status: 'pendente',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellStyled>OS</TableCellStyled>
            <TableCellStyled>Empresa</TableCellStyled>
            <TableCellStyled>CNPJ/CPF</TableCellStyled>
            <TableCellStyled>Cidade</TableCellStyled>
            <TableCellStyled>Data de Vencimento</TableCellStyled>
            <TableCellStyled>Data de Pagamento</TableCellStyled>
            <TableCellStyled>Valor combinado</TableCellStyled>
            <TableCellStyled>Situação</TableCellStyled>
            <TableCellStyled>{IconAttachFileMS}</TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((data) => (
            <TableRow key={data.os}>
              <TableCell>{data.os}</TableCell>
              <TableCell>{data.company}</TableCell>
              <TableCell>{data.cnpj_cpf}</TableCell>
              <TableCell>{data.city}</TableCell>
              <TableCell>{data.due}</TableCell>
              <TableCell>{data.payday}</TableCell>
              <TableCell>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(data.combined_value)}
              </TableCell>
              <TableCell>
                {data.status === 'pago' ? (
                  <StatusButton disabled>pago</StatusButton>
                ) : (
                  <StatusButton>pagar</StatusButton>
                )}
              </TableCell>
              <TableCell>
                <IconButtonStyled disabled={data.status !== 'pago' && true}>
                  {IconDescriptionMS}
                </IconButtonStyled>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
