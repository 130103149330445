/* eslint-disable react/require-default-props */
import { IconAssignmentMS, IconInventoryMS } from '../../../constants/icons';
import { convertDate } from '../../../helpers';
import { TitleTypography } from '../../UI/Typography';
import { OSNumber, TitleBox, CreatedStyle, FlexBox } from './styles';

interface TitleProps {
  osNumber?: number;
  title: string;
  newOs?: boolean;
  createdAt?: string;
  icon?: JSX.Element;
}

export function Title({
  osNumber,
  title,
  newOs,
  createdAt,
  icon = IconAssignmentMS,
}: TitleProps): JSX.Element {
  const isWorkOrderPage = osNumber || newOs || createdAt;
  return (
    <TitleBox>
      <TitleTypography>
        {icon}
        {title}
      </TitleTypography>
      {isWorkOrderPage && (
        <FlexBox>
          {createdAt && (
            <CreatedStyle>criação {convertDate(createdAt)}</CreatedStyle>
          )}
          {!newOs && (
            <OSNumber>
              {IconInventoryMS}
              número OS {osNumber}
            </OSNumber>
          )}
        </FlexBox>
      )}
    </TitleBox>
  );
}
