import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  margin: '0 20px 20px 0',
  height: '45px',
  border: 'none',
  textTransform: 'lowercase',
  '& :hover': {
    color: '#00B39B',
  },
  '& .MuiButtonBase-root.MuiToggleButton-root': {
    '&.Mui-selected': {
      color: '#FAFCFF',
      backgroundColor: '#004D43',
      border: 'none',
      borderRadius: '4px',
      '& :hover': {
        color: '#FAFCFF',
        backgroundColor: '#004D43',
        opacity: '80%',
      },
      '&:hover': {
        backgroundColor: '#004D43',
        opacity: '80%',
      },
    },
  },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'lowercase',
}));
