import {
  Box,
  Button,
  InputBase,
  Select,
  Typography,
  styled,
} from '@mui/material';

export const SectionTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
}));

export const SearchBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '32px 0',
  alignItems: 'center',
  '& span': { fontSize: '30px' },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderBottom: '1px solid #414244',
  padding: '0 18px',
  '& .MuiInputBase-input': {
    width: '200px',
    padding: '10px 0 10px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '200px',
      '&:focus': {
        width: '300px',
      },
    },
  },
}));

export const SearchButton = styled(Button)(() => ({
  margin: '0 8px',
  backgroundColor: 'transparent',
  color: '#004D43',
  width: 400,
  height: 48,
  fontWeight: '600',
  padding: '6px',
  border: '1px solid #004D43',
  '&:hover': {
    backgroundColor: '#0B2623',
    color: '#FAFCFF',
  },
}));

export const SelectPeriod = styled(Select)(() => ({
  backgroundColor: '#00B39B',
  color: '#FAFCFF',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
}));

export const PagesContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem 0',
}));
