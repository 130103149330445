import { useContext, useState } from 'react';

import { updateRoom } from '../../../../api/rooms';
import { RoomData } from '../../../../api/rooms/types';
import { DialogTitle } from '../../../../components/UI/Typography';
import { IconCloseMS, IconEditMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/inspection';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import {
  CloseButton,
  DialogContainer,
  FormStyled,
  SaveButton,
  StyledDialog,
  StyledMenuItem,
  TextFieldStyled,
  TopBox,
} from './styles';

export interface EditRoomDialogProps {
  room: RoomData;
  rooms: RoomData[];
  setRooms: (rooms: RoomData[]) => void;
}

export default function EditRoomDialog({
  room,
  rooms,
  setRooms,
}: EditRoomDialogProps): JSX.Element {
  const [name, setName] = useState(room.name);
  const [open, setOpen] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleUpdateRoom = async (): Promise<void> => {
    const editedName = {
      name,
      room_type_id: room.room_type_id,
    };

    try {
      const response = await updateRoom(room.id, editedName);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Cômodo atualizado!');
      setErrorMessage(false);
      setOpenSnackbar(true);

      const editRoom = rooms.find((element) => element.id === room.id);

      if (editRoom) {
        editRoom.name = name;
        const newData = [...rooms];
        setRooms(newData);
      }

      handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <StyledMenuItem disableTouchRipple onClick={handleOpen}>
        {IconEditMS}
        {Constants.editRoom}
      </StyledMenuItem>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContainer>
          <TopBox>
            <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
          </TopBox>
          <DialogTitle>
            {IconEditMS}
            {Constants.editRoom}
          </DialogTitle>
          <FormStyled>
            <TextFieldStyled
              focused
              id="name"
              value={name}
              inputProps={{ maxLength: '18px' }}
              variant="outlined"
              label="nome cômodo"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <SaveButton onClick={handleUpdateRoom}>{Constants.save}</SaveButton>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
