/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Menu } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { QuestionData } from '../../../../api/forms/types';
import { Constants } from '../../../../constants/forms';
import {
  IconDeleteMS,
  IconEditNoteMS,
  IconMoreVertMS,
  IconVisibilityMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { checkQuestionIcon } from '../../../../helpers';
import EditQuestionDialog from '../EditQuestionDialog';
import {
  CardContainer,
  CheckboxStyle,
  FlexBox,
  MenuButton,
  NumberTypography,
  StyledMenuItem,
  Title,
} from './styles';

interface QuestionCardProps {
  question: QuestionData;
  orderNumber: number;
  questions: QuestionData[];
  setQuestions: (questions: QuestionData[]) => void;
  checkedQ: QuestionData[];
  setCheckedQ: (checkedQ: QuestionData[]) => void;
  allQuestions: QuestionData[];
  checkAll: boolean;
}

export function QuestionCard({
  question,
  orderNumber,
  questions,
  setQuestions,
  checkedQ,
  setCheckedQ,
  allQuestions,
  checkAll,
}: QuestionCardProps): JSX.Element {
  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [checked, setChecked] = useState(false);
  const isMenuOpen = Boolean(anchorMenu);
  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (checkAll) {
      return;
    }
    if (checked) {
      setCheckedQ([...checkedQ, question]);
    } else {
      const deleteCheckedQ = checkedQ.filter((element) => {
        return element.id !== question.id;
      });
      setCheckedQ(deleteCheckedQ);
    }
  }, [checked]);

  useEffect(() => {
    setChecked(checkAll);
  }, [checkAll]);

  const closeMenu = (): void => setAnchorMenu(null);
  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setAnchorMenu(e.currentTarget);

  const deleteQuestion = (): void => {
    /* maps questions to filter choices that jump_to current question, then returns the element 
    with that choice or an empty list */
    const checkJumpTo = allQuestions.map((element) => {
      const checkId = element.choices.filter((e) => e.jump_to === question.id);
      const choiceList = checkId.flat(1);
      if (element.choices.includes(choiceList[0])) {
        return element;
      }
      return choiceList;
    });

    const hasJumpTo = checkJumpTo.flat(1);

    // alerts user question can't be deleted if another question jumps to it
    if (hasJumpTo.length > 0) {
      setSnackbarMessage(`${Constants.jumpToText}${hasJumpTo[0].title}!`);
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    // deletes current question
    const filteredQuestions = questions.filter((element) => {
      return element.id !== question.id;
    });
    setQuestions(filteredQuestions);
  };

  return (
    <CardContainer>
      <FlexBox>
        <NumberTypography>{orderNumber}. </NumberTypography>
        <Title>
          {checkQuestionIcon(question.type)}
          {question.title}
        </Title>
      </FlexBox>
      <FlexBox>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
          sx={CheckboxStyle}
        />
        <MenuButton onClick={openMenu}>{IconMoreVertMS}</MenuButton>
        <Menu anchorEl={anchorMenu} open={isMenuOpen} onClose={closeMenu}>
          <StyledMenuItem>
            {IconVisibilityMS}
            {Constants.view}
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setOpenEditQuestion(true)}>
            {IconEditNoteMS}
            {Constants.edit}
          </StyledMenuItem>
          <StyledMenuItem onClick={deleteQuestion}>
            {IconDeleteMS}
            {Constants.delete}
          </StyledMenuItem>
        </Menu>
      </FlexBox>
      {openEditQuestion && (
        <EditQuestionDialog
          open={openEditQuestion}
          setOpen={setOpenEditQuestion}
          handleCloseMenu={closeMenu}
          question={question}
          questions={questions}
          setQuestions={setQuestions}
          allQuestions={allQuestions}
        />
      )}
    </CardContainer>
  );
}
