import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellCollapse = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  color: '#707070',
  '&:nth-of-type(2)': {
    width: '25%',
  },
  '&:nth-of-type(3)': {
    width: '25%',
  },
  '&:nth-of-type(4)': {
    width: '20%',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '8px',
  },
}));

export const TableCellBody = styled(TableCell)(({ theme }) => ({
  color: '#707070',
  textAlign: 'center',
  fontSize: '16px',
  '& span': {
    color: '#00B39B',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '12px',
    fontSize: '14px',
  },
}));

export const StyledIcon = {
  verticalAlign: 'middle',
  padding: '0 10px 0 40px',
};
