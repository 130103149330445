import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
  gap: '40px',
}));

interface IAvmStatusBox {
  color: string;
}

export const AvmStatusBox = styled(Box)<IAvmStatusBox>(({ color }) => ({
  width: '100%',
  padding: '23px 37px',
  margin: '50px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  background: '#F5F7FB',
  border: `1px solid ${color}`,
  color,
  fontSize: 21,
  textAlign: 'justify',
}));

export const QueuePosition = styled(Typography)(({ theme }) => ({
  width: 210,
  height: '100%',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  padding: '0 8px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
}));
