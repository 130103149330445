import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ColorDotProps {
  color: string;
}

export const ColorDot = styled(Box)<ColorDotProps>(({ color }) => ({
  height: 18,
  minWidth: 18,
  borderRadius: 50,
  background: color,
}));

export const StyledList = styled(Box)(() => ({
  minHeight: '480px',
  borderRadius: '8px',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 8,
    height: 0,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#004D43CC',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '10px 0',
  },
  '@media (max-height: 820px)': {
    minHeight: 360,
  },
}));
