import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '25px',
    backgroundColor: '#FFFFFF',
    minWidth: '680px',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  padding: '60px',
  minHeight: '380px',
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseButton = styled(IconButton)(() => ({
  right: '20px',
  top: '20px',
  position: 'absolute',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const SaveButton = styled(Button)(() => ({
  textTransform: 'lowercase',
  marginLeft: 'auto',
  marginTop: '12px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const FormStyled = styled(Box)(() => ({
  width: '100%',
  margin: '32px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

export const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '16px',
    '&.Mui-focused:hover fieldset': {
      borderColor: '#00B39B',
    },
    '&.Mui-focused label': {
      color: '#595959',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #595959',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#595959',
  },
  '& .MuiSvgIcon-root': {
    color: '#00B39B',
    fontSize: '36px',
  },
}));
