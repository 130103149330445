import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import {
  ConvertedCubData,
  ConvertedCubLogicData,
  DetailProps,
  GetCubProps,
  GetTransformedCubProps,
  UpdateCubData,
} from './types';

export const getCub = (uf: number): Promise<GetCubProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/cub/${uf}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateCub = (
  uf: number,
  data: UpdateCubData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/cub/${uf}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllTransformedCubs = (
  uf: number
): Promise<GetTransformedCubProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/cub/${uf}/converted-cub`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateTransformedCubs = (
  data: ConvertedCubData[]
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/converted-cub`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateTransformedCubsLogic = (
  data: ConvertedCubLogicData[]
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/converted-cub/logic`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
