import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
  },
}));

export interface DialogButtonProps {
  previous?: boolean;
  pept?: boolean;
}

export const DialogButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'previous' && prop !== 'pept',
})<DialogButtonProps>(({ theme, previous, pept }) => ({
  width: '250px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    opacity: '80%',
  },
  ...(previous && {
    backgroundColor: '#FFA75D',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#FFA75D',
      filter: 'brightness(90%)',
    },
  }),
  [theme.breakpoints.down('lg')]: {
    ...(!pept && {
      minWidth: '180px',
      maxWidth: '200px',
    }),
  },
  [theme.breakpoints.down('xl')]: {
    ...(!pept && {
      width: 200,
      padding: 16,
    }),
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '150px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '150px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const ErrorMessage = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '16px',
  color: '#FF334B',
}));
