/* eslint-disable max-len */
export const Constants = {
  reportPreparation: 'elaboração de laudo',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  form: 'Formulário',
  noForm: 'esta OS não possui Formulário',
  seePhotos: 'visualizar todas as fotos do imóvel',
  sample: 'Amostra',
  report: 'Cálculo automatizado',
  approve: 'aprovar',
  reject: 'rejeitar',
  pept: 'enviar para PEPT',
  noInspection: 'esta OS não possui agendamento',
  noPhotos: 'esta OS não possui imagens',
  noSample: 'esta OS não possui amostra',
  reportError: 'relatório não pode ser criado',
  writeHere: 'escreva aqui',
  internal: 'descrição interna',
  register: ' (conforme matrícula)',
  preliminaries: 'preliminares',
  marketSearch: 'pesquisa de mercado e tratamento de dados',
  diagnosis: 'diagnóstico de mercado',
  sumaryDiagnosis: 'diagnóstico resumido de mercado',
  docs: 'documentação do imóvel',
  performance: 'desempenho*',
  offersNum: 'número de ofertas*',
  liquidity: 'liquidez*',
  observations: 'observações',
  assumptions: 'pressuposto, ressalvas e fatores limitantes',
  save: 'salvar alterações',
  reportTitle: 'Relatório Estatístico',
  total: 'Total',
  sum: 'Soma',
  fundObservation:
    'ª No caso de utilização de menos de cinco dados de mercado, o intervalo admissível de ajuste é de 0,80 a 1,25, pois é desejável que, com um número menor de dados de mercado, a amostra seja menos heterogênea.',
  fundResult: 'Grau de Fundamentação do Laudo',
  factorsEntrance: 'Entrada de Dados',
  factors: 'Fatores',
  factorsFund: 'Grau de Fundamentação',
  factorsFundScore: 'Enquadramento Segundo Grau de Fundamentação',
  factorsSani: 'Saneamento',
  factorsHomog: 'Homogeneização',
  factorsValue: 'Formação de Valor',
  inferenceStatistic: 'Estatística',
  infNormality: 'Normalidade dos Resíduos',
  infComplementary: 'Informações Complementares',
  infOutliers: 'Outliers do Modelo de Regressão',
  infVariance: 'Análise de Variância',
  infHypothesis: 'Testes de Hipóteses',
  infCorrelation: 'Correlação',
  infFundNbr: 'Fundamentação - nbr 14653-2',
  infPrecNbr: 'Precisão - nbr 14653-2',
  infEquation: 'Equação de Regressão Linear Múltipla',
  infFundamentation: 'Grau de Fundamentação',
  infGraphs: 'Análise Gráfica',
  protoSuggestion:
    'Essa é a sugestão do Proto, você pode alterar os fatores abaixo.',
  changeFactors: 'alterar fatores',
  selectTransformations: 'selecionar transformações',
  name: 'nome',
  select: 'selecionar',
  cancel: 'cancelar',
  confirm: 'confirmar',
  backToSample: 'voltar para criação de amostras',
  changeStatus:
    'alterar o status da OS ** de Elaboração de Laudo para Revisão e Aprovação',
  exportButtonTitle: 'exportar dados do cálculo e formulário',
  evaluationResult: 'Resultado de Avaliação',
  gallery: 'galeria',
  selectImages: 'Selecionar imagens',
  selectFromGallery: 'selecionar fotos da galeria',
  selectedImages: ' imagens selecionadas',
  downloadImages: 'baixar imagens selecionadas',
  reqFactor: 'fator obrigatório para o cálculo',
  fundObervationInference:
    'Conforme ABNT NBR 14653-2 2011, item 9.2.1.1: Para atingir o Grau III, são obrigatórias: a) apresentação do laudo na modalidade completa. Conforme solicitação do cliente, este laudo de avaliação foi elaborado na modalidade simplificado, portanto, o Grau de Fundamentação obtido foi "Grau II"',
  avmFailedHint:
    ' Volte o status da OS para criação de amostra e adicione elementos na busca automática manualmente.',
};

export const optionsPerformance = [
  'Aparentemente Aquecido',
  'Sem Destaque',
  'Aparentemente Baixo',
];

export const optionsNumberOffers = [
  'Aparentemente Alto',
  'Sem Destaque',
  'Aparentemente Baixo',
];

export const optionsLiquidity = [
  'Provavelmente Alto',
  'Sem Destaque',
  'Provavelmente Baixo',
];

export const convertConservation = (letter: string): string => {
  let word: string;
  switch (letter) {
    case 'a':
      word = 'Novo';
      break;
    case 'b':
      word = 'Entre Novo e Regular';
      break;
    case 'c':
      word = 'Regular';
      break;
    case 'd':
      word = 'Entre Regular e Necessitando Reparos Simples';
      break;
    case 'e':
      word = 'Necessitando Reparos Simples';
      break;
    case 'f':
      word = 'Necessitando Reparos Simples a Importantes';
      break;
    case 'g':
      word = 'Necessitando Reparos Importantes';
      break;
    case 'h':
      word = 'Necessitando Reparos Importantes a Edificação Sem Valor';
      break;
    case 'i':
      word = 'Sem Valor';
      break;
    default:
      word = '';
  }
  return word;
};

export const convertStandard400K = (value: string): string => {
  let word: string;
  switch (value) {
    case 'simples':
      word = 'normal';
      break;
    case 'normal':
      word = 'superior';
      break;
    case 'alto':
      word = 'fino';
      break;
    default:
      word = '';
  }
  return word;
};
