import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const SelectedText = styled(Typography)(() => ({
  fontSize: '28px',
  color: '#707070',
}));

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0',
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '450px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '300px',
  },
}));
