/* eslint-disable react/require-default-props */
import { Box, CircularProgress, Grid } from '@mui/material';

import {
  PropertyType,
  convertPropertyType,
} from '../../../../api/enumerations';
import { ElementData } from '../../../../api/samples/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import ConfirmationDeleteDialog from '../../../../components/ConfirmationDeleteDialog';
import {
  NumericTextField,
  SelectTextField,
} from '../../../../components/CustomInput';
import { ReportErrorDialog } from '../../../../components/ReportErrorDialog';
import AllPicturesSlider from '../../../../components/Sections/Sample/AllPicturesSlider';
import {
  IconDescriptionMS,
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import {
  selectConservation,
  selectStandard,
  selectStandard400K,
} from '../../../../constants/selectOptions';
import { formatFloatNumber } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { CheckErrorDialog } from './CheckErrorDialog';
import { Constants } from './constants';
import EditElement from './EditElement';
import {
  ApproveButton,
  DescriptionTypography,
  DownloadPdf,
  ErrorButton,
  FlexBox,
  IdLabel,
  InputTitle,
  LabelBox,
  LevelLabel,
  OutlinedButton,
  SampleContainer,
  SectionBox,
  SectionTitle,
  StyledIcon,
  StyledInput,
  StyledProgress,
} from './styles';
import useElementCard from './useElementCard';

interface ElementCardProps {
  sampleData: ElementData[];
  setSampleData: (sample: ElementData[]) => void;
  elementData: ElementData;
  propertyData: WorkOrderData;
  updateSample: () => void;
  sampleId: number;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  handleLastEditedPage: () => void;
  page: number;
  setPage: (value: number) => void;
  deactivateFilters?: () => void;
  goToLastPage: boolean;
}

export function ElementCard({
  sampleData,
  setSampleData,
  elementData,
  propertyData,
  updateSample,
  sampleId,
  disapprovedElements,
  setDisapprovedElements,
  handleLastEditedPage,
  page,
  setPage,
  goToLastPage = false,
  deactivateFilters,
}: ElementCardProps): JSX.Element {
  const {
    initialPictures,
    allPictures,
    age,
    setAge,
    handleConservationEstimate,
    buildingStandard,
    setBuildingStandard,
    conservation,
    setConservation,
    landArea,
    setLandArea,
    disableInfo,
    isApproved,
    showDescription,
    setShowDescription,
    openCheckError,
    setOpenCheckError,
    openErrorDialog,
    setOpenErrorDialog,
    loadingErrorReport,
    loadingMorePictures,
    updatedPictures,
    handleApproveElement,
    handleDeleteElement,
    handleDisapproveElement,
    handleOriginalElement,
    getMorePictures,
    loadingApproveElement,
    loadingDeleteElement,
    housePropertyType,
  } = useElementCard(
    disapprovedElements,
    setDisapprovedElements,
    sampleData,
    setSampleData,
    elementData,
    propertyData,
    sampleId,
    updateSample,
    page,
    setPage,
    goToLastPage,
    deactivateFilters
  );

  const { handleDownloadFile } = useGeneral();

  return (
    <SampleContainer>
      <LabelBox>
        <IdLabel>{sampleId}</IdLabel>
        <LevelLabel>
          {Constants.level}
          {elementData.description.level}
        </LevelLabel>
      </LabelBox>
      <Grid container spacing={{ xs: 2, xl: 4 }}>
        <Grid item xs={8} lg={9}>
          <InputTitle>{Constants.address}</InputTitle>
          <StyledInput sx={{ width: '100%' }}>
            {elementData.description.street}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.number}</InputTitle>
          <StyledInput>{elementData.description.number}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.cep}</InputTitle>
          <StyledInput>{elementData.description.zipcode}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.city}</InputTitle>
          <StyledInput>{elementData.description.city}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.district}</InputTitle>
          <StyledInput>{elementData.description.district}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.propertyType}</InputTitle>
          <StyledInput>
            {propertyData.real_estate_kind &&
              convertPropertyType(propertyData.real_estate_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>
            {propertyData.real_estate_kind === PropertyType.APARTMENT
              ? Constants.privateArea
              : Constants.constructedArea}
          </InputTitle>
          <StyledInput>
            {elementData.description.area}
            {Constants.squareMeters}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.parkingLots}</InputTitle>
          <StyledInput>{elementData.description?.parking_spaces}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.sellingPrice}</InputTitle>
          <StyledInput>
            {Constants.real}
            {elementData.description?.sell_price &&
              formatFloatNumber(elementData.description.sell_price)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle
            error={
              !elementData.description?.advertiser?.name ? 'true' : undefined
            }
          >
            {Constants.advertiser}
          </InputTitle>
          <StyledInput
            error={
              !elementData.description?.advertiser?.name ? 'true' : undefined
            }
          >
            {elementData.description?.advertiser?.name || ''}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle
            error={
              !elementData.description?.advertiser?.phone ? 'true' : undefined
            }
          >
            {Constants.advertiserContact}
          </InputTitle>
          <StyledInput
            error={
              !elementData.description?.advertiser?.phone ? 'true' : undefined
            }
          >
            {elementData.description?.advertiser?.phone || ''}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3} sx={{ display: 'flex', alignItems: 'end' }}>
          {elementData.description.link && (
            <a
              href={elementData.description?.link}
              target="blank"
              style={{ color: '#00B39B', fontWeight: '600' }}
            >
              {Constants.propertyLink}
            </a>
          )}
        </Grid>
        <Grid item xs={4} lg={3} sx={{ display: 'flex', alignItems: 'end' }}>
          <DownloadPdf
            disabled={!elementData.description?.pdf}
            onClick={() =>
              elementData.description?.pdf &&
              handleDownloadFile(elementData.description.pdf, 'pdf', true)
            }
          >
            {Constants.downloadPdf}
          </DownloadPdf>
        </Grid>
      </Grid>
      {elementData.description?.description && (
        <SectionBox>
          <FlexBox>
            <SectionTitle
              onClick={() => {
                setShowDescription(!showDescription);
              }}
            >
              {IconDescriptionMS}
              {Constants.propertyDescription}
            </SectionTitle>
            <StyledIcon
              onClick={() => {
                setShowDescription(!showDescription);
              }}
            >
              {showDescription ? IconExpandMoreMS : IconKeyboardArrowRightMS}
            </StyledIcon>
          </FlexBox>
          {showDescription && (
            <DescriptionTypography>
              {elementData.description?.description}
            </DescriptionTypography>
          )}
        </SectionBox>
      )}
      <SectionBox sx={{ mt: '40px' }}>
        <Grid container spacing={{ xs: 2, xl: 4 }}>
          {housePropertyType && (
            <Grid item xs={2}>
              <NumericTextField
                disable={disableInfo}
                id="land-area"
                label={Constants.landArea}
                suffix=" m²"
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={landArea}
                setValue={setLandArea}
                error={!elementData.description.land_area}
              />
            </Grid>
          )}
          <Grid
            item
            md={housePropertyType ? 2 : 3}
            lg={housePropertyType ? 2 : 3.5}
          >
            <NumericTextField
              disable={disableInfo}
              id="property-age-estimate"
              label="idade do imóvel"
              suffix=" anos"
              maxLength={9}
              value={age}
              setValue={setAge}
              error={elementData.age === null}
            />
          </Grid>
          <Grid
            item
            md={housePropertyType ? 4 : 5}
            lg={housePropertyType ? 4 : 4.5}
          >
            <OutlinedButton
              disabled={disableInfo}
              onClick={handleConservationEstimate}
            >
              {Constants.estimateConservation}
            </OutlinedButton>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <ErrorButton onClick={() => handleOriginalElement(false)}>
              {loadingErrorReport ? (
                <StyledProgress size={22} />
              ) : (
                Constants.reportError
              )}
            </ErrorButton>
            <CheckErrorDialog
              sampleId={elementData.id}
              open={openCheckError}
              handleClose={() => setOpenCheckError(false)}
              updateSample={updateSample}
            />
            <ReportErrorDialog
              element
              sampleId={elementData.id}
              open={openErrorDialog}
              setOpen={setOpenErrorDialog}
              updateSample={updateSample}
            />
          </Grid>
          <Grid item xs={3.5}>
            <SelectTextField
              disable={disableInfo}
              id="conservation-age-estimate"
              label="estado de conservação do imóvel"
              value={conservation}
              setValue={setConservation}
              selectOptions={selectConservation()}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectTextField
              disable={disableInfo}
              id="standard-age-estimate"
              label="padrão construtivo"
              value={buildingStandard}
              setValue={setBuildingStandard}
              selectOptions={
                propertyData.sample_sell_price_above_400k
                  ? selectStandard400K()
                  : selectStandard()
              }
            />
          </Grid>
          <Grid item xs={2}>
            {isApproved ? (
              <OutlinedButton onClick={handleDisapproveElement}>
                {Constants.desapprove}
              </OutlinedButton>
            ) : (
              <ApproveButton
                onClick={handleApproveElement}
                disabled={loadingApproveElement}
              >
                {loadingApproveElement ? (
                  <CircularProgress size="24px" sx={{ color: '#FFFFFF' }} />
                ) : (
                  Constants.approve
                )}
              </ApproveButton>
            )}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <Box height={48} width="100%">
              <ConfirmationDeleteDialog
                title={
                  loadingDeleteElement ? (
                    <CircularProgress size="22px" sx={{ color: '#FF334B' }} />
                  ) : (
                    Constants.remove
                  )
                }
                text={Constants.deleteText}
                modalCallback={handleDeleteElement}
              />
            </Box>
          </Grid>
        </Grid>
      </SectionBox>
      <SectionBox>
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.pictures}
        </SectionTitle>
        <Grid container spacing={1} sx={{ mt: '20px' }}>
          {initialPictures?.map((pic) => (
            <Grid item xs={2} key={pic.id}>
              <AllPicturesSlider
                file={pic}
                images={allPictures?.length ? allPictures : initialPictures}
                getMorePictures={getMorePictures}
                loading={loadingMorePictures}
                isUpdated={updatedPictures}
              />
            </Grid>
          ))}
        </Grid>
      </SectionBox>
      <SectionBox display="flex" justifyContent="end">
        <EditElement
          elementData={elementData}
          propertyData={propertyData}
          updateSample={updateSample}
          handleLastEditedPage={handleLastEditedPage}
          deactivateFilters={deactivateFilters}
          setPage={setPage}
          goToLastPage={goToLastPage && isApproved}
        />
      </SectionBox>
    </SampleContainer>
  );
}
