import { Grid, MenuItem, TextField } from '@mui/material';
import { useContext, useState } from 'react';

import {
  getAllTransformedCubs,
  getCub,
  updateCub,
  updateTransformedCubs,
  updateTransformedCubsLogic,
} from '../../api/cub';
import { TableCubData, TransformedCubData } from '../../api/cub/types';
import { CubPropertyType, Standard } from '../../api/enumerations';
import { PreffixNumericTextField } from '../../components/CustomInput';
import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/adjustment';
import {
  IconHouseMS,
  IconSquareFootMS,
  IconStoreMS,
  IconTuneMS,
  IconWarehouseMS,
} from '../../constants/icons';
import { selectRegistrationUf } from '../../constants/selectOptions';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import {
  DividerContainer,
  SectionTitle,
  SectionTitleContainer,
  StyledDivider,
  Subtitle,
  UfBox,
  UfMenuItem,
  UfTextField,
  UpdateBox,
  UpdateButton,
} from './styles';
import { Table } from './Table';

export function Adjustment(): JSX.Element {
  const [uf, setUf] = useState(0);
  const [r1low, setR1low] = useState(0);
  const [r1normal, setR1normal] = useState(0);
  const [r1high, setR1high] = useState(0);
  const [r8low, setR8low] = useState(0);
  const [r8normal, setR8normal] = useState(0);
  const [r8high, setR8high] = useState(0);
  const [r16normal, setR16normal] = useState(0);
  const [r16high, setR16high] = useState(0);
  const [cal8normal, setCal8normal] = useState(0);
  const [cal8high, setCal8high] = useState(0);
  const [csl8normal, setCsl8normal] = useState(0);
  const [csl8high, setCsl8high] = useState(0);
  const [csl16normal, setCsl16normal] = useState(0);
  const [csl16high, setCsl16high] = useState(0);
  const [gi, setGi] = useState(0);
  const [tableApartmentData, setTableApartmentData] = useState<TableCubData>();

  const { openSnackbar, setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const createApartmentData = (data: TransformedCubData[]): void => {
    const simpleItem = data.filter(
      (item) => item.constructive_standard === Standard.SIMPLE
    );
    const normalItem = data.filter(
      (item) => item.constructive_standard === Standard.NORMAL
    );
    const highItem = data.filter(
      (item) => item.constructive_standard === Standard.HIGH
    );

    setTableApartmentData({
      simple: simpleItem[0],
      normal: normalItem[0],
      high: highItem[0],
    });
  };

  const handleSearchUf = async (ufNumber: number): Promise<void> => {
    if (ufNumber === 0) {
      setSnackbarMessage('Selecione uma UF');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    setTableApartmentData(undefined);
    setR1low(0);
    setR1normal(0);
    setR1high(0);
    setR8low(0);
    setR8normal(0);
    setR8high(0);
    setR16normal(0);
    setR16high(0);
    setCal8normal(0);
    setCal8high(0);
    setCsl8normal(0);
    setCsl8high(0);
    setCsl16normal(0);
    setCsl16high(0);
    setGi(0);

    try {
      const response = await getCub(ufNumber);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }

      if (response.data) {
        setR1low(response.data.r1_baixo);
        setR1normal(response.data.r1_normal);
        setR1high(response.data.r1_alto);
        setR8low(response.data.r8_baixo);
        setR8normal(response.data.r8_normal);
        setR8high(response.data.r8_alto);
        setR16normal(response.data.r16_normal);
        setR16high(response.data.r16_alto);
        setCal8normal(response.data.cal8_normal);
        setCal8high(response.data.cal8_alto);
        setCsl8normal(response.data.csl8_normal);
        setCsl8high(response.data.csl8_alto);
        setCsl16normal(response.data.csl16_normal);
        setCsl16high(response.data.csl16_alto);
        setGi(response.data.gi);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    try {
      const response = await getAllTransformedCubs(ufNumber);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }

      if (response.data) {
        const apartmentItems = response.data.filter(
          (item) => item.property_type === CubPropertyType.APARTMENT
        );

        createApartmentData(apartmentItems);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (uf === 0) {
      setSnackbarMessage('Selecione uma UF!');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      !r1low ||
      !r1normal ||
      !r1high ||
      !r8low ||
      !r8normal ||
      !r8high ||
      !r16normal ||
      !r16high ||
      !cal8normal ||
      !cal8high ||
      !csl8normal ||
      !csl8high ||
      !csl16normal ||
      !csl16high ||
      !gi ||
      !tableApartmentData ||
      !tableApartmentData.simple ||
      !tableApartmentData.normal ||
      !tableApartmentData.high
    ) {
      setSnackbarMessage('Todos os campos são obrigatórios!');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const updatedCubData = {
      r1_baixo: r1low,
      r1_normal: r1normal,
      r1_alto: r1high,
      r8_baixo: r8low,
      r8_normal: r8normal,
      r8_alto: r8high,
      r16_normal: r16normal,
      r16_alto: r16high,
      cal8_normal: cal8normal,
      cal8_alto: cal8high,
      csl8_normal: csl8normal,
      csl8_alto: csl8high,
      csl16_normal: csl16normal,
      csl16_alto: csl16high,
      gi,
    };

    const cubLogic = [
      {
        id: tableApartmentData.simple.id,
        logic: tableApartmentData.simple.logic,
      },
      {
        id: tableApartmentData.normal.id,
        logic: tableApartmentData.normal.logic,
      },
      {
        id: tableApartmentData.high.id,
        logic: tableApartmentData.high.logic,
      },
    ];

    const cubValues = [
      {
        id: tableApartmentData.simple.id,
        converted_cub: tableApartmentData.simple.converted_cub,
      },
      {
        id: tableApartmentData.normal.id,
        converted_cub: tableApartmentData.normal.converted_cub,
      },
      {
        id: tableApartmentData.high.id,
        converted_cub: tableApartmentData.high.converted_cub,
      },
    ];

    try {
      const response = await updateTransformedCubs(cubValues);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    try {
      const response = await updateTransformedCubsLogic(cubLogic);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    try {
      const response = await updateCub(uf, updatedCubData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado.');
      }

      if (response.detail.status_code === 0) {
        setSnackbarMessage('Todos os valores foram atualizados');
        setErrorMessage(false);
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconTuneMS}
          {Constants.adjustment}
        </TitleTypography>
      </TitleBox>
      <UfBox>
        <TextField
          id="uf"
          select
          label="UF"
          color="secondary"
          value={uf}
          onChange={(e) => {
            setUf(Number(e.target.value));
            handleSearchUf(Number(e.target.value));
          }}
          sx={UfTextField}
        >
          {selectRegistrationUf().map((option) => (
            <MenuItem key={option.value} value={option.value} sx={UfMenuItem}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </UfBox>
      <Grid container sx={{ marginBottom: '20px' }} spacing={4}>
        <Grid item xs={12}>
          <SectionTitleContainer>
            <SectionTitle>
              {IconHouseMS}
              {Constants.residence}
            </SectionTitle>
            <DividerContainer>
              <StyledDivider />
            </DividerContainer>
          </SectionTitleContainer>
        </Grid>
        <Grid item xs={12}>
          <Subtitle>{Constants.house}</Subtitle>
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r1low"
            label="R1 baixo"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r1low}
            setValue={setR1low}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r1normal"
            label="R1 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r1normal}
            setValue={setR1normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r1high"
            label="R1 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r1high}
            setValue={setR1high}
          />
        </Grid>
        <Grid item xs={12}>
          <Subtitle>{Constants.flat}</Subtitle>
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r8low"
            label="R8 baixo"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r8low}
            setValue={setR8low}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r8normal"
            label="R8 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r8normal}
            setValue={setR8normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r8high"
            label="R8 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r8high}
            setValue={setR8high}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r16normal"
            label="R16 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r16normal}
            setValue={setR16normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="r16high"
            label="R16 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={r16high}
            setValue={setR16high}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: '20px' }} spacing={4}>
        <Grid item xs={12}>
          <SectionTitleContainer>
            <SectionTitle>
              {IconStoreMS}
              {Constants.comerce}
            </SectionTitle>
            <DividerContainer>
              <StyledDivider />
            </DividerContainer>
          </SectionTitleContainer>
        </Grid>
        <Grid item xs={12}>
          <Subtitle>{Constants.freeFloor}</Subtitle>
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="cal8normal"
            label="CAL8 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={cal8normal}
            setValue={setCal8normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="cal8high"
            label="CAL8 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={cal8high}
            setValue={setCal8high}
          />
        </Grid>
        <Grid item xs={12}>
          <Subtitle>{Constants.store}</Subtitle>
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="csl8normal"
            label="CSL8 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={csl8normal}
            setValue={setCsl8normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="csl8high"
            label="CSL8 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={csl8high}
            setValue={setCsl8high}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="csl16normal"
            label="CSL16 normal"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={csl16normal}
            setValue={setCsl16normal}
          />
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="csl16high"
            label="CSL16 alto"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={csl16high}
            setValue={setCsl16high}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: '20px' }} spacing={4}>
        <Grid item xs={12}>
          <SectionTitleContainer>
            <SectionTitle>
              {IconWarehouseMS}
              {Constants.warehouse}
            </SectionTitle>
            <DividerContainer>
              <StyledDivider />
            </DividerContainer>
          </SectionTitleContainer>
        </Grid>
        <Grid item xs={12}>
          <Subtitle>{Constants.industry}</Subtitle>
        </Grid>
        <Grid item xs={2}>
          <PreffixNumericTextField
            required
            id="gi"
            label="GI"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            maxLength={18}
            value={gi}
            setValue={setGi}
          />
        </Grid>
      </Grid>
      <SectionTitleContainer sx={{ margin: '60px 0' }}>
        <SectionTitle>
          {IconSquareFootMS}
          {Constants.cub}
        </SectionTitle>
        <DividerContainer>
          <StyledDivider />
        </DividerContainer>
      </SectionTitleContainer>
      {tableApartmentData && (
        <Table
          tableData={tableApartmentData}
          setTableData={setTableApartmentData}
          popularLogicValue={r8low}
          simpleLogicValue={r8low}
          normalLogicValue={r8normal}
          highLogicValue={r16high}
          luxeLogicValue={r16high}
        />
      )}
      <UpdateBox>
        <UpdateButton onClick={handleSubmit}>{Constants.confirm}</UpdateButton>
      </UpdateBox>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}
