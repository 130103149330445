import {
  Box,
  ButtonBase,
  Modal,
  Typography,
  alpha,
  styled,
} from '@mui/material';

export const ModalContainer = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Container = styled(Box)(() => ({
  background: '#FFFAFC',
  width: 800,
  height: 580,
  borderRadius: 30,
  padding: '60px 105px',
}));

export const TitleModal = styled(Typography)(() => ({
  font: 'bold 21px Montserrat',
  color: '#595959',
  paddingBottom: 40,
}));

export const DragNDropArea = styled(Box)(() => ({
  width: 598,
  height: 305,
  border: '1px dashed #737475',
  borderRadius: 30,
  paddingBottom: 40,
}));

export const ContainerButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '40px 0',
}));

export const Button = styled(ButtonBase)(() => ({
  width: 230,
  height: 48,
  outline: 'none',
  borderRadius: 30,
  font: '400 16px Montserrat',
  transition: '0.3s',

  '&.outlined': {
    border: '1px solid #004D43',
    color: '#004D43',

    '&:hover': {
      backgroundColor: '#004D43',
      color: '#FAFCFF',
    },
  },
  '&.contained': {
    background: '#004D43',
    color: '#FAFCFF',
    '&:hover': {
      backgroundColor: '#004D43CC',
      color: '#FAFCFF',
    },
  },
}));

export const UploadBox = styled(Box)(() => ({
  height: 308,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flex: 1,
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(() => ({
  '& span': { fontSize: '72px', color: '#00B39B' },
}));

export const UploadTitle = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const UploadAndPreviewContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 16,
}));

export const ImagePreview = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& div': {},

  '& img': {
    width: 250,
    height: 250,
    marginTop: 16,
    borderRadius: 16,
  },
}));

export const DeleteFacadePhoto = styled('div')(() => ({
  position: 'relative',
  right: '-93%',
  top: -116,
  background: '#CCCCCC',
  width: 32,
  height: 32,
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  cursor: 'pointer',

  '&:hover': {
    background: alpha('#CCCCCC', 0.8),
    transition: '0.3s',

    '& span': {
      color: '#A1A1A1',
    },
  },
}));
