import { TabPanel } from '@mui/lab';
import {
  Box,
  Pagination,
  Tab,
  TableCell,
  TableRow,
  styled,
} from '@mui/material';

export const BoxContainer = styled(Box)(({ theme }) => ({
  '& .Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export const TabStyled = styled(Tab)(() => ({
  color: '#707070',
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'capitalize',
  padding: -5,
}));

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    padding: '12px 0',
  },
}));

export const TableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#004D43',
  font: '500 21px Montserrat',
  backgroundColor: '#F1F5FA',
  textAlign: 'center',
  verticalAlign: 'middle',
  '& span': {
    verticalAlign: 'bottom',
    paddingLeft: '4px',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    '& span': {
      fontSize: '18px',
    },
  },
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    background: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledCell = styled(TableCell)(() => ({
  color: '#707070',
  textAlign: 'center',
}));

export const StyledPagination = styled(Pagination)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  margin: '8px',
  width: '100%',
}));

export const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '24px',
  marginRight: '18px',
  [theme.breakpoints.down('xl')]: {
    marginBottom: '12px',
  },
}));

export const InfoBox = styled(Box)(() => ({
  width: '100%',
  height: '160px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#707070',
  gap: '8px',
  fontSize: '20px',
  fontWeight: '600',
}));
