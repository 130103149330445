import { useState } from 'react';

import { usePept } from '../../hooks/usePept';
import {
  AlertTypography,
  ButtonBox,
  DialogButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextArea,
  TextTypography,
} from './styles';

interface PeptDialogProps {
  osId: number;
  referenceNumber: number | undefined;
}

export function PeptDialog({
  osId,
  referenceNumber,
}: PeptDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [observation, setObservation] = useState('');

  const { handlePept } = usePept();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = (): void => {
    handlePept(osId, referenceNumber, observation);
    handleClose();
  };

  return (
    <>
      <DialogButton onClick={handleOpen}>enviar para PEPT</DialogButton>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContainer>
          <TextTypography>Motivo:</TextTypography>
          <TextArea onChange={(e) => setObservation(e.target.value)} />
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>Cancelar</OutlinedButton>
            <FilledButton onClick={handleConfirm}>Salvar</FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
