export const Constants = {
  schedule: 'Agendamento',
  osNumber: 'número OS',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  edit: 'editar OS',
  cancelEdit: 'cancelar edição',
  clientData: 'Dados do cliente',
  evaluationType: 'tipo do cálculo:',
  clientType: 'tipo do cliente:',
  downloadText:
    'Você pode realizar o download dos seguintes documentos anexados:',
  propertyData: 'Dados do imóvel',
  propertyRegister: 'Matrícula do imóvel',
  download: 'download',
  upload: 'upload',
  iptu: 'Cartela do IPTU',
  propertyPhotos: 'Imagens do imóvel',
  propertyAddress: 'Localização do imóvel',
  searchMap: 'pesquisar no mapa',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  movePin: 'Mova o pin caso a localização esteja incorreta',
  propertyDetails: 'Características do imóvel',
  schedulingInformations: 'Informações sobre agendamento',
  confirmEdit: 'confirmar alterações',
  approve: 'aprovar',
  pept: 'enviar para PEPT',
  selectInspector: 'Selecione o vistoriador responsável',
  inspector: 'vistoriador',
  placeholderResponsible: 'digite o nome do responsável',
  placeholderContact: '(11) 99999-9999',
  responsible: 'responsável',
  contact: 'contato',
  date: 'data',
  hour: 'horário',
  beggining: 'início',
  end: 'fim',
  createSchedule: 'criar agendamento',
  editSchedule: 'editar agendamento',
  changeStatus: 'alterar o status da OS ** de Agendamento para Vistoria',
};
