import {
  Box,
  BoxProps,
  Button,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '20px',
  height: 14,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 8,
    border: '1px solid #00B39B',
    backgroundColor: '#FAFCFF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
  },
}));

export const PaperQuestions = styled(Paper)(({ theme }) => ({
  height: '560px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D7D8DB',
  borderRadius: '16px',
  marginBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#004D43CC',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
  [theme.breakpoints.up('xl')]: {
    height: '660px',
  },
}));

interface QuestionsBoxProps extends BoxProps {
  open: boolean;
}

export const QuestionsBox = styled(Box)<QuestionsBoxProps>(({ open }) => ({
  cursor: 'pointer',
  backgroundColor: '#FFFFFF',
  ...(open && {
    backgroundColor: '#8BC86F66',
  }),
}));

export const SelectTypography = styled(Typography)(() => ({
  margin: '40px',
  fontSize: '21px',
  color: '#8B8C8E',
}));

export const NavigationBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '20px',
  gap: '10px',
}));

export const OutlinedButton = styled(Button)(() => ({
  minWidth: '32px',
  width: '48px',
  height: '42px',
  display: 'flex',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
