import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
  gap: '40px',
}));

export const ButtonContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '50px 0 30px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component, theme }) => ({
    display: 'flex',
    gap: '18px',
    width: '480px',
    [theme.breakpoints.down('lg')]: {
      width: '360px',
    },
    component,
  })
);
