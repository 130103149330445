import { Box, Tabs, styled } from '@mui/material';

export const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > .Mui-selected': {
    background: theme.palette.primary.main,
    color: `${theme.palette.common.white}`,
    borderRadius: 8,
  },
}));

export const TabContainer = styled(Box)(() => ({
  display: 'flex',
  height: 670,
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minWidth: '280px',
  width: '280px',
  [theme.breakpoints.down('xl')]: {
    minWidth: '180px',
    width: '180px',
  },
}));
