/* eslint-disable react/require-default-props */
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { AvmFactorsData } from '../../../../api/workOrders/types';
import protoWink from '../../../../assets/images/proto-wink.png';
import { Constants } from '../../../../constants/report';
import { formatReal } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { ScatterTrendline } from '../../../Charts/ScatterTrendline';
import { ExportSample } from '../../../ExportSample';
import ReportsTable from '../ReportsTable';
import {
  BoldCell,
  CellCaption,
  CellContent,
  CellHeader,
  GridContainer,
  GridTable,
  ReportTitle,
  RowCaption,
  RowHeader,
  StyledTable,
} from '../styles';
import ForceFactorsDialog from './ForceFactorsDialog';
import {
  presentAggregatedFactorData,
  presentAggregatedFactorHouseName,
  presentAggregatedFactorName,
  presentBlendingData,
  presentCoeficientHeader,
  presentEntranceData,
  presentEntranceHeader,
  presentFactorsData,
  presentFactorsHeader,
  presentFactorsInitial,
  presentFundamentationData,
  presentFundamentationHeader,
  presentFundamentationTotal,
  presentResultData,
  presentResultHeader,
  presentSanitizedData,
  presentValueData,
} from './presenter';
import {
  BoxProto,
  GridFactors,
  StyledCardMedia,
  TypographyProto,
} from './styles';

export interface FactorsReportProps {
  tableData: AvmFactorsData;
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
  forceFactors?: string[];
  propertyIsApartment: boolean;
  highPrice: boolean;
}

export function FactorsReport({
  tableData,
  chartCallback,
  forceFactors,
  propertyIsApartment,
  highPrice,
}: FactorsReportProps): JSX.Element {
  const { osId } = useGeneral();
  return (
    <>
      <Box display="flex" gap={3}>
        <ExportSample osId={osId} />
        {forceFactors && (
          <ForceFactorsDialog
            tableData={tableData}
            osForceFactors={forceFactors}
            outlineButton
          />
        )}
      </Box>
      <GridContainer container>
        <GridTable item xs={12}>
          <ReportTitle>{Constants.reportTitle}</ReportTitle>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable>
            <TableHead>
              <RowCaption>
                <CellCaption colSpan={5} sx={{ fontSize: '24px' }}>
                  {formatReal(
                    tableData.aggregated_homogenization.rounded_market_value
                  )}
                </CellCaption>
              </RowCaption>
            </TableHead>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="resultado da avaliação"
            totalCol={5}
            title="Resultado da Avaliação"
            headerData={presentResultHeader(tableData)}
            bodyData={presentResultData(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela preços">
              <TableBody>
                {presentFactorsInitial(tableData).map((row) => (
                  <TableRow key={row.label}>
                    <BoldCell align="left">{row.label}</BoldCell>
                    <CellContent align="right">{row.value}</CellContent>
                    <BoldCell align="left">{row.secondLabel}</BoldCell>
                    <CellContent align="right">{row.secondValue}</CellContent>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="entrada de dados"
            totalCol={11}
            title={Constants.factorsEntrance}
            headerData={presentEntranceHeader(propertyIsApartment)}
            bodyData={tableData.factors.map((data, ind) =>
              presentEntranceData(
                data.input_data,
                ind,
                propertyIsApartment,
                highPrice
              )
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela fatores"
            totalCol={11}
            title={Constants.factors}
            headerData={presentFactorsHeader(propertyIsApartment)}
            bodyData={tableData.factors.map((data, ind) =>
              presentFactorsData(data, ind, propertyIsApartment)
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela validação de coeficiente">
              <TableHead>
                <RowHeader>
                  {presentCoeficientHeader.map((header) => (
                    <CellHeader
                      align="center"
                      minwidth={header.width}
                      key={header.label}
                    >
                      {header.label}
                    </CellHeader>
                  ))}
                </RowHeader>
              </TableHead>
              <TableBody>
                {tableData.aggregated_factors.map((data, ind) => (
                  <TableRow key={`${data.mean_ppsm}${data.std_ppsm}`}>
                    <CellContent align="center">{ind + 1}</CellContent>
                    <BoldCell align="left">
                      {propertyIsApartment
                        ? presentAggregatedFactorHouseName[data.name]
                        : presentAggregatedFactorName[data.name]}
                    </BoldCell>
                    {presentAggregatedFactorData(data).map((row) => (
                      <CellContent align="center" key={row.label}>
                        {row.value}
                      </CellContent>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={6} sx={{ pr: '20px' }}>
          <ReportsTable
            ariaLabel="tabela saneamento"
            totalCol={2}
            title={Constants.factorsSani}
            labelValueData={presentSanitizedData(
              tableData.aggregated_homogenization
            )}
          />
        </GridTable>
        <GridTable item xs={6}>
          <ReportsTable
            ariaLabel="tabela homogeneizada"
            totalCol={2}
            title={Constants.factorsHomog}
            labelValueData={presentBlendingData(
              tableData.aggregated_homogenization
            )}
          />
          <Box my="10px" />
          <ReportsTable
            ariaLabel="tabela valor"
            totalCol={2}
            title={Constants.factorsValue}
            labelValueData={presentValueData(
              tableData.aggregated_homogenization,
              propertyIsApartment
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela grau de fundamentação">
              <TableHead>
                <RowCaption>
                  <CellCaption colSpan={6}>{Constants.factorsFund}</CellCaption>
                </RowCaption>
                <RowHeader>
                  {presentFundamentationHeader.map((header) => (
                    <CellHeader
                      align="center"
                      minwidth="80px"
                      key={header.label}
                    >
                      {header.label}
                    </CellHeader>
                  ))}
                </RowHeader>
              </TableHead>
              <TableBody>
                {presentFundamentationData(tableData).data.map((row) => (
                  <TableRow key={row.item}>
                    <CellContent align="center">{row.item}</CellContent>
                    <CellContent align="center">{row.description}</CellContent>
                    <CellContent align="center">{row.third}</CellContent>
                    <CellContent align="center">{row.second}</CellContent>
                    <CellContent align="center">{row.first}</CellContent>
                    <BoldCell align="center" sx={{ color: '#00B39B' }}>
                      {row.point}
                    </BoldCell>
                  </TableRow>
                ))}
                <TableRow>
                  <BoldCell colSpan={5}>{Constants.sum}</BoldCell>
                  <BoldCell align="center">
                    {presentFundamentationData(tableData).total_score}
                  </BoldCell>
                </TableRow>
                <TableRow>
                  <CellContent colSpan={6}>
                    {Constants.fundObservation}
                  </CellContent>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela grau de fundamentação">
              <TableHead>
                <RowCaption>
                  <CellCaption colSpan={6}>
                    {Constants.factorsFundScore}
                  </CellCaption>
                </RowCaption>
              </TableHead>
              <TableBody>
                {presentFundamentationTotal().map((row, i) => (
                  <TableRow key={row[i].value}>
                    {row.map((data) => (
                      <CellContent align="center" key={data.label}>
                        {data.value}
                      </CellContent>
                    ))}
                  </TableRow>
                ))}
                <RowHeader>
                  <CellHeader colSpan={3}>{Constants.total}</CellHeader>
                  <CellHeader align="center">
                    {presentFundamentationData(tableData).total_score}
                  </CellHeader>
                </RowHeader>
                <RowCaption>
                  <CellCaption
                    colSpan={3}
                    sx={{
                      borderRight: '1px solid #fff',
                      fontSize: '18px',
                      textAlign: 'left',
                    }}
                  >
                    {Constants.factorsFund}
                  </CellCaption>
                  <CellCaption>{tableData.scores.reasoning_level}</CellCaption>
                </RowCaption>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <ReportTitle>{Constants.infGraphs}</ReportTitle>
        </GridTable>
        <GridTable item xs={6}>
          <ScatterTrendline
            chart={tableData?.chart}
            chartCallback={chartCallback}
          />
        </GridTable>
        <GridFactors item xs={6}>
          <BoxProto />
          {forceFactors && (
            <>
              <BoxProto>
                <StyledCardMedia image={protoWink} title="proto" />
                <TypographyProto>{Constants.protoSuggestion}</TypographyProto>
              </BoxProto>
              <ForceFactorsDialog
                tableData={tableData}
                osForceFactors={forceFactors}
              />
            </>
          )}
        </GridFactors>
      </GridContainer>
    </>
  );
}
