import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilledButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '25px',
    backgroundColor: '#FFFFFF',
    minWidth: '860px',
    padding: '60px 80px',
    minHeight: '500px',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseButton = styled(IconButton)(() => ({
  margin: '-20px -30px',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const IconButtonBase = styled(ButtonBase)(() => ({
  minHeight: '30px',
  display: 'flex',
  gap: '8px',
  padding: '2px 4px',
  borderRadius: '8px',
  alignItems: 'center',
  fontSize: '16px',
  '& span': { color: '#00B39B' },
  '&:hover': {
    '& span': {
      fontSize: '26px',
    },
    fontWeight: '600',
  },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#595959',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  padding: '0',
  marginBottom: '30px',
  textTransform: 'uppercase',
  '& span': { color: '#00B39B' },
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const OptionIcon = styled(Typography)(() => ({
  marginRight: '10px',
}));

export const OptionCloseIcon = styled(IconButton)(() => ({
  height: '40px',
  marginLeft: '10px',
}));

export const FlexButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-around',
  padding: '20px 40px 0',
}));
