import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilledButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '25px',
    backgroundColor: '#FFFFFF',
    minWidth: '860px',
    maxWidth: '90%',
    padding: '60px',
    minHeight: '500px',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseButton = styled(IconButton)(() => ({
  margin: '-40px -40px 0 0',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#595959',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  padding: '0',
  marginBottom: '20px',
  textTransform: 'uppercase',
  '& span': { color: '#00B39B' },
}));

export const PaperQuestions = styled(Paper)(({ theme }) => ({
  height: '540px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D7D8DB',
  borderRadius: '16px',
  '&.MuiPaper-root': {
    minWidth: '200px',
    padding: '8px 0',
    maxWidth: '100%',
  },
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#004D43CC',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
  [theme.breakpoints.up('xl')]: {
    height: '660px',
  },
}));

interface QuestionsBoxProps extends BoxProps {
  open: boolean;
}

export const QuestionsBox = styled(Box)<QuestionsBoxProps>(({ open }) => ({
  cursor: 'pointer',
  backgroundColor: '#FFFFFF',
  ...(open && {
    backgroundColor: '#8BC86F66',
  }),
}));

export const NavigationBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '20px 40px',
  gap: '10px',
}));

export const OutlinedButton = styled(Button)(() => ({
  minWidth: '32px',
  width: '48px',
  height: '42px',
  display: 'flex',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
