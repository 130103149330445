import { Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '47px 60px 0 160px',
  backgroundColor: '#FAFCFF',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 0 40px 20px',
  alignItems: 'center',
  gap: '20px',
}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  flex: '1',
  '& span': { fontSize: '26px', color: '#00B39B' },
}));

export const OSButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  maxWidth: '240px',
}));

export const TVMode = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: '150px',
}));

export const PipelineContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '26px',
  padding: '20px',
  height: '78vh',
  minHeight: '500px',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    height: '15px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#00B39B',
    },
  },
  '&::-webkit-scrollbar-track-piece:start': {
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: '2px 3px 6px #00000033',
    margin: '0 380px',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track-piece:end': {
    backgroundColor: 'white',
    borderRadius: '10px',
  },
}));

export const ScrollTypography = styled(Typography)(() => ({
  color: '#FAFCFF',
}));

export const StyledToast = styled(Snackbar)(() => ({
  '.MuiSnackbarContent-root': { minWidth: 'fit-content' },
}));

export const ToastTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '4px',
  color: '#FAFCFF',
  '& span': { color: '#62aa41' },
}));
