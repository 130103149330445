import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderRadius: '32px',
  border: '1px solid #004D43',
  padding: '0 18px',
  '& .MuiInputBase-input': {
    width: '140px',
    padding: '10px 0 10px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '94px',
      '&:focus': {
        width: '200px',
      },
    },
  },
}));
