import { FormControlLabel, MenuItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { ProfileUsers } from '../../../api/enumerations';
import { editUser } from '../../../api/users';
import { UserData } from '../../../api/users/types';
import { IconCloseMS, IconEditMS } from '../../../constants/icons';
import { selectProfileUser } from '../../../constants/selectOptions';
import { Constants } from '../../../constants/users';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  CloseButton,
  DialogContainer,
  FormStyled,
  SaveButton,
  StyledCheckbox,
  StyledDialog,
  StyledTitle,
  TextFieldStyled,
  TopBox,
} from './styles';

export interface EditUserDialogProps {
  open: boolean;
  userData: UserData;
  onClose: () => void;
  userRole: number;
}

export default function EditUserDialog({
  open,
  userData,
  onClose,
  userRole,
}: EditUserDialogProps): JSX.Element {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState(0);
  const [active, setActive] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    setEmail(userData.email);
    setName(userData.name);
    setSelectedRole(userData.role);
    setActive(userData.is_active);
  }, [userData.email, userData.is_active, userData.name, userData.role]);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (selectedRole === 0) {
      setSnackbarMessage('Selecione uma função!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    let editUserData;

    if (userData.email === email) {
      editUserData = {
        name,
        is_active: active,
        role: selectedRole,
      };
    } else {
      editUserData = {
        email,
        name,
        is_active: active,
        role: selectedRole,
      };
    }

    try {
      const response = await editUser(userData.id, editUserData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.status_code === 0) {
        setSnackbarMessage('Usuário alterado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        onClose();
        window.location.reload();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      onClose();
    }
  };

  const handleClose = (): void => {
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContainer>
        <TopBox>
          <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        </TopBox>
        <StyledTitle>
          {IconEditMS}
          {Constants.editUser}
        </StyledTitle>
        <FormStyled component="form" id="login" onSubmit={handleSubmit}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={active}
                onChange={() => setActive(!active)}
              />
            }
            label={Constants.active}
          />
          <TextFieldStyled
            required
            focused
            id="name"
            value={name}
            color="secondary"
            variant="outlined"
            label={Constants.newUser}
            placeholder={Constants.namePlaceholder}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextFieldStyled
            focused
            required
            id="email"
            type="email"
            value={email}
            color="secondary"
            variant="outlined"
            label={Constants.email}
            placeholder={Constants.emailPlaceholder}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextFieldStyled
            id="role"
            select
            color="secondary"
            label={Constants.role}
            focused
            required
            disabled={userRole !== ProfileUsers.ADMIN}
            value={selectedRole}
            onChange={(e) => setSelectedRole(Number(e.target.value))}
          >
            {selectProfileUser().map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </TextFieldStyled>
          <SaveButton form="login" type="submit">
            {Constants.buttonEdit}
          </SaveButton>
        </FormStyled>
      </DialogContainer>
    </StyledDialog>
  );
}
