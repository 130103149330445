import { Box, Button, CardMedia, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '21px',
  color: '#707070',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  borderRadius: '30px',
  height: '140px',
}));

export const SelectPhotoBox = styled(Box)(() => ({
  height: '140px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 12px',
  cursor: 'pointer',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '60px', color: '#00B39B' },
  [theme.breakpoints.down('lg')]: {
    '& span': { fontSize: '50px' },
  },
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: 'clamp(10px, 1vw, 14px)',
  textAlign: 'center',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const DeleteCircleButton = styled(IconButton)(() => ({
  position: 'absolute',
  margin: '-8px -8px 0',
  backgroundColor: '#414141',
  padding: '4px',
  color: '#FFFFFF',
  '&:hover': {
    padding: '5px',
    color: '#FFFFFF',
    backgroundColor: '#00B39B',
  },
}));

export const OutlineButton = styled(Button)(() => ({
  width: 282,
  height: 40,
  border: '2px solid #00B39B',
  borderRadius: 10,
  color: '#00B39B',
  fontWeight: 600,
  fontSize: 15,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#00B39B',
    '& span': {
      color: '#FAFCFF',
    },
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
