/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';

import { IconCloseMS, IconEastMS } from '../../../../../constants/icons';
import {
  AssignmentContainer,
  AssingData,
  FormGroupStyled,
  IconButtonStyled,
  InputContainer,
  ModalStyled,
  Title,
} from './ styles';

interface AssignModalProps {
  open: boolean;
  onClose: () => void;
}

export function AssignModal({ open, onClose }: AssignModalProps): JSX.Element {
  return (
    <ModalStyled open={open} onClose={onClose}>
      <Paper
        sx={{ width: 794, height: 750, padding: '70px 60px', borderRadius: 8 }}
      >
        <IconButtonStyled onClick={onClose}>{IconCloseMS}</IconButtonStyled>
        <Title>Atribuir</Title>
        <Divider />

        <AssignmentContainer>
          <AssingData>
            <span>OS: 1234</span>
          </AssingData>
          {IconEastMS}
          <AssingData>
            <span>Empresa Delta</span>
          </AssingData>
        </AssignmentContainer>

        <FormGroupStyled>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="laudo"
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="vistoria"
          />
        </FormGroupStyled>

        <InputContainer>
          <div>
            <span>valor combinado</span>
            <TextField
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <span>prazo para pagamento</span>
            <TextField variant="standard" />
          </div>
        </InputContainer>

        <InputContainer>
          <div>
            <span>data limite</span>
            <TextField type="date" variant="filled" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <span />
            <Select defaultValue="prazo">
              <MenuItem value="prazo">prazo</MenuItem>
            </Select>
          </div>
          <div>
            <span />
            <TextField value="0 dias" variant="outlined" />
          </div>
        </InputContainer>

        <div style={{ width: '100%', marginTop: 128, textAlign: 'center' }}>
          <Button sx={{ width: '70%' }}>confirmar</Button>
        </div>
      </Paper>
    </ModalStyled>
  );
}
