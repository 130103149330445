import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SampleButtonProps {
  samplecreation: 'true' | 'false';
}

export const SampleBackButton = styled(ButtonBase)<SampleButtonProps>(
  ({ samplecreation }) => ({
    position: 'absolute',
    margin: samplecreation === 'true' ? '404px 0 0 -80px' : '304px 0 0 -80px',
    borderRadius: '50%',
    padding: '10px 14px 10px 6px',
    color: '#00B39B',
    '&.Mui-disabled': {
      color: '#D7D8DB',
    },
    '&:hover': {
      backgroundColor: '#00B39B26',
    },
    '& span': {
      fontSize: '48px',
    },
  })
);

export const SampleForwardBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}));

export const SampleForwardButton = styled(ButtonBase)<SampleButtonProps>(
  ({ samplecreation }) => ({
    float: 'right',
    position: 'absolute',
    margin: samplecreation === 'true' ? '404px -80px 0 0' : '304px -80px 0 0',
    borderRadius: '50%',
    padding: '10px 6px 10px 14px',
    color: '#00B39B',
    '&.Mui-disabled': {
      color: '#D7D8DB',
    },
    '&:hover': {
      backgroundColor: '#00B39B26',
    },
    '& span': {
      fontSize: '48px',
    },
  })
);
