import { Box, alpha, styled } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  background: '#FFFFFE',
  boxShadow: '0px 3px 6px #00000029',
  marginTop: 48,
}));

export const SearchContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',
  width: 512,
  margin: 42,

  '& span': {
    color: '#909090',
    fontSize: 32,
  },

  '& input': {
    border: 'none',
    flex: 1,
    font: '400 16px Montserrat',
    marginLeft: 8,
    height: 32,
    borderBottom: '1px solid #909090',
  },

  '& button': {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: '#004E43',
    height: 32,
    font: '600 16px Montserrat',
    borderBottom: '1px solid #909090',

    '&:hover': {
      color: alpha('#004E43', 0.8),
    },
  },
}));

export const ImageContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px 42px 42px 42px',
  '& img': {
    width: '100%',
  },
}));
