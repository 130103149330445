import { Box, ButtonBase, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const SmallStyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '100px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const StyledBox = styled(Box)(() => ({
  minWidth: '160px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

interface IStyledCardMedia {
  disabled: boolean;
}

export const StyledCardMedia = styled(CardMedia)<IStyledCardMedia>(
  ({ disabled, theme }) => ({
    height: '13vw',
    maxHeight: '250px',
    minHeight: '150px',
    borderRadius: '12px',
    backgroundSize: 'contain',
    border: `2px solid ${theme.palette.primary.dark}`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#E6E6E6',
    ...(disabled && {
      filter: disabled && 'opacity(40%)',
      backgroundSize: 'cover',
    }),
  })
);

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  alignSelf: 'start',
  marginTop: 6,
  gap: '6px',
  fontSize: '14px',
  color: theme.palette.primary.main,
  fontWeight: '600',
  cursor: 'pointer',
  '&:disabled': {
    filter: 'opacity(50%)',
  },
}));
