import { Box, Button, Dialog, Typography, styled } from '@mui/material';

export const DeleteButton = styled(Button)(() => ({
  width: 240,
  height: 48,
  background: '#E81710 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
  opacity: 1,
  display: 'flex',
  gap: 10,
  '&:hover': {
    backgroundColor: '#D0140E',
  },
  '@media (max-width: 1800px)': {
    width: '220px',
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: '#FAFCFF',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '150px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '150px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
