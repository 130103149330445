import { TabContext, TabList } from '@mui/lab';
import { Divider } from '@mui/material';
import { useContext, useState } from 'react';

import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconEngineeringMS } from '../../constants/icons';
import { Constants } from '../../constants/userManagement';
import { GlobalContext } from '../../context/global';
import { Activities } from './Activities';
import { Payments } from './Payments';
import { BoxContainer, StyledTabPanel, TabStyled } from './styles';
import { User } from './User';

export function UserManagement(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState('payments');

  const { openSnackbar } = useContext(GlobalContext);

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconEngineeringMS}
          {Constants.userManagement}
        </TitleTypography>
      </TitleBox>
      <BoxContainer>
        <TabContext value={selectedTab}>
          <TabList onChange={(e, v) => setSelectedTab(v)}>
            <TabStyled label="Pagamentos" value="payments" />
            <TabStyled label="Atividades" value="activities" />
            <TabStyled label="Usuário" value="users" />
          </TabList>
          <Divider />
          <StyledTabPanel value="payments">
            <Payments />
          </StyledTabPanel>
          <StyledTabPanel value="activities">
            <Activities />
          </StyledTabPanel>
          <StyledTabPanel value="users">
            <User />
          </StyledTabPanel>
        </TabContext>
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}
