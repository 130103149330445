export const Constants = {
  proposal: 'Proposta',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  osNumber: 'número OS',
  upload: 'upload proposta',
  addUpload: 'adicionar nova proposta',
  files: 'Propostas: ',
  clientData: 'Dados do cliente',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  approve: 'aprovar',
  pept: 'enviar para PEPT',
  changeStatus: 'alterar o status da OS ** de Proposta para Contrato',
};
