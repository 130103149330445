/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { InspectionData } from '../../../api/inspections/types';
import { getAllPictures } from '../../../api/pictures';
import { RoomData } from '../../../api/rooms/types';
import { getUser } from '../../../api/users';
import cnhDocument from '../../../assets/images/document.jpg';
import fachada from '../../../assets/images/fachada.png';
import selfie from '../../../assets/images/selfie.png';
import { IconDownloadMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { formatDate, formatPhoneNumber } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { Constants } from './constant';
import {
  DownloadButton,
  InputTitle,
  SmallStyledInput,
  StyledBox,
  StyledCardMedia,
} from './styles';

interface ScheduleInfoProps {
  inspectionData: InspectionData;
  rooms?: RoomData[];
  showDownload?: boolean;
}

export function ScheduleInfo({
  inspectionData,
  rooms,
  showDownload = true,
}: ScheduleInfoProps): JSX.Element {
  const [frontImg, setFrontImg] = useState('');
  const [cnhImg, setCnhImg] = useState('');

  const { handleDownloadFile } = useGeneral();
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const decoded = useStoragedJwt();

  const handleDocument = async (): Promise<void> => {
    try {
      if (decoded === null) {
        return;
      }

      const response = await getUser(decoded.user.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.document) {
        setCnhImg(response.data.document);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleFront = async (roomId: number): Promise<void> => {
    try {
      const response = await getAllPictures(roomId, 1, 1);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.data) {
        setFrontImg(response.data[0].file);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const filterRooms = rooms.filter((el) => el.room_type_id === 10);
      if (filterRooms.length > 0) {
        handleFront(filterRooms[0].id);
      }
    }
  }, [rooms]);

  useEffect(() => {
    handleDocument();
  }, []);

  return (
    <Grid container spacing={4}>
      {showDownload && (
        <Grid item xs={12} sx={{ display: 'flex', gap: '20px' }}>
          <StyledBox>
            <StyledCardMedia
              image={frontImg || fachada}
              title="fachada"
              disabled={!frontImg}
            />
            <DownloadButton
              disabled={!frontImg}
              onClick={() =>
                frontImg && handleDownloadFile(frontImg, 'fachada')
              }
            >
              {IconDownloadMS} {Constants.facade}
            </DownloadButton>
          </StyledBox>
          <StyledBox>
            <StyledCardMedia
              image={inspectionData.responsible_picture_filename || selfie}
              title="selfie"
              disabled={!inspectionData.responsible_picture_filename}
            />
            <DownloadButton
              disabled={!inspectionData.responsible_picture_filename}
              onClick={() =>
                inspectionData.responsible_picture_filename &&
                handleDownloadFile(
                  inspectionData.responsible_picture_filename,
                  'selfie'
                )
              }
            >
              {IconDownloadMS} {Constants.selfie}
            </DownloadButton>
          </StyledBox>
          <StyledBox>
            <StyledCardMedia
              image={cnhDocument}
              title="document"
              disabled={!cnhImg}
            />
            <DownloadButton
              disabled={!cnhImg}
              onClick={() => cnhImg && handleDownloadFile(cnhImg, 'documento')}
            >
              {IconDownloadMS} {Constants.document}
            </DownloadButton>
          </StyledBox>
        </Grid>
      )}
      <Grid item xs={12}>
        <InputTitle>{Constants.inspector}</InputTitle>
        <SmallStyledInput>{inspectionData.engineer_name}</SmallStyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.responsible}</InputTitle>
        <SmallStyledInput>{inspectionData.responsible}</SmallStyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.contact}</InputTitle>
        <SmallStyledInput>
          {inspectionData.responsible_phone &&
            formatPhoneNumber(inspectionData.responsible_phone)}
        </SmallStyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.date}</InputTitle>
        <SmallStyledInput>
          {inspectionData.schedule_at && formatDate(inspectionData.schedule_at)}
        </SmallStyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.hour}</InputTitle>
        <SmallStyledInput>{inspectionData.start_at}</SmallStyledInput>
      </Grid>
    </Grid>
  );
}
