import { Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { getOriginalElement } from '../../../../../api/samples';
import { ReportErrorDialog } from '../../../../../components/ReportErrorDialog';
import { IconCloseMS, IconEditNoteMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import {
  ButtonBox,
  CloseButtonBox,
  CloseIcon,
  DialogButton,
  DialogContainer,
  FilledButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface CheckErrorDialogProps {
  sampleId: number;
  open: boolean;
  handleClose: () => void;
  updateSample: () => void;
}

export function CheckErrorDialog({
  sampleId,
  open,
  handleClose,
  updateSample,
}: CheckErrorDialogProps): JSX.Element {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleUpdateElement = async (): Promise<void> => {
    try {
      const response = await getOriginalElement(sampleId, true);

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setSnackbarMessage('Elemento atualizado!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      handleClose();
      updateSample();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <CloseButtonBox>
        <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
      </CloseButtonBox>
      <DialogContainer>
        <TextTypography>
          {IconEditNoteMS}
          Reportar erro
        </TextTypography>
        <Typography fontWeight="500">
          Houve uma atualização neste elemento, você gostaria de substituí-lo
          para a última versão e verificar se o erro foi corrigido? Caso
          contrário, você ainda poderá reportar o erro.
        </Typography>
        <ButtonBox>
          <DialogButton onClick={() => setOpenErrorDialog(true)}>
            reportar erro
          </DialogButton>
          <ReportErrorDialog
            element
            sampleId={sampleId}
            open={openErrorDialog}
            setOpen={setOpenErrorDialog}
            updateSample={updateSample}
          />
          <FilledButton onClick={handleUpdateElement}>
            atualizar elemento
          </FilledButton>
        </ButtonBox>
      </DialogContainer>
    </StyledDialog>
  );
}
