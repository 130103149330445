import {
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';

import { IconCloseMS, IconSearchMS } from '../../../../constants/icons';
import { TabContainer } from '../../styles';
import { PaymentTable } from './PaymentTable';
import {
  Filter,
  FilterInputsContainer,
  FilteredContainer,
  SelectStyled,
  TextFieldStyled,
} from './styles';

export function Payments(): JSX.Element {
  const [selectedMonth, setSelectedMonth] = useState('Selecione');

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const states = ['PR', 'RJ', 'RS', 'SC', 'SP'];

  const cities = [
    'Curitiba',
    'Rio de Janeiro',
    'Porto Alegre',
    'Florianópolis',
    'São Paulo',
  ];

  const paymentFilter = ['realizado', 'pendente', 'em atraso', 'todos'];

  const filterApplied = ['SP', 'São Paulo'];

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    setSelectedMonth(event.target.value as string);
  };

  return (
    <TabContainer>
      <FormControl sx={{ width: 400 }}>
        <SelectStyled
          defaultValue={selectedMonth}
          value={selectedMonth}
          onChange={handleChange}
        >
          <MenuItem value="Selecione" disabled>
            Selecione
          </MenuItem>
          {months.map((month) => (
            <MenuItem key={month} value={month}>
              {month}
            </MenuItem>
          ))}
        </SelectStyled>
      </FormControl>

      <FilterInputsContainer>
        <Input
          type="search"
          placeholder="buscar nessa página"
          startAdornment={
            <InputAdornment position="start">{IconSearchMS}</InputAdornment>
          }
        />

        <FormControl>
          <SelectStyled defaultValue="SP">
            <MenuItem value="Estado" disabled>
              Estado
            </MenuItem>
            {states.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>

        <FormControl>
          <SelectStyled defaultValue="São Paulo">
            <MenuItem value="Cidade" disabled>
              Cidade
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
        <FormControl>
          <TextFieldStyled variant="outlined" type="date" />
        </FormControl>

        <FormControl>
          <SelectStyled defaultValue="Pagamento">
            <MenuItem value="Pagamento" disabled>
              Pagamento
            </MenuItem>
            {paymentFilter.map((payment) => (
              <MenuItem key={payment} value={payment}>
                {payment}
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
      </FilterInputsContainer>
      <Divider />

      <FilteredContainer>
        {filterApplied.map((filter) => (
          <Filter>
            <span>{filter}</span>
            <IconButton>{IconCloseMS}</IconButton>
          </Filter>
        ))}
      </FilteredContainer>

      <PaymentTable />
    </TabContainer>
  );
}
