import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  DetailProps,
  GetAllInspectionFormsProps,
  GetInspectionFormProps,
  NewFormData,
} from './types';

export const addInspectionForm = (
  data: NewFormData,
  template: boolean
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${BaseUrl}/api/V1/inspection-forms?as_template=${template}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getInspectionForm = (
  id: number
): Promise<GetInspectionFormProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllInspectionForms = (
  template: boolean
): Promise<GetAllInspectionFormsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/inspection-forms?templates=${template}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateInspectionForm = (
  id: number,
  data: NewFormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteInspectionForm = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
