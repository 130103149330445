/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';

import {
  EvaluationType,
  PropertyType,
  convertClientKind,
  convertEvaluationType,
} from '../../api/enumerations';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { FacadeModal } from '../../components/FacadeModal';
import StandardMap from '../../components/Maps/StandardMap';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { WhiteProgress } from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconDownloadMS,
  IconEditNoteMS,
  IconExpandLessMS,
  IconExpandMoreMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
  IconPhotoLibraryMS,
  IconUploadMS,
} from '../../constants/icons';
import { Constants } from '../../constants/inspection';
import {
  selectConcept,
  selectConservation,
  selectPropertyType,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
} from '../../constants/selectOptions';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { InspectionForm } from './Form';
import {
  AccordionButton,
  AddedFacadeButton,
  BackButton,
  BoldTypography,
  ButtonBox,
  ButtonContainer,
  ButtonTitle,
  CepStyle,
  ConfirmEditButton,
  CoordGrid,
  DownloadButton,
  EditButton,
  FacadeImage,
  Flex,
  InputTitle,
  OutlineButton,
  SectionBox,
  SectionTitle,
  StyledInput,
  SubmitBox,
  UploadButton,
} from './styles';
import useInspection from './useInspection';

export function Inspection(): JSX.Element {
  const { openSnackbar } = useContext(GlobalContext);

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { osId, navigateHome, handleDownloadFile } = useGeneral();
  const { handlePreviousStatus, loadingPreviousStatus, loadingApprove } =
    useChangeStatus();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const {
    handleProgress,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    rooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    questionForm,
    progressValue,
    inspectionId,
    openAddFacadeModal,
    setOpenAddFacadeModal,
    enableEditing,
    setEnableEditing,
    handleCheckLocation,
    checkLocationDate,
    handleApprove,
    loadingPage,
    submitLoading,
    downloadIptu,
    downloadRegister,
    getDataCallback,
  } = useInspection();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree, expandFour, expandFive, expandSix]);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="inspection" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.inspection}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                <CancelOsBox>
                  <ConfirmationDeleteDialog
                    iconCancel
                    title={Constants.cancelOs}
                    text={Constants.cancelOsText}
                    modalCallback={handleCancelWorkOrder}
                  />
                </CancelOsBox>
                <EditButton onClick={() => setEnableEditing(!enableEditing)}>
                  {IconEditNoteMS}
                  {enableEditing ? Constants.cancelEdit : Constants.edit}
                </EditButton>
              </FlexBox>
              <AllAttachments propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            {enableEditing ? (
              <SectionBox>
                <SectionTitle>
                  {IconPersonMS}
                  {Constants.clientData}
                </SectionTitle>
                <Grid container spacing={4}>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      required
                      id="client"
                      label="proprietário do imóvel"
                      value={client}
                      setValue={setClient}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      id="solicitor"
                      label="solicitante"
                      value={solicitor}
                      setValue={setSolicitor}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <SelectTextField
                      id="os-goal"
                      label="objetivo"
                      value={goal}
                      setValue={setGoal}
                      selectOptions={selectReportGoal()}
                    />
                  </Grid>
                  <Grid item xs={6} xl={3}>
                    <InputTitle>{Constants.evaluationType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertEvaluationType(propertyData.evaluation_type)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <InputTitle>{Constants.clientType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertClientKind(propertyData.client_kind)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="os-function"
                      label="finalidade"
                      value={propertyFunction}
                      setValue={setPropertyFunction}
                      selectOptions={selectReportFunction()}
                    />
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="property-use"
                      label="uso do imóvel"
                      value={propertyUse}
                      setValue={setPropertyUse}
                      selectOptions={selectPropertyUse()}
                    />
                  </Grid>
                </Grid>
              </SectionBox>
            ) : (
              <ClientData propertyData={propertyData} />
            )}
            <Box>
              <AccordionButton onClick={toggleAccordions} disableTouchRipple>
                {expandAll ? IconExpandLessMS : IconExpandMoreMS}
                {expandAll ? 'recolher todos' : 'expandir todos'}
              </AccordionButton>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <>
                      <Typography>{Constants.downloadText}</Typography>
                      <ButtonContainer>
                        <ButtonBox>
                          <ButtonTitle>
                            {Constants.propertyRegister}
                          </ButtonTitle>
                          {downloadRegister.length > 0 ? (
                            <DownloadButton
                              onClick={() => {
                                if (downloadRegister)
                                  handleDownloadFile(
                                    downloadRegister,
                                    'matricula'
                                  );
                              }}
                            >
                              {IconDownloadMS}
                              {Constants.download}
                            </DownloadButton>
                          ) : (
                            <>
                              <label htmlFor="uploadRegister">
                                <input
                                  accept="image/*,application/pdf"
                                  id="uploadRegister"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={async (
                                    e: ChangeEvent<HTMLInputElement>
                                  ) => handleFileUpload(e, 'register')}
                                />
                                <UploadButton
                                  variant="contained"
                                  component="span"
                                >
                                  {IconUploadMS}
                                  {Constants.upload}
                                </UploadButton>
                              </label>
                              {registerFileName && (
                                <Box mt={1}>{registerFileName}</Box>
                              )}
                            </>
                          )}
                        </ButtonBox>
                        <ButtonBox>
                          <ButtonTitle>{Constants.iptu}</ButtonTitle>
                          {downloadIptu.length > 0 ? (
                            <DownloadButton
                              onClick={() => {
                                if (downloadIptu)
                                  handleDownloadFile(downloadIptu, 'iptu');
                              }}
                            >
                              {IconDownloadMS}
                              {Constants.download}
                            </DownloadButton>
                          ) : (
                            <>
                              <label htmlFor="uploadIptu">
                                <input
                                  accept="image/*,application/pdf"
                                  id="uploadIptu"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={async (
                                    e: ChangeEvent<HTMLInputElement>
                                  ) => handleFileUpload(e, 'iptu')}
                                />
                                <UploadButton
                                  variant="contained"
                                  component="span"
                                >
                                  {IconUploadMS}
                                  {Constants.upload}
                                </UploadButton>
                              </label>
                              {iptuFileName && <Box mt={1}>{iptuFileName}</Box>}
                            </>
                          )}
                        </ButtonBox>
                      </ButtonContainer>
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="register-number"
                            label="nº da matrícula"
                            value={registerNumber}
                            setValue={setRegisterNumber}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="property-type"
                            label="tipo do imóvel"
                            value={propertyType}
                            setValue={setPropertyType}
                            selectOptions={selectPropertyType()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="concept"
                            label="conceito do espaço"
                            value={concept}
                            setValue={setConcept}
                            selectOptions={selectConcept()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericTextField
                            id="zone"
                            label="ofício/zona"
                            suffix=""
                            maxLength={12}
                            value={zone}
                            setValue={setZone}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="judicial-district"
                            label="comarca"
                            value={judicialDistrict}
                            setValue={setJudicialDistrict}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="registration-uf"
                            label="UF"
                            value={registrationUf}
                            setValue={setRegistrationUf}
                            selectOptions={selectRegistrationUf()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo &&
                  (enableEditing ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={4}>
                          <Grid item xs={4}>
                            <PatternFormat
                              required
                              format="#####-###"
                              customInput={TextField}
                              id="cep"
                              label="CEP"
                              color="secondary"
                              value={cep}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                verifyCep(e.target.value)
                              }
                              sx={CepStyle}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              required
                              id="address"
                              label="logradouro"
                              value={address}
                              setValue={setAddress}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="address-number"
                              label="número"
                              value={addressNumber}
                              setValue={setAddressNumber}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              id="complement"
                              label="complemento"
                              value={complement}
                              setValue={setComplement}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="district"
                              label="bairro"
                              value={district}
                              setValue={setDistrict}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="city"
                              label="cidade"
                              value={city}
                              setValue={setCity}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="uf"
                              label="estado"
                              value={uf}
                              setValue={setUf}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <OutlineButton
                              onClick={() => {
                                setSearchMap(!searchMap);
                                setIsAddressEdited(false);
                              }}
                            >
                              {Constants.searchMap}
                            </OutlineButton>
                          </Grid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lat}</BoldTypography>
                            <Typography>{pinPlace.lat}</Typography>
                          </CoordGrid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lng}</BoldTypography>
                            <Typography>{pinPlace.lng}</Typography>
                          </CoordGrid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <StandardMap
                          searchMap={searchMap}
                          address={`${address} ${addressNumber} ${district} ${city}`}
                          pinPlace={pinPlace}
                          setPinPlace={setPinPlace}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <PropertyAddressWithMaps
                      checkLocation
                      propertyData={propertyData}
                      handleCheckLocation={handleCheckLocation}
                      checkLocationDate={checkLocationDate && checkLocationDate}
                    />
                  ))}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree &&
                  (enableEditing ? (
                    <Grid container spacing={4}>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="total-area"
                          label={
                            propertyData?.real_estate_kind ===
                            PropertyType.APARTMENT
                              ? 'área total'
                              : 'área do terreno'
                          }
                          suffix=" m²"
                          decimalSeparator=","
                          decimalScale={2}
                          maxLength={18}
                          value={totalArea}
                          setValue={setTotalArea}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="built-area"
                          label={
                            propertyData?.real_estate_kind ===
                            PropertyType.APARTMENT
                              ? 'área privativa'
                              : 'área construída'
                          }
                          suffix=" m²"
                          decimalSeparator=","
                          decimalScale={2}
                          maxLength={18}
                          value={builtArea}
                          setValue={setBuiltArea}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="rooms"
                          label="quartos"
                          suffix=" quartos"
                          maxLength={12}
                          value={propertyRooms}
                          setValue={setPropertyRooms}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="toilets"
                          label="banheiros"
                          suffix=" banheiros"
                          maxLength={13}
                          value={toilets}
                          setValue={setToilets}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="suites"
                          label="suítes"
                          suffix=" suítes"
                          maxLength={10}
                          value={suites}
                          setValue={setSuites}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="parking-lots"
                          label="vagas de garagem"
                          suffix=" vagas"
                          maxLength={9}
                          value={parkingLots}
                          setValue={setParkingLots}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumericTextField
                          id="propertyAge"
                          label="idade do imóvel"
                          suffix=" anos"
                          maxLength={9}
                          value={age}
                          setValue={setAge}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectTextField
                          id="conservation"
                          label="estado de conservação do imóvel"
                          value={conservation}
                          setValue={setConservation}
                          selectOptions={selectConservation()}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectTextField
                          id="building-standard"
                          label="padrão construtivo"
                          value={buildingStandard}
                          setValue={setBuildingStandard}
                          selectOptions={selectStandard()}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <PropertyInfo propertyData={propertyData} />
                  ))}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection?.schedule_at ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            showDownload={false}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData && (
                      <Box>
                        {propertyData.inspection?.engineer_id !== null ? (
                          <Rooms
                            navigationPath={`${osId}/inspection/${inspectionId}/rooms`}
                            rooms={rooms}
                            osId={osId}
                            inspectionStatus
                            inspectionId={propertyData.inspection?.id}
                            showUpload
                            responsiblePicture={
                              propertyData.inspection
                                ?.responsible_picture_filename
                            }
                            updatePropertyData={getDataCallback}
                          />
                        ) : propertyData.facade_image ? (
                          <FacadeImage
                            src={propertyData.facade_image}
                            alt="Facade image"
                          />
                        ) : propertyData.evaluation_type ===
                            EvaluationType.AUTOFACTORS ||
                          propertyData.evaluation_type ===
                            EvaluationType.AUTOINFERENCES ? (
                          <AddedFacadeButton
                            type="button"
                            onClick={() => setOpenAddFacadeModal(true)}
                          >
                            {Constants.addFacade}
                          </AddedFacadeButton>
                        ) : (
                          <Box>{Constants.noPhotos}</Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection && questionForm ? (
                      <InspectionForm
                        questionForm={questionForm}
                        engineerId={propertyData.inspection.engineer_id}
                        inspectionId={propertyData.inspection.id}
                        progress={progressValue}
                        handleProgress={handleProgress}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SubmitBox>
                <PeptDialog
                  osId={osId}
                  referenceNumber={propertyData?.reference_number}
                />
                <Flex>
                  <ConfirmationDialog
                    title={Constants.previousStatus}
                    loading={loadingPreviousStatus}
                    text={Constants.previousStatusText.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={() =>
                      handlePreviousStatus(
                        osId,
                        propertyData?.reference_number,
                        Constants.previousStatusName
                      )
                    }
                    previous
                  />
                  {enableEditing ? (
                    <ConfirmEditButton
                      form="inspection"
                      type="submit"
                      disabled={submitLoading}
                    >
                      {submitLoading ? (
                        <WhiteProgress size={22} />
                      ) : (
                        Constants.confirmEdit
                      )}
                    </ConfirmEditButton>
                  ) : checkLocationDate ? (
                    <ConfirmationDialog
                      title={Constants.approve}
                      loading={loadingApprove}
                      text={Constants.changeStatus.replace(
                        '**',
                        `${propertyData?.reference_number}`
                      )}
                      modalCallback={() => {
                        handleApprove();
                      }}
                    />
                  ) : (
                    <ConfirmEditButton
                      onClick={() => {
                        setSnackbarMessage(
                          'Localização pendente de confirmação'
                        );
                        setOpenSnackbar(true);
                        setErrorMessage(true);
                      }}
                    >
                      {Constants.approve}
                    </ConfirmEditButton>
                  )}
                </Flex>
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
      <FacadeModal
        osId={osId}
        open={openAddFacadeModal}
        onClose={() => setOpenAddFacadeModal(false)}
      />
    </GridContainer>
  );
}
