import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionButton = styled(ButtonBase)(() => ({
  fontSize: '16px',
  width: '100%',
  justifyContent: 'flex-end',
  '& span': {
    color: '#00B39B',
    fontSize: '32px',
  },
  '&:hover': {
    fontWeight: '600',
  },
}));
