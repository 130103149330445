/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Status } from '../../../api/enumerations';
import {
  addPicture,
  deletePicture,
  getAllPictures,
} from '../../../api/pictures';
import { GalleryPictureData } from '../../../api/pictures/types';
import { getRoom } from '../../../api/rooms';
import { GetWorkOrder } from '../../../api/workOrders';
import BackdropLoading from '../../../components/BackdropLoading';
import DeletePictureDialog from '../../../components/DeletePictureDialog';
import { Title } from '../../../components/Sections/Title';
import Snackbar from '../../../components/Snackbar';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddPhotoAlternateMS,
  IconArrowCircleLeftMS,
  IconDoorFrontMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { validateImg } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  BackButton,
  FlexReverseBox,
  GalleryContainer,
  InsideBox,
  PathologiesBox,
  PhotoContainer,
  SectionBox,
  StyledCardMedia,
  UploadBox,
  UploadIcon,
  UploadSubtitle,
  UploadTitle,
} from './styles';

export function InspectionPhotoGallery(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [pictures, setPictures] = useState<GalleryPictureData[]>([]);
  const [loading, setLoading] = useState(false);
  const picturesPerPage = 12;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const { id, inspection, room } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);
  const roomId = parseInt(room as string, 10);

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms`);
  };

  const navigateToPicture = (pictureId: number): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureId}`
    );
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await getRoom(roomId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Não foi possível carregar o nome do cômodo.');
        }

        setRoomName(response.data.name);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigateBack();
      }

      try {
        const response = await GetWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PROPOSAL ||
          response.data.status === Status.CONTRACT ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await getAllPictures(roomId, page, picturesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPictures([...pictures, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page]);

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    const formData = new FormData();
    Array.from(files).map((file) => formData.append('pictures', file));
    formData.append('room_id', roomId.toString());
    setLoading(true);
    try {
      const response = await addPicture(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      navigate(
        // eslint-disable-next-line max-len
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${response.data.first_id}`
      );
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !validateImg(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.dataTransfer;

    handleSubmitPhotos(files);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.target;

    handleSubmitPhotos(files);
  };

  const handleDeletePhoto = async (pictureId: number): Promise<void> => {
    try {
      const response = await deletePicture(pictureId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      const filterPictures = pictures.filter((el) => el.id !== pictureId);
      setPictures(filterPictures);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <SectionBox>
        <Title title={roomName} icon={IconDoorFrontMS} />
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.photoGalley}
        </SectionTitle>
        <GalleryContainer>
          {edit && (
            <UploadBox
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <label htmlFor="uploadMorePhotos">
                <input
                  accept="image/*, .heic"
                  multiple
                  id="uploadMorePhotos"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e)
                  }
                />
                {!dragActive ? (
                  <InsideBox>
                    <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                    <UploadTitle>{Constants.morePhotos}</UploadTitle>
                    <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                  </InsideBox>
                ) : (
                  <UploadTitle>{Constants.dragText}</UploadTitle>
                )}
              </label>
            </UploadBox>
          )}
          {pictures?.map((photo) => (
            <PhotoContainer key={photo.id}>
              {edit && (
                <FlexReverseBox>
                  <DeletePictureDialog
                    outlinedButton={false}
                    modalCallback={handleDeletePhoto}
                    pictureId={photo.id}
                  />
                </FlexReverseBox>
              )}
              <StyledCardMedia
                image={photo.file}
                title={photo.name || ''}
                onClick={() => navigateToPicture(photo.id)}
              />
              <FlexReverseBox>
                <PathologiesBox>{photo.total_pathologies}</PathologiesBox>
              </FlexReverseBox>
            </PhotoContainer>
          ))}
        </GalleryContainer>
      </SectionBox>
      <BackdropLoading open={loading} />
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
