/* eslint-disable max-len */
export const Constants = {
  sampleCreation: 'Criação de Amostra',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  edit: 'editar OS',
  cancelEdit: 'cancelar edição',
  clientData: 'Dados do cliente',
  propertyData: 'Dados do imóvel',
  downloadText:
    'Você pode realizar o download dos seguintes documentos anexados:',
  propertyRegister: 'Matrícula do imóvel',
  download: 'download',
  upload: 'upload',
  iptu: 'Cartela do IPTU',
  evaluationType: 'tipo do cálculo:',
  clientType: 'tipo do cliente:',
  propertyType: 'tipo do imóvel:',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  form: 'Formulário',
  noForm: 'esta OS não possui formulário',
  totalArea: 'área total:',
  privateArea: 'área privativa',
  landArea: 'área do terreno',
  constructedArea: 'área construída:',
  rooms: 'quartos:',
  toilets: 'banheiros:',
  suites: 'suítes:',
  parkingLots: 'vagas de garagem:',
  supplyFactor: 'Fator oferta:',
  samples: 'Amostra',
  editSupply: 'editar fator oferta',
  save: 'salvar',
  searchSamples: 'buscar amostra',
  deleteAllSamples: 'excluir amostra',
  addElement: 'adicionar elemento',
  estimateConservation: 'calcular estado de conservação',
  approve: 'aprovar',
  approved: 'aprovado',
  approveSubtitle: '* Calcule o estado de conservação',
  confirmChanges: 'confirmar alterações',
  newElement: 'Novo Elemento',
  movePin: 'Mova o pin caso a localização esteja incorreta',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  searchMap: 'pesquisar no mapa',
  addPhotos: 'Adicionar fotos',
  photos: 'Fotos',
  uploadText: 'arraste e solte os arquivos',
  dragText: 'solte os arquivos aqui',
  fileType: '(JPG, GIF e PNG somente)',
  acceptCheck:
    'Estou ciente que sou responsável pelos dados inseridos e os declaro como verdadeiros.',
  cancel: 'cancelar',
  pept: 'enviar para PEPT',
  noInspection: 'esta OS não possui agendamento',
  noPhotos: 'esta OS não possui imagens',
  facade: 'Fachada',
  changeStatus:
    'alterar o status da OS ** de Criação de Amostra para Processando Cálculo',
  snackbarMessage: 'OS ** foi enviada para AVM',
  complete: 'Completo',
  incomplete: 'Incompleto',
  exportButtonTitle: 'exportar dados do cálculo e formulário',
  extrapolation:
    'Não será possível avançar enquanto a extrapolação não for resolvida',
  addressText:
    'Não encontramos nenhum elemento no mesmo endereço do avaliando. Se preferir, adicione elementos comparativos no mesmo empreendimento.',
  confirmEdit: 'confirmar edição de OS',
  completeSample: 'completar elementos',
  parkingErrorMessage:
    'Utilizando menos de 20 elementos na amostra a variável de vagas de garagem será desconsiderada para que o item 2 do grau de fundamentação da norma NBR-14653-2 que considera a quantidade mínima de dados de mercado efetivamente utilizados atinja 2 pontos. Caso você queira utilizar a vaga de garagem você deverá adicionar mais elementos.',
  minInfeApartment:
    '4 variáveis = Mínimo de 20 elementos podendo atingir grau II | 3 variáveis = Mínimo de 16 elementos para atingir grau II',
  minInfeHouse: 'Mínimo de 20 elementos podendo atingir grau II',
  minFactors: 'Mínimo de 5 elementos podendo atingir grau II',
};
