import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionSectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  cursor: 'pointer',
  '& span': { color: '#00B39B' },
}));
