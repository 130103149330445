import { useState } from 'react';

interface UseAccordionProps {
  expandOne: boolean;
  setExpandOne: (value: boolean) => void;
  expandTwo: boolean;
  setExpandTwo: (value: boolean) => void;
  expandThree: boolean;
  setExpandThree: (value: boolean) => void;
  expandFour: boolean;
  setExpandFour: (value: boolean) => void;
  expandFive: boolean;
  setExpandFive: (value: boolean) => void;
  expandSix: boolean;
  setExpandSix: (value: boolean) => void;
  expandSeven: boolean;
  setExpandSeven: (value: boolean) => void;
  expandEight: boolean;
  setExpandEight: (value: boolean) => void;
  expandNine: boolean;
  setExpandNine: (value: boolean) => void;
  expandTen: boolean;
  setExpandTen: (value: boolean) => void;
  expandAll: boolean;
  setExpandAll: (value: boolean) => void;
  toggleAccordions: () => void;
}

export function useAccordion(): UseAccordionProps {
  const [expandOne, setExpandOne] = useState(false);
  const [expandTwo, setExpandTwo] = useState(false);
  const [expandThree, setExpandThree] = useState(false);
  const [expandFour, setExpandFour] = useState(false);
  const [expandFive, setExpandFive] = useState(false);
  const [expandSix, setExpandSix] = useState(false);
  const [expandSeven, setExpandSeven] = useState(false);
  const [expandEight, setExpandEight] = useState(false);
  const [expandNine, setExpandNine] = useState(false);
  const [expandTen, setExpandTen] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const toggleAccordions = (): void => {
    setExpandOne(!expandAll);
    setExpandTwo(!expandAll);
    setExpandThree(!expandAll);
    setExpandFour(!expandAll);
    setExpandFive(!expandAll);
    setExpandSix(!expandAll);
    setExpandSeven(!expandAll);
    setExpandEight(!expandAll);
    setExpandNine(!expandAll);
    setExpandTen(!expandAll);
  };

  return {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
    expandAll,
    setExpandAll,
    toggleAccordions,
  };
}
