import { Box, Button, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
    maxWidth: 'none',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogContainer = styled(Box)(() => ({
  width: '700px',
  display: 'flex',
  flexDirection: 'column',
  padding: '48px',
}));

export const ButtonsContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '32px',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  width: '300px',
  textTransform: 'lowercase',
  color: theme.palette.primary.dark,
  backgroundColor: 'transparent',
  border: `1.5px solid ${theme.palette.primary.dark}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:disabled': {
    color: theme.palette.primary.dark,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
  span: {
    marginRight: '8px',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '300px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));
