/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import-helpers/order-imports */
import { Box } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

import {
  getAllPictures,
  selectInspectionPictures,
} from '../../../../api/pictures';
import {
  GalleryPictureData,
  SelectedPicture,
} from '../../../../api/pictures/types';
import { RoomData } from '../../../../api/rooms/types';
import {
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import {
  AccordionRoomTitle,
  FlexReverseBox,
  GalleryContainer,
  PhotoContainer,
  SelectCheckbox,
  StyledCardMedia,
} from './styles';

interface AccordionRoomProps {
  roomData: RoomData;
  selectedPictures: SelectedPicture[];
  setSelectedPictures: Dispatch<SetStateAction<SelectedPicture[]>>;
  handleSelectPhoto: (picture: GalleryPictureData, roomId?: number) => void;
  loading: boolean;
}

export function AccordionRoom({
  roomData,
  selectedPictures,
  setSelectedPictures,
  handleSelectPhoto,
  loading,
}: AccordionRoomProps): JSX.Element {
  const [openAccordion, setOpenAccordion] = useState(false);
  const [inspectionPictures, setInspectionPictures] = useState<
    GalleryPictureData[]
  >([]);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);

  const picturesPerPage = 12;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { inspection } = useParams();
  const { navigateHome } = useGeneral();

  const swapSelectedIndex = (
    arr: SelectedPicture[],
    id: number,
    newIndex: number
  ) => {
    const newArr = [...arr];
    const currentObjIndex = newArr.findIndex((obj) => obj.id === id);
    if (currentObjIndex === -1) {
      return arr;
    }

    if (newArr[currentObjIndex].selected_index === newIndex) {
      return arr;
    }

    const targetObjIndex = newArr.findIndex(
      (obj) => obj.selected_index === newIndex
    );
    if (targetObjIndex === -1) {
      return arr;
    }

    const temp = newArr[currentObjIndex].selected_index;
    newArr[currentObjIndex].selected_index =
      newArr[targetObjIndex].selected_index;
    newArr[targetObjIndex].selected_index = temp;

    return newArr;
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }
    try {
      const roomID = roomData.room_type_id;
      const response = await getAllPictures(roomData.id, page, picturesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (roomID === 10 && response.data.length > 0 && !loading) {
        const picture = response.data[0];

        if (selectedPictures.length === 0 && inspection) {
          handleSelectPhoto(picture);

          const selectedPicturesData = {
            inspection_id: Number(inspection),
            selected_pictures: [{ id: picture.id, selected_index: 1 }],
          };
          try {
            const response = await selectInspectionPictures(
              selectedPicturesData
            );

            if (response.detail.description) {
              throw new Error(response.detail.description);
            }
          } catch (error) {
            setSnackbarMessage('Algo deu errado, tente novamente.');
            setErrorMessage(true);
            setOpenSnackbar(true);
          }
        } else if (selectedPictures.length > 0) {
          setSelectedPictures((prevState) =>
            swapSelectedIndex(prevState, picture.id, 1)
          );
        }
      }

      setInspectionPictures([...inspectionPictures, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateHome();
    }
  }, [page, openAccordion, selectedPictures]);

  useEffect(() => {
    if (openAccordion) {
      getDataCallback();
    }
  }, [page, openAccordion]);

  useEffect(() => {
    if (!loading && selectedPictures.length) {
      swapSelectedIndex(selectedPictures, 1, 1);
    }
  }, [selectedPictures]);

  return (
    <Box>
      <AccordionRoomTitle
        onClick={() => {
          setOpenAccordion(!openAccordion);
        }}
      >
        {roomData.name}
        {openAccordion ? IconExpandMoreMS : IconKeyboardArrowRightMS}
      </AccordionRoomTitle>
      {openAccordion && (
        <GalleryContainer>
          {inspectionPictures.map((pic) => (
            <PhotoContainer
              key={pic.id}
              onClick={() => handleSelectPhoto(pic, roomData.room_type_id)}
            >
              <FlexReverseBox>
                <SelectCheckbox
                  ischecked={
                    roomData.room_type_id === 10
                      ? 'true'
                      : selectedPictures.some((el) => el.id === pic.id)
                      ? 'true'
                      : 'false'
                  }
                >
                  {selectedPictures.map((photo) => {
                    if (photo.id === pic.id) {
                      return <span>{photo.selected_index}</span>;
                    } else {
                      return '';
                    }
                  })}
                </SelectCheckbox>
              </FlexReverseBox>
              <StyledCardMedia image={pic.file} />
            </PhotoContainer>
          ))}
        </GalleryContainer>
      )}
    </Box>
  );
}
