/* eslint-disable react/require-default-props */
import {
  IconArrowBackMS,
  IconArrowForwardMS,
} from '../../../../constants/icons';
import {
  SampleBackButton,
  SampleForwardBox,
  SampleForwardButton,
} from './styles';

interface CardPaginationProps {
  sampleLength: number | undefined;
  page: number;
  setPage: (value: number) => void;
  sampleCreationPage?: boolean;
}

export function CardPagination({
  sampleLength,
  page,
  setPage,
  sampleCreationPage,
}: CardPaginationProps): JSX.Element {
  const sampleCreation = sampleCreationPage ? 'true' : 'false';
  return (
    <>
      <SampleBackButton
        samplecreation={sampleCreation}
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
      >
        {IconArrowBackMS}
      </SampleBackButton>
      <SampleForwardBox>
        <SampleForwardButton
          samplecreation={sampleCreation}
          disabled={page === sampleLength}
          onClick={() => setPage(page + 1)}
        >
          {IconArrowForwardMS}
        </SampleForwardButton>
      </SampleForwardBox>
    </>
  );
}
