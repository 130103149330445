/* eslint-disable react/require-default-props */
import { ChangeEvent, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StatusCode } from '../../../api/enumerations';
import { updateSelfie } from '../../../api/inspections';
import { getAllPictures } from '../../../api/pictures';
import { RoomData } from '../../../api/rooms/types';
import selfie from '../../../assets/images/selfie.png';
import standard from '../../../assets/roomImages/standard.png';
import {
  IconDoorFrontMS,
  IconDownloadMS,
  IconUploadMS,
} from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { validateFiles } from '../../../helpers';
import { roomsOptions } from '../../../helpers/roomImages';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import {
  DownloadButton,
  FlexReverseBox,
  InputContainer,
  RelativeBox,
  ResponsibleCardMedia,
  ResponsiblePicture,
  RoomsContainer,
  StyledCardMedia,
  StyledCardText,
  StyledIconButton,
  StyledTooltip,
  ViewPhotosButton,
} from './styles';

interface RoomsProps {
  navigationPath: string;
  rooms: RoomData[];
  osId: number;
  inspectionId?: number;
  showUpload?: boolean;
  inspectionStatus?: boolean;
  responsiblePicture?: string | null;
  updatePropertyData?: () => Promise<void>;
}

export function Rooms({
  navigationPath,
  rooms,
  osId,
  inspectionId,
  showUpload = false,
  inspectionStatus = false,
  responsiblePicture = null,
  updatePropertyData,
}: RoomsProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const {
    setInspectionPath,
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
  } = useContext(GlobalContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>
  ): undefined | FileList => {
    let filesData;
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return filesData;
    }
    const { files } = e.target;
    filesData = files;
    return filesData;
  };

  const navigateRooms = (): void => {
    setInspectionPath(location.pathname);
    navigate(`/home/property/${navigationPath}`);
  };

  const handleRoomImage = (roomId: number): string => {
    const hasImage = roomsOptions.data.find((item) => item.id === roomId);

    if (hasImage) {
      return hasImage.image;
    }
    return standard;
  };

  const handleSubmit = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const formData = new FormData();
    const files = handleFileUpload(e);
    if (files) {
      formData.append('responsible_picture', files[0]);
    }

    if (!inspectionId) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await updateSelfie(inspectionId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto adicionada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (updatePropertyData) {
        updatePropertyData();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const navigateToRoom = async (roomId: number): Promise<void> => {
    try {
      const response = await getAllPictures(roomId, 1, 10);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setInspectionPath(location.pathname);

      if (response.detail.total_records === 0) {
        if (!inspectionStatus) {
          throw new Error('Cômodo não possui fotos.');
        }
        navigate(
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/add-photos`
        );
      } else {
        navigate(
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
        );
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <RoomsContainer>
      {showUpload && responsiblePicture === null && (
        <label htmlFor="uploadSelfie" style={{ width: 'fit-content' }}>
          <input
            accept="image/*"
            id="uploadSelfie"
            type="file"
            style={{ display: 'none' }}
            onChange={async (e: ChangeEvent<HTMLInputElement>) =>
              handleSubmit(e)
            }
          />
          <RelativeBox sx={{ cursor: 'pointer' }}>
            <FlexReverseBox onClick={() => setOpen(!open)}>
              <StyledTooltip
                title="Você deverá tirar uma foto do seu rosto com a fachada do imóvel ao fundo."
                disableHoverListener
                open={open}
              >
                <StyledIconButton>i</StyledIconButton>
              </StyledTooltip>
            </FlexReverseBox>
            <StyledCardMedia image={selfie} title="selfie" />
            <StyledCardText sx={{ gap: '10px' }}>
              {IconUploadMS} selfie
            </StyledCardText>
          </RelativeBox>
        </label>
      )}
      {responsiblePicture !== null && (
        <ResponsiblePicture>
          <ResponsibleCardMedia image={responsiblePicture} title="selfie" />
          <DownloadButton
            onClick={() => handleDownloadFile(responsiblePicture, 'selfie')}
          >
            {IconDownloadMS} download selfie
          </DownloadButton>
        </ResponsiblePicture>
      )}
      <InputContainer>
        {rooms.map((room) => (
          <RelativeBox key={room.id} onClick={() => navigateToRoom(room.id)}>
            <StyledCardMedia
              image={handleRoomImage(room.room_type_id)}
              title={room.name}
            />
            <StyledCardText>{room.name}</StyledCardText>
          </RelativeBox>
        ))}
        <ViewPhotosButton onClick={navigateRooms}>
          {IconDoorFrontMS}
          {rooms?.length > 0
            ? 'visualizar todos os cômodos'
            : 'adicionar cômodos'}
        </ViewPhotosButton>
      </InputContainer>
    </RoomsContainer>
  );
}
