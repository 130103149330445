import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  margin: '20px 0',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  '& span': { color: '#00B39B' },
}));

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '180px',
  paddingLeft: '8px',
  minHeight: '28px',
}));
