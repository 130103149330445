/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Switch,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { sendForceFactors } from '../../../../../api/workOrders';
import { AvmFactorsData } from '../../../../../api/workOrders/types';
import { IconEditMS } from '../../../../../constants/icons';
import { Constants } from '../../../../../constants/report';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../hooks/useGeneral';
import {
  BoldCell,
  CellContent,
  CellHeader,
  GridTable,
  RowHeader,
  StyledTable,
  StyledTooltip,
} from '../../styles';
import {
  presentAggregatedFactorData,
  presentAggregatedFactorName,
  presentCoeficientHeaderForceFactors,
} from '../presenter';
import { presentAggregatedFactorValue } from './presenter';
import {
  ButtonBox,
  DialogButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  SectionTitle,
  StyledDialog,
} from './styles';

interface ForceFactorsDialogProps {
  tableData: AvmFactorsData;
  osForceFactors: string[];
  outlineButton?: boolean;
}

export default function ForceFactorsDialog({
  tableData,
  osForceFactors,
  outlineButton,
}: ForceFactorsDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [forceFactors, setForceFactors] = useState<string[]>(osForceFactors);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  useEffect(() => {
    const savedFactors = tableData.aggregated_factors
      .filter((data) => data.coeff_utilized)
      .map((data) => presentAggregatedFactorValue[data.name]);

    if (savedFactors) {
      setForceFactors(savedFactors);
    }
  }, []);

  const handleForceFactors = useCallback(async (): Promise<void> => {
    const data = {
      force_factors: forceFactors,
    };

    try {
      const response = await sendForceFactors(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('O laudo será recalculado!');
      handleClose();
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [forceFactors]);

  return (
    <>
      {outlineButton ? (
        <OutlinedButton sx={{ maxWidth: '300px' }} onClick={handleOpen}>
          {IconEditMS}
          {Constants.changeFactors}
        </OutlinedButton>
      ) : (
        <DialogButton onClick={handleOpen}>
          {IconEditMS}
          {Constants.changeFactors}
        </DialogButton>
      )}
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="force factors dialog"
      >
        <DialogContainer>
          <SectionTitle>
            {IconEditMS}
            {Constants.changeFactors}
          </SectionTitle>
          <GridTable item xs={12}>
            <TableContainer>
              <StyledTable aria-label="tabela validação de coeficiente">
                <TableHead>
                  <RowHeader>
                    {presentCoeficientHeaderForceFactors.map((header) => (
                      <CellHeader
                        align="center"
                        minwidth={header.width}
                        key={header.label}
                      >
                        {header.label}
                      </CellHeader>
                    ))}
                  </RowHeader>
                </TableHead>
                <TableBody>
                  {tableData.aggregated_factors.map((data, ind) => (
                    <TableRow key={`${data.mean_ppsm}${data.std_ppsm}`}>
                      <CellContent align="center">{ind + 1}</CellContent>
                      <BoldCell align="left">
                        {presentAggregatedFactorName[data.name]}
                      </BoldCell>
                      {presentAggregatedFactorData(data).map((row) => (
                        <CellContent align="center" key={row.label}>
                          {row.value}
                        </CellContent>
                      ))}
                      <CellContent align="center">
                        {data.name === 'area_factor' ? (
                          <StyledTooltip title={Constants.reqFactor}>
                            <Switch checked />
                          </StyledTooltip>
                        ) : (
                          <Switch
                            defaultChecked={data.coeff_utilized}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked) {
                                setForceFactors([
                                  ...forceFactors,
                                  presentAggregatedFactorValue[data.name],
                                ]);
                              } else {
                                const deleteChoice = forceFactors.filter(
                                  (element) => {
                                    return (
                                      element !==
                                      presentAggregatedFactorValue[data.name]
                                    );
                                  }
                                );
                                setForceFactors(deleteChoice);
                              }
                            }}
                          />
                        )}
                      </CellContent>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </GridTable>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>
              {Constants.cancel}
            </OutlinedButton>
            <FilledButton onClick={handleForceFactors}>
              {Constants.confirm}
            </FilledButton>
          </ButtonBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
