import { Box, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
  display: 'flex',
  gap: '10px',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  height: '45px',
  border: 'none',
  '& :hover': {
    color: '#00B39B',
  },
  '& .MuiButtonBase-root.MuiToggleButton-root': {
    '&.Mui-selected': {
      color: '#FAFCFF',
      backgroundColor: '#004D43',
      border: 'none',
      borderRadius: '4px',
      '& :hover': {
        color: '#FAFCFF',
        backgroundColor: '#004D43',
        opacity: '80%',
      },
      '&:hover': {
        backgroundColor: '#004D43',
        opacity: '80%',
      },
    },
  },
}));

export const DashboardContainer = styled(Box)(() => ({
  margin: '40px 0 0',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
}));
