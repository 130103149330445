import { useContext } from 'react';

import { searchCep } from '../api/cep';
import { GlobalContext } from '../context/global';
import { cleanValue } from '../helpers';

interface SearchCepHook {
  handleSearchCep(tempCep: string): Promise<void | IAddressData>;
}

interface IAddressData {
  cep: string;
  uf: string;
  city: string;
  district: string;
  address: string;
}

export default function useSearchCep(): SearchCepHook {
  const cepLength = 8;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSearchCep = async (
    tempCep: string
  ): Promise<void | IAddressData> => {
    const cepValue = cleanValue(tempCep);
    let addressData;
    if (cepValue.length === cepLength) {
      const data = await searchCep(cepValue);
      if (data.erro) {
        setSnackbarMessage(`CEP não encontrado.`);
        setErrorMessage(true);
        setOpenSnackbar(true);
      } else {
        addressData = {
          cep: data.cep,
          uf: data.uf,
          city: data.localidade,
          district: data.bairro,
          address: data.logradouro,
        };
      }
    }
    return addressData;
  };

  return {
    handleSearchCep,
  };
}
