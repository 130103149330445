/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getCompanies } from '../../../api/companies';
import { Company } from '../../../api/companies/types';
import { Search } from '../../../components/Search';
import { IconInfoMS } from '../../../constants/icons';
import { Constants, userHeader } from '../../../constants/userManagement';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { InfoBox, SearchBox, StyledPagination } from '../styles';
import { StyledCell, StyledRow, TableCellHeader } from './styles';
import { UserData } from './UserData';

export function User(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [displayData, setDisplayData] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companyData, setCompanyData] = useState<Company>();

  const companiesPerPage = 8;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getCompanies(page, companiesPerPage, search);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTotalPages(response.detail.total_pages);
      setCompanies(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, search]);

  useEffect(() => {
    getDataCallback();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      getDataCallback();
    } else {
      setPage(1);
    }
  }, [search]);

  const handleSelectCompany = (companyData: Company): void => {
    setCompanyData(companyData);
    setDisplayData(!displayData);
  };

  return displayData ? (
    <UserData
      show={displayData}
      setShow={setDisplayData}
      company={companyData}
    />
  ) : (
    <Box>
      <SearchBox>
        <Search setSearchField={setSearch} />
      </SearchBox>
      {loading ? (
        <Box width="100%" mt={4}>
          <LinearProgress />
        </Box>
      ) : (
        <Box>
          {companies.length ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {userHeader.map((cell) => (
                        <TableCellHeader key={cell}>{cell}</TableCellHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map((company) => (
                      <StyledRow
                        key={company.id}
                        onClick={() => handleSelectCompany(company)}
                      >
                        <StyledCell width="37%">{company.name}</StyledCell>
                        <StyledCell width="37%">
                          {company.responsible_user.name}
                        </StyledCell>
                        <StyledCell width="26%">
                          {company.responsible_user.document}
                        </StyledCell>
                      </StyledRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledPagination
                page={page}
                count={totalPages}
                onChange={(_, v) => setPage(v)}
              />
            </>
          ) : (
            <InfoBox component={Paper}>
              {IconInfoMS}
              {Constants.noUsers}
            </InfoBox>
          )}
        </Box>
      )}
    </Box>
  );
}
