import { useState } from 'react';

import { IconCloseMS } from '../../constants/icons';
import {
  AlertTypography,
  ButtonBox,
  DeleteCircleButton,
  DeleteOutlineButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface DelelePictureProps {
  modalCallback: (id: number) => void;
  outlinedButton: boolean;
  pictureId: number;
}

export default function DeletePictureDialog({
  modalCallback,
  outlinedButton,
  pictureId,
}: DelelePictureProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = (): void => {
    modalCallback(pictureId);
    handleClose();
  };

  return (
    <>
      {outlinedButton ? (
        <DeleteOutlineButton disableTouchRipple onClick={handleOpen}>
          deletar foto
        </DeleteOutlineButton>
      ) : (
        <DeleteCircleButton disableTouchRipple onClick={handleOpen}>
          {IconCloseMS}
        </DeleteCircleButton>
      )}
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete picture"
      >
        <DialogContainer>
          <TextTypography>
            Você gostaria de deletar esta foto e suas patologias?
          </TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>Não</OutlinedButton>
            <FilledButton onClick={handleConfirm}>Sim</FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita.
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
