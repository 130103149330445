import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PipelineBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  minWidth: '300px',
  width: '300px',
  textAlign: 'center',
}));

export const PipelineTitle = styled(Typography)(() => ({
  borderRadius: '10px',
  backgroundColor: '#004D43',
  height: '56px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#EDEFF2',
  fontWeight: '600',
}));

export const PipelinePaper = styled(Paper)(() => ({
  height: '100%',
  overflowY: 'auto',
  backgroundColor: '#8B8C8E',
  border: '1px solid #00B39B',
  borderRadius: '10px',
  padding: '8px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
}));

export const StyledSkeleton = styled(Skeleton)(() => ({
  height: 230,
  borderRadius: 5,
  width: '100%',
  backgroundColor: 'white',
  margin: '8px 0 16px',
  padding: '16px',
}));
