/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, ReactNode } from 'react';

interface AttachmentsFilesProps {
  updateAttFiles: boolean;
  setUpdateAttFiles: (data: boolean) => void;
  iptuLink: string;
  setIptuLink: (link: string) => void;
  registerLink: string;
  setRegisterLink: (link: string) => void;
}

export const AttachmentsFilesContext = createContext(
  {} as AttachmentsFilesProps
);

interface AttachmentsFilesProviderProps {
  children: ReactNode;
}

export function AttachmentsFilesProvider({
  children,
}: AttachmentsFilesProviderProps): JSX.Element {
  const [iptuLink, setIptuLink] = useState('');
  const [registerLink, setRegisterLink] = useState('');
  const [updateAttFiles, setUpdateAttFiles] = useState(false);

  return (
    <AttachmentsFilesContext.Provider
      value={{
        iptuLink,
        setIptuLink,
        registerLink,
        setRegisterLink,
        updateAttFiles,
        setUpdateAttFiles,
      }}
    >
      {children}
    </AttachmentsFilesContext.Provider>
  );
}
