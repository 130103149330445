import jwtDecode, { JwtPayload } from 'jwt-decode';

interface CompaniesProps {
  is_responsible_user: boolean;
  name: string;
  role: number;
  subdomain: string;
}
interface TokenProps extends JwtPayload {
  exp: number;
  user: {
    companies: CompaniesProps[];
    email: string;
    id: number;
    name: string;
    session_id: number;
  };
}

interface StoragedJwtProps {
  expired: boolean;
  user: {
    companies: CompaniesProps[];
    email: string;
    id: number;
    name: string;
    session_id: number;
  };
}

export function useStoragedJwt(
  key = 'control-center-token'
): StoragedJwtProps | null {
  const jwtToken = localStorage.getItem(key);

  if (!jwtToken) {
    return null;
  }

  const decoded = jwtDecode<TokenProps>(jwtToken);
  const expirationTime = decoded.exp;
  const now = new Date().getTime() / 1000;
  const expired = now > expirationTime;

  const storagedData = {
    expired,
    user: decoded.user,
  };

  return storagedData;
}
