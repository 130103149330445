export const Constants = {
  calculation: 'processando cálculo',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noForm: 'esta OS não possui Formulário',
  noSample: 'esta OS não possui amostra',
  sample: 'Amostra',
  previousStatus: 'voltar status',
  previousStatusName: 'Criação de Amostra',
  previousStatusText:
    'alterar o status da OS ** de Processando Cálculo para Criação de Amostra',
  queuePosition: 'lugar na fila: ',
  avmFailedHint:
    // eslint-disable-next-line max-len
    ' Volte o status da OS para criação de amostra e troque os elementos na busca automática ou adicione manualmente.',
  recalculate: 'recalcular',
  forceFactors: 'Forçar Fatores',
  name: 'nome',
  forceFactor: 'forçar fator',
  cancel: 'cancelar',
  confirm: 'confirmar',
  areaTooltip: 'fator obrigatório para cálculo',
};

export const forceFactorsData = [
  { index: 0, name: 'fator de setor censitário', value: 'Localização' },
  { index: 1, name: 'fator de padrão construtivo', value: 'Padrão' },
  { index: 2, name: 'fator de área', value: 'Área Construída' },
  { index: 3, name: 'fator de vaga de garagem', value: 'Vaga' },
  { index: 4, name: 'fator de idade', value: 'Idade Estimada' },
  {
    index: 5,
    name: 'fator de estado de preservação',
    value: 'Estado de Conservação',
  },
  { index: 6, name: 'fator de área do terreno', value: 'Terreno' },
];
