import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Fragment, useState } from 'react';

import { Company, ReportPerUser } from '../../../../api/companies/types';
import {
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
  IconSubArrowRightMS,
} from '../../../../constants/icons';
import {
  Constants,
  activitiesHeader,
} from '../../../../constants/userManagement';
import { convertDate } from '../../../../helpers';
import { StyledRow, TableCellHeader } from '../../styles';
import { StyledIcon, TableCellBody, TableCellCollapse } from './styles';

interface TableStyledProps {
  companiesData: Company[];
  companyId: number | undefined;
  setCompanyId: (value: number) => void | undefined;
  usersPerCompany: ReportPerUser[] | undefined;
  setUsersPerCompany: (value: ReportPerUser[] | undefined) => void;
  loadingUsers: boolean;
}

export function TableStyled({
  companiesData,
  companyId,
  setCompanyId,
  usersPerCompany,
  setUsersPerCompany,
  loadingUsers,
}: TableStyledProps): JSX.Element {
  const [openCollapseId, setOpenCollapseId] = useState<number | null>(null);

  const handleCollapseToggle = (selectedCompanyId: number): void => {
    if (selectedCompanyId !== companyId) {
      setUsersPerCompany(undefined);
      setCompanyId(selectedCompanyId);
    }
    setOpenCollapseId((prevId) =>
      prevId === selectedCompanyId ? null : selectedCompanyId
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {activitiesHeader.map((cell) => (
              <TableCellHeader width={cell.width} key={cell.name}>
                {cell.name}
              </TableCellHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {companiesData.map((company) => (
            <Fragment key={company.id}>
              <StyledRow key={company.id}>
                <TableCellBody style={{ textAlign: 'left' }}>
                  <IconButton
                    sx={{ padding: '2px' }}
                    onClick={() => handleCollapseToggle(company.id)}
                  >
                    {openCollapseId === company.id
                      ? IconExpandMoreMS
                      : IconKeyboardArrowRightMS}
                  </IconButton>
                  {company.name}
                </TableCellBody>
                <TableCellBody>
                  {company.subscription_started_at
                    ? convertDate(company.subscription_started_at)
                    : Constants.notDone}
                </TableCellBody>
                <TableCellBody>
                  {company.last_access
                    ? convertDate(company.last_access)
                    : Constants.notDone}
                </TableCellBody>
                <TableCellBody>
                  {company.reports.finished_reports_count}
                </TableCellBody>
              </StyledRow>
              <TableCellBody colSpan={4} style={{ padding: 0 }}>
                <Collapse in={openCollapseId === company.id} timeout="auto">
                  {loadingUsers ? (
                    <Box width="100%" p={4}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <Table>
                      <TableHead>
                        {usersPerCompany &&
                          usersPerCompany?.map((user) => (
                            <TableRow key={user.id}>
                              <TableCellCollapse sx={{ textAlign: 'left' }}>
                                <span style={StyledIcon}>
                                  {IconSubArrowRightMS}
                                </span>
                                {user.responsible_name}
                              </TableCellCollapse>
                              <TableCellCollapse>
                                {user.accepted_invitation_at
                                  ? convertDate(user.accepted_invitation_at)
                                  : Constants.notDone}
                              </TableCellCollapse>
                              <TableCellCollapse>
                                {' '}
                                {user.last_access
                                  ? convertDate(user.last_access)
                                  : Constants.notDone}
                              </TableCellCollapse>
                              <TableCellCollapse>
                                {user.finished_reports_count}
                              </TableCellCollapse>
                            </TableRow>
                          ))}
                      </TableHead>
                    </Table>
                  )}
                </Collapse>
              </TableCellBody>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
