import { useContext, useState } from 'react';

import { getAllRooms } from '../api/rooms';
import { RoomData } from '../api/rooms/types';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';

interface UseRoomsProps {
  handleRooms: (id: number) => Promise<void>;
  rooms: RoomData[];
}

export function useRooms(): UseRoomsProps {
  const [rooms, setRooms] = useState<RoomData[]>([]);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleRooms = async (id: number): Promise<void> => {
    try {
      const response = await getAllRooms(id, 1, 3);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Não foi possível carregar os cômodos.');
      }

      if (response.data) {
        setRooms(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return {
    handleRooms,
    rooms,
  };
}
