import { useContext, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { deleteAllSample } from '../../../api/workOrders';
import { WhiteProgress } from '../../../components/UI/Typography';
import { IconDeleteMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  AlertTypography,
  ButtonBox,
  DeleteButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface DeleteSampleDialogProps {
  osId: number;
  reset: () => void;
}

export function DeleteSampleDialog({
  osId,
  reset,
}: DeleteSampleDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = async (): Promise<void> => {
    setLoadingDelete(true);
    try {
      const response = await deleteAllSample(osId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      reset();
      handleClose();
      setSnackbarMessage('Amostra excluída com sucesso!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setLoadingDelete(false);
    } catch (error) {
      handleClose();
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <DeleteButton onClick={handleOpen} disabled={loadingDelete}>
        {loadingDelete ? (
          <WhiteProgress size={22} />
        ) : (
          <>{IconDeleteMS} excluir amostra</>
        )}
      </DeleteButton>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContainer>
          <TextTypography>
            Você tem certeza que deseja deletar todas as amostras?
          </TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>Cancelar</OutlinedButton>
            <FilledButton onClick={handleConfirm}>excluir</FilledButton>
          </ButtonBox>
          <AlertTypography>Essa ação não poderá ser desfeita.</AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
