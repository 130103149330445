export const Constants = {
  delivery: 'emissão de nota',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  uploadSlip: 'upload boleto',
  changeUploadSlip: 'alterar boleto',
  uploadInvoice: 'upload nota fiscal',
  changeUploadInvoice: 'alterar nota fiscal',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  noSample: 'esta OS não possui amostra',
  form: 'Formulário',
  noForm: 'esta OS não possui formulário',
  sample: 'Amostra',
  report: 'Relatório',
  noReport: 'Relatório não pode ser criado',
  confirmEdit: 'confirmar alterações',
  changeStatus:
    'alterar o status da OS ** de Entrega/Emissão de nota para Pagamento',
};
