/* eslint-disable max-len */
import { TableCellProps } from '@mui/material';

import {
  AvmInferenceData,
  HypothesisTestsData,
  VarianceAnalysisData,
} from '../../../../api/workOrders/types';
import { formatFloatNumber, formatReal } from '../../../../helpers';

interface TableValues {
  value: string | number;
  label?: string;
  bold?: boolean;
  width?: string;
  align?: TableCellProps['align'];
}

export const presentResultHeader = (
  tableData: AvmInferenceData
): TableValues[] => {
  return [
    {
      value: 'Valor Unitário Médio',
    },
    {
      value: formatReal(tableData.estimated_value_mean),
    },
    {
      value: 'Amplitude do Modelo',
    },
    {
      value: 'Valor Total Médio',
    },
    {
      value: formatReal(tableData.mean_total_value_estimate),
    },
  ];
};

export const presentResultData = (
  tableData: AvmInferenceData
): TableValues[][] => {
  return [
    [
      {
        label: '1',
        value: 'Valor Unitário Mínimo',
        bold: true,
      },
      {
        label: '2',
        value: formatReal(tableData.estimated_value_lower),
      },
      {
        label: '3',
        value: `${tableData.estimated_lower_pct.toFixed(2)}%`,
      },
      {
        label: '4',
        value: 'Valor Total Mínimo',
        bold: true,
      },
      {
        label: '5',
        value: formatReal(tableData.min_total_value_estimate),
      },
    ],
    [
      {
        label: '1',
        value: 'Valor Unitário Máximo',
        bold: true,
      },
      {
        label: '2',
        value: formatReal(tableData.estimated_value_upper),
      },
      {
        label: '3',
        value: `${tableData.estimated_upper_pct.toFixed(2)}%`,
      },
      {
        label: '4',
        value: 'Valor Total Máximo',
        bold: true,
      },
      {
        label: '5',
        value: formatReal(tableData.max_total_value_estimate),
      },
    ],
  ];
};

export const presentStatisticHeader = [
  {
    value: 'estatísticas de modelo',
  },
  {
    value: 'valor',
  },
];

export const presentStatistic = (data: AvmInferenceData): TableValues[] => {
  return [
    {
      label: 'coeficiente de correlação',
      value: formatFloatNumber(data.r),
      align: 'center',
    },
    {
      label: 'coeficiente de determinação',
      value: formatFloatNumber(data.rsquared),
      align: 'center',
    },
    {
      label: 'fisher-snedecor',
      value: formatFloatNumber(data.f),
      align: 'center',
    },
    {
      label: 'significância do modelo',
      value: formatFloatNumber(data.model_significance_pct),
      align: 'center',
    },
  ];
};

export const presentNormalityHeader = [
  {
    value: 'distribuição dos resíduos',
  },
  {
    value: 'curva normal',
  },
  {
    value: 'modelo',
  },
];

export const presentWasteNormality = (
  data: AvmInferenceData
): TableValues[][] => {
  return [
    [
      {
        value: 'entre -1s a 1s',
        bold: true,
      },
      { value: '68%' },
      { value: data.residuals_distribution[0] },
    ],
    [
      {
        value: 'entre -1,64s a 1,64s',
        bold: true,
      },
      { value: '90%' },
      { value: data.residuals_distribution[1] },
    ],
    [
      {
        value: 'entre -1,96s a 1,96s',
        bold: true,
      },
      { value: '95%' },
      { value: data.residuals_distribution[2] },
    ],
  ];
};

export const presentComplementaryHeader = [
  {
    value: 'variáveis e dados do modelo',
  },
  {
    value: 'quantidade',
  },
];

export const presentComplementaryInfo = (
  data: AvmInferenceData
): TableValues[] => {
  return [
    {
      label: 'total de variáveis',
      value: data.total_variables,
      align: 'center',
    },
    {
      label: 'variáveis utilizadas no modelo',
      value: data.model_total_variables,
      align: 'center',
    },
    {
      label: 'total de dados',
      value: data.all_data_count,
      align: 'center',
    },
    {
      label: 'dados utilizados no modelo',
      value: data.model_data_count,
      align: 'center',
    },
  ];
};

export const presentOutliers = (): TableValues[] => {
  return [
    {
      label: 'quantidade de outliers',
      value: 0,
      align: 'center',
    },
    {
      label: 'porcentagem de outliers',
      value: '0,00%',
      align: 'center',
    },
  ];
};

export const presentVarianceHeader = [
  {
    value: 'fonte de variação',
  },
  {
    value: 'soma dos quadrados',
  },
  {
    value: 'graus de liberdade',
  },
  {
    value: 'quadrado médio',
  },
  {
    value: 'F',
  },
];

export const presentVarianceData = (
  data: VarianceAnalysisData
): TableValues[] => {
  return [
    {
      label: '1',
      value: data.source,
      bold: true,
    },
    {
      label: '2',
      value: data.sum_of_squares,
    },
    {
      label: '3',
      value: data.degrees_of_freedom,
    },
    {
      label: '4',
      value: data.squares_mean ? formatFloatNumber(data.squares_mean) : '',
    },
    {
      label: '5',
      value: data.f ? formatFloatNumber(data.f) : '',
    },
  ];
};

export const presentHypothesisHeader = [
  {
    value: 'variáveis',
  },
  {
    value: 'transf.',
  },
  {
    value: 't obs.',
  },
  {
    value: 'sig. (%)',
  },
];

export const presentHypothesisData = (
  data: HypothesisTestsData
): TableValues[] => {
  return [
    {
      label: '1',
      value: data.variable ? data.variable : '',
      bold: true,
    },
    {
      label: '2',
      value: data.transformation,
    },
    {
      label: '3',
      value: formatFloatNumber(data.t_obs),
    },
    {
      label: '4',
      value: formatFloatNumber(data.significance),
    },
  ];
};

export const presentCorrHeader = (data: string): TableValues[] => {
  return [
    {
      label: '1',
      value: data,
    },
    {
      label: '2',
      value: 'isoladas',
    },
  ];
};

export const presentFundamentationHeader = [
  {
    label: 'item',
  },
  {
    label: 'descrição',
  },
  {
    label: 'grau III',
  },
  {
    label: 'grau II',
  },
  {
    label: 'grau I',
  },
  {
    label: 'pontos',
  },
];

export const presentLinearRegression = [
  {
    item: 1,
    description: 'Caracterização do imóvel avaliando',
    III: 'Completa quanto a todas as variáveis analisadas',
    II: 'Completa quanto às variáveis utilizadas no modelo',
    I: 'Adoção de situação paradigma',
  },
  {
    item: 2,
    description:
      'Quantidade mínima de dados de mercado, efetivamente utilizados',
    III: '6 (k+1), onde k é o número de variáveis independentes',
    II: '4 (k+1), onde k é o número de variáveis independentes',
    I: '3 (k+1), onde k é o número de variáveis independentes',
  },
  {
    item: 3,
    description: 'Identificação dos dados de mercado',
    III: 'Apresentação de informações relativas a todos os dados e variáveis analisados na modelagem, com foto e características observadas pelo autor do laudo',
    II: 'Apresentação de informações relativas a todos os dados e variáveis analisados na modelagem',
    I: 'Apresentação de informações relativas aos dados e variáveis efetivamente utilizados no modelo',
  },
  {
    item: 4,
    description: 'Extrapolação',
    III: 'Não admitida',
    II: 'Admitida para apenas uma variável, desde que: a) as medidas das características do imóvel avaliando não sejam superiores a 100% do limite amostral superior, nem inferiores à metade do limite amostral inferior, b) o valor estimado não ultrapasse 15% do valor calculado no limite da fronteira amostral, para a referida variável',
    I: 'Admitida, desde que: a) as medidas das características do imóvel avaliando não sejam superiores a 100 % do limite amostral superior, nem inferiores à metade do limite amostral inferior b) o valor estimado não ultrapasse 20 % do valor calculado no limite da fronteira amostral, para as referidas variáveis, de per si e simultaneamente, e em módulo',
  },
  {
    item: 5,
    description:
      'Nível de significância máximo para a rejeição da hipótese nula de cada regressor',
    III: '10%',
    II: '20%',
    I: '30%',
  },
  {
    item: 6,
    description:
      'Nível de significância máximo para a rejeição da hipótese nula do modelo através do teste F de Snedecor',
    III: '1%',
    II: '2%',
    I: '5%',
  },
];

export const presentScoresData = (): TableValues[][] => {
  return [
    [
      {
        label: '1',
        value: 'graus',
      },
      {
        label: '2',
        value: 'III',
      },
      {
        label: '3',
        value: 'II',
      },
      {
        label: '4',
        value: 'I',
      },
    ],
    [
      {
        label: '1',
        value: 'pontos mínimos',
      },
      {
        label: '2',
        value: 16,
      },
      {
        label: '3',
        value: 10,
      },
      {
        label: '4',
        value: 6,
      },
    ],
    [
      {
        label: '1',
        value: 'itens obrigatórios',
      },
      {
        label: '2',
        value: '2, 4, 5 e 6 no grau III e os demais no mínimo grau II',
      },
      {
        label: '3',
        value: '2, 4, 5 e 6 no mínimo grau II e os demais no grau I',
      },
      {
        label: '4',
        value: 'todos, no mínimo no grau I',
      },
    ],
  ];
};

export const presentPrecisionData = (data: string): TableValues[] => {
  return [
    {
      label: '1',
      value:
        'Amplitude do intervalo de confiança de 80% em torno da estimativa de tendência central',
    },
    {
      label: '2',
      value: '≤ 30%',
    },
    {
      label: '3',
      value: '≤ 40%',
    },
    {
      label: '4',
      value: '≤ 50%',
    },
    {
      label: '5',
      value: data,
    },
  ];
};
