/* eslint-disable react/require-default-props */
import { Grid, Typography } from '@mui/material';

import {
  convertConcept,
  convertPropertyType,
  convertRegistrationUf,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { IconDownloadMS } from '../../../constants/icons';
import { Constants } from './constants';
import {
  ButtonBox,
  ButtonContainer,
  ButtonTitle,
  DownloadButton,
  InputTitle,
  StyledInput,
} from './styles';

interface PropertyDataProps {
  propertyData?: WorkOrderData;
}

export function PropertyData({ propertyData }: PropertyDataProps): JSX.Element {
  const registerFileVerify =
    propertyData?.real_estate_registry === null ||
    !propertyData?.real_estate_registry;
  const iptuFileVerify =
    propertyData?.iptu_document === null || !propertyData?.iptu_document;
  const downloadFile = (dataLink: string, name: string): void => {
    const linkSource = dataLink;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  };

  return (
    <>
      <Typography>{Constants.downloadText}</Typography>
      <ButtonContainer>
        <ButtonBox>
          <ButtonTitle>{Constants.propertyRegister}</ButtonTitle>
          <DownloadButton
            disabled={registerFileVerify}
            onClick={() => {
              if (propertyData?.real_estate_registry) {
                downloadFile(propertyData.real_estate_registry, 'matricula');
              }
            }}
          >
            {IconDownloadMS}
            {Constants.download}
          </DownloadButton>
        </ButtonBox>
        <ButtonBox>
          <ButtonTitle>{Constants.iptu}</ButtonTitle>
          <DownloadButton
            disabled={iptuFileVerify}
            onClick={() => {
              if (propertyData?.iptu_document) {
                downloadFile(propertyData.iptu_document, 'iptu');
              }
            }}
          >
            {IconDownloadMS}
            {Constants.download}
          </DownloadButton>
        </ButtonBox>
      </ButtonContainer>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <InputTitle>{Constants.registerNumber}</InputTitle>
          <StyledInput>{propertyData?.registration_number}</StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.propertyType}</InputTitle>
          <StyledInput>
            {propertyData && convertPropertyType(propertyData.real_estate_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.concept}</InputTitle>
          <StyledInput>
            {propertyData && convertConcept(propertyData.communities_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.zone}</InputTitle>
          <StyledInput>{propertyData?.zone}</StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.judicialDistrict}</InputTitle>
          <StyledInput>{propertyData?.judicial_district}</StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.registrationUf}</InputTitle>
          <StyledInput>
            {propertyData && convertRegistrationUf(propertyData.uf)}
          </StyledInput>
        </Grid>
      </Grid>
    </>
  );
}
