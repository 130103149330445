/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { IconButton } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import {
  convertStatus,
  convertStatusUrl,
  Status,
  StatusCode,
} from '../../api/enumerations';
import { getAllWorkOrders, getTopWorkOrders } from '../../api/workOrders';
import { GetAllWorkOrderDataProps } from '../../api/workOrders/types';
import { Search } from '../../components/Search';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/home';
import {
  IconAddMS,
  IconAssignmentMS,
  IconCheckCircleMS,
  IconCloseMS,
  IconDesktopWindowsMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';
import { Column } from './Column';
import {
  GridContainer,
  OSButton,
  PipelineContainer,
  ScrollTypography,
  StyledToast,
  TitleBox,
  TitleTypography,
  ToastTypography,
  TVMode,
} from './styles';

export function Home(): JSX.Element {
  const [defaultData, setDefaultData] = useState<GetAllWorkOrderDataProps>();
  const [pipelineData, setPipelineData] = useState<GetAllWorkOrderDataProps>();
  const [searchField, setSearchField] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();
  const decoded = useStoragedJwt();
  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );

  const getTopWorkOrdersCallback = useCallback(async () => {
    setLoading(true);
    try {
      const topWorkOrders = await getTopWorkOrders();

      if (topWorkOrders.detail.description) {
        throw new Error(topWorkOrders.detail.description);
      }

      if (topWorkOrders.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (topWorkOrders.data) {
        setDefaultData(topWorkOrders.data);
        setPipelineData(topWorkOrders.data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const messageData = lastMessage ? JSON.parse(lastMessage.data) : null;
    if (messageData) {
      setToastMessage(
        `OS ${messageData.payload.reference_number} finalizou o cálculo.`
      );
      setOpenToast(true);
    }
    getTopWorkOrdersCallback();
  }, [getTopWorkOrdersCallback, lastMessage]);

  const getWorkOrdersBySearchField = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllWorkOrders(searchField);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setPipelineData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [searchField]);

  useEffect(() => {
    if (searchField.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        getWorkOrdersBySearchField();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    return setPipelineData(defaultData);
  }, [searchField]);

  const pipelineColumn = [
    {
      name: convertStatus(Status.ENTRANCE),
      workOrders: pipelineData?.[Status.ENTRANCE],
      link: convertStatusUrl(Status.ENTRANCE),
      status: Status.ENTRANCE,
    },
    {
      name: convertStatus(Status.PROPOSAL),
      workOrders: pipelineData?.[Status.PROPOSAL],
      link: convertStatusUrl(Status.PROPOSAL),
      status: Status.PROPOSAL,
    },
    {
      name: convertStatus(Status.CONTRACT),
      workOrders: pipelineData?.[Status.CONTRACT],
      link: convertStatusUrl(Status.CONTRACT),
      status: Status.CONTRACT,
    },
    {
      name: convertStatus(Status.PEPT),
      workOrders: pipelineData?.[Status.PEPT],
      link: convertStatusUrl(Status.PEPT),
      status: Status.PEPT,
    },
    {
      name: convertStatus(Status.SCHEDULE),
      workOrders: pipelineData?.[Status.SCHEDULE],
      link: convertStatusUrl(Status.SCHEDULE),
      status: Status.SCHEDULE,
    },
    {
      name: convertStatus(Status.INSPECTION),
      workOrders: pipelineData?.[Status.INSPECTION],
      link: convertStatusUrl(Status.INSPECTION),
      status: Status.INSPECTION,
    },
    {
      name: convertStatus(Status.SAMPLE_CREATION),
      workOrders: pipelineData?.[Status.SAMPLE_CREATION],
      link: convertStatusUrl(Status.SAMPLE_CREATION),
      status: Status.SAMPLE_CREATION,
    },
    {
      name: convertStatus(Status.CALCULATION),
      workOrders: pipelineData?.[Status.CALCULATION],
      link: convertStatusUrl(Status.CALCULATION),
      status: Status.CALCULATION,
    },
    {
      name: convertStatus(Status.REPORT),
      workOrders: pipelineData?.[Status.REPORT],
      link: convertStatusUrl(Status.REPORT),
      status: Status.REPORT,
    },
    {
      name: convertStatus(Status.REVISION),
      workOrders: pipelineData?.[Status.REVISION],
      link: convertStatusUrl(Status.REVISION),
      status: Status.REVISION,
    },
    {
      name: convertStatus(Status.DELIVERY),
      workOrders: pipelineData?.[Status.DELIVERY],
      link: convertStatusUrl(Status.DELIVERY),
      status: Status.DELIVERY,
    },
    {
      name: convertStatus(Status.PAYMENT),
      workOrders: pipelineData?.[Status.PAYMENT],
      link: convertStatusUrl(Status.PAYMENT),
      status: Status.PAYMENT,
    },
    {
      name: convertStatus(Status.ARCHIVED),
      workOrders: pipelineData?.[Status.ARCHIVED],
      link: convertStatusUrl(Status.ARCHIVED),
      status: Status.ARCHIVED,
    },
  ];

  const handleNewOs = (): void => {
    navigate('/home/property/new');
  };

  const switchToPresentationMode = (): void => {
    navigate('/home/presentation-mode');
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>
          {IconAssignmentMS}
          {Constants.title}
        </TitleTypography>
        <OSButton onClick={handleNewOs}>
          {IconAddMS}
          {Constants.buttonAdd}
        </OSButton>
        <TVMode onClick={switchToPresentationMode}>
          {IconDesktopWindowsMS}
          {Constants.TVMode}
        </TVMode>
        <Search setSearchField={setSearchField} />
      </TitleBox>
      <PipelineContainer>
        {pipelineColumn?.map((column) => (
          <Column
            key={column.name}
            columnData={column}
            pipelineLoading={loading}
            isSearching={searchField.length > 0}
          />
        ))}
      </PipelineContainer>
      <ScrollTypography>-</ScrollTypography>
      {openSnackbar && <Snackbar />}
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconCheckCircleMS}
            {toastMessage}
          </ToastTypography>
        }
      />
    </GridContainer>
  );
}
