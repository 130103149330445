import { useState } from 'react';

import { Constants } from '../../../../constants/forms';
import { IconDeleteMS } from '../../../../constants/icons';
import {
  AlertTypography,
  ButtonBox,
  DialogButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface DeleteDialogProps {
  modalCallback: () => void;
}

export default function DeleteDialog({
  modalCallback,
}: DeleteDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = (): void => {
    modalCallback();
    handleClose();
  };

  return (
    <>
      <DialogButton onClick={handleOpen}>
        {IconDeleteMS}
        {Constants.delete}
      </DialogButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete form dialog"
      >
        <DialogContainer>
          <TextTypography>{Constants.deleteForm}</TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>
              {Constants.no}
            </OutlinedButton>
            <FilledButton onClick={handleConfirm}>{Constants.yes}</FilledButton>
          </ButtonBox>
          <AlertTypography>{Constants.deleteAlert}</AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
