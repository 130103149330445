import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    padding: '60px 80px',
    minWidth: '760px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const SectionTitle = styled(Typography)(() => ({
  fontSize: '36px',
  fontWeight: '600',
  color: '#004D43',
}));

export const SectionSubtitle = styled(Typography)(() => ({
  fontSize: '16px',
  margin: '60px 0',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '480px',
  margin: '20px 0',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '80px 0 40px',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '220px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '220px',
  backgroundColor: '#004D43',
  textTransform: 'uppercase',
  borderRadius: '16px',

  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
