import {
  Box,
  Button,
  Dialog,
  Table,
  TableCell,
  TableCellProps,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    minWidth: '680px',
    padding: '32px 52px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '800px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '900px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogButton = styled(Button)(() => ({
  width: '200px',
  backgroundColor: '#FAFCFF',
  fontWeight: 600,
  fontSize: 15,
  border: '2px solid #00B39B',
  color: '#00B39B',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '0 0 20px 0',
  textTransform: 'capitalize',
  color: '#707070',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'right',
  marginTop: '32px',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '220px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '220px',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '0 !important',
  },
}));

export const BoldCell = styled(TableCell)(() => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  backgroundColor: '#FAFCFF',
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth }) => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  minWidth: minwidth,
}));

export const CellContent = styled(TableCell)(() => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  color: '#707070',
  backgroundColor: '#FAFCFF',
}));

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'collapse',
}));

export const RowHeader = styled(TableRow)(() => ({
  backgroundColor: '#EDEFF2',
}));
