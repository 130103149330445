import {
  Box,
  ButtonBase,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '40px 0',
  textTransform: 'capitalize',
  '& span': { color: '#00B39B' },
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '24px',
  marginTop: '32px',
}));

export const RelativeBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '160px',
  width: '250px',
  [theme.breakpoints.down('xl')]: {
    width: '220px',
  },
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '100%',
  height: '115px',
  borderRadius: '12px',
  backgroundSize: 'cover',
  filter: 'brightness(70%)',
}));

export const StyledCardText = styled(Typography)(() => ({
  width: '100%',
  height: '100%',
  top: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  fontSize: '24px',
  color: '#FAFCFF',
  fontWeight: '600',
  padding: '0 4px',
  textAlign: 'center',
}));

export const AddRoomBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  fontSize: '24px',
  color: '#FAFCFF',
  fontWeight: '600',
  top: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  '& span': { display: 'none' },
  '&:hover': {
    backgroundColor: '#59595966',
    borderRadius: '12px',
    '& span': {
      display: 'block',
    },
  },
}));

export const AddRoomButton = styled(ButtonBase)(() => ({
  backgroundColor: '#06DFC2',
  borderRadius: '50px',
  '& span': {
    padding: '10px',
    fontSize: '24px',
    fontWeight: '600',
  },
}));

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const QuantityButton = styled(ButtonBase)(() => ({
  fontFamily: '"Montserrat", "Roboto"',
  margin: '-35px 6px 0',
  zIndex: '10',
  backgroundColor: '#91CDC0',
  borderRadius: '50%',
  padding: '4px',
  color: '#EDEFF2',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '600',
  cursor: 'pointer',
  position: 'absolute',
  fontSize: 20,
  right: 0,
  span: {
    display: 'none',
  },
  ':hover': {
    span: {
      display: 'block',
    },
    p: {
      display: 'none',
    },
  },
}));
