/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Grid, Slider, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import {
  EvaluationType,
  PropertyType,
  convertClientKind,
  convertEvaluationType,
  convertPropertyType,
} from '../../api/enumerations';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { Rooms } from '../../components/Sections/Rooms';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  ButtonBox,
  CancelOsBox,
  FlexBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCloseMS,
  IconDownloadMS,
  IconEditMS,
  IconEditNoteMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
  IconPhotoLibraryMS,
  IconSearchMS,
  IconUploadMS,
} from '../../constants/icons';
import { Constants } from '../../constants/sampleCreation';
import {
  selectConcept,
  selectConservation,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
  selectStandard400K,
} from '../../constants/selectOptions';
import { GlobalContext } from '../../context/global';
import { convertFloatDot } from '../../helpers';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import AddElement from './AddElement';
import { DeleteSampleDialog } from './DeleteSampleDialog';
import { Sample } from './Sample';
import {
  ApproveButton,
  BackButton,
  ButtonContainer,
  ButtonDownloadBox,
  ButtonTitle,
  CloseIcon,
  ConfirmButton,
  ConservationButton,
  DialogBox,
  DialogTypography,
  DownloadButton,
  EditButton,
  ExtrapolationsErrors,
  InputTitle,
  SampleButton,
  SectionBox,
  SectionTitle,
  SliderGrid,
  SmallStyledInput,
  StyledDialog,
  StyledGrid,
  StyledInput,
  StyledTextField,
  SubmitBox,
  UploadButton,
} from './styles';
import useSampleCreation from './useSampleCreation';

export function SampleCreation(): JSX.Element {
  const { openSnackbar } = useContext(GlobalContext);

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { osId, navigateHome, handleDownloadFile } = useGeneral();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const {
    propertyData,
    disableInfo,
    age,
    setAge,
    handleConservationEstimate,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    handleApproveConservation,
    handleSearchSample,
    loading,
    sampleData,
    setSampleData,
    completeElements,
    incompleteElements,
    pinPlace,
    showMap,
    showModal,
    evaluationTypeIsFactors,
    checkApprovedSample,
    updateSample,
    minElements,
    disapprovedElements,
    setDisapprovedElements,
    supplyFactor,
    setSupplyFactor,
    rooms,
    reset,
    extrapolationsErrors,
    showAddressModal,
    setShowAddressModal,
    loadingPage,
    enableEditing,
    setEnableEditing,
    client,
    setClient,
    solicitor,
    setSolicitor,
    goal,
    setGoal,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    handleFileUpload,
    registerFileName,
    iptuFileName,
    registerNumber,
    setRegisterNumber,
    concept,
    setConcept,
    zone,
    setZone,
    judicialDistrict,
    setJudicialDistrict,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    toilets,
    setToilets,
    suites,
    setSuites,
    handleSubmit,
    submitLoading,
    downloadIptu,
    downloadRegister,
    page,
    setPage,
    lastElementEdited,
    setLastElementEdited,
    estimatedConservation,
    isSupplyEditionDisabled,
    setIsSupplyEditionDisabled,
    handleNewSupplyFactor,
    approvedSampleLoading,
    maxRadius,
    setMaxRadius,
    parkingError,
  } = useSampleCreation();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
  ]);

  useEffect(() => {
    setExpandThree(true);
    setExpandSeven(true);
  }, []);

  useEffect(() => {
    if (lastElementEdited) {
      setPage(lastElementEdited);
      setLastElementEdited(undefined);
    }
  }, [sampleData]);

  const factorsMax = 12;
  const inferenceMax = 70;

  const showCompleteSampleButton = (): boolean => {
    if (!sampleData) return false;

    if (!evaluationTypeIsFactors && sampleData.length < inferenceMax)
      return true;

    if (evaluationTypeIsFactors && sampleData.length < factorsMax) return true;

    return false;
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="sample" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.sampleCreation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                <CancelOsBox>
                  <ConfirmationDeleteDialog
                    iconCancel
                    title={Constants.cancelOs}
                    text={Constants.cancelOsText}
                    modalCallback={handleCancelWorkOrder}
                  />
                </CancelOsBox>
                <EditButton onClick={() => setEnableEditing(!enableEditing)}>
                  {IconEditNoteMS}
                  {enableEditing ? Constants.cancelEdit : Constants.edit}
                </EditButton>
              </FlexBox>
              <AllAttachments propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            {enableEditing ? (
              <SectionBox>
                <SectionTitle>
                  {IconPersonMS}
                  {Constants.clientData}
                </SectionTitle>
                <Grid container spacing={4}>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      required
                      id="client"
                      label="proprietário do imóvel"
                      value={client}
                      setValue={setClient}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      id="solicitor"
                      label="solicitante"
                      value={solicitor}
                      setValue={setSolicitor}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <SelectTextField
                      id="os-goal"
                      label="objetivo"
                      value={goal}
                      setValue={setGoal}
                      selectOptions={selectReportGoal()}
                    />
                  </Grid>
                  <Grid item xs={6} xl={3}>
                    <InputTitle>{Constants.evaluationType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertEvaluationType(propertyData.evaluation_type)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <InputTitle>{Constants.clientType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertClientKind(propertyData.client_kind)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="os-function"
                      label="finalidade"
                      value={propertyFunction}
                      setValue={setPropertyFunction}
                      selectOptions={selectReportFunction()}
                    />
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="property-use"
                      label="uso do imóvel"
                      value={propertyUse}
                      setValue={setPropertyUse}
                      selectOptions={selectPropertyUse()}
                    />
                  </Grid>
                </Grid>
              </SectionBox>
            ) : (
              <ClientData propertyData={propertyData} />
            )}
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <>
                      <Typography>{Constants.downloadText}</Typography>
                      <ButtonContainer>
                        <ButtonDownloadBox>
                          <ButtonTitle>
                            {Constants.propertyRegister}
                          </ButtonTitle>
                          {downloadRegister.length > 0 ? (
                            <DownloadButton
                              onClick={() => {
                                if (downloadRegister)
                                  handleDownloadFile(
                                    downloadRegister,
                                    'matricula'
                                  );
                              }}
                            >
                              {IconDownloadMS}
                              {Constants.download}
                            </DownloadButton>
                          ) : (
                            <>
                              <label htmlFor="uploadRegister">
                                <input
                                  accept="image/*,application/pdf"
                                  id="uploadRegister"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={async (
                                    e: ChangeEvent<HTMLInputElement>
                                  ) => handleFileUpload(e, 'register')}
                                />
                                <UploadButton
                                  variant="contained"
                                  component="span"
                                >
                                  {IconUploadMS}
                                  {Constants.upload}
                                </UploadButton>
                              </label>
                              {registerFileName && (
                                <Box mt={1}>{registerFileName}</Box>
                              )}
                            </>
                          )}
                        </ButtonDownloadBox>
                        <ButtonDownloadBox>
                          <ButtonTitle>{Constants.iptu}</ButtonTitle>
                          {downloadIptu.length > 0 ? (
                            <DownloadButton
                              onClick={() => {
                                if (downloadIptu)
                                  handleDownloadFile(downloadIptu, 'iptu');
                              }}
                            >
                              {IconDownloadMS}
                              {Constants.download}
                            </DownloadButton>
                          ) : (
                            <>
                              <label htmlFor="uploadIptu">
                                <input
                                  accept="image/*,application/pdf"
                                  id="uploadIptu"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={async (
                                    e: ChangeEvent<HTMLInputElement>
                                  ) => handleFileUpload(e, 'iptu')}
                                />
                                <UploadButton
                                  variant="contained"
                                  component="span"
                                >
                                  {IconUploadMS}
                                  {Constants.upload}
                                </UploadButton>
                              </label>
                              {iptuFileName && <Box mt={1}>{iptuFileName}</Box>}
                            </>
                          )}
                        </ButtonDownloadBox>
                      </ButtonContainer>
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="register-number"
                            label="nº da matrícula"
                            value={registerNumber}
                            setValue={setRegisterNumber}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputTitle>{Constants.propertyType}</InputTitle>
                          <StyledInput>
                            {propertyData &&
                              convertPropertyType(
                                propertyData.real_estate_kind
                              )}
                          </StyledInput>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="concept"
                            label="conceito do espaço"
                            value={concept}
                            setValue={setConcept}
                            selectOptions={selectConcept()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericTextField
                            id="zone"
                            label="ofício/zona"
                            suffix=""
                            maxLength={12}
                            value={zone}
                            setValue={setZone}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="judicial-district"
                            label="comarca"
                            value={judicialDistrict}
                            setValue={setJudicialDistrict}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="registration-uf"
                            label="UF"
                            value={registrationUf}
                            setValue={setRegistrationUf}
                            selectOptions={selectRegistrationUf()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && (
                  <Grid container spacing={4}>
                    <Grid item xs={2}>
                      <InputTitle>
                        {propertyData?.real_estate_kind ===
                        PropertyType.APARTMENT
                          ? Constants.totalArea
                          : Constants.landArea}
                      </InputTitle>
                      <SmallStyledInput>
                        {propertyData?.total_area}
                      </SmallStyledInput>
                    </Grid>
                    <Grid item xs={2}>
                      <InputTitle>
                        {propertyData?.real_estate_kind ===
                        PropertyType.APARTMENT
                          ? Constants.privateArea
                          : Constants.constructedArea}
                      </InputTitle>
                      <SmallStyledInput>
                        {propertyData?.constructed_area}
                      </SmallStyledInput>
                    </Grid>
                    {enableEditing ? (
                      <>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="rooms"
                            label="quartos"
                            suffix=" quartos"
                            maxLength={12}
                            value={propertyRooms}
                            setValue={setPropertyRooms}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="toilets"
                            label="banheiros"
                            suffix=" banheiros"
                            maxLength={13}
                            value={toilets}
                            setValue={setToilets}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="suites"
                            label="suítes"
                            suffix=" suítes"
                            maxLength={10}
                            value={suites}
                            setValue={setSuites}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.rooms}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.bedrooms}
                          </SmallStyledInput>
                        </Grid>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.toilets}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.bathrooms}
                          </SmallStyledInput>
                        </Grid>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.suites}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.suites}
                          </SmallStyledInput>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={2}>
                      <InputTitle>{Constants.parkingLots}</InputTitle>
                      <SmallStyledInput>
                        {propertyData?.parking_spaces}
                      </SmallStyledInput>
                    </Grid>
                    <Grid item xs={3.5}>
                      <NumericTextField
                        disable={disableInfo}
                        id="property-age-estimate"
                        label="idade do imóvel"
                        suffix=" anos"
                        maxLength={9}
                        value={age}
                        setValue={setAge}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ConservationButton
                        disabled={disableInfo}
                        onClick={handleConservationEstimate}
                      >
                        {Constants.estimateConservation}
                      </ConservationButton>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={3.5}>
                      <SelectTextField
                        disable={disableInfo}
                        id="conservation-age-estimate"
                        label="estado de conservação do imóvel"
                        value={conservation}
                        setValue={setConservation}
                        selectOptions={selectConservation()}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectTextField
                        disable={disableInfo}
                        id="standard-age-estimate"
                        label="padrão construtivo"
                        value={buildingStandard}
                        setValue={setBuildingStandard}
                        selectOptions={
                          propertyData?.sample_sell_price_above_400k
                            ? selectStandard400K()
                            : selectStandard()
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ApproveButton
                        disabled={disableInfo || !estimatedConservation}
                        onClick={handleApproveConservation}
                      >
                        {disableInfo ? Constants.approved : Constants.approve}
                      </ApproveButton>
                      {!estimatedConservation && (
                        <Typography sx={{ fontSize: '9px', marginLeft: '5px' }}>
                          {Constants.approveSubtitle}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.samples}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={1.5}>
                        <NumericFormat
                          customInput={StyledTextField}
                          label="fator oferta"
                          id="supply-offer"
                          className="supply-offer"
                          color="secondary"
                          suffix=" %"
                          decimalSeparator="."
                          decimalScale={2}
                          allowNegative={false}
                          inputProps={{ maxLength: 7 }}
                          value={supplyFactor}
                          onChange={(e) => {
                            setSupplyFactor(convertFloatDot(e.target.value));
                          }}
                          disabled={isSupplyEditionDisabled}
                        />
                      </Grid>
                      {sampleData ? (
                        <>
                          <Grid item xs={3.5} xl={2.5}>
                            {isSupplyEditionDisabled ? (
                              <SampleButton
                                onClick={() =>
                                  setIsSupplyEditionDisabled(false)
                                }
                              >
                                {IconEditMS}
                                {Constants.editSupply}
                              </SampleButton>
                            ) : (
                              <SampleButton onClick={handleNewSupplyFactor}>
                                {Constants.save}
                              </SampleButton>
                            )}
                          </Grid>
                          <SliderGrid item xs={3.5} xl={3}>
                            <Slider
                              aria-label="raio"
                              getAriaValueText={(v) => `${v} km`}
                              value={maxRadius}
                              step={0.5}
                              marks
                              min={0.5}
                              max={2.5}
                              onChange={(_, v) => setMaxRadius(v as number)}
                              disabled={!showCompleteSampleButton()}
                              sx={{ width: '70%' }}
                            />
                            <Typography mx="10px">{maxRadius} km</Typography>
                          </SliderGrid>
                          {showCompleteSampleButton() && (
                            <StyledGrid item xs={3.5} xl={2.5}>
                              <SampleButton
                                onClick={handleSearchSample}
                                disabled={loading}
                              >
                                {Constants.completeSample}
                              </SampleButton>
                            </StyledGrid>
                          )}
                          <StyledGrid
                            item
                            xs={showCompleteSampleButton() ? 12 : 3.5}
                            xl={showCompleteSampleButton() ? 2.5 : 5}
                          >
                            <DeleteSampleDialog reset={reset} osId={osId} />
                          </StyledGrid>
                        </>
                      ) : (
                        <>
                          <SliderGrid item xs={3.5} xl={3}>
                            <Slider
                              aria-label="raio"
                              getAriaValueText={(v) => `${v} km`}
                              value={maxRadius}
                              step={0.5}
                              marks
                              min={0.5}
                              max={2.5}
                              onChange={(_, v) => setMaxRadius(v as number)}
                              sx={{ width: '70%' }}
                            />
                            <Typography mx="10px">{maxRadius} km</Typography>
                          </SliderGrid>
                          <StyledGrid item xs={3}>
                            <SampleButton onClick={handleSearchSample}>
                              {IconSearchMS} {Constants.searchSamples}
                            </SampleButton>
                          </StyledGrid>
                        </>
                      )}
                    </Grid>
                    {!loading && extrapolationsErrors.length > 0 && (
                      <ExtrapolationsErrors>
                        {extrapolationsErrors}
                        <p>{Constants.extrapolation}</p>
                      </ExtrapolationsErrors>
                    )}
                    {loading ? (
                      <LoadingBox>
                        <CircularProgress />
                      </LoadingBox>
                    ) : (
                      sampleData &&
                      sampleData.length > 0 &&
                      propertyData &&
                      completeElements &&
                      incompleteElements && (
                        <Sample
                          disapprovedElements={disapprovedElements}
                          setDisapprovedElements={setDisapprovedElements}
                          propertyData={propertyData}
                          sampleData={sampleData}
                          setSampleData={setSampleData}
                          completeElements={completeElements}
                          incompleteElements={incompleteElements}
                          pinPlace={pinPlace}
                          showMap={showMap}
                          updateSample={updateSample}
                          minElements={minElements}
                          page={page}
                          setPage={setPage}
                          setLastElementEdited={setLastElementEdited}
                        />
                      )
                    )}
                    <AddElement
                      isDisabled={
                        sampleData && evaluationTypeIsFactors
                          ? sampleData?.length >= factorsMax
                          : sampleData?.length === inferenceMax
                      }
                      osNumber={osId}
                      updateSample={updateSample}
                      highPrice={propertyData?.sample_sell_price_above_400k}
                      propertyType={propertyData?.real_estate_kind}
                    />
                  </>
                )}
              </SectionBox>
              <SubmitBox>
                <PeptDialog
                  osId={osId}
                  referenceNumber={propertyData?.reference_number}
                />
                {enableEditing ? (
                  <ConfirmButton
                    form="sample"
                    type="submit"
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmEdit
                    )}
                  </ConfirmButton>
                ) : showModal ? (
                  <ConfirmationDialog
                    title={Constants.approve}
                    loading={loadingApprove}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={() => {
                      handleStatus(
                        osId,
                        propertyData?.reference_number,
                        Constants.snackbarMessage
                      );
                    }}
                    errorMessage={
                      parkingError ? Constants.parkingErrorMessage : undefined
                    }
                  />
                ) : (
                  <ConfirmButton
                    onClick={checkApprovedSample}
                    disabled={extrapolationsErrors.length > 0}
                  >
                    {approvedSampleLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmChanges
                    )}
                  </ConfirmButton>
                )}
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
      {showAddressModal && (
        <StyledDialog
          open={showAddressModal}
          onClose={() => setShowAddressModal(false)}
          aria-labelledby="address dialog"
        >
          <DialogBox>
            <ButtonBox>
              <CloseIcon onClick={() => setShowAddressModal(false)}>
                {IconCloseMS}
              </CloseIcon>
            </ButtonBox>
            <DialogTypography>{Constants.addressText}</DialogTypography>
          </DialogBox>
        </StyledDialog>
      )}
    </GridContainer>
  );
}
