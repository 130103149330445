import { Box, ButtonBase, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionRoomTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '26px',
  fontWeight: 600,
  marginBottom: '20px',
  color: '#707070',
  textTransform: 'capitalize',
  cursor: 'pointer',
  '& span': { color: '#00B39B' },
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  margin: '14px 0 52px 0',
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  objectFit: 'cover',
  width: '300px',
  height: '220px',
  borderRadius: '30px',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  position: 'relative',
}));

interface SelectCheckboxProps {
  ischecked?: 'true' | 'false';
}

export const SelectCheckbox = styled(ButtonBase)<SelectCheckboxProps>(
  ({ ischecked, theme }) => {
    const checked = ischecked && ischecked === 'true';
    return {
      width: 25,
      height: 25,
      borderRadius: '50%',
      textAlign: 'center',
      lineHeight: '50px',
      fontSize: 16,
      color: checked ? 'white' : 'black',
      transition: 'background-color 0.3s, border-color 0.3s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '15px',
      right: '15px',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      span: {
        fontWeight: 600,
        color: theme.palette.secondary.dark,
      },
      ...(checked && {
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
      }),
    };
  }
);
