export const Constants = {
  payment: 'pagamento',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  upload: 'upload comprovante de pagamento',
  changeUpload: 'alterar comprovante de pagamento',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noForm: 'esta OS não possui Formulário',
  noSample: 'esta OS não possui amostra',
  sample: 'Amostra',
  report: 'Cálculo automatizado',
  noReport: 'Relatório não pode ser criado',
  confirmEdit: 'confirmar alterações',
  changeStatus: 'alterar o status da OS ** de Pagamento para Arquivado',
};
