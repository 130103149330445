/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { IconSearchMS } from '../../../../../constants/icons';
import { InfoCompanyModal } from './InfoCompanyModal';
import map from './Map@2x.png';
import { Container, ImageContainer, SearchContainer } from './styles';

export function Map(): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Container>
      <SearchContainer>
        {IconSearchMS}
        <input placeholder="buscar no mapa" />
        <button type="button">buscar</button>
      </SearchContainer>
      <ImageContainer>
        <img
          src={map}
          alt="mapa da cidade de são paulo"
          onClick={() => setOpenModal(!openModal)}
        />
      </ImageContainer>
      <InfoCompanyModal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
      />
    </Container>
  );
}
