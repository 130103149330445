import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
  },
  zIndex: 1000,
}));

export const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseIcon = styled(IconButton)(() => ({
  margin: '20px 20px -50px 0',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '22px',
  marginBottom: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#1A1A1A',
  textAlign: 'start',
}));

export const OsButton = styled(ButtonBase)(() => ({
  width: 'fit-content',
  justifyContent: 'flex-start',
  fontSize: '18px',
  fontWeight: '600',
  color: '#00B39B',
  margin: '-4px',
  padding: '6px',
  borderRadius: '4px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const SpaceAroundBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '20px',
}));

export const EditButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '220px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const spanStyle = {
  fontWeight: '400',
};
