import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableGrid = styled(Grid)(() => ({
  maxWidth: '1000px',
  marginBottom: '60px',
}));

export const TableHeader = styled(Typography)(() => ({
  width: '100%',
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#00B39B',
  fontSize: '21px',
  fontWeight: '600',
  color: '#EDEFF2',
  borderRadius: '6px',
}));

export const TableCell = styled(Typography)(() => ({
  width: '100%',
  minHeight: '48px',
  padding: '0 20px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  border: '1px solid #707070',
  borderRadius: '6px',
  '& span': {
    fontSize: '16px',
  },
}));

export const TableInfo = styled(Typography)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '4px 20px',
  fontSize: '14px',
}));

export const CubTextField = {
  width: '100%',
  '& .MuiInputBase-root': {
    height: '48px',
    borderRadius: '6px',
  },
  '& .MuiInputBase-input': {
    padding: '8px 14px',
  },
};
