import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DownloadButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 260,
  },
}));
