/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import {
  deletePathology,
  getAllPathologies,
  getPathology,
  updatePathology,
} from '../../../../api/pathologies';
import {
  AllPathologiesData,
  PathologiesData,
} from '../../../../api/pathologies/types';
import { PathologiesFormControl } from '../../../../components/CustomInput';
import DeletePictureDialog from '../../../../components/DeletePictureDialog';
import { DialogTitle } from '../../../../components/UI/Typography';
import {
  IconAssignmentMS,
  IconCloseMS,
  IconDesignServicesMS,
  IconDownloadMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import {
  Constants,
  DefaultPathologyOptions,
} from '../../../../constants/imageDetails';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import {
  CloseIcon,
  DownloadButton,
  FlexEndBox,
  ImageCard,
  SectionSubtitle,
  StyledDialog,
  SubmitBox,
  SubmitButton,
} from './styles';

interface KindsData {
  kind_id: number;
}

interface PathologiesOptionsData {
  kind: number;
  name: string;
  checked: boolean;
}

interface ImageDetailsProps {
  isOpen: boolean;
  setOpenModal: (value: boolean) => void;
  pathologyId: number;
  isPictureNew: boolean;
  nextModalCallback: (value: number) => void;
  pictureId: number;
  setPathologies: (pathologies: AllPathologiesData[]) => void;
  edit: boolean;
}

export default function Pathologies({
  isOpen,
  setOpenModal,
  pathologyId,
  isPictureNew,
  nextModalCallback,
  pictureId,
  setPathologies,
  edit,
}: ImageDetailsProps): JSX.Element {
  const [pathologyData, setPathologyData] = useState<PathologiesData>();
  const [pathologyKinds, setPathologyKinds] = useState<KindsData[]>([]);
  const [pathologieOptions, setPathologieOptions] = useState<
    PathologiesOptionsData[]
  >(DefaultPathologyOptions);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getPathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPathologyData(response.data);

      if (response.data.kinds) {
        const kindsList = response.data.kinds;

        const updatedOptions = pathologieOptions.map((option) => {
          const mOption = option;
          mOption.checked = kindsList.some(
            (remoteKind) => remoteKind.kind_id === option.kind
          );
          return mOption;
        });

        setPathologieOptions(updatedOptions);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setOpenModal(false);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const updatePathologies = async (): Promise<void> => {
    try {
      const response = await getAllPathologies(pictureId, 1, 40);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Erro ao carregar as patologias.');
      }

      setPathologies(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleClose = (): void => {
    updatePathologies();
    setOpenModal(false);
  };

  const handleSubmitPathologiesKinds = async (): Promise<void> => {
    if (pathologyKinds.length < 1) {
      setSnackbarMessage('Selecione uma patologia!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    pathologyKinds.map((kind) =>
      formData.append('kinds', kind.kind_id.toString())
    );

    if (pathologyData) {
      try {
        const response = await updatePathology(pathologyData.id, formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Patologias adicionadas com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        handleClose();
        if (isPictureNew && pathologyData.next_id) {
          nextModalCallback(pathologyData.next_id);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        handleClose();
      }
    }
  };

  const handleDeletePathology = async (pathologyId: number): Promise<void> => {
    try {
      const response = await deletePathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      handleClose();
      if (isPictureNew && pathologyData?.next_id) {
        nextModalCallback(pathologyData.next_id);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleCheckboxChange = (checked: boolean, value: number): void => {
    if (checked) {
      const kindId = pathologieOptions.find((e) => {
        return e.kind === value;
      });

      if (kindId) {
        const newData = [
          ...pathologyKinds,
          {
            kind_id: value,
          },
        ];
        setPathologyKinds(newData);
      }
    }
  };

  return (
    <StyledDialog open={isOpen}>
      <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
      <DialogTitle>
        {IconDesignServicesMS}
        {Constants.pathologies}
      </DialogTitle>
      <SectionSubtitle>
        {IconPhotoLibraryMS}
        {Constants.roomPathologies}
      </SectionSubtitle>
      {pathologyData && (
        <FlexEndBox>
          <ImageCard
            image={pathologyData.file}
            title={pathologyData.name || ''}
          />
          <DownloadButton
            onClick={() =>
              handleDownloadFile(
                pathologyData.file,
                pathologyData.name || 'patologia'
              )
            }
          >
            {IconDownloadMS}
            {Constants.download}
          </DownloadButton>
        </FlexEndBox>
      )}
      <SectionSubtitle>
        {IconAssignmentMS}
        {Constants.photoPathologies}
      </SectionSubtitle>
      <Grid container spacing={1} sx={{ paddingBottom: !edit ? '60px' : '' }}>
        {pathologieOptions.map((data) => {
          return (
            <Grid item xs={3} key={data.kind}>
              <PathologiesFormControl
                label={data.name}
                value={data.kind}
                isChecked={data.checked}
                onChangeCallback={handleCheckboxChange}
                disabled={!edit}
              />
            </Grid>
          );
        })}
      </Grid>
      {edit && (
        <SubmitBox>
          {pathologyData && (
            <DeletePictureDialog
              outlinedButton
              modalCallback={handleDeletePathology}
              pictureId={pathologyData.id}
            />
          )}
          <SubmitButton onClick={handleSubmitPathologiesKinds}>
            {Constants.addPathologies}
          </SubmitButton>
        </SubmitBox>
      )}
    </StyledDialog>
  );
}
