/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { EvaluationType, Status } from '../../api/enumerations';
import { getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import { GetWorkOrder } from '../../api/workOrders';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { ReportErrorDialog } from '../../components/ReportErrorDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PdfViewer } from '../../components/Sections/PdfViewer';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconDescriptionMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/revision';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';
import { BackButton, DialogButton, SectionBox, SubmitBox } from './styles';

export function Revision(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();
  const { handleRooms, rooms } = useRooms();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
  ]);

  const getDataCallback = useCallback(async () => {
    let getSamples = false;
    let isFactors = false;

    try {
      const response = await GetWorkOrder(osId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data || response.data.status !== Status.REVISION) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const avmData = response.data.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;

      if (avmData) {
        setHasAvmReport(true);
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (
        response.data.evaluation_type === EvaluationType.AUTOFACTORS ||
        response.data.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      try {
        const response = await getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [osId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    setExpandNine(true);
    getDataCallback();
  }, [getDataCallback]);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.revision}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <AllAttachments propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.report}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && (
                  <Box>
                    {hasAvmReport && propertyData ? (
                      <ReportInfo propertyData={propertyData} />
                    ) : (
                      <Box>{Constants.noReport}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.pdfReport}
                  icon={IconDescriptionMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine && <PdfViewer osId={osId} />}
              </SectionBox>
              <SubmitBox>
                <DialogButton onClick={() => setOpenErrorReport(true)}>
                  {Constants.errorReport}
                </DialogButton>
                <ReportErrorDialog
                  open={openErrorReport}
                  setOpen={setOpenErrorReport}
                />
                <ConfirmationDialog
                  title={Constants.approve}
                  loading={loadingApprove}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={() =>
                    handleStatus(osId, propertyData?.reference_number)
                  }
                />
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
