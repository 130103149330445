import { Box, TextField, Typography, styled } from '@mui/material';

export const DateText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontSize: '24px',
  marginRight: '12px',
  span: {
    color: '#00B39B',
    fontWeight: 600,
    fontSize: '24px',
  },
  [theme.breakpoints.down('xl')]: {
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '18px',
    span: {
      paddingLeft: '4px',
      fontSize: '18px',
    },
  },
}));

export const CalendaContainer = styled(Box)(() => ({
  padding: '0 20px 30px 20px',
  '@media (max-height: 820px)': {
    marginTop: '-20px',
  },
}));

export const FiltersContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
}));

export const JustifyEnd = styled(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'end',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#00B39B',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiFormLabel-root': {
    color: '#595959 !important',
  },
}));

export const LoadingBox = styled(Box)(() => ({
  width: '100%',
  marginTop: 16,
}));
