import {
  Grid,
  Table,
  TableCell,
  TableCellProps,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(({ theme }) => ({
  border: '1px solid #D7D8DB',
  backgroundColor: theme.palette.common.white,
  padding: '32px',
  marginTop: '40px',
}));

export const GridTable = styled(Grid)(() => ({
  marginBottom: '40px',
}));

export const ReportTitle = styled(Typography)(({ theme }) => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  width: 'fit-content',
}));

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'collapse',
}));

export const RowCaption = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: '1px solid #8B8C8E',
}));

export const CellCaption = styled(TableCell)(() => ({
  paddingLeft: '40px',
  fontSize: '21px',
  fontWeight: '600',
  color: '#ECF0F6',
  textAlign: 'center',
}));

export const BoldCell = styled(TableCell)(() => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  backgroundColor: '#FAFCFF',
}));

export const RowHeader = styled(TableRow)(() => ({
  backgroundColor: '#EDEFF2',
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth }) => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  minWidth: minwidth,
}));

export const CellContent = styled(TableCell)(() => ({
  border: '1px solid #8B8C8E',
  padding: '8px',
  fontSize: '16px',
  color: '#707070',
  backgroundColor: '#FAFCFF',
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '0 !important',
  },
}));
