/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from '@mui/material';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';

import { StatusCode } from '../../api/enumerations';
import {
  firstAccessEmail,
  firstAccessToken,
  recoverPasswordEmail,
} from '../../api/resetPassword';
import logo from '../../assets/images/logo-realprice-login.png';
import { EmailIcon } from '../../components/InputIcon';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/firstAccess';
import { IconRefreshMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { validateEmail } from '../../helpers';
import useErrorMessage from '../../hooks/useErrorMessage';
import ChangePassword from './ChangePassword';
import {
  CancelButton,
  ContainerGrid,
  FlexBox,
  InputBox,
  InputTitle,
  LoginGrid,
  LogoGrid,
  LogoImg,
  SmallSubmitButton,
  StyledButtonBase,
  StyledPatternFormat,
  StyledTextField,
  SubmitButton,
  TimerTypography,
} from './styles';

export function FirstAccess(): JSX.Element {
  const [firstAccess, setFirstAccess] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [emailToken, setEmailToken] = useState('');
  const [disableNewToken, setDisableNewToken] = useState(true);
  const [tempToken, setTempToken] = useState('');
  const [seconds, setSeconds] = useState(63);
  const [openModal, setOpenModal] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('control-center-token');

  useEffect(() => {
    if (location.pathname.includes('/forgot-password')) {
      setFirstAccess(false);
    }
  }, []);

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      navigate('/home');
    }
    if (seconds === 0) {
      setDisableNewToken(false);
    }
    if (seconds < 61 && seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [navigate, token, seconds]);

  const handleSendEmail = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    if (location.pathname.includes('/forgot-password')) {
      try {
        const response = await recoverPasswordEmail(trimEmail);

        if (response.detail.status_code === StatusCode.NOT_FOUND) {
          setSnackbarMessage('Um código foi enviado para seu email.');
          setErrorMessage(false);
          setOpenSnackbar(true);
          setHasToken(true);
          setSeconds(60);
          return;
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setHasToken(true);
        setSeconds(60);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/login');
      }
    } else {
      try {
        const response = await firstAccessEmail(trimEmail);

        if (response.detail.status_code === StatusCode.NOT_FOUND) {
          setSnackbarMessage('Um código foi enviado para seu email.');
          setErrorMessage(false);
          setOpenSnackbar(true);
          setHasToken(true);
          setSeconds(60);
          return;
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setHasToken(true);
        setSeconds(60);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/login');
      }
    }
  };

  const handleRefreshToken = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    if (location.pathname.includes('/forgot-password')) {
      try {
        const response = await recoverPasswordEmail(trimEmail);

        if (response.detail.status_code === StatusCode.NOT_FOUND) {
          setSnackbarMessage('Um código foi enviado para seu email.');
          setErrorMessage(false);
          setOpenSnackbar(true);
          setDisableNewToken(true);
          setSeconds(60);
          return;
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setDisableNewToken(true);
        setSeconds(60);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/login');
      }
    } else {
      try {
        const response = await firstAccessEmail(trimEmail);

        if (response.detail.status_code === StatusCode.NOT_FOUND) {
          setSnackbarMessage('Um código foi enviado para seu email.');
          setErrorMessage(false);
          setOpenSnackbar(true);
          setDisableNewToken(true);
          setSeconds(60);
          return;
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setDisableNewToken(true);
        setSeconds(60);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/login');
      }
    }
  };

  const handleSendToken = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();
    const trimToken = emailToken.replace(/\s/g, '');

    try {
      const response = await firstAccessToken(trimEmail, trimToken);

      if (response.detail.status_code === StatusCode.NOT_FOUND) {
        throw new Error('Código inválido. Verifique e tente novamente');
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.token) {
        setTempToken(response.data.token);
        setOpenModal(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/login');
    }
  };

  return (
    <ContainerGrid container>
      <LogoGrid item xs={8}>
        <LogoImg image={logo} title="logo" />
      </LogoGrid>
      <LoginGrid item xs={4}>
        {hasToken ? (
          <InputBox component="form" id="token" onSubmit={handleSendToken}>
            <InputTitle>
              {firstAccess ? Constants.firstAccess : Constants.recoverPassword}
            </InputTitle>
            <PatternFormat
              format="### ###"
              mask="&#8212;"
              customInput={TextField}
              variant="standard"
              id="token-input"
              helperText="insira o código de 6 dígitos"
              color="secondary"
              value={emailToken}
              onChange={(e) => setEmailToken(e.target.value)}
              sx={StyledPatternFormat}
            />
            <TimerTypography>
              {Constants.timer}
              <br />
              {seconds}
              {Constants.seconds}
            </TimerTypography>
            <StyledButtonBase
              disableTouchRipple
              disabled={disableNewToken}
              onClick={handleRefreshToken}
            >
              {IconRefreshMS}
              <span>{Constants.newToken}</span>
            </StyledButtonBase>
            <SubmitButton form="token" type="submit" disabled={emailError}>
              {Constants.check}
            </SubmitButton>
          </InputBox>
        ) : (
          <InputBox
            component="form"
            id="first-access"
            onSubmit={handleSendEmail}
          >
            <InputTitle>
              {firstAccess ? Constants.firstAccess : Constants.recoverPassword}
            </InputTitle>
            <StyledTextField
              required
              id="email"
              variant="standard"
              InputProps={EmailIcon}
              placeholder={Constants.emailPlaceholder}
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                !validateEmail(e.target.value)
                  ? setEmailError(true)
                  : setEmailError(false);
              }}
            />
            <FlexBox>
              <CancelButton onClick={() => navigate('/login')}>
                {Constants.cancel}
              </CancelButton>
              <SmallSubmitButton
                form="first-access"
                type="submit"
                disabled={emailError}
              >
                {Constants.submit}
              </SmallSubmitButton>
            </FlexBox>
          </InputBox>
        )}
      </LoginGrid>
      {openModal && (
        <ChangePassword
          isOpen={openModal}
          setOpenModal={setOpenModal}
          tempToken={tempToken}
        />
      )}
      {openSnackbar && <Snackbar />}
    </ContainerGrid>
  );
}
