/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { QuestionType } from '../../../../api/enumerations';
import { ChoicesData, QuestionData } from '../../../../api/forms/types';
import {
  CustomFormControl,
  CustomTextField,
  SelectTextField,
} from '../../../../components/CustomInput';
import { Constants } from '../../../../constants/forms';
import {
  Icon123MS,
  IconAddMS,
  IconCalendarMonthMS,
  IconCheckBoxMS,
  IconCheckCircleMS,
  IconCloseMS,
  IconNotesMS,
  IconPlaylistAddMS,
  IconRadioButtonCheckedMS,
} from '../../../../constants/icons';
import { selectQuestionType } from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import { checkQuestionSelection } from '../../../../helpers';
import ChoiceInput from '../ChoiceInput';
import {
  FilledDarkButton,
  IconButtonBase,
  OutlinedDarkButton,
} from '../styles';
import {
  CloseButton,
  FilledButton,
  FlexButtonBox,
  FlexCenterGrid,
  OptionIcon,
  ShortAnswerTextField,
  StyledDialog,
  StyledTitle,
  TopBox,
} from './styles';

interface NewQuestionDialogProps {
  newQuestionId: number;
  questions: QuestionData[];
  setQuestions: (questions: QuestionData[]) => void;
  allQuestions: QuestionData[];
}

export default function NewQuestionDialog({
  newQuestionId,
  questions,
  setQuestions,
  allQuestions,
}: NewQuestionDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [options, setOptions] = useState<ChoicesData[]>([]);
  const [questionType, setQuestionType] = useState(0);
  const [requiredAnswer, setRequiredAnswer] = useState(false);
  const [addConditional, setAddConditional] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleDialog = (): void => setOpen(!open);

  useEffect(() => {
    if (options.length === 0) {
      if (
        questionType === QuestionType.SINGLE_SELECTION ||
        questionType === QuestionType.MULTIPLE_CHOICE ||
        questionType === QuestionType.LIST
      ) {
        const temp = {
          id: 1,
          title: '',
          jump_to: null,
        };
        setOptions([temp]);
      }
    }
  }, [questionType]);

  const clearData = (): void => {
    setTitle('');
    setOptions([]);
    setQuestionType(0);
    setRequiredAnswer(false);
    setAddConditional(false);
  };

  const handleNewId = (idArray: ChoicesData[]): number => {
    if (idArray.length === 0) {
      return 1;
    }
    const ids = idArray.map((item) => item.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  };

  const addNewOption = (): void => {
    if (
      questionType === QuestionType.SINGLE_SELECTION ||
      questionType === QuestionType.MULTIPLE_CHOICE ||
      questionType === QuestionType.LIST
    ) {
      const newOpt = {
        id: handleNewId(options),
        title: '',
        jump_to: null,
      };
      setOptions([...options, newOpt]);
    }
  };

  const handleQuestionChoices = (): ChoicesData[] => {
    if (questionType === QuestionType.SINGLE_SELECTION) {
      return options;
    }
    if (
      questionType === QuestionType.MULTIPLE_CHOICE ||
      questionType === QuestionType.LIST
    ) {
      return options.map((obj) =>
        obj.jump_to === null ? obj : { ...obj, jump_to: null }
      );
    }
    return [];
  };

  const handleSubmit = (): void => {
    if (!title || questionType === 0) {
      setSnackbarMessage('Preencha os dados obrigatórios!');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const newQuestion = {
      allow_multiple_selection: checkQuestionSelection(questionType),
      choices: handleQuestionChoices(),
      id: newQuestionId,
      required: requiredAnswer,
      title,
      type: questionType,
      answer: null,
      blocked: false,
      use_in_report: false,
    };

    setQuestions([...questions, newQuestion]);
    clearData();
    handleDialog();
  };

  return (
    <>
      <FilledButton onClick={handleDialog}>
        {Constants.newQuestion}
      </FilledButton>
      <StyledDialog
        open={open}
        onClose={handleDialog}
        aria-labelledby="nova pergunta"
      >
        <TopBox>
          <CloseButton onClick={handleDialog}>{IconCloseMS}</CloseButton>
        </TopBox>
        <StyledTitle>
          {IconPlaylistAddMS}
          {Constants.newQuestion}
        </StyledTitle>
        <FlexCenterGrid container spacing={3}>
          <Grid item xs={8}>
            <CustomTextField
              required
              id="question"
              label="pergunta"
              value={title}
              setValue={setTitle}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectTextField
              id="question-type"
              label="tipo de pergunta"
              value={questionType}
              setValue={setQuestionType}
              selectOptions={selectQuestionType()}
            />
          </Grid>
          {questionType === QuestionType.SELECT && <Grid item xs={12} />}
          {questionType === QuestionType.SHORT_ANSWER && (
            <FlexCenterGrid item xs={12}>
              <OptionIcon>{IconNotesMS}</OptionIcon>
              <ShortAnswerTextField
                id="short-answer"
                multiline
                disabled
                rows={3}
                label="resposta curta"
                color="secondary"
              />
            </FlexCenterGrid>
          )}
          {questionType === QuestionType.SINGLE_SELECTION && (
            <>
              {options.map((opt) => (
                <FlexCenterGrid item xs={12} key={opt.id}>
                  <ChoiceInput
                    choiceData={opt}
                    choices={options}
                    setChoices={setOptions}
                    jump={addConditional}
                    icon={IconRadioButtonCheckedMS}
                    questions={allQuestions}
                  />
                </FlexCenterGrid>
              ))}
              <Grid item xs={12}>
                <IconButtonBase
                  sx={{ minHeight: '30px' }}
                  onClick={addNewOption}
                >
                  {IconAddMS}
                  {Constants.addOption}
                </IconButtonBase>
              </Grid>
            </>
          )}
          {questionType === QuestionType.MULTIPLE_CHOICE && (
            <>
              {options.map((opt) => (
                <FlexCenterGrid item xs={12} key={opt.id}>
                  <ChoiceInput
                    choiceData={opt}
                    choices={options}
                    setChoices={setOptions}
                    jump={false}
                    icon={IconCheckBoxMS}
                    questions={allQuestions}
                  />
                </FlexCenterGrid>
              ))}
              <Grid item xs={12}>
                <IconButtonBase
                  sx={{ minHeight: '30px' }}
                  onClick={addNewOption}
                >
                  {IconAddMS}
                  {Constants.addOption}
                </IconButtonBase>
              </Grid>
            </>
          )}
          {questionType === QuestionType.LIST && (
            <>
              {options.map((opt) => (
                <FlexCenterGrid item xs={12} key={opt.id}>
                  <ChoiceInput
                    choiceData={opt}
                    choices={options}
                    setChoices={setOptions}
                    jump={false}
                    icon={IconCheckCircleMS}
                    questions={allQuestions}
                  />
                </FlexCenterGrid>
              ))}
              <Grid item xs={12}>
                <IconButtonBase
                  sx={{ minHeight: '30px' }}
                  onClick={addNewOption}
                >
                  {IconAddMS}
                  {Constants.addOption}
                </IconButtonBase>
              </Grid>
            </>
          )}
          {questionType === QuestionType.DATE && (
            <FlexCenterGrid item xs={4}>
              <OptionIcon>{IconCalendarMonthMS}</OptionIcon>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disabled
                  label="data"
                  format="DD-MM-YYYY"
                  disablePast
                />
              </LocalizationProvider>
            </FlexCenterGrid>
          )}
          {questionType === QuestionType.NUMERIC && (
            <FlexCenterGrid item xs={12}>
              <OptionIcon>{Icon123MS}</OptionIcon>
              <NumericFormat
                customInput={ShortAnswerTextField}
                id="numeric"
                label="resposta numérica"
                color="secondary"
                disabled
              />
            </FlexCenterGrid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {questionType === QuestionType.SINGLE_SELECTION ? (
            <>
              <Grid item xs={4.5} />
              <Grid item xs={4.5}>
                <CustomFormControl
                  label="adicionar condicional"
                  isChecked={addConditional}
                  setIsChecked={setAddConditional}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={9} />
          )}
          <Grid item xs={3}>
            <CustomFormControl
              label={Constants.required}
              isChecked={requiredAnswer}
              setIsChecked={setRequiredAnswer}
            />
          </Grid>
        </FlexCenterGrid>
        <FlexButtonBox>
          <OutlinedDarkButton onClick={handleDialog}>
            {Constants.cancel}
          </OutlinedDarkButton>
          <FilledDarkButton onClick={handleSubmit}>
            {Constants.save}
          </FilledDarkButton>
        </FlexButtonBox>
      </StyledDialog>
    </>
  );
}
