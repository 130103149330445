import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogButton = styled(Button)(() => ({
  width: '264px',
  height: '56px',
  borderRadius: '10px',
  '@media (max-height: 820px)': {
    height: '48px',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
    maxWidth: 'none',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  margin: '4px 0 20px',
  textTransform: 'capitalize',
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '500px',
  maxWidth: '540px',
  minHeight: '400px',
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: '2rem',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': { backgroundColor: '#d485eed1' },
}));

export const CloseIcon = styled(IconButton)(() => ({
  right: '20px',
  top: '20px',
  position: 'absolute',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const ButtonsSpace = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  maxWidth: '200px',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  maxWidth: '200px',
  textTransform: 'lowercase',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    filter: 'brightness(85%)',
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
  },
}));
