/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';

import { deleteInspection } from '../../../api/inspections';
import { InspectionAgendaData } from '../../../api/inspections/types';
import { GetWorkOrder } from '../../../api/workOrders';
import { WorkOrderData } from '../../../api/workOrders/types';
import { EventDialog } from '../../../components/EventDialog';
import { IconBlockMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  CalendarBox,
  EventBlockageBox,
  EventBox,
  EventDisabledBox,
  StyledTooltip,
} from './styles';

interface CalendarProps {
  eng: number;
  scheduledData: InspectionAgendaData[];
  callback: (eng: number, date: string) => void;
}

interface ScheduleProps {
  id: string | undefined;
  type: string;
  groupId: string | undefined;
  title: string;
  start: string;
  end: string;
  belongsToCompany: boolean | null;
}

export default function Calendar({
  eng,
  scheduledData,
  callback,
}: CalendarProps): JSX.Element {
  const [selectedEvent, setSelectedEvent] = useState<EventImpl | null>(null);
  const [modalData, setModalData] = useState<WorkOrderData>();
  const [openModal, setOpenModal] = useState(false);
  const [scheduleEvents, setScheduleEvents] = useState<ScheduleProps[]>([]);

  const today = new Date().toISOString().split('T');
  const [newDate, setNewDate] = useState(today[0]);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleOpen = (): void => setOpenModal(true);
  const handleClose = (): void => setOpenModal(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const calendarRef = useRef<FullCalendar>(null!);

  const handleModalData = async (id: string): Promise<void> => {
    const intId = Number(id);
    try {
      const response = await GetWorkOrder(intId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setModalData(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteSchedule = async (id: string): Promise<void> => {
    const intId = Number(id);
    try {
      const response = await deleteInspection(intId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.status_code === 0) {
        setSnackbarMessage('Agendamento deletado!');
        setErrorMessage(false);
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const filteredFiles = scheduledData.map((e) => {
      return {
        id: e.id?.toString(),
        type: e.type,
        groupId: e.work_order?.id.toString(),
        title: `OS ${e.work_order?.reference_number}`,
        start: e.start_at,
        end: e.finish_at,
        belongsToCompany: e.belongs_to_company,
      };
    });

    const myCalendar = calendarRef?.current?.getApi();
    if (scheduledData.length > 0) {
      myCalendar?.removeAllEvents();
      myCalendar?.addEventSource(filteredFiles);
      myCalendar?.gotoDate(scheduledData[0].start_at);
      setScheduleEvents(filteredFiles);
      setNewDate(scheduledData[0].finish_at);
    } else {
      myCalendar?.removeAllEvents();
      myCalendar?.gotoDate(newDate);
    }
  }, [scheduledData]);

  const handleEventClick = (clickInfo: EventClickArg): void => {
    if (clickInfo.event.groupId !== undefined) {
      // eslint-disable-next-line no-underscore-dangle
      if (clickInfo.event._def.extendedProps.belongsToCompany) {
        handleModalData(clickInfo.event.groupId);
        setSelectedEvent(clickInfo.event);
        handleOpen();
      }
    }
  };

  const renderEventContent = (eventInfo: EventContentArg): JSX.Element => {
    const eventStart = dayjs(eventInfo.event.start);
    const eventEnd = dayjs(eventInfo.event.end);
    const showTooltip = eventStart.diff(eventEnd, 'm') >= -50;
    const isSameDay = eventStart.isSame(eventEnd, 'day');

    let startAt = '';
    let finishAt = '';

    if (isSameDay) {
      startAt = eventStart.format('HH:mm');
      finishAt = eventEnd.format('HH:mm');
    } else {
      startAt = eventStart.format('DD/MM - HH:mm');
      finishAt = eventEnd.format('DD/MM - HH:mm');
    }
    return eventInfo.event._def.extendedProps.type === 'calendar-blockage' ? (
      showTooltip ? (
        <StyledTooltip title={`${startAt} - ${finishAt}`} placement="bottom">
          <EventBlockageBox>
            {IconBlockMS}
            <p>
              {startAt} - {finishAt}
            </p>
          </EventBlockageBox>
        </StyledTooltip>
      ) : (
        <EventBlockageBox>
          {IconBlockMS}
          <p>
            {startAt} {isSameDay ? '-' : ''} {finishAt}
          </p>
        </EventBlockageBox>
      )
    ) : eventInfo.event._def.extendedProps.belongsToCompany ? (
      <EventBox>
        <p>{eventInfo.event.title}</p>
      </EventBox>
    ) : (
      <StyledTooltip
        title="Agendamento não pertence a esta empresa"
        placement="bottom"
      >
        <EventDisabledBox>
          <p>{eventInfo.event.title}</p>
        </EventDisabledBox>
      </StyledTooltip>
    );
  };

  const renderDayContent = (info: DayHeaderContentArg): JSX.Element => {
    const { date } = info;
    const weekDay = dayjs(date).locale('pt-br').format('dddd');
    const monthNumber = date.getMonth() + 1;
    const dayNumber = date.getDate();

    return (
      <div>
        <div>{weekDay}</div>
        <div>
          {dayNumber.toString().padStart(2, '0')}/
          {monthNumber.toString().padStart(2, '0')}
        </div>
      </div>
    );
  };

  return (
    <div>
      <CalendarBox>
        <FullCalendar
          ref={calendarRef}
          allDaySlot={false}
          dayHeaderContent={renderDayContent}
          displayEventTime={false}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          customButtons={{
            prev: {
              text: '',
              click: () => {
                const lastweek = dayjs(newDate).subtract(1, 'week');
                const day = dayjs(lastweek).format('YYYY-MM-DD');
                setNewDate(day);
                callback(eng, day);
              },
            },
            monthPrev: {
              icon: 'chevrons-left',
              click: () => {
                const lastmonth = dayjs(newDate).subtract(1, 'month');
                const day = dayjs(lastmonth).format('YYYY-MM-DD');
                setNewDate(day);
                callback(eng, day);
              },
            },
            next: {
              text: '',
              click: () => {
                const nextweek = dayjs(newDate).add(1, 'week');
                const day = dayjs(nextweek).format('YYYY-MM-DD');
                setNewDate(day);
                callback(eng, day);
              },
            },
            monthNext: {
              icon: 'chevrons-right',
              click: () => {
                const nextmonth = dayjs(newDate).add(1, 'month');
                const day = dayjs(nextmonth).format('YYYY-MM-DD');
                setNewDate(day);
                callback(eng, day);
              },
            },
          }}
          headerToolbar={{
            left: 'prev, monthPrev',
            right: 'monthNext, next',
          }}
          initialEvents={scheduleEvents}
          initialView="timeGridWeek"
          locale="pt-br"
          plugins={[timeGridPlugin, interactionPlugin]}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
          }}
          weekends
        />
      </CalendarBox>
      {selectedEvent && (
        <EventDialog
          selectedEvent={selectedEvent}
          modalData={modalData}
          open={openModal}
          handleClose={handleClose}
          deleteSchedule={handleDeleteSchedule}
        />
      )}
    </div>
  );
}
