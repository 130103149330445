import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
  },
}));

export const DialogButton = styled(Button)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#FAFCFF',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
  border: '2px solid #FF334B',
  borderRadius: 10,
  color: '#FF334B',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#FF334B',
  },
  '&:active': {
    backgroundColor: '#FF334B',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '150px',
  border: '1.5px solid #FF334B',
  color: '#FF334B',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#FF334B',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '150px',
  backgroundColor: '#FF334B',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#FF334B',
    filter: 'brightness(85%)',
  },
}));
