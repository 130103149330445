import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 32px 64px 120px',
  backgroundColor: '#FAFCFF',
}));

export const BackIcon = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const Container = styled(Box)(() => ({
  margin: '20px 160px 60px',
}));

export const SectionContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '800px',
}));

export const FlexEndBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}));

export const ImageCard = styled(CardMedia)(({ theme }) => ({
  backgroundColor: '#bcbcbc66',
  width: '768px',
  height: '576px',
  borderRadius: '15px',
  alignSelf: 'center',
  backgroundSize: 'contain',
  objectFit: 'contain',
  margin: 'auto',
  [theme.breakpoints.down('xl')]: {
    width: '640px',
    height: '480px',
  },
}));

export const DownloadButton = styled(ButtonBase)(() => ({
  float: 'right',
  height: '42px',
  width: '280px',
  border: '1px solid #737475',
  borderRadius: '16px',
  display: 'flex',
  gap: '10px',
  '& span': {
    color: '#06DFC2',
  },
  '&:hover': {
    backgroundColor: '#EDEFF2',
    borderColor: '#06DFC2',
  },
}));

export const SectionSubtitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
  fontSize: '18px',
  fontWeight: '600',
  '& span': { color: '#00B39B' },
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
}));

export const UploadBox = styled(Box)(() => ({
  width: '240px',
  minHeight: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '8px',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(() => ({
  '& span': { fontSize: '72px', color: '#00B39B' },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: '#bcbcbc66',
  objectFit: 'contain',
  backgroundSize: 'contain',
  width: '240px',
  height: '220px',
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const PathologieButton = styled(Button)(() => ({
  border: '1px solid #00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '700',
  color: '#00B39B',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
}));

export const SubmitButton = styled(Button)(() => ({
  width: '300px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const PicBackButton = styled(ButtonBase)(({ theme }) => ({
  position: 'absolute',
  margin: '288px 0 0 -90px',
  borderRadius: '50%',
  padding: '10px 14px 10px 6px',
  color: '#00B39B',
  '&.Mui-disabled': {
    color: '#D7D8DB',
  },
  '&:hover': {
    backgroundColor: '#00B39B26',
  },
  '& span': {
    fontSize: '48px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '240px 0 0 -90px',
  },
}));

export const PicForwardButton = styled(ButtonBase)(({ theme }) => ({
  float: 'right',
  position: 'absolute',
  margin: '288px -90px 0 0',
  borderRadius: '50%',
  padding: '10px 6px 10px 14px',
  color: '#00B39B',
  '&.Mui-disabled': {
    color: '#D7D8DB',
  },
  '&:hover': {
    backgroundColor: '#00B39B26',
  },
  '& span': {
    fontSize: '48px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '240px -90px 0 0',
  },
}));
