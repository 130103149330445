import { useNavigate } from 'react-router-dom';

interface UseErrorMessageHook {
  getErrorMessage(error: unknown, navigateHome?: boolean): string;
}

export default function useErrorMessage(): UseErrorMessageHook {
  const navigate = useNavigate();

  const getErrorMessage = (error: unknown, navigateHome?: boolean): string => {
    if (error instanceof Error) {
      if (error.name === 'TypeError') {
        return 'Algo deu errado, tente novamente';
      }
      if (error.message === 'Sessão inválida') {
        localStorage.removeItem('control-center-token');
        navigate('/login');
      } else if (navigateHome) {
        navigate('/home');
      }
      return error.message;
    }
    return 'Algo deu errado, tente novamente';
  };

  return {
    getErrorMessage,
  };
}
