import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  CardMediaProps,
  IconButton,
  Modal,
  styled,
} from '@mui/material';
import { ElementType } from 'react';

export const StyledCardMedia = styled(CardMedia)(() => ({
  borderRadius: '30px',
  height: '140px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  margin: 32,
  overflow: 'auto',
  width: '88%',
  height: '88%',
}));

export const MainBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  paddingTop: 20,
  width: '100%',
}));

interface MediaProps extends CardMediaProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ElementType<any>;
}

export const MainCardMedia = styled(CardMedia)<MediaProps>(({ theme }) => ({
  minHeight: 600,
  minWidth: 200,
  maxHeight: 900,
  maxWidth: 'fit-content',
  objectFit: 'contain',
  borderRadius: '8px',
  [theme.breakpoints.down('xl')]: {
    minHeight: 420,
    maxHeight: 520,
  },
}));

export const ThumbCardMedia = styled(CardMedia)<MediaProps>(() => ({
  height: 100,
  minWidth: 150,
  maxWidth: 150,
  backgroundSize: 'contain',
  borderRadius: '8px',
  marginBottom: '10px',
  cursor: 'pointer',
}));

export const ContainerModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiBackdrop-root': {
    backgroundColor: '#000000cc',
  },
}));

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  backgroundColor: '#ECF0F6',
  width: 42,
  height: 42,
  borderRadius: '100%',
  zIndex: 300,
  '& span': {
    color: '#1c1c1c',
    display: 'flex',
    justifyContent: 'center',
  },
  '&:hover': {
    backgroundColor: '#00B39B',
    '& span': {
      color: '#FFFFFF',
    },
  },
}));

export const ArrowButton = styled(ButtonBase)(() => ({
  '& span': {
    fontSize: 72,
    color: '#FCFAFF',
    fontWeight: 'bold',
    '&:hover': {
      color: '#00B39B',
    },
  },
}));

export const PicturesContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '90%',
  marginTop: 24,
  overflowX: 'hidden',
  alignSelf: 'center',
}));

export const ScrollContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '0.5rem',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: 10,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#00B39B',
    marginTop: '1rem',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#004D43',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '60px 0',
    marginTop: '1rem',
  },
}));

export const MoreImages = styled(Button)(() => ({
  height: 100,
  minWidth: 150,
  maxWidth: 150,
  borderRadius: '8px',
  cursor: 'pointer',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
}));
