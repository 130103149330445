import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    xxxl: true;
  }
  interface Components {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            '& .MuiButtonBase-root': {
              width: string;
              height: string;
            };
          };
        };
      };
    };
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            '.Mui-selected:hover': {
              backgroundColor: string;
            };
            ':hover': {
              backgroundColor: string;
            };
          };
          '& .MuiList-root': {
            marginRight: string;
            ':after': {
              height: string;
            };
            '&::-webkit-scrollbar': {
              width: string;
            };
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: string;
              borderRadius: string;
              '&:hover': {
                backgroundColor: string;
              };
            };
          };
        };
      };
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#004D43',
      main: '#00B39B',
      light: '#06DFC2',
    },
    secondary: {
      main: '#595959',
      light: '#EDEFF2',
    },
    common: {
      white: '#FFFFFF',
    },
    background: {
      default: '#FAFCFF',
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto"`,
    allVariants: {
      color: '#595959',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 1920,
      xxxl: 3840,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          width: '280px',
          height: '48px',
          textTransform: 'none',
          backgroundColor: '#00B39B',
          color: '#FAFCFF',
          fontFamily: 'Montserrat',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: '#004D43',
            color: '#FAFCFF',
          },
          '&:active': {
            backgroundColor: '#004D43',
            color: '#FAFCFF',
          },
          '&:disabled': {
            opacity: '40%',
            color: '#FAFCFF',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root': {
            '&.Mui-selected': {
              ':focus': {
                backgroundColor: '#00B39B',
              },
            },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            '& .MuiButtonBase-root': {
              width: '140px',
              height: '42px',
            },
          },
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            marginRight: '10px',
            ':after': {
              height: '20px',
            },
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#004D4399',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#004D43CC',
              },
            },
          },
          '& .MuiMenuItem-root': {
            '.Mui-selected:hover': {
              backgroundColor: '#00B39B',
            },
            ':hover': {
              backgroundColor: '#00B39B4D',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minWidth: '1280px',
          minHeight: '720px',
          height: '100%',
          margin: '0 auto',
        },
        body: {
          backgroundColor: '#FAFCFF',
        },
      },
    },
  },
});
