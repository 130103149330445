/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getReportPDF } from '../../../api/workOrders';
import { IconDownloadMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { Constants } from './constants';
import { DownloadButton } from './styles';

interface ReportDownloadProps {
  id: number;
}

export function ReportDownload({ id }: ReportDownloadProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [pdfFile, setPDFFile] = useState<File>();

  const { setOpenSnackbar, setSnackbarMessage, setErrorMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadPdf } = useGeneral();

  const getReport = useCallback(async () => {
    try {
      const response = await getReportPDF(id);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const file = binaryData
        ? new File([binaryData], 'report.pdf', { type: binaryData.type })
        : undefined;
      setPDFFile(file);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  }, [id, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <Box>
      {loading ? (
        <DownloadButton disabled>{Constants.loading}</DownloadButton>
      ) : pdfFile ? (
        <DownloadButton
          disabled={!pdfFile}
          onClick={() => handleDownloadPdf(pdfFile)}
        >
          {IconDownloadMS}
          {Constants.download}
        </DownloadButton>
      ) : (
        <Typography>{Constants.noPdf}</Typography>
      )}
    </Box>
  );
}
