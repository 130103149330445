import {
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 32px 32px 120px',
  backgroundColor: '#FAFCFF',
}));

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const FilledButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
}));

export const IconButtonBase = styled(ButtonBase)(() => ({
  display: 'flex',
  gap: '8px',
  padding: '2px 4px',
  borderRadius: '8px',
  alignItems: 'center',
  fontSize: '16px',
  '& span': { color: '#00B39B' },
  '&:hover': {
    '& span': {
      fontSize: '26px',
    },
    fontWeight: '600',
  },
}));

export const GridQuestions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const TitleQuestions = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
}));

export const PaperQuestions = styled(Paper)(({ theme }) => ({
  height: '560px',
  overflowY: 'auto',
  backgroundColor: '#FAFCFF',
  border: '1px solid #0000003b',
  borderRadius: '16px',
  padding: '8px',
  marginBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#004D43',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#004D43CC',
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
  [theme.breakpoints.up('xl')]: {
    height: '660px',
  },
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
}));

export const FilledDarkButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
  backgroundColor: '#004D43',
  color: '#FAFCFF',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const OutlinedDarkButton = styled(Button)(() => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: '2px solid #004D43',
  borderRadius: '16px',
  color: '#004D43',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    color: '#004D43',
  },
}));
