import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PaperQuestion = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #D7D8DB',
  padding: '40px',
  width: '620px',
  '&.MuiPaper-root': {
    minHeight: '460px',
    minWidth: '300px',
    padding: '40px',
    maxWidth: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '820px',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

export const NumberTypography = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#004D43',
}));

export const TitleTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  margin: '8px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SingleSelectTypo = styled(Typography)(() => ({
  marginTop: '-10px',
  fontSize: '14px',
  color: '#8B8C8E',
}));

export const MultiFormControl = styled(FormControlLabel)(() => ({
  margin: '8px 0',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: '#06DFC2',
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
      fontSize: '18px',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
  },
}));

export const CheckboxFormControl = styled(FormControlLabel)(() => ({
  padding: '8px 12px 0',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: '#06DFC2',
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
  },
}));

export const ListTextField = styled(TextField)(() => ({
  margin: '40px 20px 16px',
  minWidth: '180px',
  width: 'fit-content',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const ListMenuItem = styled(MenuItem)(() => ({
  '&:first-of-type': {
    color: '#7c7c7c',
    '&.Mui-selected': { backgroundColor: '#EDEDED' },
    '&:hover': {
      backgroundColor: '#EDEDED',
      cursor: 'auto',
    },
  },
  '&:hover': { backgroundColor: '#00B39B66' },
}));

export const SubmitButton = styled(Button)(() => ({
  margin: '40px 0 0 10px',
  border: '2px solid #004D43',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  color: '#004D43',
  fontWeight: '600',
  maxWidth: '90px',
  textTransform: 'uppercase',
}));

export const StyledDatePicker = {
  margin: '24px 6px 16px',
  minWidth: '180px',
  '& .MuiOutlinedInput-root': { borderRadius: '16px' },
};
