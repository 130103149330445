/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getPayedInvoices } from '../../../../../api/invoices';
import { Invoice } from '../../../../../api/invoices/types';
import {
  IconArrowDownwardMS,
  IconArrowUpwardMS,
  IconDescriptionMS,
  IconInfoMS,
} from '../../../../../constants/icons';
import {
  Constants,
  paymentsHeader,
} from '../../../../../constants/userManagement';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../hooks/useGeneral';
import {
  InfoBox,
  StyledCell,
  StyledPagination,
  StyledRow,
  TableCellHeader,
} from '../../../styles';

interface PaymentsMadeProps {
  search: string;
}

export function PaymentsMade({ search }: PaymentsMadeProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [order, setOrder] = useState(false);

  const invoicesPerPage = 10;
  const nowDate = new Date();

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);
  const { handleDownloadFile } = useGeneral();
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    let orderBy = 'due_date%23asc';
    if (order) orderBy = 'due_date%23desc';

    try {
      const response = await getPayedInvoices(
        page,
        invoicesPerPage,
        search,
        orderBy
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTotalPages(response.detail.total_pages);
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [page, search, order]);

  useEffect(() => {
    getDataCallback();
  }, [page, order]);

  useEffect(() => {
    if (page === 1) {
      getDataCallback();
    } else {
      setPage(1);
    }
  }, [search]);

  return loading ? (
    <Box width="100%" mt={4}>
      <LinearProgress />
    </Box>
  ) : (
    <Box>
      {invoices.length ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {paymentsHeader.map((cell) => (
                    <TableCellHeader key={cell}>{cell}</TableCellHeader>
                  ))}
                  <TableCellHeader
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setOrder(!order)}
                  >
                    {Constants.dueDate}
                    <span>
                      {order ? IconArrowUpwardMS : IconArrowDownwardMS}
                    </span>
                  </TableCellHeader>
                  <TableCellHeader>{Constants.attachment}</TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((item) => (
                  <StyledRow key={item.id}>
                    <StyledCell>{item.responsible_user}</StyledCell>
                    <StyledCell>{item.document}</StyledCell>
                    <StyledCell>{item.company}</StyledCell>
                    <StyledCell
                      sx={{
                        color:
                          new Date(item.due_date) < nowDate ? 'red' : 'default',
                      }}
                    >
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(item.due_date)
                      )}
                    </StyledCell>
                    <StyledCell>
                      <IconButton
                        color="primary"
                        disabled={!item.attachment}
                        onClick={() =>
                          handleDownloadFile(
                            item.attachment,
                            `anexo ${item.due_date}`
                          )
                        }
                      >
                        {IconDescriptionMS}
                      </IconButton>
                    </StyledCell>
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledPagination
            page={page}
            count={totalPages}
            onChange={(_, v) => setPage(v)}
          />
        </>
      ) : (
        <InfoBox component={Paper}>
          {IconInfoMS}
          {Constants.noOpenPayment}
        </InfoBox>
      )}
    </Box>
  );
}
