import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SendToPept } from '../api/workOrders';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';

interface UseSendPeptProps {
  handlePept: (
    id: number,
    reference: number | undefined,
    obs: string
  ) => Promise<void>;
}

export function usePept(): UseSendPeptProps {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();

  const handlePept = async (
    osId: number,
    reference: number | undefined,
    obs: string
  ): Promise<void> => {
    try {
      const response = await SendToPept(osId, obs);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(`OS ${reference} enviada para PEPT!`);
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate('/home');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return {
    handlePept,
  };
}
