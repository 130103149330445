import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  TextField,
} from '@mui/material';

import { Constants } from '../../../constants/agenda';
import { IconSearchMS } from '../../../constants/icons';
import { IEngineerData } from '../useAgenda';
import { ColorDot, StyledList } from './styles';

interface FilterEngineersProps {
  usersData: IEngineerData[];
  handleCheckboxChange: (engineerId: number) => void;
  selectedEngineers: number[];
}

export default function FilterEngineers({
  usersData,
  handleCheckboxChange,
  selectedEngineers,
}: FilterEngineersProps): JSX.Element {
  return (
    <Autocomplete
      multiple
      id="search-user-checkboxes"
      options={usersData}
      disableCloseOnSelect
      renderTags={() => null}
      getOptionLabel={(option) => `${option.id} - ${option.name || ''}`}
      noOptionsText={Constants.noOption}
      disableClearable
      forcePopupIcon={false}
      renderOption={(props, option) => {
        return (
          <li
            key={option.id}
            style={{
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={selectedEngineers.includes(option.id)}
              onChange={() => handleCheckboxChange(option.id)}
            />
            <ColorDot color={option.color} sx={{ marginRight: '8px' }} />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={Constants.searchUser}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ color: '#004D43', marginLeft: '8px' }}
              >
                {IconSearchMS}
              </InputAdornment>
            ),
          }}
        />
      )}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          borderRadius: '32px',
          width: 380,
          '@media (max-width: 1600px)': {
            width: 260,
          },
        },
      }}
      ListboxComponent={StyledList}
    />
  );
}
