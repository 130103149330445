import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const OpenDialogButton = styled(Button)(() => ({
  width: 210,
  height: 32,
  border: '1.5px solid #00B39B',
  borderRadius: 10,
  color: '#00B39B',
  fontWeight: 600,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 4,
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 30,
    backgroundColor: theme.palette.common.white,
    padding: '48px',
    minHeight: 'auto',
    [theme.breakpoints.down('xl')]: {
      minWidth: 780,
      '& .css-biqaug': {
        margin: '48px 60px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 920,
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: 10,
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const AttachmentsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DialogTitle = styled(Typography)(() => ({
  font: '700 21px Montserrat',
  textTransform: 'capitalize',
  color: '#595959',
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { color: '#00B39B' },
}));

export const FileList = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: 0,
  padding: 'none',
  listStyleType: 'none',
  marginTop: 0,
}));

export const List = styled('li')(() => ({
  borderBottom: '1px solid #ccc',
  padding: '12px 4px',
  font: '400 16px Montserrat',
  width: '100%',
  color: '#595959',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& div': {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  '&:first-of-type': {
    borderTop: '1px solid #ccc',
  },
  '& span': {
    fontSize: 20,
    fontWeight: 400,
    color: '#00B39B',
  },
  '& button:disabled': {
    '& span': {
      color: '#ccc',
    },
  },
}));

export const FileContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: '1px solid #ccc',
  padding: '8px 0 8px 4px',
}));

export const FileNameTypography = styled(Typography)(() => ({
  minWidth: '300px',
  display: 'flex',
  gap: '6px',
  alignContent: 'center',
  color: '#595959',
  font: '400 16px Montserrat',
  '& span': {
    color: '#00B39B',
    fontSize: 20,
  },
}));

export const DateTypography = styled(Typography)(() => ({
  color: '#717171',
  fontSize: '14px',
  minWidth: '80px',
  font: '400 12px Montserrat',
}));

export const StyledDownload = styled(ButtonBase)(() => ({
  '& span': {
    borderRadius: '50%',
    padding: '4px',
  },
}));

export const StyledIcon = styled(ButtonBase)(() => ({
  color: '#00B39B',
  padding: '4px',
  span: {
    fontSize: 20,
    borderRadius: '50%',
  },
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CloseButton = styled(IconButton)(() => ({
  margin: '-28px -28px 0 0',
  height: 'fit-content',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
    '&:hover': {
      color: '#004D43',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  borderRadius: '30px',
  border: '0.5px solid #8B8C8E',
}));

type AttachFileButtonProps = {
  component: string;
};

export const AttachFileButon = styled(Button)<AttachFileButtonProps>(
  ({ component, theme }) => ({
    component,
    width: '220px',
    margin: '10px',
    color: '#FFFFFF',
    backgroundColor: '#00B39B',
    '&:hover': {
      backgroundColor: '#00B39B',
      filter: 'brightness(85%)',
    },
    [theme.breakpoints.down('xl')]: {
      width: '170px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '220px',
    },
  })
);

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginTop: '24px',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '24px',
  marginTop: '24px',
  width: '100%',
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontWeight: '700',
  color: '#004D43',
  border: '1px solid #004D43',
  borderRadius: '30px',
  '&:hover': {
    color: '#00B39B',
    border: '1px solid #00B39B',
    background: 'transparent',
  },
  [theme.breakpoints.down('xl')]: {
    width: '150px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '220px',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '220px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  [theme.breakpoints.down('xl')]: {
    width: '150px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '220px',
  },
}));
