/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress, Paper } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { companyStats, getCompanies } from '../../../api/companies';
import { Company, ReportPerUser } from '../../../api/companies/types';
import { Search } from '../../../components/Search';
import { IconInfoMS } from '../../../constants/icons';
import { Constants } from '../../../constants/userManagement';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { InfoBox, SearchBox, StyledPagination } from '../styles';
import { TableStyled } from './TableStyled';

export function Activities(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [usersPerCompany, setUsersPerCompany] = useState<ReportPerUser[]>();
  const [companyId, setCompanyId] = useState<number>();
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const companiesPerPage = 8;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getCompanies(page, companiesPerPage, search);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTotalPages(response.detail.total_pages);
      setCompanies(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }, [page, search]);

  const getCompanyStats = useCallback(async () => {
    if (!companyId) return;
    setLoadingUsers(true);

    try {
      const response = await companyStats(companyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const newArray = Object.values(response.data.reports.reports_per_user);
      setUsersPerCompany(newArray);
      setLoadingUsers(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingUsers(false);
    }
  }, [companyId]);

  useEffect(() => {
    getDataCallback();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      getDataCallback();
    } else {
      setPage(1);
    }
  }, [search]);

  useEffect(() => {
    getCompanyStats();
  }, [companyId]);

  return (
    <Box>
      <SearchBox>
        <Search setSearchField={setSearch} />
      </SearchBox>
      {loading ? (
        <Box width="100%" mt={4}>
          <LinearProgress />
        </Box>
      ) : (
        <Box>
          {companies.length ? (
            <>
              <TableStyled
                companiesData={companies}
                companyId={companyId}
                setCompanyId={setCompanyId}
                setUsersPerCompany={setUsersPerCompany}
                usersPerCompany={usersPerCompany}
                loadingUsers={loadingUsers}
              />
              <StyledPagination
                page={page}
                count={totalPages}
                onChange={(_, v) => setPage(v)}
              />
            </>
          ) : (
            <InfoBox component={Paper}>
              {IconInfoMS}
              {Constants.noActivities}
            </InfoBox>
          )}
        </Box>
      )}
    </Box>
  );
}
