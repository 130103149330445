import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
    padding: '32px 40px',
    minWidth: '900px',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      minWidth: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1500px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseIcon = styled(IconButton)(() => ({
  margin: '-20px',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const Title = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '32px',
  textTransform: 'capitalize',
  '& span': { color: '#00B39B' },
}));

export const EditButton = styled(Button)(() => ({
  borderRadius: '10px',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  width: 240,
  color: '#004D43',
  border: '2px solid #004D43',
  display: 'flex',
  gap: '10px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '32px',
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '40%',
  border: '1.5px solid #004D43',
  color: '#004D43',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  width: '40%',
  backgroundColor: '#004D43',
  textTransform: 'lowercase',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    opacity: 0.8,
  },
}));

export const InputText = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 16,
  },
}));

export const CoordContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '8px',
  border: '1px solid #D7D8DB',
  borderRadius: '10px',
  margin: '16px 0 26px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const MapButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
