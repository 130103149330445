import {
  Box,
  Button,
  CardMedia,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OpenDialogButton = styled(Button)(() => ({
  width: '100%',
  marginTop: '40px',
  border: '1.5px solid #004D43',
  borderRadius: '10px',
  color: '#004D43',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: '10px',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#004D43',
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    color: '#004D43',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    padding: '60px 80px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '800px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1500px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D43',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#004D43CC',
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const CloseIcon = styled(IconButton)(() => ({
  margin: '-20px -30px',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: '#00B39B',
  },
}));

export const SectionBox = styled(Box)(() => ({
  marginBottom: '40px',
}));

export const SectionTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '40px',
  '& span': { color: '#00B39B' },
}));

export const SectionSubtitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '20px',
  '& span': { color: '#00B39B' },
}));

export const PhotosContainer = styled(Box)(() => ({
  margin: '20px 4px',
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
}));

export const FacadeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const UploadBox = styled(Box)(() => ({
  width: '260px',
  minHeight: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(() => ({
  '& span': { fontSize: '92px', color: '#00B39B' },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#00B39B',
}));

export const CoordContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '8px',
  border: '1px solid #D7D8DB',
  borderRadius: '10px',
  margin: '16px 0 28px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '40px',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '260px',
  height: '220px',
  borderRadius: '30px',
  margin: '0 20px',
}));

export const CancelButton = styled(Button)(() => ({
  width: '400px',
  border: '1px solid #004D43',
  backgroundColor: '#FAFCFF',
  fontWeight: '700',
  color: '#004D43',
  '&:hover': {
    backgroundColor: '#004D43',
  },
}));

export const SubmitButton = styled(Button)(() => ({
  width: '400px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    opacity: 0.8,
  },
}));

export const CepStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
};

export const OutlinedButton = styled(Button)(() => ({
  borderRadius: '10px',
  width: '100%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
