import {
  Box,
  ButtonBase,
  ListItem,
  Select,
  alpha,
  styled,
} from '@mui/material';

export const RadioButtonContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}));

export const TopButton = styled(ButtonBase)(() => ({
  flex: 1,
  height: 40,
  color: '#004E43',
  font: '400 16px Montserrat',
  border: '1px solid #004E43',
  borderRadius: '4px 0px 0px 4px',

  '& + button': {
    borderRadius: '0px 4px 4px 0px',
  },

  '&.selected': {
    font: '600 16px Montserrat',
    background: '#004E43',
    color: '#FFFFFE',
  },
}));

const BoxesStyles = {
  // flex: 1,
  background: '#FFFFFE',
  height: 600,
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
};

export const ListCompanies = styled(Box)(() => ({
  ...BoxesStyles,
  flex: 1,
  padding: '40px 30px',
  overflowY: 'scroll',
}));

export const CompanyDescription = styled(Box)(() => ({
  ...BoxesStyles,
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > div': {
    // alignItems: 'center',
  },
}));

export const HeaderDescription = styled('header')(() => ({
  display: 'flex',
  color: '#004E43',
  justifyContent: 'center',
  gap: 32,
  margin: '60px 0',
}));

export const DescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: 32,
  justifyContent: 'flex-start',
}));

export const CitiesServerd = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',

  borderRight: '1px solid #DCDDE0',
  padding: '0px 24px 24px 24px',

  '&:first-child': {
    fontWeight: '400 !important',
  },
  '& p': {
    font: '500 16px Montserrat',
    lineHeight: 0.5,
  },
}));

export const CompanyTextDescription = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 24px 24px 24px',
  '& p': {
    lineHeight: 0.5,

    '& strong': {
      fontWeight: 500,
    },
  },
}));

export const SearchContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  // width: '100%',
  marginBottom: 42,
  position: 'static',

  '& span': {
    color: '#909090',
    fontSize: 32,
  },

  '& input': {
    border: 'none',
    flex: 1,
    font: '400 16px Montserrat',
    marginLeft: 8,
    height: 32,
    borderBottom: '1px solid #909090',
  },

  '& button': {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: '#004E43',
    height: 32,
    font: '600 16px Montserrat',
    borderBottom: '1px solid #909090',

    '&:hover': {
      color: alpha('#004E43', 0.8),
    },
  },
}));

export const SelectContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 80,
  width: '100%',
  padding: '30px 0',
}));

export const SelectStyled = styled(Select)(() => ({
  height: 56,
  flex: 1,
  borderRadius: 16,
}));

export const ListItemStyled = styled(ListItem)(() => ({
  font: '400 16px Montserrat',
  gap: 16,
  borderBottom: '1px solid #DCDDE0',
  cursor: 'pointer',

  '& img': {
    width: 32,
  },

  '&.active': {
    background: '#ECF0F6',
    margin: 2,
    borderRadius: 4,
  },
  '&:hover': {
    background: alpha('#ECF0F6', 0.5),
  },
}));
