import { BaseUrl } from '../global';
import { DetailProps, TokenProps } from './types';

export const firstAccessEmail = (email: string): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`${BaseUrl}/api/V1/reset-password/${email}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export const firstAccessToken = (
  email: string,
  token: string
): Promise<TokenProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(
    `${BaseUrl}/api/V1/reset-password/${email}/${token}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export const resetPassword = (
  token: string,
  password: FormData
): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: password,
  };

  return fetch(`${BaseUrl}/api/V1/reset-password`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export const recoverPasswordEmail = (email: string): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(
    `${BaseUrl}/api/V1/reset-password/${email}?first_access=false`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};
