import { QuestionData } from '../../../api/forms/types';

export const localQuestionsData: QuestionData[] = [
  {
    allow_multiple_selection: false,
    choices: [
      {
        id: 1,
        title: 'Residencial unifamiliar',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Residencial multifamiliar',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Comercial',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Industrial',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Institucional',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Outro',
        jump_to: null,
      },
    ],
    id: 1,
    required: true,
    title: 'Uso predominante da região',
    type: 2,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: false,
    choices: [
      {
        id: 1,
        title: 'Alto (Superior, luxo)',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Alto (Por predominância)',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Normal (C/ aspecto de alto)',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Normal (Forte predominância)',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Normal (C/ Aspecto de baixo)',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Baixo',
        jump_to: null,
      },
      {
        id: 7,
        title: 'Mínimo',
        jump_to: null,
      },
    ],
    id: 2,
    required: true,
    title: 'Edificações na região',
    type: 2,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: false,
    choices: [
      {
        id: 1,
        title: 'Região central',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Região não central',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Ocupação periférica/suburbana',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Não inserido na malha',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Outro',
        jump_to: null,
      },
    ],
    id: 3,
    required: true,
    title: 'Contexto urbano',
    type: 2,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: false,
    choices: [
      {
        id: 1,
        title: 'Arterial ou principal',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Coletora ou secundária',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Local',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Rodovia',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Vicinal',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Outro',
        jump_to: null,
      },
    ],
    id: 4,
    required: true,
    title: 'Via de acesso (endereço)',
    type: 2,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: true,
    choices: [
      {
        id: 1,
        title: 'Sem destaque',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Shopping',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Comércio',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Orla',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Estação de trem/metrô',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Indústria',
        jump_to: null,
      },
      {
        id: 7,
        title: 'Clubes',
        jump_to: null,
      },
      {
        id: 8,
        title: 'Empreendimentos',
        jump_to: null,
      },
      {
        id: 9,
        title: 'Outros',
        jump_to: null,
      },
    ],
    id: 5,
    required: true,
    title: 'Influências valorizantes',
    type: 3,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: true,
    choices: [
      {
        id: 1,
        title: 'Sem destaque',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Área de desmoronamento',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Aterro sanitário/lixão',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Estação de tratamento de esgoto',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Sub-habitação',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Torre telefonia/energia',
        jump_to: null,
      },
      {
        id: 7,
        title: 'Empreendimento',
        jump_to: null,
      },
      {
        id: 8,
        title: 'Empreendimento',
        jump_to: null,
      },
      {
        id: 9,
        title: 'Indústria',
        jump_to: null,
      },
      {
        id: 10,
        title: 'Área inundável',
        jump_to: null,
      },
      {
        id: 11,
        title: 'Presídio',
        jump_to: null,
      },
      {
        id: 12,
        title: 'Feira livre',
        jump_to: null,
      },
      {
        id: 13,
        title: 'Outro',
        jump_to: null,
      },
    ],
    id: 6,
    required: true,
    title: 'Influências desvalorizantes',
    type: 3,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: true,
    choices: [
      {
        id: 1,
        title: 'Água potável',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Esgoto sanitário',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Energia elétrica',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Telefone',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Pavimentação',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Esgoto pluvial',
        jump_to: null,
      },
      {
        id: 7,
        title: 'Iluminação Pública',
        jump_to: null,
      },
    ],
    id: 7,
    required: true,
    title: 'Infraestrutura urbana',
    type: 3,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: true,
    choices: [
      {
        id: 1,
        title: 'Coleta de lixo',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Transporte coletivo',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Comércio',
        jump_to: null,
      },
      {
        id: 4,
        title: 'Rede bancária',
        jump_to: null,
      },
      {
        id: 5,
        title: 'Educação',
        jump_to: null,
      },
      {
        id: 6,
        title: 'Saúde',
        jump_to: null,
      },
      {
        id: 7,
        title: 'Segurança',
        jump_to: null,
      },
      {
        id: 8,
        title: 'Lazer',
        jump_to: null,
      },
    ],
    id: 8,
    required: true,
    title: 'Serviços públicos e comunitários',
    type: 3,
    answer: null,
    blocked: true,
    use_in_report: true,
  },
  {
    allow_multiple_selection: false,
    choices: [
      {
        id: 1,
        title: 'Interna e externa',
        jump_to: null,
      },
      {
        id: 2,
        title: 'Somente externa',
        jump_to: null,
      },
      {
        id: 3,
        title: 'Não realizada',
        jump_to: null,
      },
    ],
    id: 9,
    required: true,
    title: 'Vistoria',
    type: 2,
    answer: null,
    blocked: true,
    use_in_report: false,
  },
];
