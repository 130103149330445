import { BaseUrl, myHeadersWithToken } from '../global';
import { GetCompaniesProps, GetCompanyStats } from './types';

export const getCompanies = (
  page: number,
  size: number,
  search: string
): Promise<GetCompaniesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    `${BaseUrl}/api/V1/companies?page=${page}&size=${size}&search=${search}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getCompaniesPayments = (
  page: number,
  size: number,
  search: string,
  capture: string,
  orderBy: string
): Promise<GetCompaniesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/companies?page=${page}&size=${size}&search=${search}&payment_capture=${capture}&order_by=${orderBy}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const companyStats = (id: number): Promise<GetCompanyStats> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    `${BaseUrl}/api/V1/companies/${id}/company-stats`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
