import { TableCell, TableRow, alpha, styled } from '@mui/material';

export const TableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#004D43',
  font: '500 21px Montserrat',
  backgroundColor: '#F1F5FA',
  verticalAlign: 'middle',
  '& span': {
    verticalAlign: 'bottom',
    paddingLeft: '4px',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    '& span': {
      fontSize: '18px',
    },
  },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
  color: '#707070',
  [theme.breakpoints.up('xl')]: {
    padding: '20px',
    fontSize: '16px',
  },
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    '& > td': {
      fontWeight: 600,
      background: alpha('#06dfc2', 0.5),
    },
  },
}));
