/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Status } from '../../../api/enumerations';
import { addRoom, deleteRoom, getAllRooms } from '../../../api/rooms';
import { RoomData } from '../../../api/rooms/types';
import { GetWorkOrder } from '../../../api/workOrders';
import { Title } from '../../../components/Sections/Title';
import Snackbar from '../../../components/Snackbar';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddMS,
  IconArrowCircleLeftMS,
  IconDeleteMS,
  IconDoorFrontMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { roomsOptions } from '../../../helpers/roomImages';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  AddRoomBox,
  AddRoomButton,
  BackButton,
  GalleryContainer,
  QuantityButton,
  RelativeBox,
  SectionBox,
  StyledCardMedia,
  StyledCardText,
} from './styles';

interface RoomOption {
  id: number;
  image: string;
  name: string;
  quantity: number;
}

export function SelectRooms(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [rooms, setRooms] = useState<RoomData[]>([]);
  const [roomsWithQuantity, setRoomsWithQuantity] = useState<RoomOption[]>();
  const pageSize = 20;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const { id, inspection } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms`);
  };

  useEffect(() => {
    const roomsWithQuantity: RoomOption[] = roomsOptions.data.map((room) => ({
      ...room,
      quantity: 0,
    }));
    setRoomsWithQuantity(roomsWithQuantity);
  }, []);

  const getRooms = useCallback(async () => {
    if (page === lastCalledPage) {
      setPage(1);
      setLastCalledPage(0);
      return;
    }

    try {
      const response = await getAllRooms(inspectionId, page, pageSize);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setRooms([...rooms, ...response.data]);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }
  }, [page]);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (
        response.data.status === Status.ENTRANCE ||
        response.data.status === Status.PROPOSAL ||
        response.data.status === Status.CONTRACT ||
        response.data.status === Status.PEPT ||
        response.data.status === Status.SCHEDULE
      ) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.status === Status.INSPECTION) {
        setEdit(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/home');
    }
    getRooms();
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleAddRoom = async (
    roomType: number,
    roomName: string
  ): Promise<void> => {
    const room: RoomOption | undefined = roomsWithQuantity?.find(
      (room) => room.id === roomType
    );

    const hasRoom = rooms
      .filter((item) => item.name.includes(roomName))
      .map((data) => data.name);

    let name = roomName;

    if (hasRoom.length === 1) {
      name = `${roomName} ${1}`;
    }

    if (hasRoom.length > 1) {
      const roomNumbers = hasRoom.map((data) => Number(data.match(/\d+/g)));
      const numbersArr = roomNumbers.flat();
      const maxId = Math.max(...numbersArr);
      name = `${roomName} ${maxId + 1}`;
    }

    const roomData = {
      inspection_id: inspectionId,
      room_type_id: roomType,
      name,
    };

    try {
      const response = await addRoom(roomData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      if (room) room.quantity += 1;
      getRooms();
      setSnackbarMessage('Cômodo adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteRoom = async (roomType: number): Promise<void> => {
    const roomsByType = rooms.filter((room) => room.room_type_id === roomType);
    const room: RoomOption | undefined = roomsWithQuantity?.find(
      (room) => room.id === roomType
    );

    try {
      const response = await deleteRoom(roomsByType[0].id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      if (room && room.quantity > 0) room.quantity -= 1;
      getRooms();
      setSnackbarMessage('Cômodo deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <SectionBox>
        <Title title={Constants.inspection} />
        <SectionTitle>
          {IconDoorFrontMS}
          {Constants.addRoom}
        </SectionTitle>
        <GalleryContainer>
          {roomsWithQuantity?.map((room: RoomOption) => (
            <RelativeBox key={room.id}>
              <StyledCardMedia image={room.image} title={room.name} />
              <StyledCardText>{room.name}</StyledCardText>
              <AddRoomBox>
                <AddRoomButton
                  onClick={() => handleAddRoom(room.id, room.name)}
                  disabled={!edit}
                >
                  {IconAddMS}
                </AddRoomButton>
              </AddRoomBox>
              {room.quantity > 0 && (
                <QuantityButton onClick={() => handleDeleteRoom(room.id)}>
                  {IconDeleteMS}
                  <p>{room.quantity}</p>
                </QuantityButton>
              )}
            </RelativeBox>
          ))}
        </GalleryContainer>
      </SectionBox>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
