/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ptBr from 'dayjs/locale/pt-br';
import { useContext, useState } from 'react';

import { SelectTextField } from '../../components/CustomInput';
import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import {
  Constants,
  calendarViewOptions,
  months,
  views,
} from '../../constants/agenda';
import { IconCalendarMonthMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import BlockAgendaDialog from './BlockAgenda';
import { Calendar } from './Calendar';
import FilterEngineers from './Engineers';
import {
  CalendaContainer,
  DateText,
  FiltersContainer,
  JustifyEnd,
  LoadingBox,
  StyledTextField,
} from './styles';
import useAgenda from './useAgenda';

export function Agenda(): JSX.Element {
  dayjs.locale(ptBr);
  const weekDay = dayjs().format('dddd');
  const currentDate = dayjs().format('DD [de] MMMM [de] YYYY');

  const [openBlockAgenda, setOpenBlockAgenda] = useState(false);

  const { openSnackbar } = useContext(GlobalContext);
  const {
    handleCheckboxChange,
    usersData,
    blockUsersData,
    engineersData,
    selectedEngineers,
    searchMonth,
    setSearchMonth,
    searchYear,
    setSearchYear,
    calendarView,
    setCalendarView,
    getAgendaEvents,
    engineersIdQuery,
    loading,
    newDate,
    setNewDate,
    updateAgenda,
  } = useAgenda();

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconCalendarMonthMS}
          {Constants.agenda}
        </TitleTypography>
      </TitleBox>
      {loading ? (
        <LoadingBox>
          <LinearProgress />
        </LoadingBox>
      ) : (
        <CalendaContainer>
          <FiltersContainer>
            <DateText>
              {Constants.today}
              {' - '}
              <span>
                {weekDay}
                {', '}
              </span>
              <span>{currentDate}</span>
            </DateText>
            <SelectTextField
              id="month-filter"
              label={Constants.month}
              value={searchMonth}
              setValue={setSearchMonth}
              selectOptions={months}
              allSelectable
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={Constants.year}
                format="YYYY"
                views={['year']}
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                value={searchYear ? dayjs(`${searchYear}-01-01`) : null}
                onChange={(date) => {
                  if (date !== null && typeof date !== 'undefined') {
                    const selectedYear = dayjs(date).year();
                    setSearchYear(selectedYear);
                  }
                }}
              />
            </LocalizationProvider>
            <SelectTextField
              id="calendar-view-filter"
              label={Constants.view}
              value={calendarView}
              setValue={setCalendarView}
              selectOptions={calendarViewOptions}
              allSelectable
            />
            <FilterEngineers
              usersData={usersData}
              handleCheckboxChange={handleCheckboxChange}
              selectedEngineers={selectedEngineers}
            />
          </FiltersContainer>
          <Calendar
            view={views[calendarView]}
            month={searchMonth}
            setMonth={setSearchMonth}
            year={searchYear}
            setYear={setSearchYear}
            engineersData={engineersData}
            callback={getAgendaEvents}
            engineersIdQuery={engineersIdQuery}
            newDate={newDate}
            setNewDate={setNewDate}
            usersData={blockUsersData}
            updateAgenda={updateAgenda}
          />
          <JustifyEnd>
            <BlockAgendaDialog
              usersData={blockUsersData}
              open={openBlockAgenda}
              setOpen={setOpenBlockAgenda}
              updateAgenda={updateAgenda}
            />
          </JustifyEnd>
        </CalendaContainer>
      )}
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}
