import { Grid } from '@mui/material';
import { ChangeEvent, DragEvent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { addPicture } from '../../../api/pictures';
import BackdropLoading from '../../../components/BackdropLoading';
import Snackbar from '../../../components/Snackbar';
import { GridContainer } from '../../../components/UI/Grid';
import {
  IconAddPhotoAlternateMS,
  IconArrowCircleLeftMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants, InspectionTips } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { validateImg } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  BackButton,
  SectionBox,
  SectionTitle,
  StyledDivider,
  TipsBox,
  TipsText,
  TipsTitle,
  UploadBox,
  UploadButton,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export function InspectionAddPhotos(): JSX.Element {
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const { id, inspection, room } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);
  const roomId = parseInt(room as string, 10);

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms`);
  };

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    const formData = new FormData();
    Array.from(files).map((file) => formData.append('pictures', file));
    formData.append('room_id', roomId.toString());
    setLoading(true);
    try {
      const response = await addPicture(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      navigate(
        // eslint-disable-next-line max-len
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${response.data.first_id}`
      );
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !validateImg(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.dataTransfer;

    handleSubmitPhotos(files);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.target;

    handleSubmitPhotos(files);
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <SectionBox>
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.addPhotos}
        </SectionTitle>
        <UploadBox
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
          <UploadText>
            {dragActive ? Constants.dragText : Constants.uploadText}
          </UploadText>
          <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
          <label htmlFor="uploadPictures">
            <input
              accept="image/*"
              multiple
              id="uploadPictures"
              type="file"
              style={{ display: 'none' }}
              onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                handleFileUpload(e)
              }
            />
            {!dragActive && (
              <UploadButton component="span">
                {IconAddPhotoAlternateMS}
                {Constants.selectPhotos}
              </UploadButton>
            )}
          </label>
        </UploadBox>
      </SectionBox>
      <SectionBox>
        <StyledDivider role="presentation">
          {Constants.dividerTitle}
        </StyledDivider>
        <Grid container spacing={2}>
          {InspectionTips.map((tips) => (
            <Grid key={tips.title} item xs={4}>
              <TipsBox>
                <TipsTitle>{tips.title}</TipsTitle>
                <TipsText>{tips.text}</TipsText>
              </TipsBox>
            </Grid>
          ))}
        </Grid>
      </SectionBox>
      <BackdropLoading open={loading} />
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
