import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo-realprice-login.png';
import { IconWarningMS } from '../../constants/icons';
import {
  BoldTypography,
  BoxContainer,
  GridContainer,
  StyledCardMedia,
  StyledIcon,
  StyledTypography,
} from './styles';

export function ErrorPage(): JSX.Element {
  return (
    <GridContainer>
      <StyledCardMedia image={logo} title="logo" />
      <BoxContainer>
        <StyledIcon>{IconWarningMS}</StyledIcon>
        <BoldTypography>Página não encontrada!</BoldTypography>
        <StyledTypography>
          Verifique se o endereço do site está escrito corretamente.
          <br />
          Ou vá para a <NavLink to="/home">página inicial</NavLink> e use as
          opções do menu para navegar para uma seção específica.
        </StyledTypography>
      </BoxContainer>
    </GridContainer>
  );
}
