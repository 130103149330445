import { CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { PatternFormat } from 'react-number-format';

import { PropertyType } from '../../../api/enumerations';
import {
  CustomFormControl,
  CustomTextField,
  NumericTextField,
  PreffixNumericTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import StandardMap from '../../../components/Maps/StandardMap';
import {
  IconAddMS,
  IconAddPhotoAlternateMS,
  IconCloseMS,
  IconFactCheckMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import {
  selectConservation,
  selectStandard,
  selectStandard400K,
} from '../../../constants/selectOptions';
import { formatPhone } from '../../../helpers';
import {
  BoldTypography,
  ButtonBox,
  CancelButton,
  CepStyle,
  CloseIcon,
  CoordContainer,
  FacadeContainer,
  FlexBox,
  InsideBox,
  OpenDialogButton,
  OutlinedButton,
  PhotosContainer,
  SectionBox,
  SectionSubtitle,
  SectionTitle,
  StyledCardMedia,
  StyledDialog,
  StyledText,
  SubmitBox,
  SubmitButton,
  UploadBox,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';
import useAddElement from './useAddElement';

interface AddElementProps {
  isDisabled: boolean;
  osNumber: number;
  updateSample: () => Promise<void>;
  highPrice: boolean | null | undefined;
  propertyType: PropertyType | undefined;
}

export default function AddElement({
  isDisabled,
  osNumber,
  updateSample,
  highPrice,
  propertyType,
}: AddElementProps): JSX.Element {
  const housePropertyType = propertyType === PropertyType.HOUSE;

  const {
    age,
    setAge,
    open,
    handleOpen,
    handleClose,
    handleDrag,
    handleDrop,
    handleFileUpload,
    createElement,
    cep,
    verifyCep,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    addressComplement,
    setAddressComplement,
    city,
    setCity,
    district,
    setDistrict,
    uf,
    setUf,
    privateArea,
    setPrivateArea,
    landArea,
    setLandArea,
    parkingLots,
    setParkingLots,
    sellingPrice,
    setSellingPrice,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    rooms,
    setRooms,
    communityValue,
    setCommunityValue,
    advertiser,
    setAdvertiser,
    advertiserContact,
    setAdvertiserContact,
    propertyLink,
    setPropertyLink,
    searchMap,
    setSearchMap,
    pinPlace,
    setPinPlace,
    checked,
    setChecked,
    facadePicture,
    dragActive,
    pictureFiles,
    submitLoading,
    setIsAddressEdited,
  } = useAddElement(osNumber, updateSample, propertyType);

  return (
    <>
      <OpenDialogButton
        disableTouchRipple
        onClick={handleOpen}
        disabled={isDisabled}
      >
        {IconAddMS}
        {Constants.addElement}
      </OpenDialogButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add element dialog"
      >
        <ButtonBox>
          <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
        </ButtonBox>
        <SectionBox component="form" id="add-element" onSubmit={createElement}>
          <SectionTitle>
            {IconFactCheckMS}
            {Constants.newElement}
          </SectionTitle>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <PatternFormat
                    required
                    format="#####-###"
                    customInput={TextField}
                    id="cep"
                    label="CEP"
                    color="secondary"
                    value={cep}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      verifyCep(e.target.value)
                    }
                    sx={CepStyle}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CustomTextField
                    required
                    id="address"
                    label="logradouro"
                    value={address}
                    setValue={setAddress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    required
                    id="address-number"
                    label="número"
                    value={addressNumber}
                    setValue={setAddressNumber}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CustomTextField
                    id="address-complement"
                    label="complemento"
                    value={addressComplement}
                    setValue={setAddressComplement}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    required
                    id="city"
                    label="cidade"
                    value={city}
                    setValue={setCity}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    required
                    id="district"
                    label="bairro"
                    value={district}
                    setValue={setDistrict}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    required
                    id="uf"
                    label="estado"
                    value={uf}
                    setValue={setUf}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericTextField
                    id="total-area"
                    label={
                      propertyType === PropertyType.APARTMENT
                        ? Constants.privateArea
                        : Constants.constructedArea
                    }
                    suffix=" m²"
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={privateArea}
                    setValue={setPrivateArea}
                  />
                </Grid>
                {housePropertyType && (
                  <Grid item xs={4}>
                    <NumericTextField
                      id="land-area"
                      label={Constants.landArea}
                      suffix=" m²"
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={landArea}
                      setValue={setLandArea}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <NumericTextField
                    id="parking-lots"
                    label="vagas de garagem*"
                    suffix=" vagas"
                    maxLength={9}
                    value={parkingLots}
                    setValue={setParkingLots}
                    required={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PreffixNumericTextField
                    id="selling-price"
                    label="preço de venda*"
                    prefix="R$ "
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={sellingPrice}
                    setValue={setSellingPrice}
                  />
                </Grid>
                <Grid item xs={housePropertyType ? 4 : 8}>
                  <SelectTextField
                    id="conservation-age-estimate"
                    label="estado de conservação do imóvel*"
                    value={conservation}
                    setValue={setConservation}
                    selectOptions={selectConservation()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectTextField
                    id="standard-age-estimate"
                    label="padrão construtivo*"
                    value={buildingStandard}
                    setValue={setBuildingStandard}
                    selectOptions={
                      highPrice ? selectStandard400K() : selectStandard()
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericTextField
                    id="property-age-estimate"
                    label="idade do imóvel*"
                    suffix=" anos"
                    maxLength={9}
                    value={age}
                    setValue={setAge}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericTextField
                    id="rooms"
                    label="quartos*"
                    suffix=" quartos"
                    maxLength={12}
                    value={rooms}
                    setValue={setRooms}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PreffixNumericTextField
                    id="community-value"
                    label="valor do condomínio"
                    prefix="R$ "
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={communityValue}
                    setValue={setCommunityValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    id="advertiser"
                    label="anunciante"
                    value={advertiser}
                    setValue={setAdvertiser}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="advertiser-contact"
                    label="contato anunciante"
                    value={advertiserContact}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setAdvertiserContact(formatPhone(e.target.value))
                    }
                    inputProps={{ maxLength: 15 }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': { borderRadius: '16px' },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    id="property-link"
                    label="link do imóvel"
                    value={propertyLink}
                    setValue={setPropertyLink}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <StandardMap
                searchMap={searchMap}
                address={`${address} ${addressNumber} ${district} ${city}`}
                pinPlace={pinPlace}
                setPinPlace={setPinPlace}
              />
              <CoordContainer>
                <FlexBox>
                  <BoldTypography>{Constants.lat}</BoldTypography>
                  <Typography>{pinPlace.lat}</Typography>
                </FlexBox>
                <FlexBox>
                  <BoldTypography>{Constants.lng}</BoldTypography>
                  <Typography>{pinPlace.lng}</Typography>
                </FlexBox>
              </CoordContainer>
              <OutlinedButton
                onClick={() => {
                  setSearchMap(!searchMap);
                  setIsAddressEdited(false);
                }}
              >
                {Constants.searchMap}
              </OutlinedButton>
            </Grid>
          </Grid>
        </SectionBox>
        <SectionBox>
          <SectionSubtitle>
            {IconPhotoLibraryMS}
            {Constants.addPhotos}
          </SectionSubtitle>
          <PhotosContainer>
            {!facadePicture ? (
              <FacadeContainer>
                <UploadBox
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <label htmlFor="uploadFacadePhotos">
                    <input
                      accept="image/*"
                      multiple
                      id="uploadFacadePhotos"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                        handleFileUpload(e)
                      }
                    />
                    {!dragActive ? (
                      <InsideBox>
                        <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                        <UploadText>{Constants.uploadText}</UploadText>
                        <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                      </InsideBox>
                    ) : (
                      <UploadText>{Constants.dragText}</UploadText>
                    )}
                  </label>
                </UploadBox>
                <StyledText>{Constants.facade}</StyledText>
              </FacadeContainer>
            ) : (
              <UploadBox
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                <label htmlFor="uploadSamplePhotos">
                  <input
                    accept="image/*"
                    multiple
                    id="uploadSamplePhotos"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e)
                    }
                  />
                  {!dragActive ? (
                    <InsideBox>
                      <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                      <UploadText>{Constants.uploadText}</UploadText>
                      <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                    </InsideBox>
                  ) : (
                    <UploadText>{Constants.dragText}</UploadText>
                  )}
                </label>
              </UploadBox>
            )}
            {facadePicture && (
              <StyledCardMedia
                image={URL.createObjectURL(facadePicture)}
                title={facadePicture.name}
              />
            )}
            {pictureFiles?.map((file) => (
              <StyledCardMedia
                key={file.name}
                image={URL.createObjectURL(file)}
                title={file.name}
              />
            ))}
          </PhotosContainer>
        </SectionBox>
        <SectionBox>
          <CustomFormControl
            label={Constants.acceptCheck}
            isChecked={checked}
            setIsChecked={setChecked}
          />
        </SectionBox>
        <SubmitBox>
          <CancelButton onClick={handleClose}>{Constants.cancel}</CancelButton>
          <SubmitButton
            form="add-element"
            type="submit"
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={22} />
            ) : (
              Constants.addElement
            )}
          </SubmitButton>
        </SubmitBox>
      </StyledDialog>
    </>
  );
}
