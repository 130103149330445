import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': { fontSize: '40px', color: '#00B39B' },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '60px 0',
  gap: '40px',
}));

export const SubmitButton = styled(Button)(() => ({
  minWidth: '250px',
  backgroundColor: '#004D43',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const PeptTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  '& span': { color: '#00B39B' },
}));

export const PeptText = styled(Typography)(() => ({
  color: '#ec5353',
}));
