import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  paddingLeft: '3rem',
}));

export const ReportTitle = styled(Typography)(() => ({
  margin: '28px 0',
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
  borderBottom: '2px solid #00B39B',
  width: 'fit-content',
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  margin: '8px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledButton = styled(Button)(() => ({
  margin: '20px',
  marginLeft: '0',
  borderRadius: '10px',
  width: '70%',
  border: '2px solid #00B39B',
  color: '#00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
}));
