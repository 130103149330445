import { useState } from 'react';

import { IconDeleteMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/inspection';
import {
  AlertTypography,
  ButtonBox,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  StyledMenuItem,
  TextTypography,
} from './styles';

interface DeleteRoomDialogProps {
  roomId: number;
  modalCallback: (id: number) => void;
}

export default function DeleteRoomDialog({
  roomId,
  modalCallback,
}: DeleteRoomDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = (): void => {
    modalCallback(roomId);
    handleClose();
  };

  return (
    <>
      <StyledMenuItem disableTouchRipple onClick={handleOpen}>
        {IconDeleteMS}
        {Constants.delete}
      </StyledMenuItem>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete room"
      >
        <DialogContainer>
          <TextTypography>{Constants.deleteRoomText}</TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>
              {Constants.no}
            </OutlinedButton>
            <FilledButton onClick={handleConfirm}>{Constants.yes}</FilledButton>
          </ButtonBox>
          <AlertTypography>{Constants.deleteRoomAlert}</AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
