/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  DragEvent,
  useContext,
  useEffect,
  useState,
} from 'react';

import { PropertyType, StatusCode } from '../../../api/enumerations';
import { addNewElement } from '../../../api/samples';
import { GlobalContext } from '../../../context/global';
import { validateImg } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSearchCep from '../../../hooks/useSearchCep';

type LatLngLiteral = google.maps.LatLngLiteral;

interface UseAddElementHook {
  age: number;
  setAge: (value: number) => void;
  handleOpen: () => void;
  open: boolean;
  handleClose: () => void;
  handleDrag: (e: DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
  createElement: (e: React.FormEvent) => Promise<void>;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  cep: string;
  verifyCep: (tempCep: string) => Promise<void>;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  addressComplement: string;
  setAddressComplement: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  district: string;
  setDistrict: (value: string) => void;
  uf: string;
  setUf: (value: string) => void;
  privateArea: number;
  setPrivateArea: (value: number) => void;
  landArea: number;
  setLandArea: (value: number) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  sellingPrice: number;
  setSellingPrice: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  rooms: number;
  setRooms: (value: number) => void;
  communityValue: number;
  setCommunityValue: (value: number) => void;
  advertiser: string;
  setAdvertiser: (value: string) => void;
  advertiserContact: string;
  setAdvertiserContact: (value: string) => void;
  propertyLink: string;
  setPropertyLink: (value: string) => void;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  checked: boolean;
  setChecked: (value: boolean) => void;
  facadePicture: File | undefined;
  dragActive: boolean;
  pictureFiles: File[] | undefined;
  submitLoading: boolean;
  setIsAddressEdited: (value: boolean) => void;
}

export default function useAddElement(
  osNumber: number,
  updateSample: () => Promise<void>,
  propertyType: PropertyType | undefined
): UseAddElementHook {
  const [open, setOpen] = useState(false);
  const [privateArea, setPrivateArea] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [communityValue, setCommunityValue] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [propertyLink, setPropertyLink] = useState('');
  const [age, setAge] = useState(0);
  const [advertiser, setAdvertiser] = useState('');
  const [advertiserContact, setAdvertiserContact] = useState('');
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [facadePicture, setFacadePicture] = useState<File>();
  const [pictureFiles, setPictureFiles] = useState<File[]>([]);
  const [searchMap, setSearchMap] = useState(true);
  const [checked, setChecked] = useState(false);
  const [acceptedAt, setAcceptedAt] = useState('');
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleSearchCep } = useSearchCep();

  const clearValues = (): void => {
    setPrivateArea(0);
    setLandArea(0);
    setParkingLots(0);
    setRooms(0);
    setCep('');
    setAddress('');
    setAddressNumber('');
    setCity('');
    setDistrict('');
    setCommunityValue(0);
    setSellingPrice(0);
    setPropertyLink('');
    setAge(0);
    setBuildingStandard(0);
    setConservation(0);
    setFacadePicture(undefined);
    setPictureFiles([]);
    setAdvertiser('');
    setAdvertiserContact('');
    setChecked(false);
    setPinPlace({
      lat: -23.56162,
      lng: -46.65591,
    });
  };

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    clearValues();
    setOpen(false);
  };

  const createElement = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (buildingStandard === 0 || conservation === 0) {
      setSnackbarMessage(
        'Estado de conservação e padrão construtivo são obrigatórios'
      );
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (sellingPrice === 0 || privateArea === 0 || rooms === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (propertyType === PropertyType.HOUSE && landArea === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (!checked) {
      setSnackbarMessage('Confirme a responsabilidade pelos dados declarados.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const description = {
      zipcode: cep,
      street: address,
      number: addressNumber,
      address_complement: addressComplement,
      city,
      district,
      state: uf,
      area: privateArea,
      land_area: propertyType === PropertyType.HOUSE ? landArea : null,
      parking_spaces: parkingLots,
      sell_price: sellingPrice,
      bedrooms: rooms,
      condominium_fee: communityValue,
      advertiser,
      advertiser_contact: advertiserContact,
      link: propertyLink,
      latitude: pinPlace.lat,
      longitude: pinPlace.lng,
      level: 1,
      accepted: checked,
      accepted_at: acceptedAt,
    };

    const formData = new FormData();
    formData.append('work_order_id', osNumber.toString());
    formData.append('constructive_standard', buildingStandard.toString());
    formData.append('preservation_state', conservation.toString());
    formData.append('age', age.toString());
    formData.append('description', JSON.stringify(description));

    if (facadePicture) {
      formData.append('facade_picture', facadePicture);
    }
    if (pictureFiles.length > 0) {
      pictureFiles.map((file) => formData.append('pictures', file));
    }

    try {
      setSubmitLoading(true);
      const response = await addNewElement(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Elemento adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      clearValues();
      handleClose();
      updateSample();
      setSubmitLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !validateImg(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.dataTransfer;
    if (!facadePicture) {
      setFacadePicture(files[0]);
    } else {
      setPictureFiles([...pictureFiles, ...Array.from(files)]);
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const { files } = e.target;
    if (!facadePicture) {
      setFacadePicture(files[0]);
    } else {
      setPictureFiles([...pictureFiles, ...Array.from(files)]);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
    setIsAddressEdited(true);
  };

  useEffect(() => {
    if (checked) {
      const dateTime = new Date().toLocaleString('pt-br');
      setAcceptedAt(dateTime);
    }
  }, [checked]);

  return {
    age,
    setAge,
    open,
    handleOpen,
    handleClose,
    handleDrag,
    handleDrop,
    handleFileUpload,
    createElement,
    cep,
    verifyCep,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    addressComplement,
    setAddressComplement,
    city,
    setCity,
    district,
    setDistrict,
    uf,
    setUf,
    privateArea,
    setPrivateArea,
    landArea,
    setLandArea,
    parkingLots,
    setParkingLots,
    sellingPrice,
    setSellingPrice,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    rooms,
    setRooms,
    communityValue,
    setCommunityValue,
    advertiser,
    setAdvertiser,
    advertiserContact,
    setAdvertiserContact,
    propertyLink,
    setPropertyLink,
    searchMap,
    setSearchMap,
    pinPlace,
    setPinPlace,
    checked,
    setChecked,
    facadePicture,
    dragActive,
    pictureFiles,
    submitLoading,
    setIsAddressEdited,
  };
}
