import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  alignItems: 'center',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const OSNumber = styled(Typography)(() => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: '#004D43',
  borderRadius: '10px',
  padding: '4px 16px',
  color: '#FAFCFF',
}));

export const CreatedStyle = styled(Typography)(() => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  border: '2px solid #004D43',
  borderRadius: '10px',
  padding: '4px 16px',
  color: '#004D43',
  fontWeight: '600',
}));
